import React from 'react';
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { FormTitleDiv, SelectBar, CheckBar } from '../../components'
import { Fonts, SelectStyles, Images, Colors } from '../../themes'
import Select from 'react-select'

import { Mutation } from "react-apollo";
import { UPDATE_REFUND } from '../../api';
import emailjs from '@emailjs/browser';

const ContentArea = styled.div`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    display: grid;
    justify-items: center;
    .selectBar{
        max-width: 344px;
        margin: 0 auto;
    }
    .checkBar{
        margin: 0 auto;
        max-width: 250px;
        z-index: 0;
    }
`
const TextArea = styled.div`
    padding: 22px 24px;
    max-width: 480px;
`

const Text = styled(Fonts.P_16())`
    text-align: left;
    .br{
        margin-bottom: 20px;
    }
`
const CheckWrapper = styled.div`
    display: flex;
    align-items: center;
`
const ToggleDiv = styled.div`
    display: flex;
    align-items: center;
    p{
        color: ${Colors.Blue};
        font-weight: 500;
    }
    img{
        filter: invert(0.4) sepia(1) saturate(20) hue-rotate(210deg) brightness(0.8);
    }
    p:hover{
        cursor: default;
    }
    img:hover{
        cursor: pointer;
    }
    img.open{
        transform: scaleY(-1);
        transition: .2s;
    }
`
const ToggleMore = styled.div`
    transition: .5s;
    max-height: 0px;
    overflow: hidden;
    p{
        font-size: 12px;
        line-height: 18px;
    }
    &.open{
        max-height: 100px;
    }
`

const Refund = (props) => {
    const [ check, setCheck ] = React.useState(false);
    const [ open, setOpen ] = React.useState(false)
    const reasons = [
        { value: "0", label: "臨時有事" },
        { value: "1", label: "原時間已有安排" },
        { value: "2", label: "改買其他票種" },
        { value: "3", label: "改變心意不想參加" },
        { value: "4", label: "身體有狀況無法參加" },
        { value: "5", label: "其他原因" },
    ];
    return (
        <Mutation mutation={UPDATE_REFUND(props.userId,props.orderNum)} onError={props.error}>
            {(mutate) => (
            <FormTitleDiv title='辦理退票' close={props.close} Submit={()=>{
                    if(props.orderNum){
                        mutate().then( props.submit )
                        //service_91dfvul
                        //template_2l96nra
                        //bM9SknGUecod32N0W
                        console.log(props.memberName,props.userId,props.orderNum)
                        emailjs.send('service_91dfvul', 'template_2l96nra', {
                            subject: `彩虹數字學員: ${props.memberName} 已對學會送出退費需求!`, 
                            to_email: 'tingyun@mindcraft.com.tw',
                            from_email: 'rainbownumen.service@gmail.com',
                            message: `彩虹數字學員 ${props.memberName} 已對學會送出退費需求!
                            會員名稱: ${props.memberName},
                            UserID: ${props.userId},
                            訂單編號: ${props.orderNum},
                            請盡快處理~謝謝~`,
                        }, 'bM9SknGUecod32N0W')
                    }else{
                        mutate().then( props.error )
                    }
                }} checkClass={check ? '' : 'disable'}>
                <ContentArea>
                    <SelectBar className='full' inputTitle='原因'>
                        <Select 
                            options={reasons} 
                            name='reasons'
                            isSearchable={ false }
                            defaultValue={reasons[0]} 
                            placeholder='請選擇'
                            styles={SelectStyles}
                        />
                    </SelectBar>
                    <TextArea>
                        <Text>我們將會按彩虹數字協會的退票處理辦法進行退票作業</Text>
                        <Text className='br'>有任何問題請洽 <Link to='mailto:service@rainbow.com' target='_blank'>service@rainbow.com</Link></Text>
                        <Text >點擊確認後，票券即無法恢復。</Text>
                        <Text>有部分特殊情形，僅接受整筆訂單申請退票，無法按票券個別申請退票例如:雙人套票</Text>
                    </TextArea>
                    <CheckWrapper className={open ? 'open' : ''}>
                        <CheckBar className='black blue'>
                            <input type="checkbox" name="time" id='time' onChange={()=>setCheck(!check)} />
                            <span htmlFor="time" className='bold'>我已閱讀並同意</span>
                        </CheckBar>
                        <ToggleDiv>
                            <Text>退票處理辦法</Text>
                            <img src={Images.arrow_down} alt='arrow' className={open ? 'open' : ''} onClick={()=>setOpen(!open) } />
                        </ToggleDiv>
                    </CheckWrapper>
                    <ToggleMore className={open ? 'open' : ''}>
                        <Text>完成報名繳費後因個人因素無法上課，依下列標準退費：</Text>
                        {/* <Text>1.開課日8天前因故申請退費，酌收課程原價10%之行政處理費用。</Text>
                        <Text>2.開課日前3-7天申請退費，酌收課程原價30%之行政處理費用。</Text>
                        <Text>3.開課日前3天內，因個人因素無法到課，恕不接受退費申請。</Text> */}
                        <Text>1. 付款後七日內因故申請退費，將全額退回。</Text>
                        <Text>2. 付款後超過七日因故申請退費，將酌收課程原價5%之金流手續費和行政處理費用。</Text>
                        <Text>3. 課程購買後經確認並開通線上課程影片觀看權限者，將不予受理退費申請。</Text>
                    </ToggleMore>
                </ContentArea>
            </FormTitleDiv>
        )}
        </Mutation>
    );
}

export default Refund;