import React from 'react'
import styled from 'styled-components'
import { Colors, Fonts, Images } from '../../themes'

const RadioOption = styled.div`
    width: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    label{
        width: 100%;
        display: inline-block;
        padding: 12px 16px ;
        border: 1px solid ${Colors.B10};
        margin: 0;
        cursor: pointer;
        border-radius: 0.25em;
        background-color: ${Colors.W100};
        transition: 0.8s cubic-bezier(0.2,0.8,0.2,1);
        border-radius: 10px;
        position: relative;
    }   

    input[type="radio"]:hover+label{
        border: 1px solid ${Colors.Blue};
        background-color: ${Colors.Blue05};
        transition: .2s;
        h5,p{
            color: ${Colors.Blue};
        }
    }
    input[type="radio"]:checked+label,
    &.check input[type="radio"]+label{
        border: 1px solid ${Colors.Blue};
        background-color: ${Colors.Blue05};
        transition: .2s;
        h5,p{
            color: ${Colors.Blue};
        }
    }
    ${'' /* .check{
        display: none;
    } */}

    input[type="radio"]:checked+label .no-check,
    &.check input[type="radio"]+label .no-check{
        display: none;
    }

    input[type="radio"]:checked+label .check,
    .check input[type="radio"]+label .check{
        display: block;
    }
    .hidden{
        display: none;
    }
    .two:nth-child(2){
        ${'' /* margin-top: 8px; */}
    }
`

const Title = styled(Fonts.h5())`
    color: ${Colors.G5};
`

const Text = styled(Fonts.P_16())`
    color: ${Colors.G4};
    
`
const UnCheck = styled.img`
    position: absolute;
    top: 12px;
    right: 16px;
`
const CheckIcon = styled.img`
    position: absolute;
    top: 12px;
    right: 16px;
    color: ${Colors.Blue};
`

const RadioArea = (props) => {
    return ( 
        <RadioOption className={`radioBar ${props.className}`} onClick={props.onClick}>
            <input
                type='radio'
                name={props.name}
                id={props.id}
                value={props.value}
                className='hidden'
                onChange={props.onChange}
            />
            <label for={props.id}>
                {props.city &&
                     <>
                        {props.city.map((i,index)=>(
                            <div className='two'>
                                <Title>{i.title}</Title>
                            </div>
                        ))}
                    </>
                }
                {props.time &&
                     <>
                        {props.time.map((i,index)=>(
                            <div className='two'>
                                <Title>{i.title}</Title>
                                <Text >{i.text}</Text>
                            </div>
                        ))}
                    </>
                }
                {props.location &&
                     <>
                        {props.location.map((i,index)=>(
                            <div className='two'>
                                <Title>{i.title}</Title>
                                <Text >{i.text}</Text>
                            </div>
                        ))}
                    </>
                }
                {!props.time && !props.city && !props.location &&
                     <>
                        <Title>{props.title}</Title>
                        <Text>{props.text}</Text>
                    </>
                }
                
                <CheckIcon className='check' src={Images.check_circle} alt='check' />
                <UnCheck className='no-check' src={Images.radio_n} alt='no-check'/>
            </label>
        </RadioOption>
    )
}
  
export default RadioArea