import React from 'react'
import styled from 'styled-components'
import { Colors, Fonts, Images } from '../../themes'

const RadioOption = styled.div`
    width: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    margin-bottom: 8px;
    label{
        width: 100%;
        display: inline-block;
        padding: 12px 16px ;
        border: 1px solid ${Colors.B10};
        margin: 0;
        cursor: pointer;
        border-radius: 0.25em;
        background-color: ${Colors.W100};
        transition: 0.8s cubic-bezier(0.2,0.8,0.2,1);
        border-radius: 10px;
        position: relative;
    }   

    input[type="radio"]:hover+label{
        border: 1px solid ${Colors.Blue};
        background-color: ${Colors.Blue05};
        transition: .2s;
        h5,p{
            color: ${Colors.Blue};
        }
    }

    input[type="radio"]:checked+label{
        border: 1px solid ${Colors.Blue};
        background-color: ${Colors.Blue05};
        transition: .2s;
        h5,p{
            color: ${Colors.Blue};
        }
    }
    .check{
        display: none;
    }
    input[type="radio"]:checked+label .no-check{
        display: none;
    }
    input[type="radio"]:checked+label .check{
        display: block;
    }
    .hidden{
        display: none;
    }
    input[type="radio"]+label .show{
        max-height: 0;
        margin-top: 0; 
        border-top: 0px;
        padding-top: 0px;
    }
    input[type="radio"]:checked+label .show{
        max-height: 500px;
        transition: max-height 2s;
        margin-top: 16px;
        border-top: 1px solid ${Colors.B10};
        padding-top: 8px;
    }
`

const Title = styled(Fonts.h5())`
    color: ${Colors.G5};
    margin-right: 16px;
`

const Text = styled(Fonts.P_16())`
    color: ${Colors.G2};
    .br{
        padding-bottom: 16px;
    }
    .bold{
        font-weight: 600
    }
`
const UnCheck = styled.img`
    position: absolute;
    top: 16px;
    right: 16px;
`
const CheckIcon = styled.img`
    position: absolute;
    top: 16px;
    right: 16px;
    color: ${Colors.Blue};
`
const IconArea = styled.div`
    width:32px;
    height:32px;
    border-radius: 3.2px;
    margin-right: 16px;
    img{
        width: 100%;
        height: auto;
    }
`
const Label = styled.div`
    display: flex;
    align-items: center;
`
const LabelDetail = styled.div`
    ${'' /* margin-top: 16px;
    border-top: 1px solid ${Colors.B10};
    padding-top: 8px; */}
    overflow: hidden;
`

const PayRadio = (props) => {
    return ( 
        <RadioOption className='radioBar' onClick={props.onClick}>
            <input
                type='radio'
                name={props.name}
                id={props.id}
                value={props.value}
                className='hidden'
                onChange={props.onChange}
            />
            <label for={props.id}>
                <Label>
                    <IconArea>
                        <img src={props.img} alt='payment-icon'/>
                    </IconArea>
                    <Title>{props.title}</Title>
                    <Text>{props.text}</Text>
                </Label>
                <CheckIcon className='check' src={Images.check_circle} alt='check' />
                <UnCheck className='no-check' src={Images.radio_n} alt='no-check'/>
                <LabelDetail className='show'>
                    <Text className='bold'>注意事項</Text>
                    <Text className='br'>每筆消費享 2% 街口幣回饋無上限，街口幣可折抵消費，1 點折 1 元。</Text>
                    <Text>目前僅支援「街口帳戶」餘額付款及連結「銀行帳戶」扣款，使用前請先將街口支付 App 更新至最新版，並開通街口帳戶支付。</Text>
                </LabelDetail>
            </label>
        </RadioOption>
    )
}
  
export default PayRadio