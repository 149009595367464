import React from 'react';
import styled from 'styled-components'
import { Fonts, Metrics, Colors} from '../../themes'

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    z-index: 102;
    padding: 40px 60px;
    @media (max-width: ${Metrics.mobile}){
        height: 100%;
        padding: 8px 20px;
    }
`
const Content = styled.div`
    @media (max-width: ${Metrics.mobile}){
        position: relative;
        top: 50%;
        transform: translateY(-50%)
    }
`
const TextArea = styled.div`
    position: relative;
    padding: 24px;
    z-index: 1;
    @media (max-width: ${Metrics.mobile}){
        padding: 24px 0;
    }
`
const Title = styled(Fonts.h3())`
    margin: 0;
    text-align: center;
    padding-bottom: 8px;
    color: ${Colors.B100};
    
`
const Text = styled(Fonts.P_16())`
    text-align: center;
    color: ${Colors.B100};
    a{
        padding-left: 8px;
        word-wrap: break-word;
        color: ${Colors.Blue};
    }
`

const EventModal_2 = (props) => {
    return (
        <Wrapper>
            <Content>
                <TextArea>
                    <Title>時間解碼</Title>
                    <Text>您的輪迴劇本為何? 是外星人?</Text>
                    <Text>還是地球老靈魂? 下一世會回地球投胎? </Text>
                    <Text>還是提升頻率到不同次元界?</Text>
                    <Text>「時間解碼」是彩虹數字學的進階課程，旨在解析「生命--時間」的運行規則。</Text>
                    <Text>透過「時間解碼」多種運算模式之學習，得以清楚洞察生命密碼中的程式設計規則（所謂的「命被注定」），俯瞰生命輪迴運轉軌跡，理解生命劇本是如何的被編排、設計與推動。</Text>
                    <Text>最重要的是，學習如何依照自由意識來掌握「生命--時間」運行規則中的每一個機緣點，切斷輪迴、揚昇自我，改變過去、未來和現在 （所謂的「運可以改」），做自己生命的主人。</Text>
                </TextArea>
            </Content>
        </Wrapper>
    );
}

export default EventModal_2;