import React from 'react';
import {
    Seo, Layout, TitleArea, TitleOnlyArea, CardEvent,
    FlexFree, CellNote, EventModal1, EventModal2, EventModal3,
    EventModal4, EventModal5
} from '../components'
import styled from 'styled-components'
import { Images, Colors, Fonts } from '../themes'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Query } from "react-apollo";
import { useHistory } from "react-router-dom";
import Modal from 'react-modal'
import { GET_LIST } from '../api';
var moment = require('moment');

const TextArea = styled.div`
`
const Text = styled(Fonts.P_16())`
    color:${Colors.G3};
    white-space: pre-wrap;
    &.br{
        padding-bottom: 20px;
    }
    text-align: center;
`
const TabView = styled.div`
    padding: 24px 16px 40px;
`

const CardBottom = styled.div`
    height: 16px;
`
const Close = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 10000;
    width: 24px;
    height: 24px;
    &:hover{
        cursor: pointer;
    }
`
const customStyles = {
    overlay: {
        backgroundColor: "rgba(0,0,0,0.5)",
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '16px',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
        zIndex: '1000',
    }
};
const EventPage = (props) => {
    const id = props.match?.params?.id;
    const [value, setValue] = React.useState(Number(id));
    const history = useHistory();
    const handleChange = (event, newValue) => {
        setValue(newValue);
        history.push(`${newValue}`)
    };
    // 心性解碼
    const [modalHeart, setModalHeart] = React.useState(false);
    function openHeart() {
        setModalHeart(true);
    }
    function closeHeart() {
        setModalHeart(false);
    }
    // 時間解碼
    const [modalTime, setModalTime] = React.useState(false);
    function openTime() {
        setModalTime(true);
    }
    function closeTime() {
        setModalTime(false);
    }
    // 感情解碼
    const [modalEmotion, setModalEmotion] = React.useState(false);
    function openEmotion() {
        setModalEmotion(true);
    }
    function closeEmotion() {
        setModalEmotion(false);
    }
    //單元主題
    const [modalUnit, setModalUnit] = React.useState(false);
    function openUnit() {
        setModalUnit(true);
    }
    function closeUnit() {
        setModalUnit(false);
    }
    //案例分析
    const [modalCase, setModalCase] = React.useState(false);
    function openCase() {
        setModalCase(true);
    }
    function closeCase() {
        setModalCase(false);
    }
    return (
        <Query query={GET_LIST}>
            {({ loading, error, data, refetch }) => {
                if (loading) return <p></p>;
                if (error) return <p>error</p>;
                /*
                const DefaultList = data.event.filter(i=>i.category==='核心基礎課程').filter(i => !i.code.includes('-0'))
                const DeepList = data.event.filter(i=>i.category==='深度解碼課程')
                const UnitList = data.event.filter(i=>i.category==='單元主題課程' || i.category==='案例研析課程' )  
                const BasicList = data.event.filter(i=>i.category==='核心基礎課程'&&i.code.includes('-0'))
                const TeaList = data.event.filter(i=>i.category==='其他活動')
                
                const DefaultList = data.event.filter(i => i.type === '心性解碼').filter(i => i.status === '已上架')
                const DeepList = data.event.filter(i => i.type === '時間解碼').filter(i => i.status === '已上架')
                const BasicList = data.event.filter(i => i.type === '感情解碼').filter(i => i.status === '已上架')
                const UnitList = data.event.filter(i => i.type.includes('單元')).filter(i => i.status === '已上架')
                const TeaList = data.event.filter(i => i.type.includes('其他')).filter(i => i.status === '已上架')
                
                */
                //const DefaultList = data.event.filter(i => i.category.includes('入門')).filter(i => i.status === '已上架')
                const DefaultList = data.event.filter(i => i.category.includes('活動講座')).filter(i => i.status === '已上架')
                const DeepList = data.event.filter(i => i.category.includes('進階')).filter(i => i.status === '已上架')
                const BasicList = data.event.filter(i => i.category.includes('講師')).filter(i => i.status === '已上架')
                const UnitList = data.event.filter(i => i.category.includes('單元')).filter(i => i.status === '已上架')
                const TeaList = data.event.filter(i => i.category.includes('其他')).filter(i => i.status === '已上架')
                return (
                    <Layout event>
                        <Seo
                            title='彩虹數字學會 - 探索課程'
                            metaDescription='運用彩虹數字解讀你的生命密碼，了解自己的生命課題。學習「心性解碼」、「時間解碼」、「感情解碼」課程，免費試算自己的數字。'
                            metaTitle='彩虹數字學會 - 探索課程'
                            metaUrl='https://www.rainbownumen.org/event/0'
                            //metaImage={`${Images.Rainbow_SEO}`}
                            metaImage='https://rainbow-digital.glitch.me/i/ktlc6ygb.png'
                        />
                        <TitleArea
                            title='彩虹數字學課程'
                        //text='彩虹數字學課程分為三階段：「心性解碼」用以解讀人生藍圖與特質潛能，「時間解碼」用以掌握時機及探索前世今生，「感情解碼」用以剖析人與人之間的緣份與相處模式。
                        //建議初學者循序漸進由入門系列修到核心系列；已有彩虹數字基礎者，可考慮直接修核心課程。'
                        />
                        <TextArea>


                            <Text >
                                彩虹數字學課程分為三階段：「心性解碼」用以解讀人生藍圖與特質潛能，
                            </Text>
                            <Text >
                                「時間解碼」用以掌握時機及探索前世今生，「感情解碼」用以剖析人與人之間的緣份與相處模式。
                            </Text>


                        </TextArea>


                        <Tabs
                            value={value}
                            className='EventTab'
                            onChange={handleChange}
                        >
                            
                            <Tab label='核心系列' />
                            <Tab label='諮詢師證照班' />
                            <Tab label='單元主題' />
                            <Tab label='活動講座' />
                            {/* <Tab label='其他活動' /> */}
                        </Tabs>
                        {value === 3 &&
                            <TabView>
                                <CellNote
                                    text='活動講座介紹：透過短講或主題活動，體驗解碼生命QRcode讓生活大小事更順心。'
                                />
                                <TitleOnlyArea title='活動講座' //onClick={openHeart} 
                                />
                                <FlexFree>
                                    {DefaultList.map((i, index) => (
                                        <CardEvent
                                            onClick={() => history.push(`/eventDetailPack/${i.id}`)}
                                            img={require(`../img/Card/${i.cover}.svg`)}
                                            title={i.title}
                                            text={i.excerpt}
                                            price={moment(i.sessions[0].session_start_time).format("YYYY/MM/DD")}
                                            required={i.required}
                                        />
                                    ))}
                                </FlexFree>
                                <CardBottom />
                            </TabView>
                        }
                        {value === 0 &&
                            <TabView>
                                <CellNote
                                    text='核心系列介紹：以40小時扎實學習三階段課程，養成完整強大的解析能力，幫助自己與他人。'
                                />
                                <TitleOnlyArea title='核心系列' //onClick={openTime} 
                                />
                                <FlexFree>
                                    {DeepList.map((i, index) => (
                                        <CardEvent
                                            onClick={() => history.push(`/eventDetail/${i.id}`)}
                                            img={require(`../img/Card/${i.cover}.svg`)}
                                            title={i.title}
                                            text={i.excerpt}
                                            price={moment(i.sessions[0].session_start_time).format("YYYY/MM/DD")}
                                            required={i.required}
                                        />
                                    ))}
                                </FlexFree>
                                <CardBottom />

                            </TabView>
                        }
                        {value === 1 &&
                            <TabView>
                                <CellNote
                                    //text='師資培訓課程是學習「彩虹數字」或有志成為「彩虹數字諮詢師」所必學的課程。由於課程具有連貫性，初學者須依照「入門班」、「進階班」、「講師班」循序漸進探索。'
                                    //text='敬啟期待'
                                    text='本課程資格限定上完「彩虹數字核心課程」之學員。透過田老師所實際帶領、演練之20小時的「案例研習訓練」精實課程，能有效解答學員們所遇到個案的種種疑難雜症。獲頒本證照之學員即可成為中華民國彩虹數字學會所認可之諮詢師。'
                                />
                                <TitleOnlyArea title='諮詢師證照班' //onClick={openEmotion} 
                                />
                                <FlexFree>
                                    {BasicList.map((i, index) => (
                                        <CardEvent
                                            onClick={() => history.push(`/eventDetail/${i.id}`)}
                                            img={require(`../img/Card/${i.cover}.svg`)}
                                            title={i.title}
                                            text={i.excerpt}
                                            price={moment(i.sessions[0].session_start_time).format("YYYY/MM/DD")}
                                            required={i.required}
                                        />
                                    ))}
                                </FlexFree>

                            </TabView>
                        }
                        {value === 2 &&
                            <TabView>
                                <CellNote
                                    text='曾修習「心性解碼」課程者皆可報名單元主題課程。'
                                />
                                <TitleOnlyArea title='單元主題' onClick={openUnit} noti='no-noti' />
                                <FlexFree>
                                    {UnitList.map((i, index) => (
                                        <CardEvent
                                            onClick={() => history.push(`/eventDetail/${i.id}`)}
                                            img={require(`../img/Card/${i.cover}.svg`)}
                                            title={i.title}
                                            text={i.excerpt}
                                            price={moment(i.sessions[0].session_start_time).format("YYYY/MM/DD")}
                                            required={i.required}
                                        />
                                    ))}
                                </FlexFree>
                                {/*
                                <TitleOnlyArea title='案例分析' onClick={openCase} noti='no-noti' />
                                <FlexFree>
                                    {UnitList.filter(i => i.category === '案例研析課程').map((i, index) => (
                                        <CardEvent
                                            onClick={() => history.push(`/eventDetail/${i.id}`)}
                                            img={require(`../img/Card/${i.cover}.svg`)}
                                            title={i.title}
                                            text={i.excerpt}
                                            price={moment(i.sessions[0].session_start_time).format("YYYY/MM/DD")}
                                            required={i.required}
                                        />
                                    ))}
                                </FlexFree>
                                    */}
                            </TabView>
                        }
                        {value === 4 &&
                            <TabView>
                                <TitleOnlyArea title='其他活動' noti='no-noti' />
                                <FlexFree>
                                    {TeaList.map((i, index) => (
                                        <CardEvent
                                            onClick={() => history.push(`/eventDetail/${i.id}`)}
                                            img={require(`../img/Card/${i.cover}.svg`)}
                                            title={i.title}
                                            text={i.excerpt}
                                            price={moment(i.sessions[0].session_start_time).format("YYYY/MM/DD")}
                                            required={i.required}
                                        />
                                    ))}
                                </FlexFree>
                            </TabView>
                        }
                        <Modal
                            isOpen={modalHeart}
                            onRequestClose={closeHeart}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <Close onClick={closeHeart}>
                                <img src={Images.close} alt='' />
                            </Close>
                            <EventModal1 />
                        </Modal>
                        <Modal
                            isOpen={modalTime}
                            onRequestClose={closeTime}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <Close onClick={closeTime}>
                                <img src={Images.close} alt='' />
                            </Close>
                            <EventModal2 />
                        </Modal>
                        <Modal
                            isOpen={modalEmotion}
                            onRequestClose={closeEmotion}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <Close onClick={closeEmotion}>
                                <img src={Images.close} alt='' />
                            </Close>
                            <EventModal3 />
                        </Modal>
                        <Modal
                            isOpen={modalUnit}
                            onRequestClose={closeUnit}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <Close onClick={closeUnit}>
                                <img src={Images.close} alt='' />
                            </Close>
                            <EventModal4 />
                        </Modal>
                        <Modal
                            isOpen={modalCase}
                            onRequestClose={closeCase}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <Close onClick={closeCase}>
                                <img src={Images.close} alt='' />
                            </Close>
                            <EventModal5 />
                        </Modal>
                    </Layout>
                );
            }}
        </Query>
    );
}

export default EventPage;
