import React from 'react';
import styled from 'styled-components'
import { Fonts, Colors, Images } from '../../themes'

const Wrapper = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 36px auto;
    padding: 16px 20px 16px 16px;
    border: 1px solid ${Colors.Yellow};
    background: ${Colors.Light_Yellow};
    margin: 0 auto;
    border-radius: 8px;
    max-width: 1100px;
    &.no-show{
        display: none;
    }
    @media (max-width: 1000px){
        flex-wrap: wrap;
        margin: 0 auto 15px;
    }
`
const Icon = styled.img`
    filter: invert(0.4) sepia(1) saturate(17.4) hue-rotate(0deg) brightness(0.96);
`

const TextArea = styled.div``

const Text = styled(Fonts.P_20())`
`

const Close = styled.div`
    position: absolute;
    top: 4px;
    right: 8px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
    transition: .2s;
    &:hover{
        cursor: pointer;
        opacity: 1;
    }
`

const CellNote = ( props ) => {
    const [close,setClose] = React.useState(false)
    return (
        <Wrapper className={close ? 'no-show' : ''}>
            <Icon src={Images.notice} alt='info-icon'/>
            <TextArea>
                {props.text.split('\\n').map(item => (
                    <Text>{item === '' ? <br/> : item}</Text>
                ))}
            </TextArea>
            <Close onClick={()=>setClose(true)}>
                <img src={Images.close} alt='' />
            </Close>
        </Wrapper>
    );
}

export default CellNote;