import React from 'react';
import styled from 'styled-components'
import { Metrics } from '../../themes'


const Wrapper = styled.div`
    max-width: 880px;
    margin: 0 auto 24px;
    display: grid;
    grid-template-columns: repeat(4, 4fr);
    grid-column-gap: 0;
    grid-row-gap: 0;

    @media (max-width: ${Metrics.tablet}) {
        grid-template-columns: repeat(2, 2fr);
        align-self: center;
    }

`

const GridCol4 = (props) => {
    const { children } = props
    return (
        <Wrapper>
            { children }
        </Wrapper>
    );
}

export default GridCol4;