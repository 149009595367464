import React from 'react';
import styled from 'styled-components'

const Wrapper = styled.div`
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
`

const FlexFree = (props) => {
    const { children } = props
    return (
        <Wrapper>
            { children }
        </Wrapper>
    );
}

export default FlexFree;