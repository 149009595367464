import React from 'react';
import styled from 'styled-components'
import { Colors, Fonts, Styles } from '../../themes'
// import { BtnL } from '../../components'
import YouTube from 'react-youtube';
const Wrapper = styled.div`
    position: relative;
    max-width: 1080px;
    height: 360px;
    border-radius: 20px;
    margin: 0 16px;
    overflow: hidden;
    &.center{
        margin: 0 auto;
        img{
            width: 100%;
        }
    }
    img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: auto;
        height: 100%;
        max-width: 1080px;
    }
    &.sh-img{
        img{
            width: 100%;
            height: auto;
        }
    }
    @media (max-width: 820px){
        &.center,&.sh-img{
            img{
                width: auto;
                height: 100%;
            }
        }
    }
`

const TopLayer = styled(Styles.toplayer())`
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    padding: 68px auto;
`

const TitleArea = styled.div`
    margin-bottom: 16px;
    padding: 0 16px;
`

const Title = styled(Fonts.h2())`
    text-align: center;
    color: ${Colors.W100};
    text-shadow: 0px 2px 4px ${Colors.B50};
`

const Text = styled(Fonts.h5())`
    text-align: center;
    color: ${Colors.W100};
    padding: 0 16px;
    text-shadow: 0px 2px 4px ${Colors.B50};
`

const BtnL = styled(Styles.btnL())`
    margin: 16px auto;
`


const CardBannerY = (props) => {
    const videoId = 'v1jasWORV-w';

    // YouTube 视频的播放器参数
    const opts = {
        //height: '500',
        width: '100%',
        playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
        },
    };

    const onReady = (event) => {
        // 访问播放器实例 (event.target)。可以执行一些操作。
        event.target.pauseVideo();
    };
    return (
        <Wrapper className={props.className}>
            <YouTube
                videoId={videoId}
                opts={opts}
                onReady={onReady}
            />
        </Wrapper>
    );
}

export default CardBannerY;