import React from 'react';
import styled from 'styled-components'
import { Colors, Fonts, Metrics } from '../../themes'

// import { Link } from 'react-router-dom'

const Wrapper = styled.div`
    box-sizing: border-box;
    max-width: 242px;
    margin-left: auto;
    margin-right: auto;
`
const Img = styled.img`
    display: block;
    margin-left: auto;
    margin-right: auto;
`

const Title = styled(Fonts.h5())`
    margin-top: 8px;
    text-align: center;
`

const Text = styled(Fonts.P_16())`
    margin-top: 8px;
    text-align: center;
    color: ${Colors.G3};
    max-width: 145px;
    margin-left: auto;
    margin-right: auto;


    @media (max-width: ${Metrics.tablet}) {
        max-width: 240px;
        padding: 0 16px;
      }
`


const IconCard = (props) => {
    return (
        <Wrapper>
            <Img src={props.img} alt=''/>
            <Title>{props.title}</Title>
            <Text>{props.text}</Text>
        </Wrapper>
    );
}

export default IconCard;