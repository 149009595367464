import React from 'react';
import styled from 'styled-components'
import { Fonts, Colors } from '../../themes'

const Wrapper = styled.div`
    background: white;
    border-radius: 16px;
    box-shadow: 0px 4px 8px 0 rgba(0,0,0,0.1);
    width: 100%;
    max-width: 704px;
    height: min-content;
    overflow: hidden;
`
const TitleArea = styled.div`
    padding: 24px 16px 15px;
    border-bottom: 1px solid ${Colors.B10};
`
const BigTitle = styled(Fonts.h4())`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: center;
`
const TextArea = styled.div`
    padding: 0px 24px 28px;
`


const CardArticleWithTitle = (props) => {
    const {children} = props
    return (
        <Wrapper>
            <TitleArea>
                <BigTitle>{props.bigTitle}</BigTitle>
            </TitleArea>
            <TextArea>
                {children}
            </TextArea>
        </Wrapper>

    );
}

export default CardArticleWithTitle;