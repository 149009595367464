import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { CheckCourseModal } from '../components'
import { Colors, Fonts, Images, Metrics, Styles } from '../themes'
import { Query } from "react-apollo";
import { useHistory } from "react-router-dom";
import { GET_MEMBER } from '../api';
import Cookies from 'js-cookie';
import Modal from 'react-modal'

const Close = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 10000;
    width: 24px;
    height: 24px;
    &:hover{
        cursor: pointer;
    }
`
const customStyles = {
    overlay:{
        backgroundColor: "rgba(0,0,0,0.5)",
        pointerEvents: 'none',
    },
    content : {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '16px',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
        zIndex: '1000',
        pointerEvents: 'auto',
        background: '#FFF'
    }
}

const Wrapper = styled.div`
    width: 100%;
    position: fixed;
    z-index: 101;
    height: 100px;
    background: ${Colors.BackGround};
    transition: .3s;
    padding: 0 20px;
    &.HeaderScrolled{
        background: ${Colors.W100};
        box-shadow: 0 2px 4px 0 ${Colors.B10};
    }
    @media (max-width: 700px){
       height: 75px;
       transition: .4s;
       &.open{
         background: ${Colors.W100};
       }
       &.open.HeaderScrolled{
         box-shadow: inherit;
       }
    }
    @media (max-width: ${Metrics.mobile}){
        height: 55px;
        padding: 8px 20px;
    }
`

const Content = styled.div`
    display: flex;
    max-width: 1080px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
`

const Logo = styled.img`
    height: 100px;
    width: auto;
    @media (max-width: 700px){
       height: 75px;
    }
    @media (max-width: ${Metrics.mobile}){
        height: 44px;
    }
`

const LinkAreaFull = styled.div`
    @media (max-width: 700px){
        position: fixed;
        top: 75px;
        max-height: 0;
        overflow: hidden;
        transition: .4s;
        z-index: 1000;
        background: ${Colors.W100};
        height: 0;
        width: 100%;
        &.open{
            height: 100%;
            max-height: 4000px;
            transition: .4s;
        }
    }
    @media (max-width: ${Metrics.mobile}){
        top: 44px;
        left: 0px;
    }
`

const LinkArea = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    a{
        margin: 0 16px;
    }
    .active h6{
        color: ${Colors.B100};
        font-weight: 700;
    }
    @media (max-width: 700px){
        display: grid;
        padding: 16px 14px;
    }
`

const Text = styled(Fonts.h6())`
    color: ${Colors.G3};
    &:hover {
        color: ${Colors.Blue};
        transition: 0.4s cubic-bezier(0.2,0.8,0.2,1);
    }
    @media (max-width: 700px){
        border-bottom: 1px solid ${Colors.B10};
        padding: 12px 0px;
        font-size: 16px;
    }
`

const Btn = styled(Styles.btnS())`
    margin: 0 16px;
    @media (max-width: 700px){
        margin-top: 40px;
    }
`

const Menu = styled.img`
    left: 30px;
    width: 30px;
    height: 30px;
    display: none;
    @media (max-width: 700px){
       display: block;
    }
`

const UserArea = styled.div`
    display: flex;
    background: rgba(17,19,20,0.05);
    align-items: center;
    border-radius: 17px;
    height: 34px;
    margin-left: 16px;
    &:hover{
        cursor: pointer;
    }
    @media (max-width: ${Metrics.tablet}){
        background: ${Colors.W100};
        height: 65px;
        border-bottom: 1px solid rgba(17,19,20,0.10);
        border-radius: 0px;
    }
`
const UserImg = styled.div`
    height: 30px;
    width: 30px;
    overflow: hidden;
    border-radius: 100%;
    img{
        width: 30px;
    }
    @media (max-width: ${Metrics.tablet}){
        background: ${Colors.W100};
        height: 40px;
        width: 40px;
        img{
            width: 40px;
        }
    }
`
const UserInfo = styled(Fonts.h6())`
    padding: 6px 12px 6px 9px;
    @media (max-width: ${Metrics.tablet}){
        font-size: 20px;
    }
`
const Header = (props) => {
    const history = useHistory();
    const [open, setOpen] = React.useState(false);
    const [ hasScrolled, setScrolled ] = React.useState(false);
    const [ user, setUser ] = React.useState();
    const [ userId, setUserId ] = React.useState();
    const [ refresh, setRefresh ] = React.useState(true);
    
    const [ width, setWidth ] = useState(window.innerWidth);
    const updateWidthAndHeight = () => {
        setWidth(window.innerWidth);
    };

    //ModalCheckCourse
    const [ CourseIsOpen,setCourseIsOpen ] = React.useState(false);
    function openCourse() {
        setCourseIsOpen(true);
    }
    function closeCourse(){
        setCourseIsOpen(false);
    }

    let accessToken;
    
    async function fetchUser() {
        const res = await fetch(process.env.REACT_APP_BRANCH === 'master' ? `https://rainbow-numen.us.auth0.com/userinfo` : `https://rainbow-numen-dev.us.auth0.com/userinfo`,{
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
        res
            .json()
            .then(res => {
                setUser(res)
                setUserId(res.sub)
                Cookies.set('rainbow', `${res.sub}`, { expires: 1, path: '/' });
            })
    }
      
    React.useEffect(() => {
        if(window.location.href.split('#')[1] && window.location.href.split('#')[1]!=='media' && window.location.href.split('#')[1]!=='map'){
            accessToken = window.location.href.split('#')[1].split('&')[0].split('=')[1]
            if(!user){
                fetchUser()
            }else{
                setUserId(user.sub)
                Cookies.set('rainbow', `${user.sub}`, { expires: 1, path: '/' });
            }
            if(Cookies.get('rainbow')){
                if(refresh){
                    window.location.href=window.location.href.split('#')[0];
                    setRefresh(false)
                } 
            }
        }else{
            if(Cookies.get('rainbow')){
                setUserId(Cookies.get('rainbow'))
            }
            
        }
        if(userId){
            Cookies.set('rainbow', `${Cookies.get('rainbow')}`, { expires: 1, path: '/' });
        }
    },[])

    if(userId){
        if(Cookies.get('rainbow_return')){
            let retrun = Cookies.get('rainbow_return')
            Cookies.remove('rainbow_return')
            window.location.href=retrun
            setRefresh(false)
        }
    }

    React.useEffect(() => {
        const headerScrollEvent = window.addEventListener('scroll', e =>
          setScrolled(window.pageYOffset > 50 && window.innerWidth > 425)
        )
        return () => {
          headerScrollEvent && window.removeEventListener(headerScrollEvent)
        }
    })

    window.addEventListener("resize", updateWidthAndHeight);

    return (
        <Wrapper className={`${hasScrolled ? 'HeaderScrolled' : ''} ${open ? 'open' : '' }`}>
            <Content>
            <Link to='/'>
                <Logo src={Images.Logo_Header} />
            </Link>
            <Menu src={open ? Images.close : Images.menu } onClick={()=>setOpen(!open)} />
            <LinkAreaFull className={open ? 'open' : '' }>
                <LinkArea className={open ? 'open' : '' }>
                    {width<768 && userId &&
                        <Query query={GET_MEMBER(userId)}>
                            {({ loading, error, data, refetch }) => {
                                if(data){
                                    if(data.member[0].role==='舊生'){
                                        closeCourse()
                                    }else if(data.member[0].role==='新生'){
                                        closeCourse()
                                    }else{
                                        openCourse()
                                    }
                                }
                                return (
                                    <>
                                    {data &&
                                        <UserArea onClick={()=>history.push('/account/0')}>
                                            {data.member.length > 0 &&
                                                <>
                                                <UserImg>
                                                    <img src={data.member[0].picture} alt='user-img' />
                                                </UserImg>
                                                <UserInfo>{data.member[0].name}</UserInfo>
                                                {/* <UserInfo>{data.member[0].name} Lv.{data.member[0].score === null ? '0' : data.member[0].score}</UserInfo> */}
                                                </>
                                            }
                                        </UserArea>
                                    }
                                    </>
                                );
                            }}
                        </Query>
                    }
                    <Link to='/compute/0' className={props.compute ? 'active' : ''}>
                        <Text>數字計算機</Text>
                    </Link>
                    <Link to='/event/0' className={props.event ? 'active' : ''}>
                        <Text>報名課程</Text>
                    </Link>
                    <Link to='/news/1' className={props.news ? 'active' : ''}>
                        <Text>最新消息</Text>
                    </Link>
                    <Link to='/learn/0' className={props.learn ? 'active' : ''}>
                        <Text>認識彩虹數字</Text>
                    </Link>
                    <Link to='/reservation' className={props.reservation ? 'active' : ''}>
                        <Text>預約諮詢</Text>
                    </Link>
                    <Link to='/team' className={props.team ? 'active' : ''}>
                        <Text>學會介紹</Text>
                    </Link>
                    {!userId && !user && !sessionStorage.getItem('userId') 
                        ? <Btn onClick={props.onClick}>登入</Btn>
                        : <Query query={GET_MEMBER(userId)}>
                            {({ loading, error, data, refetch }) => {
                                if(data){
                                    if(data.member[0].role==='舊生' || data.member[0].role==='忘記' || data.member[0].role==='新生'){
                                        closeCourse()
                                    }else{
                                        openCourse()
                                    }
                                }
                                return (
                                    <>
                                    {data && width > 768 &&
                                        <UserArea onClick={()=>history.push('/account/0')}>
                                            {data.member.length > 0 &&
                                                <>
                                                <UserImg>
                                                    <img src={data.member[0].picture} alt='user-img' />
                                                </UserImg>
                                                <UserInfo>{data.member[0].name}</UserInfo>
                                                {/* <UserInfo>{data.member[0].name} Lv.{data.member[0].score === null ? '0' : data.member[0].score}</UserInfo> */}
                                                </>
                                            }
                                        </UserArea>
                                    }
                                    </>
                                );
                            }}
                        </Query>
                    }
                </LinkArea>
            </LinkAreaFull>
            </Content>
            {/*
            <Modal
                isOpen={CourseIsOpen}
                onRequestClose={closeCourse}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <CheckCourseModal />
                <Close onClick={closeCourse}>
                    <img src={Images.close} alt='' />
                </Close>
            </Modal>*/}
        </Wrapper>
    )
};

export default Header;