import React from 'react';
import styled from 'styled-components'
import { Colors, Fonts, Styles, Metrics } from '../../themes'
// import { BtnL } from '../../components'

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    max-width: 1080px;
    height: 400px;
    border-radius: 20px;
    margin: 0 auto 40px;
    overflow: hidden;
    &.center{
        margin: 0 auto;
        img{
            width: auto;
            height: 100%;
        }
    }
    img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: auto;
        height: 100%;
    }
    &.sh-img{
        img{
            width: 100%;
            height: auto;
        }
    }
    @media (max-width: 820px){
        &.center,&.sh-img{
            img{
                width: auto;
                height: 100%;
            }
        }
    }
    @media (max-width: ${Metrics.mobile}){
        height: 500px;
    }
`

const TopLayer = styled(Styles.toplayer())`
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 16px;
`

const TitleArea = styled.div`
    margin-bottom: 16px;
`

const Title = styled(Fonts.h2())`
    text-align: center;
    color: ${Colors.W100};
    text-shadow: 0px 2px 4px ${Colors.B50};
    &.small{
        font-size: 32px;
        font-weight: 600;
        line-height: 45px;
    }
`

const Text = styled(Fonts.P_16())`
    text-align: center;
    color: ${Colors.W100};
    text-shadow: 0px 2px 4px ${Colors.B50};
`

const BtnL = styled(Styles.btnL())`
    margin: 16px auto;
`




const CardBanner = (props) => {
    return (
        <Wrapper>
            <img src={props.bg} alt='' />
            <TopLayer>
                <TitleArea>
                    {props.title.split('\\n').map((item,index) => (
                        <div key={`title-${index}`}><Title>{item === '' ? <br/> : item}</Title></div>
                    ))}
                    <Title className='small'>{props.small}</Title>
                </TitleArea>
                {/* {props.subtitle.split('\\n').map((item,index)=> (
                    <div key={`subtitle-${index}`}><Subtitle>{item === '' ? <br/> : item}</Subtitle></div>
                ))} */}
                {props.text.split('\\n').map((item,index)=> (
                    <div key={`text-${index}`}><Text>{item === '' ? <br/> : item}</Text></div>
                ))}
                {props.BtnTitle &&
                    <BtnL className='secondary' onClick={props.onClick}>
                        <div><p>{props.BtnTitle}</p></div>
                    </BtnL>
                }
            </TopLayer>
        </Wrapper>
    );
}

export default CardBanner;