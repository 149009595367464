const ContactData = [
    {
        name: 'couple',
        text: "伴侶/兩人關係"
    },
    {
        name: 'family',
        text: "家庭/親子關係"
    },
    {
        name: 'friend',
        text: "朋友/人際關係"
    },
    {
        name: 'career',
        text: "事業/職涯規劃"
    },
    {
        name: 'study',
        text: "學業/讀書技巧"
    },
    {
        name: 'soul',
        text: "志業/靈修契機"
    },
    {
        name: 'self',
        text: "自我/人格特質解析"
    },
    {
        name: 'future',
        text: "流年/未來流年規劃"
    },
    {
        name: 'life',
        text: "解答各種生命課題"
    },
]

export default ContactData;
  
