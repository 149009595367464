import React from 'react';
import { Seo, Layout, CardBannerS, CardArticle, CellNumber, 
         CellToggle, Calculator, SelectBar } from '../components'
import styled from 'styled-components'
import { Images, Fonts, Metrics, SelectStyles } from '../themes'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import markdown from '../data/Learning.md'
import { Query } from "react-apollo";
import Modal from 'react-modal'
import Sticky from 'react-stickynode';
import Select from 'react-select'
import { useHistory } from "react-router-dom";
import { GET_NEWS } from '../api';
const ReactMarkdown = require('react-markdown/with-html')

const Wrapper = styled.div`
    padding: 24px 0;
    display: grid;
    grid-template-columns: 230px auto;
    max-width: 1000px;
    margin: 0 auto;
    @media (max-width: ${Metrics.mobile}){
        grid-template-columns: 100%;
        padding: 0;
    }
`
const MobileTab = styled.div`
`
const TabOption = styled.div`
    position: relative;
    max-width: 220px;
    margin: 0 16px;
    .NewsTab{
        max-width: 220px;
        margin: 0 auto;
        box-sizing: border-box;
    }
    .MuiTabs-flexContainer{
        display: grid;
        justify-items: start;
    }
    .MuiTabs-indicator{
        display: none;
    }
    .MuiButtonBase-root{
        text-align: left;
        margin:2px 0;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
    }
    .Mui-selected{
        color: #3578F1;
    }
    .MuiTab-root{
        min-width: inherit;
        min-height: inherit;
    }
    @media (max-width: ${Metrics.mobile}){
        max-width: inherit;
        margin: 0;
    }
`
const TabTitle = styled(Fonts.h4())`
    padding: 10px;
`
const TabArea = styled.div`
`
const BtnArea = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: space-between;
    grid-gap: 16px;
    margin: 40px 20px 10px;
    @media (max-width: ${Metrics.mobile}){
        margin: 28px 0 4px;
    }
`
const BtnLeft = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    p{
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 22px;
        color: #3578F1;
        text-align: center;
        margin: 0;
    }
    img{
        filter: invert(0.4) sepia(1) saturate(20) hue-rotate(187.2deg) brightness(0.84);
    }
    &:hover{
        cursor: pointer;
    }
`
const BtnRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    p{
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 22px;
        color: #3578F1;
        text-align: center;
        margin: 0;
    }
    img{
        filter: invert(0.4) sepia(1) saturate(20) hue-rotate(187.2deg) brightness(0.84);
    }
    &:hover{
        cursor: pointer;
    }
`
const Close = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 10000;
    width: 24px;
    height: 24px;
    &:hover{
        cursor: pointer;
    }
`
const customStyles = {
    overlay:{
        backgroundColor: "rgba(0,0,0,0.5)",
    },
    content : {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '16px',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
        zIndex: '1000',
    }
};

const NewsPage = (props) => {
    const id = props.match?.params?.id;
    const [value, setValue] = React.useState(Number(id));
    const history = useHistory();
    const [ height, setHeight ] = React.useState(0);
    const elementRef = React.createRef();
    /*const tabs = [
        { value: "0", label: "當代彩虹數字學的由來" },
        { value: "1", label: "彩虹數字學" },
        { value: "2", label: "生命密碼程式計算方式" },
        { value: "3", label: "生命密碼程式定義" },
        { value: "4", label: "數字能量個性" },
        { value: "5", label: "靈魂功課等級" },
        { value: "6", label: "常見問題" },
    ];*/

    const handleChange = (event, newValue) => {
        setValue(newValue);
        history.push(`${newValue}`)
    };

    const MobileSelect = select => {
        setValue(Number(select.value));
        history.push(`${select.value}`)
    }
    function Next(){
        setValue(value+1);
        history.push(`${Number(id)+1}`)
    }
    function Prev(){
        setValue(value-1);
        history.push(`${Number(id)-1}`)
    }
    const [ content, setContent ] = React.useState([])
    
    // for modal
    const [ modalIsOpen,setIsOpen ] = React.useState(false);
    function openModal() {
        setIsOpen(true);
        
    }
    function closeModal(){
        setIsOpen(false);
    }

    React.useEffect(() => {
        fetch(markdown).then((response) => response.text()).then((text) => {
            setContent(text.split('--'))
            
            // setNumber(text.split('--')[5].toString().split(','))
        })
       
        setValue(Number(id))

        if(elementRef.current){
            setHeight(elementRef.current.offsetHeight);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    
    return (
        <Query query={GET_NEWS}>
            {({ loading, error, data, refetch }) => {
            if (loading) return <p></p>;
            if (error) return <p>error</p>;
            const DefaultList = data.news.filter(i=>i.tag==='未分類')
            const tabs = [];
            DefaultList.map((i,index) =>
                tabs.push({
                    value: i.id,
                    label: i.title,
                })
            )
            return (
                <Layout news>
                    <Seo 
                        title='彩虹數字學會 - 最新消息'
                        metaDescription='運用彩虹數字解讀你的生命密碼，了解自己的生命課題。學習「心性解碼」、「時間解碼」、「感情解碼」課程，免費試算自己的數字。'
                        metaTitle='彩虹數字學會'
                        metaUrl='https://www.rainbownumen.org/news/1'
                        //metaImage={`${Images.Rainbow_SEO}`}
                        metaImage='https://rainbow-digital.glitch.me/i/ktlc6ygb.png'
                    />
                    <Wrapper ref={elementRef}>
                        {/* web tab */}
                        <Sticky enabled={true} top={113} bottomBoundary={height+100} activeClass='sticky' releasedClass='noSticky' className='web'>
                            <TabOption>
                                <TabTitle>新聞報導</TabTitle>
                                <Tabs
                                    value={value}
                                    className='NewsTab'
                                    onChange={handleChange}
                                >
                                    {DefaultList.map((i, index) => (
                                        <Tab label={i.title} value={i.id} />
                                    ))}
                                </Tabs>
                            </TabOption>
                        </Sticky>
                        {/* mobile tab */
                        <MobileTab className='mobile'>
                            <TabOption>
                                <SelectBar>
                                    <Select 
                                        options={tabs} 
                                        name='tabs'
                                        placeholder='請選擇'
                                        isSearchable={ false }
                                        styles={SelectStyles}
                                        onChange={MobileSelect}
                                    />
                                </SelectBar>
                            </TabOption>
                        </MobileTab>
                        }
                        {/* 
                        {value === 0 &&
                        <TabArea>
                            <CardArticle>
                                <ReactMarkdown
                                    source={content[0]}
                                    escapeHtml={false}
                                />
                                <BtnArea>
                                    <BtnLeft></BtnLeft>
                                    <BtnRight onClick={Next}>
                                        <div><p>彩虹數字學</p></div>
                                        <img src={Images.arrow_right} alt='arrow_right'/>
                                    </BtnRight>
                                </BtnArea>
                            </CardArticle>
                            <CardBannerS 
                                bg={Images.banner_02}
                                title='彩虹數字計算機'
                                text={`馬上試算，了解自己的基本個性。`}
                                BtnTitle='免費試算'
                                onClick={()=>openModal()}
                            />
                        </TabArea>
                        }
                        {value === 1 &&
                        <TabArea>
                            <CardArticle>
                                <ReactMarkdown
                                    source={content[1]}
                                    escapeHtml={false}
                                />
                                <BtnArea>
                                    <BtnLeft onClick={Prev}>
                                        <img src={Images.arrow_left} alt='arrow_left'/>
                                        <div><p>當代彩虹數字學的由來</p></div>
                                    </BtnLeft>
                                    <BtnRight onClick={Next}>
                                        <div><p>生命密碼程式計算方式</p></div>
                                        <img src={Images.arrow_right} alt='arrow_right'/>
                                    </BtnRight>
                                </BtnArea>
                            </CardArticle>
                            <CardBannerS 
                                bg={Images.banner_02}
                                title='你也想知道自己的生命密碼嗎？'
                                text={`馬上試算，了解自己的基本個性。`}
                                BtnTitle='免費試算'
                                onClick={()=>openModal()}
                            />
                        </TabArea>
                        }
                        {value === 2 &&
                        <TabArea>
                            <CardArticle>
                                <ReactMarkdown
                                    source={content[2]}
                                    escapeHtml={false}
                                />
                                <BtnArea>
                                    <BtnLeft onClick={Prev}>
                                        <img src={Images.arrow_left} alt='arrow_left'/>
                                        <div><p>彩虹數字學</p></div>
                                    </BtnLeft>
                                    <BtnRight onClick={Next}>
                                        <div><p>生命密碼程式定義</p></div>
                                        <img src={Images.arrow_right} alt='arrow_right'/>
                                    </BtnRight>
                                </BtnArea>
                            </CardArticle>
                            <CardBannerS 
                                bg={Images.banner_02}
                                title='快速體驗彩虹數字的神奇'
                                text='馬上試算，了解自己的基本個性。'
                                BtnTitle='免費試算'
                                onClick={()=>openModal()}
                            />
                        </TabArea>
                        }
                        {value === 3 &&
                        <TabArea>
                            <CardArticle>
                                <ReactMarkdown
                                    source={content[3]}
                                    escapeHtml={false}
                                />
                                <BtnArea>
                                    <BtnLeft onClick={Prev}>
                                        <img src={Images.arrow_left} alt='arrow_left'/>
                                        <div><p>生命密碼程式計算方式</p></div>
                                    </BtnLeft>
                                    <BtnRight onClick={Next}>
                                        <div><p>數字能量個性</p></div>
                                        <img src={Images.arrow_right} alt='arrow_right'/>
                                    </BtnRight>
                                </BtnArea>
                            </CardArticle>
                            <CardBannerS 
                                bg={Images.Event_09}
                                title='彩虹數字學課程'
                                text='彩虹數字學三大類型課程：\n「心性解碼」、「時間解碼」、「感情解碼」'
                                BtnTitle='立即查看'
                                onClick={()=>history.push(`/event/0`)}
                            />
                        </TabArea>
                        }
                        {value === 4 &&
                        <TabArea>
                            <CardArticle>
                                <ReactMarkdown
                                    source={content[4]}
                                    escapeHtml={false}
                                />
                                <CellNumber bg='#E65C52' num='1' text='獨立，專注，領導力強，重視大原則，具有堅持到底的毅力。' />
                                <CellNumber bg='#FF9F47' num='2' text='溫柔，細膩，懂得傾聽，注重小細節，會委曲求全配合別人。' />
                                <CellNumber bg='#FFC900' num='3' text='點子多，有創意，溝通力強，充滿好奇心，喜歡學習新事物。' />
                                <CellNumber bg='#59C08E' num='4' text='務實，沉穩，腳踏實地，重視紀律，有責任感，以家庭為重。' />
                                <CellNumber bg='#47AAD3' num='5' text='追求自由，熱愛戶外活動，富有正義感，具冒險、挑戰精神。' />
                                <CellNumber bg='#377AF1' num='6' text='善解人意，熱心公益，具有愛心慈悲心，是一位完美主義者。' />
                                <CellNumber bg='#B780F2' num='7' text='質疑性強，打破砂鍋問到底，重視氛圍，常有莫名的好運氣。' />
                                <CellNumber bg='#692FA6' num='8' text='察言觀色，謹言慎行，以退為進，懂得風險評估、理財規劃。' />
                                <CellNumber bg='#6D562D' num='9' text='喜歡探究靈性力量獲得生命智慧，一切隨緣，無我成就別人。' />
                                <CellNumber bg='#B0B0B0' num='0' text='喜愛大自然，有時會讓腦袋放空、沉澱，有時卻會突然頓悟。' />
                                <BtnArea>
                                    <BtnLeft onClick={Prev}>
                                        <img src={Images.arrow_left} alt='arrow_left'/>
                                        <div><p>生命密碼程式定義</p></div>
                                    </BtnLeft>
                                    <BtnRight onClick={Next}>
                                        <div><p>靈魂功課等級</p></div>
                                        <img src={Images.arrow_right} alt='arrow_right'/>
                                    </BtnRight>
                                </BtnArea>
                            </CardArticle>
                            <CardBannerS 
                                bg={Images.Event_09}
                                title='彩虹數字學課程'
                                text='彩虹數字學三大類型課程：\n「心性解碼」、「時間解碼」、「感情解碼」'
                                BtnTitle='立即查看'
                                onClick={()=>history.push(`/event/0`)}
                            />
                        </TabArea>
                        }
                        {value === 5 &&
                        <TabArea>
                            <CardArticle>
                                <ReactMarkdown
                                    source={content[5]}
                                    escapeHtml={false}
                                />
                                <BtnArea>
                                    <BtnLeft onClick={Prev}>
                                        <img src={Images.arrow_left} alt='arrow_left'/>
                                        <div><p>數字能量個性</p></div>
                                    </BtnLeft>
                                    <BtnRight onClick={Next}>
                                        <div><p>常見問題</p></div>
                                        <img src={Images.arrow_right} alt='arrow_right'/>
                                    </BtnRight>
                                </BtnArea>
                            </CardArticle>
                            <CardBannerS 
                                bg={Images.Event_09}
                                title='彩虹數字學課程'
                                text='彩虹數字學三大類型課程：\n「心性解碼」、「時間解碼」、「感情解碼」'
                                BtnTitle='立即查看'
                                onClick={()=>history.push(`/event/0`)}
                            />
                        </TabArea>
                        }
                        {value === 6 &&
                        <TabArea>
                            <CardArticle>
                                <h5>常見問題</h5>
                                <CellToggle 
                                    title='彩虹數字怎麼計算？'
                                    text='彩虹數字是以西元出生年月日的8個數字，將所有數字相加後，就會得到你的主命數囉。但特別的是，加總過程中的所有數字，都有各種不同的解析意義，也會有相對應的能量影響唷~(在課堂中都會詳細的說明)'
                                />
                                <CellToggle 
                                    title='彩虹數字跟生命靈數一樣嗎？'
                                    text='彩虹數字與生命靈數，同樣都需要以西元出生年月日的時間來解讀我們的生命密碼。但是彩虹數字除了最重要的主命數以外，還能解答其他階段出現的所有數字。更加入農曆日期來做剖析，將我們的生命藍圖做更廣泛及多元的數字密碼解析，讓我們更了解自己的能量在身體裡面如何流動與影響，看到自己的無限潛能。'
                                />
                                <CellToggle 
                                    title='彩虹數字跟八字哪個比較準？'
                                    text='每一種算命都是在為生命作解答，只是工具不同。\n但是彩虹數字透過剖析生日而展開密碼，主要是為了要分析生命，而非算命。藉由數字所展現出來的能量組合，從我們所困惑事件中找出能量有問題的地方，讓我們了解自己能量，並且提供我們改變能量的方式。彩虹數字和算命不同，彩虹數字重視人的自由意識具有強大的力量，相信命運是可以改變的。\n彩虹數字是提供解決自己生命問題的方式，並非只給予吉凶可否之類的答案。'
                                />
                                <CellToggle 
                                    title='了解彩虹數字有什麼好處？'
                                    text='了解彩虹數字的我們可以：知道自己的強項、了解自己的缺點、知道自己要注意的個性、發覺自己的特質、知道家人跟自己的關係、改善跟家人的關係、知道隔壁學長的個性、了解怎麼追喜歡的學妹......等等。彩虹數字可以了解自我，改善人際關係，還能擁有了解與接納他人的勇氣與能力，好處實在說不完~'
                                />
                                <CellToggle 
                                    title='同年同月同日的人是不是就一模一樣？'
                                    text='同一天生日的人雖然計算出來的數字一樣，但出生的時分也會不一樣，自然小時候的能量影響就會不同；即使連時分都一樣，每個數字也都還各自有著高階與低階的不同展現，也會產生不同的影響唷~（在課堂中都會詳細的說明）'
                                />
                                <CellToggle 
                                    title='我適合諮詢嗎？諮詢對我的幫助？'
                                    text='只要是動物，都可以諮詢唷~(但諮詢師目前只會跟人類溝通，抱歉)\n諮詢因為是個別時間，完全針對個人的問題進行分析，找到問題的癥結點，不僅能夠解答諮詢的問題，往往在解碼過程中，還能解開自己內心深處的結，將潛意識之下的問題也剖析開來，讓自己有煥然一新的感覺，因此而懂得更加愛自己，更能與自己對話。'
                                />
                                <CellToggle 
                                    title='線上諮詢與面對面諮詢會差很多嗎？'
                                    text='一般線上諮詢與面對面諮詢，都會獲得相對的解答。但是線上諮詢，比較像是在上課一般。而面對面諮詢，最重要的是具有「溫度」，更能感受到諮詢師的正面能量，產生能量之間的交流。不只是「聽到」老師說話，而是「感覺到」一股暖流串接，更能有正向能量調整和尋回自己失去能量的效果。'
                                />
                                <CellToggle 
                                    title='請問我也想要學習彩虹數字心靈諮詢，有什麼管道嗎？'
                                    text='你可以在我們的官方網站裡直接預約~我們會為你安排時間！'
                                />
                                <CellToggle 
                                    title='我要如何報名彩虹數字課程？'
                                    signUp
                                />
                                <CellToggle 
                                    title='彩虹數字課程有連結性嗎？'
                                    map
                                />
                                <BtnArea>
                                    <BtnLeft onClick={Prev}>
                                        <img src={Images.arrow_left} alt='arrow_left'/>
                                        <div><p>靈魂功課等級</p></div>
                                    </BtnLeft>
                                    <BtnRight></BtnRight>
                                </BtnArea>
                            </CardArticle>
                            <CardBannerS 
                                bg={Images.Event_09}
                                title='彩虹數字學課程'
                                text='彩虹數字學三大類型課程：\n「心性解碼」、「時間解碼」、「感情解碼」'
                                BtnTitle='立即查看'
                                onClick={()=>history.push(`/event/0`)}
                            />
                        </TabArea>
                        }*/}
                        {
                            value == id &&
                                <TabArea>
                                    <CardArticle>
                                    <img src= {data.news.filter(i=>i.id==id)[0].cover} width='100%'/>
                                        <ReactMarkdown                                         
                                            source={data.news.filter(i=>i.id==id)[0].content}
                                            escapeHtml={false}
                                        />
                                    </CardArticle>
                                    <CardBannerS 
                                        bg={Images.Event_09}
                                        title='彩虹數字學課程'
                                        text='彩虹數字學三大類型課程：\n「心性解碼」、「時間解碼」、「感情解碼」'
                                        BtnTitle='立即查看'
                                        onClick={()=>history.push(`/event/0`)}
                                    />
                                </TabArea>
                            
                        }
                    </Wrapper>
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >
                        <Close onClick={closeModal}>
                            <img src={Images.close} alt='' />
                        </Close>
                        <Calculator />
                    </Modal>
                </Layout>
                );
            }}
        </Query>
    );
}

export default NewsPage;