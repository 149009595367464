import styled from 'styled-components'
import Metrics from './Metrics';
import Colors from './Colors';

const type = {
  base: '-apple-system, BlinkMacSystemFont,"Segoe UI", Roboto, Oxygen, Ubuntu, "Open Sans", "Helvetica Neue","Noto Sans TC","微軟正黑體", sans-serif',
}

const Fonts = {
  h1: () => styled.h1`
    font-family: ${type.base} ;
    color: ${Colors.TitleBlack};
    margin: 0;
    font-size: 60px;
    font-weight: 600;
    line-height: 84px;
    @media (max-width: ${Metrics.tablet}){
      font-size: 50px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 70px;
    } 
    @media (max-width: ${Metrics.mobile}){
      font-size: 40px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 56px;
    }
  `,
  h2: () => styled.h2`
    font-family: ${type.base} ;
    color: ${Colors.TitleBlack};
    margin: 0;
    font-size: 40px;
    font-weight: 600;
    line-height: 56px;
    @media (max-width: ${Metrics.tablet}){
      font-size: 36px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 50px;
    } 
    @media (max-width: ${Metrics.mobile}){
      font-size: 32px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 45px;
    }
  `,
  h3: () => styled.h3`
    font-family: ${type.base} ;
    -webkit-font-smoothing: antialiased;
    color: ${Colors.TitleBlack};
    font-size: 32px;
    font-weight: 600;
    line-height: 45px;
    margin: 0;
    @media (max-width: ${Metrics.tablet}){
      font-size: 28px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 40px;
    } 
    @media (max-width: ${Metrics.mobile}){
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 33px;
    }
  `,
  h4: () => styled.h4`
    font-family: ${type.base} ;
    color: ${Colors.TitleBlack};
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    line-height: 33px;
    @media (max-width: ${Metrics.tablet}){
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 30px;
    } 
    @media (max-width: ${Metrics.mobile}){
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 28px;
    }
  `,
  h5: () => styled.h5`
    font-family: ${type.base} ;
    color: ${Colors.TitleBlack};
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    @media (max-width: ${Metrics.tablet}){
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 25px;
    } 
    @media (max-width: ${Metrics.mobile}){
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 22px;
    }
  `,
  h6: () => styled.h6`
    font-family: ${type.base} ;
    color: ${Colors.TitleBlack};
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    @media (max-width: ${Metrics.tablet}){
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 21px;
    } 
    @media (max-width: ${Metrics.mobile}){
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
    }
  `,
  P_20: () => styled.p`    
    font-family: ${type.base} ;
    color: ${Colors.TitleBlack};
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    @media (max-width: ${Metrics.tablet}){
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 21px;
    } 
    @media (max-width: ${Metrics.mobile}){
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 20px;
    }
  `,
  P_16: () => styled.p`    
    font-family: ${type.base} ;
    color: ${Colors.TitleBlack};
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  `,
  info: () => styled.p`    
    font-family: ${type.base} ;
    color: ${Colors.TitleBlack};
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
  `,
  button: () => styled.button`
    font-family: ${type.base} ;
  `,
  C_12: () => styled.p`
    font-family: ${type.base} ;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
  `
}

export default Fonts;