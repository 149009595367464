import React from 'react'
import styled from 'styled-components'
import { Metrics, Fonts, Colors } from '../../themes'

const BtnDiv = styled(Fonts.button())`
    width: 32px;
    height: 32px;
    background: ${Colors.G2};
    border-radius: 100%;
    border: none;
    transition: 0.8s cubic-bezier(0.2,0.8,0.2,1);
    outline: none;
    background: ${props => props.bg};
    justify-self: center;
    display: grid;
    padding: 4px;
    align-item: center;
    position: relative;
    img{
        filter: ${props => props.filter};
        margin: 0 auto;
    }
    &.arrow{
        box-shadow: 2px 4px 8px 0 ${Colors.B10};
    }
    &.arrow:hover{
        background: ${Colors.W100};
        box-shadow: 6px 8px 8px 0 ${Colors.B20};
        transition: 0.8s cubic-bezier(0.2,0.8,0.2,1);
    }
    
    &:hover,&.hover{
        cursor: pointer;
        background: rgba(53, 120, 241, 0.2);
    }
    
    @media (max-width: ${Metrics.mobile}){
        img{
            margin: 0 auto;
            align-self: center;
            justify-self: center;
        }
    }
`

const Rounded = props => (
    <BtnDiv className={props.className} onClick={props.onClick} bg={props.bg} filter={props.filter}>
        <img src={props.icon} alt={props.alt} className='icon' />
    </BtnDiv>
)

export default Rounded