import { recomposeColor } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

const InputGroup = styled.div`
    width: 100%;
    padding: 8px 8px 4px;
    position: relative;
`
const InputTitle = styled.p`
    font-size: 12px;
    letter-spacing: 0;
    line-height: 17px;
    position: relative;
    width: 100%;
    padding-left: 8px;
`

const SelectBar = (props) => {
  const { children } = props
  return ( 
    <InputGroup className='selectBar' onClick={props.onClick}>
      <InputTitle>{props.inputTitle}</InputTitle>
        { children }
    </InputGroup>
  )
}
  
export default SelectBar