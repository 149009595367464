import React from 'react';
import styled from 'styled-components'
import { Fonts } from '../../themes'

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 6px 0;
`
const Num = styled(Fonts.P_16())`
    color: ${props => props.bg};
    padding: 5px 12px;
`
const Text = styled(Fonts.P_16())`
    padding-left: 5px;
`

const CellNumber = ( props ) => {
    return (
        <Wrapper>
            <Num bg={props.bg}>{props.num}</Num>
            <Text>{props.text}</Text>
        </Wrapper>
    );
}

export default CellNumber;