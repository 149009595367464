import React, { useState, useEffect } from 'react';
import {
    Seo, Layout, CellIconTitle, CardArticleWithTitle, CardSignUpDetail,
    FormRadio, GridCol2, CardTicketRadio, CardEventSignUpFixed,
    CardArticle, InputBar, CheckBar, CellBDEmpty, InputDateBar, RadioCheck
} from '../components'
import { DatePicker } from 'antd';
import { Images, Metrics, Fonts, Colors, Styles } from '../themes'
import styled from 'styled-components'
import { useHistory } from "react-router-dom";
import { Query, Mutation, useQuery } from "react-apollo";
import {
    GET_PROFILE, ADD_UPDATE_TRADE, UPDATE_PROFILE,
    GET_FORM_LIST, GET_FORM_LIST_NEW, GET_PROMO
} from '../api';
import { useForm } from "react-hook-form";
import Cookies from 'js-cookie';
var moment = require('moment');

const Wrapper = styled.div`
    position: relative;
    height: auto;
    padding: 0 16px;
`
const Content = styled.div`
    padding: 24px 0;
    display: grid;
    grid-gap: 24px;
    grid-template-columns: auto 349px;
    max-width: 1080px;
    margin: 0 auto;
    @media (max-width: ${Metrics.tablet}) {
        padding: 13px 0;
        grid-template-columns: 100%;
        grid-gap: inherit;
    }
`
const UnLockArea = styled.div`
    display: grid;
    grid-template-columns: 140px auto;
`
const Text = styled(Fonts.P_16())`
    .br{
        margin-bottom: 20px;
    }
    &:first-child{
        margin-top: 16px;
    }
`
const TextCenter = styled(Fonts.P_16())`
    text-align: center;
    font-size: 20px;
    margin-top: 20px;
    .br{
        margin-bottom: 20px;
    }
    &:first-child{
        margin-top: 16px;
    }
`
const ContentArea = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 6px;
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 24px;
    .input-area,.black{
        padding: 8px 6px 4px 0;
    }
    @media (max-width: ${Metrics.mobile}){
        display: block;
        height: 100%;
    }
`
const CheckWrapper = styled.div`
    display: flex;
    align-items: center;
    transition: .5s;
    .checkBar{
        z-index: inherit
    }
    .bold{
        display: flex;
    }
    .bold p {
        margin: 0;
    }
    @media (max-width: ${Metrics.mobile}){
        .bold:before{
            width: 24px !important;
        }
    }
`
const ToggleDiv = styled.div`
    display: flex;
    align-items: center;
    padding-left: 8px;
    p{
        color: ${Colors.Blue};
        font-weight: 500;
        margin: 0 !important;
    }
    img{
        filter: invert(0.4) sepia(1) saturate(20) hue-rotate(210deg) brightness(0.8);
    }
    p:hover{
        cursor: default;
    }
    img:hover{
        cursor: pointer;
    }
    img.open{
        transform: scaleY(-1);
        transition: .2s;
    }
`
const ToggleMore = styled.div`
    transition: .5s;
    max-height: 0px;
    overflow: hidden;
    margin-left: 36px;
    p{
        font-size: 12px;
        line-height: 18px;
        margin: 0 !important;
    }
    &.open{
        max-height: 150px;
    }
    @media (max-width: ${Metrics.tablet}) {
        margin-left: 16px;
    }

`
const Button = styled(Styles.btnL())`
    margin-top: 16px;
    p:hover{
        cursor: default;
    }
`

const BtnArea = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    justify-items: center;
    width: 100%;
    bottom: 0px !important;
    z-index: 1000;
    margin: 16px;
    button{
        margin: 0 4px;
    }
`
const InputArea = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
    box-sizing: border-box;
    margin: 16px 0;
    .no-show{
        width: 0;
        height: 0;
        position: absolute;
        opacity: 0;
    }
    @media (max-width: ${Metrics.mobile}){
        display: block;
        height: 100%;
    }
`

const Status = [
    '選擇票券',
    '個人資料',
    '付款方式',
    '報名成功'
]
const SignUpPage = (props) => {
    const id = props.match?.params?.id;
    const history = useHistory();
    // const state = history.location.state
    const [height, setHeight] = useState(0);
    const elementRef = React.createRef();
    const [width, setWidth] = useState(window.innerWidth);
    const [area, setArea] = useState(1)
    const [time, setTime] = useState(1)
    const [location, setLocation] = useState(1)
    const [ticket, setTicket] = useState(0)
    const [statusId, setStatusId] = useState(0)
    const [check, setCheck] = useState(false);
    const [userId, setUserId] = useState()
    const [Trade, setTrade] = useState()
    const [refresh, setRefresh] = useState(true)
    // const [ payFail, setPayFail ] = useState(false)
    const [open, setOpen] = React.useState(false)

    //for profile
    const [UserData, setUserData] = useState();
    const [gender, setGender] = useState()
    const [name, setName] = useState()
    const [birth, setBirth] = useState();
    const [cellphone, setCellphone] = useState();
    const [email, setEmail] = useState();
    const [address, setAddress] = useState();
    const [remark, setRemark] = useState();
    const [pcode, setPcode] = useState();
    const [recommender, setRecommender] = useState();
    const [Date, setDate] = useState();
    const [Ready, setReady] = useState(false);
    const [first, setFirst] = useState(true);
    const [checkEmail, setCheckEmail] = useState(false);
    const [checkPhone, setCheckPhone] = useState(false);
    const [checkAddress, setCheckAddress] = useState(false);
    const [checkGender, setCheckGender] = useState(false);
    const [checkBirth, setCheckBirth] = useState(false);
    const [checkName, setCheckName] = useState(false);
    const [payIndex, setPayIndex] = useState(0);
    const [ticketIndex, setTicketIndex] = useState(0);
    const [ticketID, setTicketID] = useState(4);
    const [ticketExcerpt, setTicketExcerpt] = useState('--');
    const [price, setPrice] = useState(0);
    const [promoPrice, setPromoPrice] = useState(0);
    const [promoID, setPromoID] = useState(1);
    const [lastPrice, setLastPrice] = useState(price);
    const [atSession, setAtSession] = useState([]);
    const [Notice, setNotice] = useState();
    const { loading: loadingp, error: errorp, data: datap } = useQuery(GET_PROMO);
    const { register, handleSubmit, setValue } = useForm();
    const onSubmit = data => {
        if (data.name && data.birth && data.email && data.gender && data.phone) {//&& data.address) {
            setReady(true);
        }
        if (!data.birth) {
            data.birth = birth
            setFirst(false)
        }
        setUserData(data)
    };
    function onChange(date, dateString) {
        setDate(dateString)
        setValue("birth", dateString);
        setBirth(dateString)
    }

    const updateWidthAndHeight = () => {
        setWidth(window.innerWidth);
    };
    const handleTicket = (i) => {
        setTicketIndex(0)
        setTicket(i.id);
        if (i.tickets[0]) {
            setTicketID(i.tickets[0].id)
            setLastPrice(i.tickets[0].price);
            setPrice((i.tickets[0].price-promoPrice)<0?0:(i.tickets[0].price-promoPrice))
            setTicketExcerpt(i.tickets[0].excerpt)
        } else {
            setTicketID(4)
            setPrice(0)
            setTicketExcerpt('--')
        }
    };
    //檢查表單內容
    function checkEmailF(email) {
        var myreg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
        if (!myreg.test(email)) {
            setReady(false)
            setCheckEmail(true)
            return false;
        } else {
            setCheckEmail(false)
        }
    }
    function checkPhoneF(cellphone) {
        //var phone = /^09[0-9]{8}$/;
        //if (!phone.test(cellphone)) {
        if (cellphone && cellphone.length < 5 || !cellphone) {
            setReady(false)
            setCheckPhone(true)
            return false;
        } else {
            setCheckPhone(false)
        }
    }
    function checkAddressF(address) {
        //if (address == '尚未設定' || address == '必填' || (address && address.length < 5)) {
        if (address && address.length < 2 || !address) {
            //setReady(false)
            //setCheckAddress(true)
            return false;
        }
        setCheckAddress(false)
    }
    function checkGenderF(gender) {

        if (gender && gender.length < 1 || !gender) {
            setReady(false)
            setCheckGender(true)
            return false;
        } else {
            setCheckGender(false)
        }
    }
    function checkNameF(name) {

        if (name && name.length < 1 || !name) {
            setReady(false)
            setCheckName(true)
            return false;
        } else {
            setCheckName(false)
        }
    }
    function checkBirthF(name) {

        if (birth && birth.length < 1 || !birth) {
            setReady(false)
            setCheckBirth(true)
            return false;
        } else {
            setCheckBirth(false)
        }
    }
    useEffect(() => {
        if(datap?.promo?.length>0){
            for(const datapcode of datap?.promo){
                if(datapcode.pcode===pcode&&datapcode.event_id===parseInt(id)){
                    setPromoID(datapcode.id)
                    setLastPrice(price);
                    setPromoPrice(datapcode.price)
                    setPrice((price-datapcode.price)<0?0:(price-datapcode.price))
                    return
                }
            }
        }
        if(lastPrice>0){
            setPrice(lastPrice)
        }
        setPromoPrice(0)
        setPromoID(1)
    }, [datap,pcode])
    useEffect(() => {
        if (Cookies.get('rainbow')) {
            setUserId(Cookies.get('rainbow'))
        } else {
            window.location.href = '/'
        }
        if (elementRef.current) {
            setHeight(elementRef.current.offsetHeight);
        }

        if (name && birth && email && gender && cellphone) {//&& address) {
            setReady(true);
        } else {
            setReady(false);
        }

        checkEmailF(email)
        checkPhoneF(cellphone)
        checkAddressF(address)
        checkGenderF(gender)
        checkNameF(name)
        checkBirthF(birth)

        if (checkEmail && checkPhone) {
            setNotice('both')
        } else if (checkEmail && !checkPhone) {
            setNotice('email')
        } else if (!checkEmail && checkPhone) {
            setNotice('cellphone')
        } else if (checkAddress) {
            setNotice('address')
        } else if (checkGender) {
            setNotice('gender')
        } else if (checkName) {
            setNotice('name')
        } else if (checkBirth) {
            setNotice('birth')
        } else {
            setNotice('none')
        }

        if (statusId === 0) {
            setCheck(false)
        }
        // 付款後處理
        if (window.location.href.split('?').length > 1) {
            if (window.location.href.split('?')[1] === 'success') {
                setStatusId(2)
                sessionStorage.setItem('status', 'success');
                setTrade(sessionStorage.getItem('status'))
                if (window.location.href.split('?').length > 2) {
                    setPrice(window.location.href.split('?')[2])
                }
                /*
                if (sessionStorage.getItem('status')) {
                    if (refresh) {
                        window.location.href = window.location.href.split('?')[0];
                        setRefresh(false)
                    }
                }*/
            } else if (window.location.href.split('?')[1] === 'fail') {
                setStatusId(3)
                sessionStorage.setItem('status', 'fail');
                setTrade(sessionStorage.getItem('status'))
                if (window.location.href.split('?').length > 2) {
                    setPrice(window.location.href.split('?')[2])
                }
                /*
                if (sessionStorage.getItem('status')) {
                    if (refresh) {
                        window.location.href = window.location.href.split('?')[0];
                        setRefresh(false)
                    }
                }*/
            } else {
                window.location.href = '/'
            }
        }
        if (sessionStorage.getItem('status') === 'success') {
            setStatusId(2)
        } else if (sessionStorage.getItem('status') === 'fail') {
            setStatusId(3)
        } else {

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementRef])



    window.addEventListener("resize", updateWidthAndHeight);

    history.listen(() => { setStatusId(statusId); sessionStorage.removeItem('status'); });

    function handleClick(paymentItem) {
        setPayIndex(paymentItem)
    }
    function handleTicketClick(item, i) {
        setTicketID(item.id)
        setTicketIndex(i)
        setLastPrice(item.price);
        setPrice((item.price-promoPrice)<0?0:(item.price-promoPrice))
        setTicketExcerpt(item.excerpt)
    }
    return (
        <Layout event >
            <Seo
                title='彩虹數字學會 - 活動報名'
                metaDescription='運用彩虹數字解讀你的生命密碼，了解自己的生命課題。學習「心性解碼」、「時間解碼」、「感情解碼」課程，免費試算自己的數字。'
                metaTitle='彩虹數字學會'
                metaUrl='https://www.rainbownumen.org'
                //metaImage={`${Images.Rainbow_SEO}`}
                metaImage='https://rainbow-digital.glitch.me/i/ktlc6ygb.png'
                metaImageAlt=''
            />
            <Query query={GET_PROFILE(userId)} >
                {({ loading, error, data, refetch }) => {
                    if (loading) return <p>Loading...</p>;
                    if (error) return <p>Error :(</p>;
                    const User = data
                    if (data) {
                        if (data.member.length > 0) {
                            if (first) {
                                setName(data.member[0].name)
                                setCellphone(data.member[0].cellphone)
                                setEmail(data.member[0].email)
                                setAddress(data.member[0].address)
                                setRemark(data.member[0].remark)
                                setRecommender(data.member[0].recommender)
                                setBirth(data.member[0].birth)
                                if (data.member[0].gender === 'M') {
                                    setGender('男')
                                } else if (data.member[0].gender === 'F') {
                                    setGender('女')
                                } else {
                                    setGender(data.member[0].gender)
                                }
                                setFirst(false)

                            }
                        }
                    }
                    return (
                        <Wrapper ref={elementRef}>
                            <Query query={User.role === '舊生' ? GET_FORM_LIST(id) : GET_FORM_LIST_NEW(id)} >
                                {({ loading, error, data, refetch }) => {
                                    if (loading) return <p>Loading...</p>;
                                    if (error) return <p>Error :(</p>;
                                    let detail = data.event[0]

                                    let selectSession;
                                    if (statusId === 2 || statusId === 3) {
                                        let ticketId = Number(Cookies.get('_rb_ticketId'))
                                        selectSession = detail.sessions.filter(i => i.id === ticketId)[0]
                                    } else {
                                        selectSession = detail.sessions.filter(i => i.id === ticket)[0]
                                    }
                                    if (selectSession) {
                                        setAtSession(selectSession)
                                    }
                                    return (
                                        <>
                                            {data &&
                                                <Content>
                                                    {statusId === 0 &&
                                                        <CardArticleWithTitle bigTitle='選擇課程'>
                                                            <CellIconTitle
                                                                icon={Images.ticket}
                                                                alt='icon'
                                                                title='場次'
                                                            />
                                                            {detail.sessions.map((i, index) => (
                                                                ticket == 0 && index == 0 ? handleTicket(i) : '',
                                                                <CardTicketRadio
                                                                    name='class'
                                                                    id={`class_${index}`}
                                                                    className={ticket === i.id ? 'check' : ticket == 0 && index == 0 ? 'check' : ''}
                                                                    value={`class_${index}`}
                                                                    // title={`${i.classroom.city.split('市')[0]}場`}
                                                                    title={i.title}
                                                                    content={i.content}
                                                                    startTime={i.session_start_time}
                                                                    endTime={i.session_end_time}
                                                                    location={`${i.classroom.city} - ${i.classroom.name} (${i.classroom.address})`}
                                                                    //price={`NT$ ${i.price}`}
                                                                    status='normal'
                                                                    onClick={() => handleTicket(i)}
                                                                />
                                                            ))}
                                                            {/* {detail.code.includes('-0') && !detail.code.includes('C')
                                                            ? <>
                                                                <CardTicketRadio
                                                                    name='class'
                                                                    id='class_1'
                                                                    value='1'
                                                                    className='check'
                                                                    code={detail.code}
                                                                    data={detail.sessions}
                                                                    title={detail.sessions[0].title}
                                                                    price={`NT$ ${detail.sessions[0].price}`}
                                                                    status='normal'
                                                                    onClick={()=>setTicket(1)}
                                                                />
                                                            </>
                                                            : <>
                                                                <CardTicketRadio
                                                                    name='class'
                                                                    id={`class_1`}
                                                                    className='check'
                                                                    value='1'
                                                                    title={`${detail.sessions[0].classroom.city.split('市')[0]}場`}
                                                                    time={`${moment(detail.sessions[0].session_start_time).format('yyyy/MM/DD (dd) a H:mm')}~${moment(detail.sessions[0].session_end_time).format('H:mm')}`}
                                                                    location={`${detail.sessions[0].classroom.city} - ${detail.sessions[0].classroom.name} (${detail.sessions[0].classroom.address})`}
                                                                    price={`NT$ ${detail.sessions[0].price}`}
                                                                    status='normal'
                                                                    onClick={()=>setTicket(1)}
                                                                />
                                                            </>
                                                        } */}
                                                        </CardArticleWithTitle>
                                                    }
                                                    {statusId === 1 &&
                                                        <CardArticleWithTitle bigTitle='填寫參加人資訊'>
                                                            <Text>報名資料將用於主辦單位安排活動，活動入場電子票券將寄至訂購人信箱</Text>
                                                            <>
                                                                <form id='check' onChange={handleSubmit(onSubmit)}>
                                                                    <ContentArea>
                                                                        <InputBar inputTitle='姓名'>
                                                                            <input id='name' name='name' placeholder='請輸入姓名' value={name} onInput={(e) => setName(e.target.value)} ref={register} required />
                                                                        </InputBar>
                                                                        <InputDateBar className='black' inputTitle='生日(請務必填寫此欄位,否則資料將無法儲存)'>
                                                                            <DatePicker id='birth' name='birth' placeholder='2020-01-01' /*defaultValue={User.member[0].birth && moment(User.member[0].birth, 'YYYY/MM/DD')}*/ onChange={onChange} onOpenChange={handleSubmit(onSubmit)} require />
                                                                        </InputDateBar>
                                                                        <InputBar inputTitle='性別'>
                                                                            <input id='gender' name='gender' placeholder='男/女' value={gender} onInput={(e) => setGender(e.target.value)} ref={register} required />
                                                                        </InputBar>
                                                                        <InputBar inputTitle='手機號碼'>
                                                                            {/*<input id='cellphone' name='cellphone' type='tel' size="10" minlength="9" placeholder='請輸入手機號碼' value={cellphone} onInput={(e) => setCellphone(e.target.value)} pattern="09[0-9]{8}" ref={register} required />*/}
                                                                            <input id='cellphone' name='cellphone' type='tel' size="10" minlength="9" placeholder='請輸入手機號碼' value={cellphone} onInput={(e) => setCellphone(e.target.value)} ref={register} required />
                                                                        </InputBar>
                                                                        <InputBar inputTitle='電子信箱'>
                                                                            <input id='email' name='email' type='email' placeholder='請輸入電子信箱' value={email} onInput={e => setEmail(e.target.value)} ref={register} required />
                                                                        </InputBar>
                                                                        <InputBar inputTitle='推薦人'>
                                                                            <input id='recommender' name='recommender' placeholder='請輸入推薦人' value={recommender} onInput={e => setRecommender(e.target.value)} ref={register} required />
                                                                        </InputBar>
                                                                        <InputBar inputTitle='地址'>
                                                                            <input id='address' name='address' placeholder='請輸入地址' value={address} onInput={e => setAddress(e.target.value)} ref={register} />
                                                                        </InputBar>
                                                                        <InputBar inputTitle={'優惠代碼'+(promoPrice>0?('(折價:'+promoPrice+'元)'):'')}>
                                                                            <input id='pcode' name='pcode' placeholder='請輸入優惠代碼' value={pcode} onInput={e => setPcode(e.target.value)} ref={register}  />
                                                                        </InputBar>
                                                                        <InputBar inputTitle='備註'>
                                                                            <input id='remark' name='remark' placeholder='請輸入備註' value={remark} onInput={e => setRemark(e.target.value)} ref={register}  />
                                                                        </InputBar>
                                                                    </ContentArea>
                                                                </form>
                                                                <CheckWrapper className={open ? 'open' : ''}>
                                                                    <CheckBar className='black blue'>
                                                                        <input type="checkbox" name="read" id='time' onChange={() => setCheck(!check)} />
                                                                        <span htmlFor="time" className='bold'><p>我已閱讀 彩虹數字-票券訂購 內容所有條款</p></span>
                                                                    </CheckBar>
                                                                    <ToggleDiv>
                                                                        <Text>退票處理辦法</Text>
                                                                        <img src={Images.arrow_down} alt='arrow' className={open ? 'open' : ''} onClick={() => setOpen(!open)} />
                                                                    </ToggleDiv>
                                                                </CheckWrapper>
                                                                <ToggleMore className={open ? 'open' : ''}>
                                                                    <Text>完成報名繳費後因個人因素無法上課，依下列標準退費：</Text>
                                                                    {/* <Text>1.開課日8天前因故申請退費，酌收課程原價10%之行政處理費用。</Text>
                                                                    <Text>2.開課日前3-7天申請退費，酌收課程原價30%之行政處理費用。</Text>
                                                                    <Text>3.開課日前3天內，因個人因素無法到課，恕不接受退費申請。</Text> */}
                                                                    <Text>1. 付款後七日內因故申請退費，將全額退回。</Text>
                                                                    <Text>2. 付款後超過七日因故申請退費，將酌收課程原價5%之金流手續費和行政處理費用。</Text>
                                                                    <Text>3. 課程購買後經確認並開通線上課程影片觀看權限者，將不予受理退費申請。</Text>
                                                                </ToggleMore>
                                                                {selectSession.tickets ? (<><TextCenter>選擇票卷種類</TextCenter>
                                                                    {width > 768 && (
                                                                        <BtnArea>
                                                                            {selectSession.tickets.map((item, index) => (<Button className={ticketIndex == index ? `solid full-width` : `solid third full-width`} onClick={(e) => handleTicketClick(item, index)}>{item.name}/{(item.price-promoPrice)<0?0:(item.price-promoPrice)}元</Button>))}

                                                                        </BtnArea>)}
                                                                    {width <= 768 && selectSession.tickets.map((item, index) => (<Button className={ticketIndex == index ? `solid full-width` : `solid third full-width`} onClick={(e) => handleTicketClick(item, index)}>{item.name}/{(item.price-promoPrice)<0?0:(item.price-promoPrice)}元</Button>))}
                                                                </>) : (<></>)

                                                                }
                                                                <TextCenter>選擇付款方式</TextCenter>
                                                                {width > 768 && (
                                                                    <>
                                                                        <Text>一次付清</Text>
                                                                        <BtnArea>
                                                                            {/* <Button className={payIndex == 0 ? `solid full-width` : `solid third full-width`} onClick={(e) => handleClick(0)}>其他</Button> */}
                                                                            <Button className={payIndex == 1 ? `solid full-width` : `solid third full-width`} onClick={(e) => handleClick(1)}>信用卡</Button>
                                                                            {/* <Button className={payIndex == 5 ? `solid full-width` : `solid third full-width`} onClick={(e) => handleClick(5)}>網路ATM</Button> */}
                                                                            <Button className={payIndex == 6 ? `solid full-width` : `solid third full-width`} onClick={(e) => handleClick(6)}>ATM轉帳</Button>
                                                                            <Button className={payIndex == 7 ? `solid full-width` : `solid third full-width`} onClick={(e) => handleClick(7)}>超商條碼</Button>
                                                                            <Button className={payIndex == 8 ? `solid full-width` : `solid third full-width`} onClick={(e) => handleClick(8)}>超商代碼</Button>

                                                                        </BtnArea>
                                                                        <Text>分期付款</Text>
                                                                        <BtnArea>
                                                                            <Button className={payIndex == 2 ? `solid full-width` : `solid third full-width`} onClick={(e) => handleClick(2)}>信用卡分期-3期</Button>
                                                                            <Button className={payIndex == 3 ? `solid full-width` : `solid third full-width`} onClick={(e) => handleClick(3)}>信用卡分期-6期</Button>
                                                                            <Button className={payIndex == 4 ? `solid full-width` : `solid third full-width`} onClick={(e) => handleClick(4)}>信用卡分期-12期</Button>
                                                                        </BtnArea>
                                                                    </>)}
                                                                {width <= 768 &&
                                                                    <>
                                                                        <Text>一次付清</Text>
                                                                        {/* <Button className={payIndex == 0 ? `solid full-width` : `solid third full-width`} onClick={(e) => handleClick(0)}>其他</Button> */}
                                                                        <Button className={payIndex == 1 ? `solid full-width` : `solid third full-width`} onClick={(e) => handleClick(1)}>信用卡</Button>
                                                                        {/* <Button className={payIndex == 5 ? `solid full-width` : `solid third full-width`} onClick={(e) => handleClick(5)}>網路ATM</Button> */}
                                                                        <Button className={payIndex == 6 ? `solid full-width` : `solid third full-width`} onClick={(e) => handleClick(6)}>ATM轉帳</Button>
                                                                        <Button className={payIndex == 7 ? `solid full-width` : `solid third full-width`} onClick={(e) => handleClick(7)}>超商條碼</Button>
                                                                        <Button className={payIndex == 8 ? `solid full-width` : `solid third full-width`} onClick={(e) => handleClick(8)}>超商代碼</Button>
                                                                        <Text>分期付款</Text>
                                                                        <Button className={payIndex == 2 ? `solid full-width` : `solid third full-width`} onClick={(e) => handleClick(2)}>信用卡分期-3期</Button>
                                                                        <Button className={payIndex == 3 ? `solid full-width` : `solid third full-width`} onClick={(e) => handleClick(3)}>信用卡分期-6期</Button>
                                                                        <Button className={payIndex == 4 ? `solid full-width` : `solid third full-width`} onClick={(e) => handleClick(4)}>信用卡分期-12期</Button>
                                                                    </>}
                                                            </>
                                                        </CardArticleWithTitle>
                                                    }
                                                    {statusId === 2 &&
                                                        <CardArticle>
                                                            <CellBDEmpty
                                                                className='center'
                                                                img={Images.success}
                                                                title='報名成功'
                                                                text='感謝您報名此課程，請至會員頁面查看此課程'
                                                            />
                                                        </CardArticle>
                                                    }
                                                    {statusId === 3 &&
                                                        <CardArticle>
                                                            <CellBDEmpty
                                                                className='center'
                                                                img={Images.error}
                                                                title='報名失敗'
                                                                text='付款失敗，請重新報名，感謝！'
                                                            />
                                                        </CardArticle>
                                                    }

                                                    <Mutation mutation={ADD_UPDATE_TRADE} onError={() => console.log('error')}>
                                                        {(mutate) => (
                                                            <>
                                                                {width > 768
                                                                    ? <Mutation mutation={UPDATE_PROFILE(userId)} onError={() => console.log('error')}>
                                                                        {(mutate2) => (
                                                                            <CardSignUpDetail
                                                                                img={Images.Event_01}
                                                                                title={detail.title}
                                                                                text={detail.excerpt}
                                                                                code={detail.code}
                                                                                //price={selectSession ? `${selectSession.price}` : '--'}
                                                                                price={price}
                                                                                ticketExcerpt={ticketExcerpt}
                                                                                open={detail.open}
                                                                                required={detail.required}
                                                                                duration={selectSession ? selectSession.duration : '--'}
                                                                                teacher={selectSession ? `${selectSession.member.name}` : '--'}
                                                                                location={selectSession ? `${selectSession.classroom.city}` : '--'}
                                                                                content={selectSession && selectSession.content}
                                                                                startTime={selectSession && selectSession.session_start_time}
                                                                                endTime={selectSession && selectSession.session_end_time}
                                                                                classroom={selectSession ? selectSession.classroom.name : '--'}
                                                                                height={height}
                                                                                now={statusId + 1}
                                                                                //check
                                                                                check={Notice}
                                                                                onClick={() => {
                                                                                    setStatusId(statusId + 1)
                                                                                    //setTicketIndex(0)
                                                                                }}
                                                                                prev={() => {
                                                                                    setStatusId(statusId - 1)
                                                                                    //setTicketIndex(0)
                                                                                }}
                                                                                next={() => {
                                                                                    if (process.env.REACT_APP_BRANCH === 'master') {
                                                                                        if (price > 0) {
                                                                                            mutate({
                                                                                                variables: { event_id: id, member_id: userId, payment: "未付款", order_number: "", status: "等待付款", session_id: selectSession.id, ticket_id: ticketID, promo_id: promoID }
                                                                                            })
                                                                                        } else {
                                                                                            mutate({
                                                                                                variables: { event_id: id, member_id: userId, payment: "已付款", order_number: "", status: "已付款", session_id: selectSession.id, ticket_id: ticketID, promo_id: promoID }
                                                                                            })
                                                                                        }
                                                                                        mutate2({
                                                                                            variables: { name: name, gender: gender, birth: birth, cellphone: cellphone, email: email, address: address, remark: remark, recommender: recommender }
                                                                                        })
                                                                                        if (price > 0) {
                                                                                            setTimeout(() => {
                                                                                                window.location.href = `https://pay.rainbownumen.org:443/payment/paymentaction?item_id=${id}&total_amount=${price}&item_name=${encodeURI(detail.title)}&user=${userId}&session=${selectSession.id}&username=${name}&payindex=${payIndex}&ticket=${ticketID}`
                                                                                            }, 1000);
                                                                                        } else {
                                                                                            setTimeout(() => {
                                                                                                window.location.href = `https://www.rainbownumen.org/signUp/${id}?success`
                                                                                            }, 1000);
                                                                                        }

                                                                                    } else {
                                                                                        if (price > 0) {
                                                                                            mutate({
                                                                                                variables: { event_id: id, member_id: userId, payment: "未付款", order_number: "", status: "等待付款", session_id: selectSession.id, ticket_id: ticketID, promo_id: promoID }
                                                                                            })
                                                                                        } else {
                                                                                            mutate({
                                                                                                variables: { event_id: id, member_id: userId, payment: "已付款", order_number: "", status: "已付款", session_id: selectSession.id, ticket_id: ticketID, promo_id: promoID }
                                                                                            })
                                                                                        }
                                                                                        mutate2({
                                                                                            variables: { name: name, gender: gender, birth: birth, cellphone: cellphone, email: email, address: address, remark: remark, recommender: recommender }
                                                                                        })
                                                                                        if (selectSession.price > 0) {
                                                                                            setTimeout(() => {
                                                                                                window.location.href = `http://61.219.19.212:3030/payment/paymentaction?item_id=${id}&total_amount=${price}&item_name=${encodeURI(detail.title)}&user=${userId}&session=${selectSession.id}&username=${name}&payindex=${payIndex}&ticket=${ticketID}`
                                                                                            }, 1000);
                                                                                        } else {
                                                                                            setTimeout(() => {
                                                                                                window.location.href = `http://localhost:3000/signUp/${id}?success`
                                                                                            }, 1000);
                                                                                        }


                                                                                    }
                                                                                    Cookies.set('_rb_ticketId', `${ticket}`, { expires: 1, path: '/' });
                                                                                }}
                                                                                btn_1={ticket ? '' : 'disable'}
                                                                                btn_2={check && Ready ? '' : 'disable '}
                                                                                backDetail={() => history.push(`/eventDetail/${id}`)}
                                                                                goAccount={() => history.push(`/account/2`)}
                                                                                Retry={() => { sessionStorage.removeItem('status'); window.location.reload(); }}
                                                                            />
                                                                        )}
                                                                    </Mutation>
                                                                    : <Mutation mutation={UPDATE_PROFILE(userId)} onError={() => console.log('error')}>
                                                                        {(mutate2) => (
                                                                            <CardEventSignUpFixed
                                                                                detail
                                                                                pack={detail.code.includes('-0') ? true : false}
                                                                                img={Images.Event_01}
                                                                                teacher={selectSession ? `${selectSession.member.name}` : '--'}
                                                                                code={detail.code}
                                                                                title={detail.title}
                                                                                text={detail.excerpt}
                                                                                content={selectSession && selectSession.content}
                                                                                //price={selectSession ? `NT$ ${selectSession.price}` : 'NT$ --'}
                                                                                price={`NT$ ${price}`}
                                                                                ticketExcerpt={ticketExcerpt}
                                                                                open={detail.open}
                                                                                required={detail.required}
                                                                                duration={selectSession ? selectSession.duration : '--'}
                                                                                location={selectSession ? `${selectSession.classroom.city}` : '--'}
                                                                                startTime={selectSession && selectSession.session_start_time}
                                                                                endTime={selectSession && selectSession.session_end_time}
                                                                                classroom={selectSession ? selectSession.classroom.name : '--'}
                                                                                height={height}
                                                                                now={statusId + 1}
                                                                                all='4'
                                                                                check={Notice}
                                                                                // onClick={()=>history.push(`/signUp/${Number(id)+1}`)}
                                                                                onClick={() => {
                                                                                    setStatusId(statusId + 1)
                                                                                    //setTicketIndex(0)
                                                                                }}
                                                                                prev={() => {
                                                                                    setStatusId(statusId - 1)
                                                                                    //setTicketIndex(0)
                                                                                }}
                                                                                profile={UserData && UserData}
                                                                                next={() => {
                                                                                    if (process.env.REACT_APP_BRANCH === 'master') {
                                                                                        if (price > 0) {
                                                                                            mutate({
                                                                                                variables: { event_id: id, member_id: userId, payment: "未付款", order_number: "", status: "等待付款", session_id: selectSession.id, ticket_id: ticketID, promo_id: promoID }
                                                                                            })
                                                                                        } else {
                                                                                            mutate({
                                                                                                variables: { event_id: id, member_id: userId, payment: "已付款", order_number: "", status: "已付款", session_id: selectSession.id, ticket_id: ticketID, promo_id: promoID }
                                                                                            })
                                                                                        }
                                                                                        mutate2({
                                                                                            variables: { name: name, gender: gender, birth: birth, cellphone: cellphone, email: email, address: address, remark: remark, recommender: recommender }
                                                                                        })
                                                                                        if (price > 0) {
                                                                                            setTimeout(() => {
                                                                                                window.location.href = `https://pay.rainbownumen.org:443/payment/paymentaction?item_id=${id}&total_amount=${price}&item_name=${encodeURI(detail.title)}&user=${userId}&session=${selectSession.id}&username=${name}&payindex=${payIndex}&ticket=${ticketID}`
                                                                                            }, 1000);
                                                                                        } else {
                                                                                            setTimeout(() => {
                                                                                                window.location.href = `https://www.rainbownumen.org/signUp/${id}?success`
                                                                                            }, 1000);
                                                                                        }

                                                                                    } else {
                                                                                        if (price > 0) {
                                                                                            mutate({
                                                                                                variables: { event_id: id, member_id: userId, payment: "未付款", order_number: "", status: "等待付款", session_id: selectSession.id, ticket_id: ticketID, promo_id: promoID }
                                                                                            })
                                                                                        } else {
                                                                                            mutate({
                                                                                                variables: { event_id: id, member_id: userId, payment: "已付款", order_number: "", status: "已付款", session_id: selectSession.id, ticket_id: ticketID, promo_id: promoID }
                                                                                            })
                                                                                        }
                                                                                        mutate2({
                                                                                            variables: { name: name, gender: gender, birth: birth, cellphone: cellphone, email: email, address: address, remark: remark, recommender: recommender }
                                                                                        })
                                                                                        if (price > 0) {
                                                                                            setTimeout(() => {
                                                                                                window.location.href = `http://61.219.19.212:3030/payment/paymentaction?item_id=${id}&total_amount=${price}&item_name=${encodeURI(detail.title)}&user=${userId}&session=${selectSession.id}&username=${name}&payindex=${payIndex}&ticket=${ticketID}`
                                                                                            }, 1000);
                                                                                        } else {
                                                                                            setTimeout(() => {
                                                                                                window.location.href = `http://localhost:3000/signUp/${id}?success`
                                                                                            }, 1000);
                                                                                        }

                                                                                    }
                                                                                    Cookies.set('_rb_ticketId', `${ticket}`, { expires: 1, path: '/' });
                                                                                }}
                                                                                btn_1={ticket ? '' : 'disable'}
                                                                                btn_2={check && Ready ? '' : 'disable '}
                                                                                backDetail={() => history.push(`/eventDetail/${id}`)}
                                                                                goAccount={() => history.push(`/account/2`)}
                                                                                Retry={() => { sessionStorage.removeItem('status'); window.location.reload(); }}
                                                                            />
                                                                        )}
                                                                    </Mutation>
                                                                }
                                                            </>
                                                        )}
                                                    </Mutation>
                                                </Content>
                                            }
                                        </>
                                    );
                                }}
                            </Query>
                        </Wrapper>
                    );
                }}
            </Query>
        </Layout>
    );

}

export default SignUpPage;

