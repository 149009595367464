import React from 'react';
import styled from 'styled-components'
import { Colors, Fonts, Metrics } from '../../themes'

// import { Link } from 'react-router-dom'

const Wrapper = styled.div`
    ${'' /* max-width: 349px; */}
    box-sizing: border-box;
    border-radius: 12px;

    @media (max-width: ${Metrics.tablet}){
        display: grid;
        grid-column-gap: 16px;
        align-items: top;
        grid-template-columns: 1fr 1.3fr;
        width: 100%;

        padding: 0;
        margin: 0 auto; 
      }
`
const Img = styled.img`
    max-width: 323px;
    background: ${props => props.bg};
    border-radius: 6px;
    position:relative;
    align-items: center;

    @media (max-width: ${Metrics.tablet}){
        width: 100%;
}
`

const Textarea = styled.div`     
`  

const TitleArea = styled.div`
    display: grid;
    grid-template-columns: 80px 1fr;
    margin-top: 16px;
    align-items: center;

    @media (max-width: ${Metrics.mobile}){
        grid-template-columns: 64px 1fr;
        margin: 0;
}
    
`
const Title = styled(Fonts.h4())`
    
`
const JobTitle = styled(Fonts.h6())`
    color:${Colors.G2}

`

const Text = styled(Fonts.P_16())`
    color:${Colors.G5}
    margin-top: 8px;

    @media (max-width: ${Metrics.tablet}){
        margin-top: 8px;
}

    @media (max-width: ${Metrics.mobile}){
        margin-top: 4px;
}
`

const Card = (props) => {
    return (
        <Wrapper>
            <Img src={props.img} alt=''/>
            <Textarea>
            <TitleArea>
                <Title>{props.title}</Title>
                <JobTitle>{props.jobTitle}</JobTitle>
            </TitleArea>
            <Text>{props.text}</Text>
            </Textarea>
        </Wrapper>
    );
}

export default Card;