import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import { InputBar, InputDateBar, RadioCheck } from '../../components'
import { DatePicker } from 'antd';
import { Fonts, Styles, Metrics, Colors } from '../../themes'
import { useForm } from "react-hook-form";
import ContactData from '../../data/contactRadio'

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    z-index: 102;
    ${'' /* padding: 40px 60px; */}
    @media (max-width: ${Metrics.mobile}){
        height: 100%;
        padding: 8px 20px;
        overflow: scroll;
    }
`
const Content = styled.div`
    @media (max-width: ${Metrics.mobile}){
        position: relative;
    }
    
`
const TextArea = styled.div`
    position: relative;
    padding: 24px;
    z-index: 1;
    @media (max-width: ${Metrics.mobile}){
        padding: 24px 0;
    }
`
const Title = styled(Fonts.h4())`
    margin: 0;
    text-align: center;
    padding: 8px;
    color: ${Colors.B100};
`
const Text = styled(Fonts.P_16())`
    text-align: center;
    color: ${Colors.B100};
    a{
        padding-left: 8px;
        word-wrap: break-word;
        color: ${Colors.Blue};
    }
`
const BtnL = styled(Styles.btnL())`
   margin: 0 auto;
`
const InputArea = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    box-sizing: border-box;
    margin: 16px 0;
    .no-show{
        width: 0;
        height: 0;
        position: absolute;
        opacity: 0;
    }
    @media (max-width: ${Metrics.mobile}){
        display: block;
        height: 100%;
    }
`

const ContactModal = (props) => {

    const [ name, setName ] = useState()
    const [ birth, setBirth ] = useState();
    const [ cellphone, setCellphone ] = useState();
    const [ email, setEmail ] = useState();
    const { register, handleSubmit, setValue } = useForm();

    const onSubmit = data => {
        if(!data.birth){
            data.birth = birth
        }
    };

    useEffect(() => {
        document.getElementById(props.check).checked = true;
    });

    function onChange(date, dateString) {
        setValue("birth", dateString);
        setBirth(dateString)
    }
    return (
        <Wrapper>
            <Content>
                <form id='contact' name='contact' method="POST" data-netlify="true" onSubmit={()=>{alert("傳送成功！");window.location.href = "/reservation"}}>
                <input type="hidden" name="form-name" value="contact" />
                <TextArea>
                    <Title>如欲預約諮詢</Title>
                    <Text>請填寫以下表單，我們會與您聯繫安排諮詢</Text>
                    <InputArea>
                        <InputBar inputTitle='姓名'>
                            <input id='name' name='姓名' placeholder='請輸入姓名' value={name} onInput={(e)=>setName(e.target.value)} ref={register} required />
                        </InputBar>
                        <InputDateBar className='' inputTitle='生日'>
                            <DatePicker id='birth' name='生日' placeholder='2020-01-01' onChange={onChange} onOpenChange={handleSubmit(onSubmit)} require />
                        </InputDateBar>
                        <InputBar inputTitle='手機號碼'>
                            {/*<input id='cellphone' name='手機號碼' type='tel' size="10" minlength="9" placeholder='請輸入手機號碼' value={cellphone} onInput={e => setCellphone(e.target.value)} pattern="09[0-9]{8}" ref={register} required/>*/}
                            <input id='cellphone' name='手機號碼' type='tel' size="10" minlength="9" placeholder='請輸入手機號碼' value={cellphone} onInput={e => setCellphone(e.target.value)} ref={register} required/>
                        </InputBar>
                        <InputBar inputTitle='電子信箱'>
                            <input id='email' name='電子信箱' type='email' placeholder='請輸入電子信箱' value={email} onInput={e => setEmail(e.target.value)} ref={register} required/>
                        </InputBar>
                    </InputArea>
                    <Title>諮詢項目（可複選）</Title>
                    <InputArea>
                        {ContactData.map((i,index) => (
                            <RadioCheck 
                                className=''
                                name={i.text}
                                id={`${i.name}`}
                                value="已選擇"
                                onChange=''
                                text={i.text}
                            />
                        ))}
                    </InputArea>
                </TextArea>
                <BtnL className='solid' type='submit'>我知道了</BtnL>
                </form>
            </Content>
            
        </Wrapper>
    );
}

export default ContactModal;