import React from 'react';
import styled from 'styled-components'
import { Fonts, Colors, Styles } from '../../themes'
import Sticky from 'react-stickynode';
var moment = require('moment');

const Wrapper = styled.div`
    background: white;
    border-radius: 16px;
    box-shadow: 0px 4px 8px 0 ${Colors.B10};
    width: 350px; 
    max-height: 1280px;
    transition: .4s;
    top: 116px !important;
    &.forBottom{
        align-self: end;
    }
`
const Img = styled.img`
    width: 100%;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
`
const TextArea = styled.div`
    padding: 16px 24px 16px;
`
const Title = styled(Fonts.h4())`
`
const Text = styled(Fonts.P_16())`
    padding: 4px 0;
    &.info{
        color: ${Colors.G2}
    }
`
const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 45px;
    padding: 10px 0;
    
`
const Price = styled(Fonts.h4())`
    color:${Colors.Blue};
`

const Required = styled.p`
    background: ${Colors.Light_Red};
    padding: 2px 4px;
    border-radius: 4px;
    color: ${Colors.Red};
    text-align: center;
    align-self: end;
    margin: 0;
`
const InfoArea = styled.div`
    padding: 12px 0;
    border-top: 1px solid ${Colors.B10};
`
const InfoName = styled(Fonts.h6())`
`
const Button = styled(Styles.btnL())`
`
const BtnArea = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    justify-items: center;
    width: 100%;
    bottom: 0px !important;
    z-index: 1000;
    margin-bottom: 16px;
    button{
        margin: 0 4px;
    }
    p{
        margin: 0 !important;
    }
`
const Alert = styled.p`
    color: ${Colors.Red};
    position: absolute;
    bottom: 6px;
    right: 24px;
`

const CardSignUpDetail = (props) => {
    const [bottom, setBottom] = React.useState(false)
    const height = props.height + 100

    React.useEffect(() => {
        if (window.pageYOffset > 0) {
            setBottom(true)
            // console.log(height)
        } else {
            setBottom(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Sticky enabled={true} top={116} bottomBoundary={height} activeClass='sticky' releasedClass='noSticky'>
            <Wrapper className={`${props.className} ${bottom ? 'forBottom' : ''}`} >
                <Img src={props.img} alt='' className='img' />
                <TextArea>
                    <Title>{props.code}</Title>
                    <Title>{props.title}</Title>
                    <Text>{props.text}</Text>
                    <Grid>
                        <Price>NT$ {props.price}</Price>
                        {props.required === '必修' &&
                            <div><Required>{props.required}</Required></div>
                        }
                    </Grid>
                    <InfoArea>
                        <Text className='info'>課程講師：{props.teacher}</Text>
                        <Text className='info'>課程總長：{props.duration} {props.duration && '小時'}</Text>
                        <Text className='info'>課程地點：{props.location}</Text>
                        {props.content
                            ? <Text className='info'>上課時間：{props.content}</Text>
                            : <Text className='info'>上課時間：--</Text>
                        }

                        {/* {props.startTime && props.endTime 
                            ? <>
                                {moment(props.startTime).format('MM/DD') === moment(props.endTime).format('MM/DD')
                                    ? <Text className='info'>上課時間：{moment(props.startTime).format('YYYY/MM/DD (dd) a H:mm')} ~ {moment(props.endTime).format('a H:mm')}</Text>
                                    : <Text className='info'>上課時間：{moment(props.startTime).format('YYYY/MM/DD (dd) a H:mm')} ~ {moment(props.endTime).format('MM/DD (dd) a H:mm')}</Text>
                                }
                            </>
                            : <Text className='info'>上課時間：--</Text>
                        } */}

                        {/* {props.two 
                            ? <Text className='info'>上課時間：{props.startTime}</Text>
                            : <>
                                {props.data.map( (i,index) => (
                                    <Text className='info'>上課時間：{i.time}</Text>
                                ))}
                            </>
                        } */}
                        <Text className='info'>上課教室：{props.classroom}</Text>
                        <Text className='info'>票卷說明：{props.ticketExcerpt}</Text>
                    </InfoArea>
                    {props.now === 1 && props.open === '可報名' &&
                        <Button className={`solid full-width ${props.btn_1}`} onClick={props.onClick}>立即報名</Button>
                    }
                    {props.now === 1 && props.open === '未開放' &&
                        <Button className={`solid full-width disabled`} >尚未開放報名</Button>
                    }
                    {props.now === 2 &&
                        <BtnArea>
                            <Button className={`solid third full-width`} onClick={props.prev}>上一步</Button>
                            <Button className={`solid ${props.btn_2} full-width`} onClick={props.next}>下一步</Button>
                            {props.check === 'both' &&
                                <Alert>請檢查手機與 Email 輸入資料</Alert>
                            }
                            {props.check === 'email' &&
                                <Alert>請檢查 Email 輸入資料</Alert>
                            }
                            {props.check === 'cellphone' &&
                                <Alert>請檢查手機輸入資料</Alert>
                            }
                            {/*props.check === 'address' &&
                                <Alert>請檢查地址輸入資料</Alert>
                        */}
                            {
                                props.check === 'gender' &&
                                <Alert>請檢查性別輸入資料</Alert>
                            }
                            {
                                props.check === 'name' &&
                                <Alert>請檢查性名輸入資料</Alert>
                            }
                            {
                                props.check === 'birth' &&
                                <Alert>請檢查生日輸入資料</Alert>
                            }
                            {
                                props.check === 'none' && props.btn_2 &&
                                <Alert>請檢查欄位並勾選同意</Alert>
                            }
                        </BtnArea>
                    }
                    {props.now === 3 &&
                        <BtnArea>
                            <Button className={`solid third full-width`} onClick={props.backDetail}>返回頁面</Button>
                            <Button className={`solid full-width`} onClick={props.goAccount}>前往會員頁</Button>
                        </BtnArea>
                    }
                    {props.now === 4 &&
                        <Button className={`solid full-width`} onClick={props.Retry}>重新報名</Button>
                    }
                </TextArea>
            </Wrapper>
        </Sticky>
    );
}

export default CardSignUpDetail;