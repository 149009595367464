import React from 'react'
import styled from 'styled-components'
import { Colors, Metrics } from '../../themes'
import 'antd/dist/antd.css';

const InputArea = styled.div`
    width: 100%;
    ${'' /* margin: 8px 0; */}
    padding: 8px 8px 4px;
    position: relative;
    display: grid;
    max-width: 344px;
    .ant-picker,.ant-space,input[type=date],input[type=time]{
        width: 100%;
        max-width: 344px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        z-index: 0;
        background: ${Colors.W10};
        color: ${Colors.B100};
    }
    &.black .ant-picker,.ant-space,input[type=date],input[type=time]{
        color: ${Colors.B100};
    }
    input[type=date],input[type=time]{
        min-height: 40px;
    }
    .ant-picker,input[type=date],input[type=time]{
        border: 1px solid ${Colors.B10};
        border-radius: 6px;
        padding: 8px 16px;
        transition: .2s;
        svg{
            fill: ${Colors.B30};
        }
    }
    &.black .ant-picker,input[type=date],input[type=time]{
        border: 1px solid ${Colors.B10};
        svg{
            fill: ${Colors.B30};
        }
    }
    &.black .ant-picker:hover,input[type=date]:hover,input[type=time]:hover{
        border: 1px solid ${Colors.Blue};
        svg{
            fill: ${Colors.Blue};
        }
    }
    input[type="time"]::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }
    .ant-picker-focused{
        border: 1px solid ${Colors.Blue};
        box-shadow: inherit !important;
        width: 100%;
        svg{
            fill: ${Colors.Blue};
        }
    }
    &.black .ant-picker-focused{
        border: 1px solid ${Colors.Blue};
        svg{
            fill: ${Colors.Blue};
        }
    }
    input[type=time]:focus,input[type=date]:focus{
        border: 1px solid ${Colors.Blue};
        box-shadow: inherit !important;
        width: 100%;
        outline: none;
    }
    &.black input[type=time]:focus,&.black input[type=date]:focus{
        border: 1px solid ${Colors.Blue};
    }
    img {
        position: absolute;
        left: 90%;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        pointer-events: none;
        filter: invert(0.5);
        width: 16px;
        height: 16px;
    }
    input[type=time]:focus + img,input[type=date]:focus + img{
        filter: invert(0.4) sepia(1) saturate(20) hue-rotate(187.2deg) brightness(0.7);
    }
    input[type="date"]:before, input[type="time"]:before{ 
    	content: attr(placeholder);
    	color: ${Colors.G2} !important;
    }
    input[type="date"], input[type="time"]{ 
    	color: ${Colors.B100}!important;
        font-size: 14px;
    }
    &.black input[type="date"], &.black input[type="time"]{ 
    	color: ${Colors.B100}!important;
    }
    &.black input[type="date"]:before, &.black input[type="time"]:before{ 
        color: ${Colors.G2} !important;
    }
    input[type="date"]:focus:before,input[type="time"]:focus:before,
    .no-placeholder:valid:before { 
        display: none 
    }
    input[type="date"]::-webkit-calendar-picker-indicator,
    input[type="time"]::-webkit-calendar-picker-indicator{
        opacity: 0
    }
    .disable{
        background: ${Colors.B10} !important;
        border: 1px solid rgba(0,0,0,0.1) !important;
        pointer-events: none;
    }
    &.black .disable{
        background: ${Colors.B30};
    }
    .alert{
        border: 1px solid ${Colors.Red};
    }
    .ant-picker-input > input{
        font-size: 14px;
        letter-spacing: 0;
        line-height: 22px;
        color: ${Colors.B100};
    }
    &.black .ant-picker-input > input{
        color: ${Colors.B100};
    }
    &.black p{
        color: ${Colors.B100};
    }
    @media (max-width: ${Metrics.mobile}){
        max-width: inherit;
        .ant-picker,.ant-space,input[type=date],input[type=time]{
            max-width: 100% !important;
        }
    }
    &.top{
        z-index: 1000000;
    }
    &.disable:hover{
        cursor: not-allowed !important;
    }
`
const InputTitle = styled.p`
    font-size: 12px;
    letter-spacing: 0;
    line-height: 17px;
    position: relative;
    width: 100%;
    padding-left: 8px;
    color: ${Colors.B100};
    @media (max-width: ${Metrics.mobile}){
        padding-left: inherit;
    }
`
const Input = styled.div`
    position: relative
`


const InputDateBar = ( props ) => {
    const { children } = props
    return ( 
        <InputArea className={props.className} ref={props.ref}>
            <InputTitle>{props.inputTitle}</InputTitle>
            <Input>
            { children }
            </Input>
        </InputArea>
    )
  }
  
  export default InputDateBar