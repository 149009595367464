import React from 'react';
import { useHistory } from "react-router-dom";
import styled from 'styled-components'
import {
    Seo, Layout, CardArticleWithTitle, CellIconTitle,
    CellAccountLevel, CellBDEmpty, CellTable, CardArticleWithTab,
    CardTicket, CardTicketDone, EditProfileModal, EditRecommendModal,
    RefundModal, SelectBar, OutputText, Calculator, RefundSuccessModal,
    RefundFailModal, CheckCourseModal
} from '../components'
import { Colors, Images, Fonts, Metrics, SelectStyles, Styles } from '../themes'
import '../css/modalWhite.css'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Modal from 'react-modal'
import Select from 'react-select'
import Sticky from 'react-stickynode';
import { Query } from "react-apollo";
import { GET_EVENTLIST, GET_MEMBER } from '../api';
import Cookies from 'js-cookie';

const FormStyles = {
    overlay: {
        backgroundColor: "rgba(0,0,0,0.5)",
    },
    content: {
        position: 'relative',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '16px',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
        zIndex: '1000',
        width: '100%',
        maxWidth: '640px',
        padding: '0'
    }
};
const customStyles = {
    overlay: {
        backgroundColor: "rgba(0,0,0,0.5)",
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '16px',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
        zIndex: '1000',
    }
}
const Close = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 10000;
    width: 24px;
    height: 24px;
    &:hover{
        cursor: pointer;
    }
`

const Wrapper = styled.div`
    padding: 24px 16px;
    display: grid;
    grid-template-columns: 220px auto;
    max-width: 1000px;
    margin: 0 auto;
    @media (max-width: ${Metrics.mobile}){
        grid-template-columns: 100%;
        padding: 0;
    }
`
const MobileTab = styled.div`
`
const TabOption = styled.div`
    position: relative;
    max-width: 152px;
    margin: 0 16px;
    .LearnTab{
        max-width: 152px;
        margin: 0 auto;
        box-sizing: border-box;
    }
    .MuiTabs-flexContainer{
        display: grid;
        justify-items: start;
    }
    .MuiTabs-indicator{
        display: none;
    }
    
    .MuiButtonBase-root{
        text-align: left;
        margin: 2px 0;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
    }
    .Mui-selected{
        color: #3578F1;
    }
    .MuiTab-root{
        min-width: inherit;
        min-height: inherit;
    }
    @media (max-width: ${Metrics.mobile}){
        max-width: inherit;
        margin: 0;
        
    }
`
const TabTitle = styled(Fonts.h4())`
    padding: 10px;
`
const TabArea = styled.div`
    margin: 0 16px;
`
const Title = styled(Fonts.h5())`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin: 8px 0 8px;
    &:first-child{
        margin-top: 0;
    }
`
const Text = styled(Fonts.P_16())`
    ${'' /* margin-top: 8px; */}
    color:${Colors.G3};
    white-space: pre-wrap;
    &.br{
        padding-bottom: 20px;
    }
    &:last-child{
        ${'' /* padding-bottom: 20px; */}
    }
`
const TextArea = styled.div`
    padding: 16px 24px 28px;
`
const BtnL = styled(Styles.btnL())`
    margin: 16px auto;
    @media (max-width: ${Metrics.mobile}){
        width: 100%;
    }
`

const AccountPage = (props) => {
    const id = props.match?.params?.id;
    const [value, setValue] = React.useState(0);
    const history = useHistory();
    const [valueInCard, setValueInCard] = React.useState(0);
    const [height, setHeight] = React.useState(0);
    const [userId, setUserId] = React.useState()
    const [orderNum, setOrderNum] = React.useState();
    const [memberName, setMemberName] = React.useState();
    const elementRef = React.createRef();
    const tabs = [
        { value: "0", label: "總覽" },
        { value: "1", label: "設定" },
        { value: "2", label: "修課紀錄" },
    ];
    const handleChange = (event, newValue) => {
        setValue(newValue);
        history.push(`${newValue}`)
    };
    const handleChangeInCard = (event, newValue) => {
        setValueInCard(newValue);
    };
    const MobileSelect = select => {
        setValue(Number(select.value));
        history.push(`${select.value}`)
    }
    function GoToSetting() {
        setValue(1);
        history.push(`/account/1`)
    }
    //ModalProfile
    const [ProfileIsOpen, setProfileIsOpen] = React.useState(false);
    function openProfile() {
        setProfileIsOpen(true);
    }
    function closeProfile() {
        setProfileIsOpen(false);
    }
    //ModalRecom
    const [RecomIsOpen, setRecomIsOpen] = React.useState(false);
    function openRecom() {
        setRecomIsOpen(true);
    }
    function closeRecom() {
        setRecomIsOpen(false);
    }
    //ModalRefund
    const [RefundIsOpen, setRefundIsOpen] = React.useState(false);
    function openRefund(data, name) {
        setRefundIsOpen(true);
        setOrderNum(data)
        setMemberName(name)
    }
    function closeRefund() {
        setRefundIsOpen(false);
    }
    //ModalRefund
    const [RefundSuccessIsOpen, setRefundSuccessIsOpen] = React.useState(false);
    function openRefundSuccess(data) {
        setRefundSuccessIsOpen(true);
    }
    function closeRefundSuccess() {
        setRefundSuccessIsOpen(false);
    }
    //ModalRefund
    const [RefundFailIsOpen, setRefundFailIsOpen] = React.useState(false);
    function openRefundFail(data) {
        setRefundFailIsOpen(true);
    }
    function closeRefundFail() {
        setRefundFailIsOpen(false);
    }
    //ModalCalculator
    const [modalIsOpen, setIsOpen] = React.useState(false);
    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }
    //ModalCheckCourse
    const [CourseIsOpen, setCourseIsOpen] = React.useState(false);
    // function openCourse() {
    //     setCourseIsOpen(true);
    // }
    function closeCourse() {
        setCourseIsOpen(false);
    }


    React.useEffect(() => {
        setValue(Number(id))
        if (Cookies.get('rainbow')) {
            setUserId(Cookies.get('rainbow'))
        } else {
            window.location.href = '/'
        }
        if (elementRef.current) {
            setHeight(elementRef.current.offsetHeight);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function Logout() {
        sessionStorage.clear();
        Cookies.remove('rainbow')
        window.location.href = '/'
    }

    return (
        <Layout>
            <Seo
                title='彩虹數字學會 - 功能總覽'
                metaDescription='運用彩虹數字解讀你的生命密碼，了解自己的生命課題。學習「心性解碼」、「時間解碼」、「感情解碼」課程，免費試算自己的數字。'
                metaTitle='彩虹數字學會'
                metaUrl=''
                //metaImage={`${Images.Rainbow_SEO}`}
                metaImage='https://rainbow-digital.glitch.me/i/ktlc6ygb.png'
                metaImageAlt=''
            />
            <Wrapper ref={elementRef}>
                {/* web tab */}
                <Sticky enabled={true} top={113} bottomBoundary={height + 100} activeClass='sticky' releasedClass='noSticky' className='web'>
                    <TabOption>
                        <TabTitle>基本資料</TabTitle>
                        <Tabs
                            value={value}
                            className='LearnTab'
                            onChange={handleChange}
                        >
                            <Tab label='總覽' />
                            <Tab label='設定' />
                            <Tab label='修課紀錄' />
                            {/* <button onClick={openCourse}>上課表單</button> */}
                        </Tabs>
                    </TabOption>
                </Sticky>
                {/* mobile tab */}
                <MobileTab className='mobile'>
                    <TabOption>
                        <SelectBar>
                            <Select
                                options={tabs}
                                name='tabs'
                                placeholder='請選擇'
                                isSearchable={false}
                                styles={SelectStyles}
                                defaultValue={tabs[0]}
                                value={tabs[value]}
                                onChange={MobileSelect}
                            />
                        </SelectBar>
                    </TabOption>
                </MobileTab>
                {value === 0 &&
                    <TabArea>
                        <CardArticleWithTitle bigTitle='會員總覽'>
                            <Query query={GET_MEMBER(userId)}>
                                {({ loading, error, data, refetch }) => {
                                    return (
                                        <>
                                            {data &&
                                                <>
                                                    {data.member.length > 0 &&
                                                        <>
                                                            <CellAccountLevel
                                                                img={data.member[0].picture}
                                                                name={data.member[0].name}
                                                                title={`Lv.${data.member[0].score === null ? '0' : data.member[0].score}`}
                                                            />
                                                            <CellIconTitle
                                                                icon={Images.birthday}
                                                                alt='birthday-icon'
                                                                title='我的生日密碼'
                                                            />
                                                            {data.member[0].birth
                                                                ? <>
                                                                    <Title>{data.member[0].birth && data.member[0].birth.replace('-', '/').replace('-', '/')}</Title>
                                                                    <OutputText date={data.member[0].birth} />
                                                                    <BtnL onClick={openModal}>算算其他生日</BtnL>
                                                                </>
                                                                : <>
                                                                    <CellBDEmpty
                                                                        img={Images.ask}
                                                                        title='尚無結果'
                                                                        text='想知道你的生日秘密嗎？\n請至個人資料設定 > 輸入您的生日'
                                                                        btnTitle='前往設定'
                                                                        onClick={GoToSetting}
                                                                    />
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            }
                                        </>
                                    );
                                }}
                            </Query>
                        </CardArticleWithTitle>
                    </TabArea>
                }
                {value === 1 &&
                    <TabArea>
                        <CardArticleWithTitle bigTitle='個人資料設定'>
                            <Query query={GET_MEMBER(userId)}>
                                {({ loading, error, data, refetch }) => {
                                    let gender
                                    if (data) {
                                        if (data.member.length > 0) {
                                            if (data.member[0].gender === 'M') {
                                                gender = '男'
                                            } else if (data.member[0].gender === 'F') {
                                                gender = '女'
                                            } else {
                                                gender = data.member[0].gender
                                            }
                                        }
                                    }
                                    return (
                                        <>
                                            {data &&
                                                <>
                                                    {data.member.length > 0 &&
                                                        <>
                                                            <CellIconTitle icon={Images.phone} alt='phone-icon'
                                                                title='登入方式'
                                                            />
                                                            <CellTable
                                                                title='登入方式'
                                                                text={data.member[0].id.indexOf('google') >= 0 ? '以 Google 登入' : '以 Facebook 登入'}
                                                                logout
                                                                onClick={Logout}
                                                            />
                                                            {/* <CellIconTitle icon={Images.medal} alt='medal-icon'
                                                title='會員資訊'
                                            /> */}
                                                            <CellIconTitle icon={Images.profile} alt='profile-icon'
                                                                title='基本資料'
                                                                onClick={openProfile}
                                                            />
                                                            <CellTable title='姓名' text={data.member[0].name} />
                                                            <CellTable title='生理性別' text={gender} />
                                                            <CellTable title='生日' text={data.member[0].birth && data.member[0].birth.replace('-', '/').replace('-', '/')} />
                                                            <CellTable title='手機號碼' text={data.member[0].cellphone} />
                                                            <CellTable title='電子信箱' text={data.member[0].email !== 'undefined' ? data.member[0].email : ''} />
                                                            <CellTable title='地址' text={data.member[0].address} />
                                                            {/* <CellIconTitle icon={Images.name} alt='name-icon'
                                                title='自我介紹'
                                                onClick={openRecom}
                                            />
                                            <Text>{data.member[0].info}</Text> */}
                                                        </>
                                                    }
                                                </>
                                            }
                                        </>
                                    );
                                }}
                            </Query>
                        </CardArticleWithTitle>
                    </TabArea>
                }
                {value === 2 &&
                    <TabArea>
                        <CardArticleWithTab bigTitle='修課紀錄' >
                            <Query query={GET_EVENTLIST(userId)}>
                                {({ loading, error, data, refetch }) => {
                                    let A;
                                    let B;
                                    let C;
                                    let D;
                                    let E;
                                    if (data) {
                                        A = data.member_event_session.filter(i => i.status === '已付款')
                                        B = data.member_event_session.filter(i => i.status === '等待付款')
                                        C = data.member_event_session.filter(i => i.status === '處理中')
                                        D = data.member_event_session.filter(i => i.status === '已退費')
                                        E = data.member_event_session.filter(i => i.status === '已結束')
                                    }
                                    return (
                                        <>
                                            {data &&
                                                <Tabs
                                                    value={valueInCard}
                                                    className='InCardTab'
                                                    onChange={handleChangeInCard}
                                                >
                                                    <Tab label={`已付款(${A.length})`} />
                                                    <Tab label={`等待付款(${B.length})`} />
                                                    <Tab label={`處理中(${C.length})`} />
                                                    <Tab label={`已退費(${D.length})`} />
                                                    <Tab label={`已結束(${E.length})`} />
                                                </Tabs>
                                            }
                                        </>
                                    );
                                }}
                            </Query>
                            {valueInCard === 0 &&
                                <Query query={GET_EVENTLIST(userId)}>
                                    {({ loading, error, data, refetch }) => {
                                        let A;
                                        if (data) {
                                            A = data.member_event_session.filter(i => i.status === '已付款')
                                        }
                                        return (
                                            <>
                                                {data &&
                                                    <TextArea>
                                                        {A.map((item, index) => (
                                                            <CardTicket
                                                                key={`${item.event.title}-${index}`}
                                                                title={item.event.title}
                                                                //price={`NT$ ${item.session.price}`}
                                                                control='申請退票'
                                                                status=''
                                                                onClick={() => openRefund(item.order_number,item.member.name)}
                                                                content={item.session.content}
                                                                time_start={item.session.session_start_time}
                                                                time_end={item.session.session_start_end}
                                                                location={`${item.session.classroom.city} - ${item.session.classroom.name} (${item.session.classroom.address})`}
                                                                ticket_id={item.order_number}
                                                                id={item.event_id}
                                                            />
                                                        ))}
                                                    </TextArea>
                                                }
                                            </>
                                        );
                                    }}
                                </Query>
                            }
                            {valueInCard === 1 &&
                                <Query query={GET_EVENTLIST(userId)}>
                                    {({ loading, error, data, refetch }) => {
                                        let B;
                                        if (data) {
                                            B = data.member_event_session.filter(i => i.status === '等待付款')
                                        }
                                        return (
                                            <>
                                                {data &&
                                                    <TextArea>
                                                        {B.map((item, index) => (
                                                            <CardTicket
                                                                key={`${item.event.title}-${index}`}
                                                                title={item.event.title}
                                                                //price={`NT$ ${item.session.price}`}
                                                                control='立即付款'
                                                                status='pay'
                                                                onClick={() => {
                                                                    if (item.event.title.includes('-0') && !item.event.title.includes('C')) {
                                                                        history.push(`/eventDetailPack/${item.event_id}`)
                                                                    } else {
                                                                        history.push(`/eventDetail/${item.event_id}`)
                                                                    }
                                                                }}
                                                                content={item.session.content}
                                                                time_start={item.session.session_start_time}
                                                                time_end={item.session.session_start_end}
                                                                location={`${item.session.classroom.city} - ${item.session.classroom.name} (${item.session.classroom.address})`}
                                                                ticket_id={item.order_number}
                                                                id={item.event_id}
                                                            />
                                                        ))}
                                                    </TextArea>
                                                }
                                            </>
                                        );
                                    }}
                                </Query>
                            }
                            {valueInCard === 2 &&
                                <Query query={GET_EVENTLIST(userId)}>
                                    {({ loading, error, data, refetch }) => {
                                        let C;
                                        if (data) {
                                            C = data.member_event_session.filter(i => i.status === '處理中')
                                        }
                                        return (
                                            <>
                                                {data &&
                                                    <TextArea>
                                                        {C.map((item, index) => (
                                                            <CardTicket
                                                                key={`${item.event.title}-${index}`}
                                                                title={item.event.title}
                                                                //price={`NT$ ${item.session.price}`}
                                                                control='處理中'
                                                                status='dealWith'
                                                                content={item.session.content}
                                                                time_start={item.session.session_start_time}
                                                                time_end={item.session.session_start_end}
                                                                location={`${item.session.classroom.city} - ${item.session.classroom.name} (${item.session.classroom.address})`}
                                                                ticket_id={item.order_number}
                                                                id={item.event_id}
                                                            />
                                                        ))}
                                                    </TextArea>
                                                }
                                            </>
                                        );
                                    }}
                                </Query>
                            }
                            {valueInCard === 3 &&
                                <Query query={GET_EVENTLIST(userId)}>
                                    {({ loading, error, data, refetch }) => {
                                        let D;
                                        if (data) {
                                            D = data.member_event_session.filter(i => i.status === '已退費')
                                        }
                                        return (
                                            <>
                                                {data &&
                                                    <TextArea>
                                                        {D.map((item, index) => (
                                                            <CardTicket
                                                                key={`${item.event.title}-${index}`}
                                                                title={item.event.title}
                                                                //price={`NT$ ${item.session.price}`}
                                                                control='重新報名'
                                                                onClick={() => {
                                                                    if (item.event.title.includes('-0') && !item.event.title.includes('C')) {
                                                                        history.push(`/eventDetailPack/${item.event_id}`)
                                                                    } else {
                                                                        history.push(`/eventDetail/${item.event_id}`)
                                                                    }
                                                                }}
                                                                status='resign'
                                                                content={item.session.content}
                                                                time_start={item.session.session_start_time}
                                                                time_end={item.session.session_start_end}
                                                                location={`${item.session.classroom.city} - ${item.session.classroom.name} (${item.session.classroom.address})`}
                                                                ticket_id={item.order_number}
                                                                id={item.event_id}
                                                            />
                                                        ))}
                                                    </TextArea>
                                                }
                                            </>
                                        );
                                    }}
                                </Query>
                            }
                            {valueInCard === 4 &&
                                <Query query={GET_EVENTLIST(userId)}>
                                    {({ loading, error, data, refetch }) => {
                                        let E;
                                        if (data) {
                                            E = data.member_event_session.filter(i => i.status === '已結束')
                                        }
                                        return (
                                            <>
                                                {data &&
                                                    <TextArea>
                                                        {E.map((item, index) => (
                                                            <CardTicketDone
                                                                key={`${item.event.title}-${index}`}
                                                                title={item.event.title}
                                                            //price={`NT$ ${item.session.price}`}
                                                            />
                                                        ))}
                                                    </TextArea>
                                                }
                                            </>
                                        );
                                    }}
                                </Query>
                            }
                        </CardArticleWithTab>
                    </TabArea>
                }
            </Wrapper>
            <Modal
                isOpen={ProfileIsOpen}
                onRequestClose={closeProfile}
                style={FormStyles}
                contentLabel="Example Modal"
            >
                <EditProfileModal
                    onClick={closeProfile}
                    close={closeProfile}
                // submit={closeProfile}
                />
            </Modal>
            {/* <Modal
                isOpen={RecomIsOpen}
                onRequestClose={closeRecom}
                style={FormStyles}
                contentLabel="Recommend Modal"
            >
                <EditRecommendModal
                    onClick={closeRecom}
                    close={closeRecom}
                    submit={closeRecom}
                />
            </Modal> */}
            <Modal
                isOpen={RefundIsOpen}
                onRequestClose={closeRefund}
                style={FormStyles}
                contentLabel="Refund Modal"
                className='white'
            >
                <RefundModal
                    submit={() => {
                        closeRefund();
                        openRefundSuccess();
                    }}
                    close={closeRefund}
                    error={() => {
                        closeRefund();
                        openRefundFail();
                    }}
                    userId={userId}
                    orderNum={orderNum}
                    memberName={memberName}
                />
            </Modal>
            <Modal
                isOpen={RefundSuccessIsOpen}
                onRequestClose={closeRefundSuccess}
                style={FormStyles}
                contentLabel="RefundSuccess Modal"
                className='white'
            >
                <RefundSuccessModal
                    onClick={closeRefundSuccess}
                    close={closeRefundSuccess}
                    userId={userId}
                    orderNum={orderNum}
                    memberName={memberName}
                />
            </Modal>
            <Modal
                isOpen={RefundFailIsOpen}
                onRequestClose={closeRefundFail}
                style={FormStyles}
                contentLabel="RefundFail Modal"
                className='white'
            >
                <RefundFailModal
                    onClick={closeRefundFail}
                    close={closeRefundFail}
                    userId={userId}
                    orderNum={orderNum}
                    memberName={memberName}
                />
            </Modal>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <Close onClick={closeModal}>
                    <img src={Images.close} alt='' />
                </Close>
                <Calculator />
            </Modal>
            {/*
            <Modal
                isOpen={CourseIsOpen}
                onRequestClose={closeCourse}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <Close onClick={closeCourse}>
                    <img src={Images.close} alt='' />
                </Close>
                <CheckCourseModal />
            </Modal>*/}

        </Layout>
    );
}

export default AccountPage;