import React, { useState, useRef } from 'react';
import styled from 'styled-components'
import { Images, Colors, Fonts } from '../themes'
import { CalculateBtn, InputDateBar, CheckBar } from '.'
import Slider from "react-slick"
import { DatePicker, Space, TimePicker } from 'antd';
import { isMobileOnly, isSafari } from 'react-device-detect';
var moment = require('moment');
const { Solar, Lunar, HolidayUtil } = require('lunar-javascript')

const CheckOne = [
    '不太會堅持自己想法的人。',
    '不太會表達自己的人。通常很多話還沒有說出口，就會先打退堂鼓。',
    '會尊重別人，也會說出自己想法的人，但是你卻不會堅持己見，所以，即使對方不接受你的意見，你也覺得無所謂。',
    '說到做到、貫徹執行的人。因此，通常不需要別人要求，你自己就會設定好目標和進度的把事情完成。',
    '外柔內剛的人。通常你對事情都會有自己的想法，可是卻往往會為了配合別人而不願說出來，但是當結果不如預期時，你又會自己氣自己。',
    '外表缺乏自信、會自我否定、不敢表達己見、只會配合別人的人，但是其實你的內在卻是一位堅持已見、愈挫愈勇、堅強又具有意志力的人。',
    '外表不會堅持己見，會尊重並配合別人的人，但是其實你的內在卻是一位堅持目標、愈挫愈勇、堅強又具有意志力的人。'
]
const CheckNight = [
    '不太習慣地球人類的文明和傳統，因此常常會感覺到與世界格格不入。',
    '相信命運的存在，有時會想探索生命意義，或是去算命。',
    '有些孤僻，有時會喜歡離群索居，把自己關在房間裡，用土法煉鋼的方式自行研究感興趣的事物。',
    '很有智慧又有點自負，因此在面對事情時，常會表現出一副老神在在的樣子。',
    '很純真且富有想像力，有時還會希望自己能夠擁有瞬間移動的特異功能。',
    '很喜歡看美人魚、獨角獸、半人半獸等類型的故事，或是會在夢中夢到這些景象。'
]
const CheckZero = [
    '喜歡單純、不複雜的生活和工作，一遇到比較複雜的問題或事情，還沒開始做，頭就先痛了。',
    '具有戰鬥性格，但是在做決定時又常常覺得這樣做也不是，那樣做也不對，而會比較掙扎些。',
    '不喜歡傷害別人，在觀念上認為男女平等、眾生平等，是一位和平主義者。',
    '想法很另類，在別人眼中是一個怪胎。',
    '是一個帶有高能量頻率的新人類，無懼、有膽識，對任何事並沒有太多的執著，只在乎是否和諧。',
    '對外在時空的敏感度非常的強，是一個帶有高能量頻率的新人類。'
]
const NumberOptions = [
    '',
    '',
    '溫柔，細膩，懂得傾聽，會委曲求全的來配合別人；',
    '樂觀開朗，喜歡學習新事物，對任何事情都充滿好奇心，常有創意新點子；',
    '務實，沉穩，腳踏實地，很有責任感；',
    '喜歡自由自在，富有正義感，熱愛戶外活動，又深具冒險精神；',
    '善解人意，熱心服務，愛心、慈悲心，追求完美主義；',
    '質疑性強，會打破砂鍋問到底，重視氛圍，經常有莫名其妙的好運氣；',
    '察言觀色，謹言慎行，懂得以退為進，也會忍辱負重；',
    '',
]
const SoulLVOptions = [
    { title: '第一級', text: '受爸爸影響很深。每當事情做不好或是做錯事時會很容易哭，尤其不喜歡有人對你大聲講話或是大聲罵你。' },
    { title: '第二級', text: '對老師(或上司)很尊敬，也會乖乖聽老師(或上司)的話，只要老師(或上司) 幫你設定好時間和目標，你都會把事情做好。' },
    { title: '第三級', text: '很叛逆，不喜歡別人用權威的方式來命令你。但是你喜歡熱鬧、喜歡和朋友搏感情，遇到問題時也只會找朋友商量。' },
    { title: '第四級', text: '不喜歡被說教，不想聽別人重複講你已經知道的事情；但是對於不懂的事情你卻又會想追根究底的問清楚。' },
    { title: '第五級', text: '希望別人尊重你，如果尊重你，你就會充滿熱忱的傾囊相受；否則會不想理對方。' },
    { title: '第六級', text: '是一個聰明又有智慧的人，但是卻因為會壓抑自己的個性，悶著頭做自己的事，因此常會有一股莫名的鬱卒感。' },
    { title: '第七級', text: '是一個聰明又有智慧的人，遇到任何問題幾乎都有能力自己解決，可是當發覺沒辦法解決或是學不會時，就會懊惱自己為什麼不會。' },
]

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    max-width: 568px;
    max-height: 683px;
    min-height: 400px;
    padding-bottom: 80px;
    .slick-slider{
        width: 100%;
    }
    .slick-next, .slick-prev{
        position: relative;
        display: flex;
        top: 0;
        left: 50%;
        background: #000;
    }
    @media (max-width: 425px){
        max-height: inherit;
        display: flex;
        align-items: center;
    }
`
const Content = styled.div`
    position: relative;
    display: grid !important;
    justify-items: center;
    padding: 46px 24px 20px;
    width: 90%;
    box-sizing: border-box;
    .submit{
        margin-top: 24px;
        position: relative;
        z-index: 10000;
    }
    .checkBar{
        justify-self: center;
    }
`
const Title = styled(Fonts.h3())`
    color: ${Colors.B100};
    text-align: center;
    margin: 0 !important;
    padding-bottom: 12px;
    text-align: center;
`
const Text = styled.p`
    color: ${Colors.B100};
    font-size: 20px;
    margin: 0;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
    max-height: 210px;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
    @media (max-width: 425px){
        ${'' /* max-height: inherit; */}
    }
`
const BtnArea = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    bottom: 0px !important;
    z-index: 1000;
    margin-bottom: 16px;
    button{
        margin: 0 4px;
    }
`

const CalculatorDefault = (props) => {
    const [date, setDate] = useState(props.parent_date||"2000-01-01");
    const [time, setTime] = useState(props.parent_time||"");
    if (props?.set_parent_date) {
        props.set_parent_date(date);
      }
      
      if (props?.set_parent_time) {
        props.set_parent_time(time);
      }
    const [check, setCheck] = useState(false);
    const [alert, setAlert] = useState(false)

    const gregorianYear = moment(date).format('YYYY');
    const gregorianMonth = moment(date).format('MM');
    const gregorianDay = moment(date).format('DD');

    const lunarDate1 = Solar.fromYmd(gregorianYear, gregorianMonth, gregorianDay).getLunar();
    const lunarDate = lunarDate1.getYear() + '-' + String(lunarDate1.getMonth()).padStart(2, '0') + '-' + String(lunarDate1.getDay()).padStart(2, '0')
    const NumberList = date.split("").filter(i => i !== '-')
    const NumberListLunar = lunarDate.split("").filter(i => i !== '-')
    const NumberList1 = time.split("").filter(i => i !== ':')
    const NumberList2 = NumberList.concat(NumberList1)
    const NumberList2Lunar = NumberListLunar.concat(NumberList1)

    let NumbersCheck = ''
    let NumbersCheck2 = ''

    let BackNumbers1 = sum(NumberList.slice(0, 4).map(Number)).toString().padStart(2, '0').split("")
    let SecondBackNumbers1 = ''
    let MainNmber1 = ''
    let SoulLV1
    let BackNumbers2 = sum(NumberList.slice(0, 6).map(Number)).toString().padStart(2, '0').split("")
    let SecondBackNumbers2 = ''
    let MainNmber2 = ''
    let SoulLV2
    let BackNumbers3 = sum(NumberList.map(Number)).toString().padStart(2, '0').split("")
    let SecondBackNumbers3 = ''
    let MainNmber3 = ''
    let SoulLV3
    let BackNumbers4 = sum(NumberList2.slice(0, 10).map(Number)).toString().padStart(2, '0').split("")
    let SecondBackNumbers4 = ''
    let MainNmber4 = ''
    let SoulLV4
    let BackNumbers5 = sum(NumberList2.map(Number)).toString().padStart(2, '0').split("")
    let SecondBackNumbers5 = ''
    let MainNmber5 = ''
    let SoulLV5

    let BackNumbers6 = sum(NumberListLunar.slice(0, 4).map(Number)).toString().padStart(2, '0').split("")
    let SecondBackNumbers6 = ''
    let MainNmber6 = ''
    let SoulLV6
    let BackNumbers7 = sum(NumberListLunar.slice(0, 6).map(Number)).toString().padStart(2, '0').split("")
    let SecondBackNumbers7 = ''
    let MainNmber7 = ''
    let SoulLV7
    let BackNumbers8 = sum(NumberListLunar.map(Number)).toString().padStart(2, '0').split("")
    let SecondBackNumbers8 = ''
    let MainNmber8 = ''
    let SoulLV8
    let BackNumbers9 = sum(NumberList2Lunar.slice(0, 10).map(Number)).toString().padStart(2, '0').split("")
    let SecondBackNumbers9 = ''
    let MainNmber9 = ''
    let SoulLV9
    let BackNumbers10 = sum(NumberList2Lunar.map(Number)).toString().padStart(2, '0').split("")
    let SecondBackNumbers10 = ''
    let MainNmber10 = ''
    let SoulLV10

    const ResultSlider = useRef();
    const [sliderSettings] = useState({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        speed: 300,
        cssEase: `linear`,
        adaptiveHeight: true,
        draggable: false,
        swipe: false,
    })
    const [index, setIndex] = useState(0);

    const gotoNext = () => {
        ResultSlider.current.slickNext()
        setIndex(index + 1)
    }
    const gotoPrev = () => {
        ResultSlider.current.slickPrev()
        setIndex(index - 1)
    }
    const Restart = () => {
        ResultSlider.current.slickGoTo(0)
        setIndex(0)
    }

    function onChange(e, dateString) {
        setAlert(false)
        // if (isMobileOnly) {
        //     setDate(e.target.value)
        //     if(e.target.value===''){
        //         setDate('2000-01-01')
        //     }
        // } else {
        //     setDate(dateString)
        //     if(dateString===''){
        //         setDate('2000-01-01')
        //     }
        // }
        setDate(dateString)
        if (dateString === '') {
            setDate('2000-01-01')
        }
    }
    function Submit() {
        if (date) {
            setAlert(false)
            ResultSlider.current.slickNext()
            setIndex(index + 1)
        } else {
            setAlert(true)
        }
    }

    function sum(arr) {
        var len = arr.length;
        if (len === 0) {
            return 0;
        } else if (len === 1) {
            return arr[0];
        } else {
            return arr[0] + sum(arr.slice(1));
        }
    }

    if (sum(BackNumbers1.map(Number)).toString().split("").length > 1) {
        SecondBackNumbers1 = sum(BackNumbers1.map(Number)).toString().split("")
        MainNmber1 = sum(SecondBackNumbers1.map(Number)).toString()
    } else {
        MainNmber1 = sum(BackNumbers1.map(Number)).toString()
    }
    if (sum(BackNumbers2.map(Number)).toString().split("").length > 1) {
        SecondBackNumbers2 = sum(BackNumbers2.map(Number)).toString().split("")
        MainNmber2 = sum(SecondBackNumbers2.map(Number)).toString()
    } else {
        MainNmber2 = sum(BackNumbers2.map(Number)).toString()
    }
    if (sum(BackNumbers3.map(Number)).toString().split("").length > 1) {
        SecondBackNumbers3 = sum(BackNumbers3.map(Number)).toString().split("")
        MainNmber3 = sum(SecondBackNumbers3.map(Number)).toString()
    } else {
        MainNmber3 = sum(BackNumbers3.map(Number)).toString()
    }
    if (sum(BackNumbers4.map(Number)).toString().split("").length > 1) {
        SecondBackNumbers4 = sum(BackNumbers4.map(Number)).toString().split("")
        MainNmber4 = sum(SecondBackNumbers4.map(Number)).toString()
    } else {
        MainNmber4 = sum(BackNumbers4.map(Number)).toString()
    }
    if (sum(BackNumbers5.map(Number)).toString().split("").length > 1) {
        SecondBackNumbers5 = sum(BackNumbers5.map(Number)).toString().split("")
        MainNmber5 = sum(SecondBackNumbers5.map(Number)).toString()
    } else {
        MainNmber5 = sum(BackNumbers5.map(Number)).toString()
    }
    if (sum(BackNumbers6.map(Number)).toString().split("").length > 1) {
        SecondBackNumbers6 = sum(BackNumbers6.map(Number)).toString().split("")
        MainNmber6 = sum(SecondBackNumbers6.map(Number)).toString()
    } else {
        MainNmber6 = sum(BackNumbers6.map(Number)).toString()
    }
    if (sum(BackNumbers7.map(Number)).toString().split("").length > 1) {
        SecondBackNumbers7 = sum(BackNumbers7.map(Number)).toString().split("")
        MainNmber7 = sum(SecondBackNumbers7.map(Number)).toString()
    } else {
        MainNmber7 = sum(BackNumbers7.map(Number)).toString()
    }
    if (sum(BackNumbers8.map(Number)).toString().split("").length > 1) {
        SecondBackNumbers8 = sum(BackNumbers8.map(Number)).toString().split("")
        MainNmber8 = sum(SecondBackNumbers8.map(Number)).toString()
    } else {
        MainNmber8 = sum(BackNumbers8.map(Number)).toString()
    }
    if (sum(BackNumbers9.map(Number)).toString().split("").length > 1) {
        SecondBackNumbers9 = sum(BackNumbers9.map(Number)).toString().split("")
        MainNmber9 = sum(SecondBackNumbers9.map(Number)).toString()
    } else {
        MainNmber9 = sum(BackNumbers9.map(Number)).toString()
    }
    if (sum(BackNumbers10.map(Number)).toString().split("").length > 1) {
        SecondBackNumbers10 = sum(BackNumbers10.map(Number)).toString().split("")
        MainNmber10 = sum(SecondBackNumbers10.map(Number)).toString()
    } else {
        MainNmber10 = sum(BackNumbers10.map(Number)).toString()
    }

    function CheckNum() {
        let NumberCheck = []
        for (let t = 0; t < 10; t++) {
            if (t === 0 || t === 1 || t === 9) {
                NumberCheck.push('')
            } else {
                if (NumberList.filter(i => i === `${t}`).length > 0) {
                    NumberCheck.push(`${t}`)
                } else {
                    NumberCheck.push('')
                }
            }
        }
        NumbersCheck = NumberCheck
    }
    function CheckNum2() {
        let NumberCheck = []
        for (let t = 0; t < 10; t++) {
            if (t === 0 || t === 1 || t === 9) {
                NumberCheck.push('')
            } else {
                if (NumberListLunar.filter(i => i === `${t}`).length > 0) {
                    NumberCheck.push(`${t}`)
                } else {
                    NumberCheck.push('')
                }
            }
        }
        NumbersCheck2 = NumberCheck
    }
    function checkArrayValues(array1, array2) {
        // 检查第二个数组中的值是否都存在于第一个数组中
        const allValuesExist = array2.every(value => array1.includes(value));

        // 检查第二个数组中是否有任意一个值存在于第一个数组中
        const anyValueExists = array2.some(value => array1.includes(value));

        if (allValuesExist) {
            return 1; // 第二个数组中的所有值都在第一个数组中
        } else if (anyValueExists) {
            return 2; // 第二个数组中有值存在于第一个数组中
        } else {
            return 3; // 第二个数组中的所有值都不在第一个数组中
        }
    }
    function CheckSoul(BackNumbers, SecondBackNumbers, MainNmber) {
        let origin = []
        if (SecondBackNumbers.length > 1) {
            origin = BackNumbers.concat(SecondBackNumbers)
        } else {
            origin = BackNumbers
        }
        let result = origin.filter(function (element, index, arr) {
            return arr.indexOf(element) === index;
        });
        if (NumberList.filter(i => i === MainNmber).length > 0) {
            if (checkArrayValues(NumberList, result) == 1) {
                let NumberCheck = []
                for (let t = 0; t < 10; t++) {
                    if (NumberList.filter(i => i === `${t}`).length > 2 && t !== 0) {
                        NumberCheck.push('6')
                    } else {
                        NumberCheck.push('7')
                    }
                }
                if ((NumberCheck.filter(i => i === "6").length > 0)) {
                    return 6
                } else {
                    return 7
                }
            } else if (checkArrayValues(NumberList, result) == 2) {
                return 5
            } else {
                return 4
            }
        } else {
            if (checkArrayValues(NumberList, result) == 1) {
                return 3
            } else if (checkArrayValues(NumberList, result) == 2) {
                return 2
            } else {
                return 1
            }
        }
    }
    function CheckSoul2(BackNumbers, SecondBackNumbers, MainNmber) {
        let origin = []
        if (SecondBackNumbers.length > 1) {
            origin = BackNumbers.concat(SecondBackNumbers)
        } else {
            origin = BackNumbers
        }
        let result = origin.filter(function (element, index, arr) {
            return arr.indexOf(element) === index;
        });
        if (NumberListLunar.filter(i => i === MainNmber).length > 0) {
            if (checkArrayValues(NumberListLunar, result) == 1) {
                let NumberCheck = []
                for (let t = 0; t < 10; t++) {
                    if (NumberListLunar.filter(i => i === `${t}`).length > 2 && t !== 0) {
                        NumberCheck.push('6')
                    } else {
                        NumberCheck.push('7')
                    }
                }
                if ((NumberCheck.filter(i => i === "6").length > 0)) {
                    return 6
                } else {
                    return 7
                }
            } else if (checkArrayValues(NumberListLunar, result) == 2) {
                return 5
            } else {
                return 4
            }
        } else {
            if (checkArrayValues(NumberListLunar, result) == 1) {
                return 3
            } else if (checkArrayValues(NumberListLunar, result) == 2) {
                return 2
            } else {
                return 1
            }
        }
    }

    CheckNum()
    CheckNum2()
    SoulLV1 = CheckSoul(BackNumbers1, SecondBackNumbers1, MainNmber1)
    SoulLV2 = CheckSoul(BackNumbers2, SecondBackNumbers2, MainNmber2)
    SoulLV3 = CheckSoul(BackNumbers3, SecondBackNumbers3, MainNmber3)
    SoulLV4 = CheckSoul(BackNumbers4, SecondBackNumbers4, MainNmber4)
    SoulLV5 = CheckSoul(BackNumbers5, SecondBackNumbers5, MainNmber5)
    SoulLV6 = CheckSoul2(BackNumbers6, SecondBackNumbers6, MainNmber6)
    SoulLV7 = CheckSoul2(BackNumbers7, SecondBackNumbers7, MainNmber7)
    SoulLV8 = CheckSoul2(BackNumbers8, SecondBackNumbers8, MainNmber8)
    SoulLV9 = CheckSoul2(BackNumbers9, SecondBackNumbers9, MainNmber9)
    SoulLV10 = CheckSoul2(BackNumbers10, SecondBackNumbers10, MainNmber10)
    if (time == '') {
        SoulLV4 = 0
        SoulLV5 = 0
        SoulLV9 = 0
        SoulLV10 = 0
    }
    //console.log(SoulLV1,SoulLV2,SoulLV3,SoulLV4,SoulLV5)
    const handleTimeChange = (t) => {
        let hours = t.hours().toString().padStart(2, "0");
        let minutes = t.minutes().toString().padStart(2, "0");
        setTime(`${hours}:${minutes}`);
    };
    const handleTimeBlur = (t) => {
        // 在失去焦點時更新時間狀態
        setTime(t.target.value);
    };
    return (
        <Wrapper>


            <Slider {...sliderSettings} ref={ResultSlider}>
                <Content>
                    <Title>彩虹數字解碼機</Title>
                    {/* {isMobileOnly
                        ? <InputDateBar inputTitle='年、月、日 (單月、日要多加一個0，例:1983-01-02)' className='top'>
                            <input id='date' className={date ? 'no-placeholder': ''} type='date' placeholder={date} value={date} onChange={e=>onChange(e)} />
                            <img src={Images.duedate_w} alt='duedate' />
                        </InputDateBar>
                        : <InputDateBar inputTitle='年、月、日 (單月、日要多加一個0，例:1983-01-02)' className='top'>
                            <Space direction="vertical">
                                <DatePicker className={alert ? 'alert' : ''} placeholder={date} onChange={onChange} require />
                            </Space>
                        </InputDateBar>
                    } */}
                    <InputDateBar inputTitle='年、月、日 (單月、日要多加一個0，例:1983-01-03)' className='top'>
                        <Space direction="vertical">
                            <DatePicker className={alert ? 'alert' : ''} placeholder={date} onChange={onChange} require />
                        </Space>
                    </InputDateBar>
                    <InputDateBar inputTitle='時、分' className='top'>
                        <TimePicker
                            //defaultValue={moment('00:00', 'HH:mm')}
                            value={time === '' ? moment('00:00', 'HH:mm') : moment(time, 'HH:mm')}
                            onChange={handleTimeChange}
                            format='HH:mm'
                            allowClear={false}
                            className={`${check ? 'disable' : ''}`}
                            disabled={check ? true : false}
                            inputReadOnly
                            clearIcon={false}
                            showNow={false}
                            onBlur={handleTimeBlur}
                        />
                    </InputDateBar>
                    {/* {isMobileOnly
                        ? <InputDateBar inputTitle='時辰' className='top'>
                        <input className={`${check ? 'disable' : ''} ${time ? 'no-placeholder' : ''}`} type='time' value={time}  onChange={e=>setTime(e.target.value)} />
                        <img src={Images.clock_w} alt='clock' />
                    </InputDateBar>
                        : <>
                            {isSafari 
                                ? <InputDateBar inputTitle='時辰' className='top'>
                                    <TimePicker
                                        defaultValue={moment('00:00', 'HH:mm')}
                                        format={'HH:mm'}
                                        allowClear={false}
                                        className={`${check ? 'disable' : ''}`}
                                        disabled={check ? true : false}
                                        inputReadOnly
                                    />
                                </InputDateBar>
                                : <InputDateBar inputTitle='時辰' className={`${check ? 'disable' : ''} top`}>
                                    <input className={`${check ? 'disable' : ''} ${time ? 'no-placeholder' : ''}`} type='time' value={time}  onChange={e=>setTime(e.target.value)} />
                                    <img src={Images.clock_w} alt='clock' />
                                </InputDateBar>
                            }
                        </>
                    } */}
                    <CheckBar className='blue'>
                        <input type="checkbox" name="time" id='time' onChange={() => setCheck(!check)} />
                        <span htmlFor="time">忘記時、分</span>
                    </CheckBar>
                </Content>
                {/*
                <Content>
                    <Text>你的陽曆生日:</Text>
                    <Title>+{date.replace('-','/').replace('-','/')}{time!==''?('/'+time.split(":")[0]+'/'+time.split(":")[1]):''}</Title>
                    <Text>你的陽曆五大階段數:</Text>
                    <Text>{BackNumbers1.join("")}/{SecondBackNumbers1==""?"":SecondBackNumbers1.join("")+"/"}{MainNmber1}、
                    {BackNumbers2.join("")}/{SecondBackNumbers2==""?"":SecondBackNumbers2.join("")+"/"}{MainNmber2}、
                    {BackNumbers3.join("")}/{SecondBackNumbers3==""?"":SecondBackNumbers3.join("")+"/"}{MainNmber3}{time==""?"":'、'
                    +BackNumbers4.join("")+"/"+(SecondBackNumbers4==""?"":SecondBackNumbers4.join("")+"/")+MainNmber4+"、"
                    +BackNumbers5.join("")+"/"+(SecondBackNumbers5==""?"":SecondBackNumbers5.join("")+"/")+MainNmber5}</Text>
                    <Text>你的陽曆靈魂等級:</Text>
                    <Text>{SoulLV1}、{SoulLV2}、{SoulLV3}{time==""?"":'、'+SoulLV4+'、'+SoulLV5}</Text>
                </Content>
                <Content>
                    <Text>你的農曆生日:</Text>
                    <Title>-{lunarDate.replace('-','/').replace('-','/')}{time!==''?('/'+time.split(":")[0]+'/'+time.split(":")[1]):''}</Title>
                    <Text>你的農曆五大階段數:</Text>
                    <Text>{BackNumbers6.join("")}/{SecondBackNumbers6==""?"":SecondBackNumbers6.join("")+"/"}{MainNmber6}、
                    {BackNumbers7.join("")}/{SecondBackNumbers7==""?"":SecondBackNumbers7.join("")+"/"}{MainNmber7}、
                    {BackNumbers8.join("")}/{SecondBackNumbers8==""?"":SecondBackNumbers8.join("")+"/"}{MainNmber8}{time==""?"":'、'
                    +BackNumbers9.join("")+"/"+(SecondBackNumbers9==""?"":SecondBackNumbers9.join("")+"/")+MainNmber9+"、"
                    +BackNumbers10.join("")+"/"+(SecondBackNumbers10==""?"":SecondBackNumbers10.join("")+"/")+MainNmber10}</Text>
                    <Text>你的農曆靈魂等級:</Text>
                    <Text>{SoulLV6}、{SoulLV7}、{SoulLV8}{time==""?"":'、'+SoulLV9+'、'+SoulLV10}</Text>
                </Content>*/}
                <Content>
                    <Title>{date.replace('-', '/').replace('-', '/')}{time !== '' ? ('/' + time.split(":")[0] + '/' + time.split(":")[1]) : ''}</Title>
                    <Text>你的基本個性是一位</Text>
                    <Text>{CheckOne[NumberList.filter(i => i === '1').length]}</Text>
                </Content>
                <Content>
                    <Title>{date.replace('-', '/').replace('-', '/')}{time !== '' ? ('/' + time.split(":")[0] + '/' + time.split(":")[1]) : ''}</Title>
                    <Text>而且，你{CheckNight[NumberList.filter(i => i === '9').length]}</Text>
                </Content>
                <Content>
                    <Title>{date.replace('-', '/').replace('-', '/')}{time !== '' ? ('/' + time.split(":")[0] + '/' + time.split(":")[1]) : ''}</Title>
                    <Text>再加上，你{CheckZero[NumberList.filter(i => i === '0').length]}</Text>
                </Content>
                <Content>
                    <Title>{date.replace('-', '/').replace('-', '/')}{time !== '' ? ('/' + time.split(":")[0] + '/' + time.split(":")[1]) : ''}</Title>
                    <Text>再者，你{SoulLVOptions[SoulLV3 - 1].text}</Text>
                </Content>
                <Content>
                    <Title>{date.replace('-', '/').replace('-', '/')}{time !== '' ? ('/' + time.split(":")[0] + '/' + time.split(":")[1]) : ''}</Title>
                    <Text>
                        除此之外，你還具備有
                        {NumbersCheck.filter(i => i !== '').map((i, index) => (
                            NumberOptions[NumbersCheck.filter(i => i !== '')[index]]
                        ))}
                        等等的特質。
                    </Text>
                </Content>
                <Content>
                    <Title>{date.replace('-', '/').replace('-', '/')}{time !== '' ? ('/' + time.split(":")[0] + '/' + time.split(":")[1]) : ''}</Title>
                    <Text>你的基本個性是一位{CheckOne[NumberList.filter(i => i === '1').length]}
                        而且，你{CheckNight[NumberList.filter(i => i === '9').length]}
                        再加上，你{CheckZero[NumberList.filter(i => i === '0').length]}
                        再者，你{SoulLVOptions[SoulLV3 - 1].text}
                        除此之外，你還具備有
                        {NumbersCheck.filter(i => i !== '').map((i, index) => (
                            NumberOptions[NumbersCheck.filter(i => i !== '')[index]]
                        ))}
                        等等的特質。
                    </Text>
                </Content>
            </Slider>
            {index === 0 &&
                <BtnArea>
                    <CalculateBtn
                        className={date ? 'submit solid lg' : 'solid submit disable lg'}
                        btnName='立即試算'
                        onClick={() => Submit()}
                    />
                </BtnArea>
            }
            {index === 1 &&
                <BtnArea>
                    <CalculateBtn
                        className="prev solid lg"
                        btnName='上一步'
                        onClick={Restart}
                    />
                    <CalculateBtn
                        className="next solid lg"
                        btnName='下一步'
                        onClick={() => gotoNext()}
                    />
                </BtnArea>
            }
            {index > 1 && index < 6 &&
                <BtnArea>
                    <CalculateBtn
                        className="prev solid lg"
                        btnName='上一步'
                        onClick={() => gotoPrev()}
                    />
                    <CalculateBtn
                        className="next solid lg"
                        btnName='下一步'
                        onClick={() => gotoNext()}
                    />
                </BtnArea>
            }
            {index === 6 &&
                <BtnArea>
                    <CalculateBtn
                        className="prev solid lg"
                        btnName='上一步'
                        onClick={() => gotoPrev()}
                    />
                    <CalculateBtn
                        className='solid lg'
                        btnName='算算其他生日'
                        onClick={Restart}
                    />
                </BtnArea>
            }

        </Wrapper>
    );
}

export default CalculatorDefault;
