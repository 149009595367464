import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Images, Colors, Fonts, Metrics } from '../themes'
import { Rounded } from '../components'
import Scrollchor from 'react-scrollchor';

const Wrapper = styled.div`
  background-color: ${Colors.G1};
  padding: 24px;
`
const Content = styled.div`
  max-width: 1080px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 3fr);
  .logo{
    max-width: 140px;
    margin: 0 auto;
  }
  @media (max-width: ${Metrics.tablet}){
    grid-template-columns: repeat(1, 100%);
    align-self: center;
  }
  `

const IconArea = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-column-gap: 16px;
  margin: 0 auto;
  padding: 16px;
  .social{
    max-width: 32px;
    max-height: 32px;
  }
`

const Logo = styled.img`
  width: auto;
  height: 80px;
`

const LinkAreaGroup = styled.div`
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-column-gap: 16px;
    @media (max-width: ${Metrics.tablet}){
      max-width: 640px;
      margin: 0 auto;
    }
`

const LinkArea = styled(Fonts.h6())`
  color: ${Colors.G5}; 
  margin: 8px;
`

const List =  styled.div`
  font-color: ${Colors.G3};
  margin: 8px 0;
  &:hover{
    cursor: pointer;
  }
`

const Text = styled(Fonts.P_16())`
    color: ${Colors.G3};
    &:hover {
        color ${Colors.Blue};
        transition: 0.8s cubic-bezier(0.2,0.8,0.2,1);
     }
` 



const Footer = props => {
    return ( 
      <Wrapper id='footer'>
        <Content>
            <Link className='logo' to='/'>
                <Logo src={Images.Logo_Footer} />
            </Link>
            <LinkAreaGroup>
              <LinkArea>產品服務
                <List>
                  <Link to='/event/0'>
                      <Text>報名課程</Text>
                  </Link>
                </List>
                <List>
                  <Link to='/reservation'>
                      <Text>預約諮詢</Text>
                  </Link>
                </List>
                <List onClick={props.onClick}>
                    <Text>免費試算</Text>
                </List>
              </LinkArea>
              <LinkArea>關於我們
                <List>
                  <Link to='/team'>
                    <Text>學會介紹</Text>
                  </Link>
                </List>
                <List>
                  <Link to='/learn/0'>
                    <Text>認識彩虹數字</Text>
                  </Link>
                </List>
                {props.event || props.reservation || props.team || props.learn || props.news
                  ? <List>
                      <Link to='/#media'>
                        <Text>媒體報導</Text>
                      </Link>
                  </List>
                  : <List>
                      <Scrollchor to="#media" animate={{offset: -116}}>
                        <Text>媒體報導</Text>
                      </Scrollchor>
                  </List> 
                }                   
              </LinkArea>
              <LinkArea>其他
                <List>
                  <Link to='/privacyPoclicy'>
                    <Text>隱私權政策</Text>
                  </Link>
                </List>
                <List>
                  <Link to='/termsOfService'>
                    <Text>服務條款</Text>
                  </Link>
                </List>
                <List>
                  <Link to='/news/1'>
                    <Text>最新消息</Text>
                  </Link>
                </List>
              </LinkArea>
            </LinkAreaGroup>  
            <IconArea>
              <a className='social' href='https://www.facebook.com/rainbow.numen/'>
                <Rounded
                  icon={Images.facebook}
                /> 
              </a>
              <a className='social' href='https://lin.ee/nTByXp1' >
                <Rounded
                  icon={Images.line}
                /> 
              </a>
              <a className='social' href='mailto:service.rainbownumen@gmail.com' >
                <Rounded
                  icon={Images.mail}
                /> 
              </a>
            </IconArea> 
        </Content>
      </Wrapper>
    )
}
export default Footer
