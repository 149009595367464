import React from 'react';
import styled from 'styled-components'
import { Fonts, Colors, Metrics} from '../../themes'

import { Link } from 'react-router-dom'

const Wrapper = styled.div`
    ${'' /* max-width: 349px; */}
    box-sizing: border-box;
    border: 1px solid transparent;
    border-radius: 12px;
    width: 100%;
    padding: 8px;

    &:hover {
        border: 1px solid ${Colors.B10};
        transition: 0.8s cubic-bezier(0.2,0.8,0.2,1);
     }

     @media (max-width: ${Metrics.tablet}){
        display: grid;
        grid-column-gap: 16px;
        align-items: top;
        grid-template-columns: 1fr 1.3fr;
        width: 100%;
        margin: 0 auto; 
      }

      @media (max-width: ${Metrics.mobile}){
        padding: 0;

      }

`
const Img = styled.img`
    background: ${props => props.bg};
    border-radius: 6px;
    width: 100%;  
    margin: 0 auto;
    align-items: center;
    @media (max-width: ${Metrics.tablet}){
        width: 100%;
      }

`
const TextArea = styled.div`
    padding: 8px;

    @media (max-width: ${Metrics.tablet}){
        padding: 0px;
      }
`
    
const Title = styled(Fonts.h4())`
    margin-top: 8px;
    @media (max-width: ${Metrics.mobile}){
        font-size:16px;
        line-height: 22px;
        margin: 0;
    }
`

const SourceDate = styled.div`
    margin-top: 8px;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 80px;

    @media (max-width: ${Metrics.mobile}){
        margin: 0px;
      }
`
const Source = styled(Fonts.h6())`
    color:${Colors.G2}
    
`

const Date = styled(Fonts.info())`
    text-align: right;
    color:${Colors.G2}

`

const Card = (props) => {
    return (
        <Link to={{ pathname: `${props.href}` }} target="_blank">
            <Wrapper>
                <Img src={props.img} alt=''/>
                <TextArea>
                <SourceDate>
                    <Source>{props.source}</Source>
                    <Date>{props.date}</Date>
                </SourceDate>
                <Title>{props.title}</Title>
                </TextArea>
            </Wrapper>
        </Link>
    );
}

export default Card;