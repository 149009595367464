import React from 'react';
import styled from 'styled-components'
import { Metrics } from '../../themes'

const Wrapper = styled.div`
    max-width: 1080px;
    margin: 0 auto 40px;
    display: grid;
    grid-template-columns: repeat(3, 3fr);
    align-self: center;
    grid-column-gap: 16px;
    grid-row-gap: 16px; 

    @media (max-width: ${Metrics.tablet}) {
        grid-template-columns: repeat(1, 100%);
        align-self: center;
        grid-gap: 16px;
      }


`

const GridCol3 = (props) => {
    const { children } = props
    return (
        <Wrapper>
            { children }
        </Wrapper>
    );
}

export default GridCol3;