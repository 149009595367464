import React from 'react';
import styled from 'styled-components'
import { Fonts, Colors } from '../../themes'

const Wrapper = styled.div`
    background: white;
    border-radius: 16px;
    box-shadow: 0px 4px 8px 0 rgba(0,0,0,0.1);
    width: 100%;
    max-width: 704px;
    height: auto;
    overflow: hidden;
    .MuiTabs-scroller.MuiTabs-fixed{
        overflow-x: scroll !important;
        -ms-overflow-style: none;
        scrollbar-width: none;
        ::-webkit-scrollbar {
            display: none;
        }
    }
    .MuiTabs-flexContainer{
        display: block;
        max-width: 610px;
        margin: 0 auto;
    }
    
`
const TitleArea = styled.div`
    padding: 24px 16px 15px;
    border-bottom: 1px solid ${Colors.B10};
`
const BigTitle = styled(Fonts.h4())`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: center;
`


const CardArticleWithTab = (props) => {
    const { children } = props
    return (
        <Wrapper>
            <TitleArea>
                <BigTitle>{props.bigTitle}</BigTitle>
            </TitleArea>
            {children} 
        </Wrapper>
    );
}

export default CardArticleWithTab;