import React from 'react';
import styled from 'styled-components'
import { Fonts, Colors } from '../../themes'

const Wrapper = styled.div`
    background: ${Colors.B05};
    border-radius: 10px;
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    padding: 16px;
    margin-top: 16px;
    &:hover {
        ${'' /* box-shadow: 0px 8px 12px 0 ${Colors.B20}; */}
    }
    &:first-child{
        margin-top: 0;
    }
`
const TextArea = styled.div`
`
const Title = styled(Fonts.h4())`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-bottom: 8px;
    color: #A9A9A9;
`

const Price = styled(Fonts.h3())`
    color: #A9A9A9;
    align-self: end;
`

const CardTicketDone = (props) => {
    return (
        // <Link to={props.href}>
            <Wrapper onClick={props.onClick}>
                <TextArea>
                    <Title>{props.title}</Title>
                    {/* {props.text.split('\\n').map(item => (
                        <Text>{item === '' ? <br/> : item}</Text>
                    ))} */}
                    <Price>{props.price}</Price>
                </TextArea> 
            </Wrapper>
        // </Link>
    );
}

export default CardTicketDone;