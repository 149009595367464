import React from 'react';
import styled from 'styled-components'
import { Colors } from '../../themes'

const BtnDiv = styled.button`
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    color: ${Colors.B100};
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    text-decoration: none;
    transition: background-color .3s,border-color .3s;
    background-color: ${props => props.bg};
    border: calc(.06887vw + .67769px) solid #4c69ba;
    padding: calc(.34435vw + 13.38843px) calc(.34435vw + 18.38843px);
    padding: 13px 36px;
    border-radius: 8px;
    display: flex;
    width: 100%;
    max-width: 311px;
    border: 0px;
    align-items: center;
    margin: 8px auto;
    justify-content: center;
    img{
        padding-right: 10px;
    }
    p{
        margin: 0
    }
    &:hover{
        opacity: 0.85
    }
    &.no-hover:hover{
        opacity: 1
    }
    div{
        display: flex;
    }
`

const LoginBtn = props => (
    <BtnDiv className={`login-button ${props.className}`} bg={props.bg} onClick={props.onClick}>
        <div>
            <img src={props.img} alt={props.alt} />
            <p>{props.text}</p>
        </div>
    </BtnDiv>
)
export default LoginBtn