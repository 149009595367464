import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import { FormTitleDiv, InputBar, InputDateBar, SelectBar } from '../../components'
import { DatePicker } from 'antd';
import { Metrics, SelectStyles } from '../../themes'
import Select from 'react-select'
import { useForm } from "react-hook-form";
import { Query, Mutation } from "react-apollo";
import { GET_PROFILE, UPDATE_PROFILE } from '../../api';
import Cookies from 'js-cookie';
var moment = require('moment');

const ContentArea = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    box-sizing: border-box;
    .no-show{
        width: 0;
        height: 0;
        position: absolute;
        opacity: 0;
    }
    @media (max-width: ${Metrics.mobile}){
        display: block;
        height: 100%;
    }
`

const EditProfile = (props) => {
    const [UserData, setUserData] = useState();
    const [Ready, setReady] = useState(false);
    const [userId, setUserId] = useState()

    const [gender, setGender] = useState()
    const [name, setName] = useState()
    const [birth, setBirth] = useState();
    const [cellphone, setCellphone] = useState();
    const [address, setAddress] = useState();
    const [remark, setRemark] = useState();
    const [recommender, setRecommender] = useState();
    const [email, setEmail] = useState();
    const [first, setFirst] = useState(true);

    const genderOptions = [
        { value: "0", label: "男" },
        { value: "1", label: "女" },
    ];

    const { register, handleSubmit, setValue } = useForm();
    const onSubmit = data => {

        if (data.name && data.birth && data.email && data.gender && data.phone && data.address) {
            setReady(true);
        }
        if (!data.birth) {
            if (birth) {
                data.birth = birth
            } else {
                data.birth = ''
            }
            setFirst(false)
        }
        if (!data.gender) {
            data.gender = gender
            setFirst(false)
        }

        setUserData(data)
    };

    function onChange(date, dateString) {
        setValue("birth", dateString);
        setBirth(dateString)
    }

    function onGenderChange(selectedState) {
        setValue("gender", selectedState.label);
        setGender(selectedState.label)
    }

    const handleCellphone = (e) => {
        setCellphone(e.target.value)
    }
    const handleEmail = (e) => {
        setEmail(e.target.value)
    }
    const handleAddress = (e) => {
        setAddress(e.target.value)
    }
    const handleRemark = (e) => {
        setRemark(e.target.value)
    }
    const handleRecommender = (e) => {
        setRecommender(e.target.value)
    }
    useEffect(() => {
        register({ name: "birth", required: true });
        register({ name: "gender", required: true });
        if (Cookies.get('rainbow')) {
            setUserId(Cookies.get('rainbow'))
        }
    }, [setUserId, register]);

    return (
        <Mutation mutation={UPDATE_PROFILE(userId)} onError={() => console.log('error')}>
            {(mutate) => (
                <form onChange={handleSubmit(onSubmit)} onSubmit={(e) => {
                    e.preventDefault();
                    mutate({
                        variables: { name: UserData.name, gender: UserData.gender, birth: UserData.birth, cellphone: UserData.cellphone, email: UserData.email, address: UserData.address, remark: UserData.remark, recommender: UserData.recommender }
                    }).then(window.location.reload())
                }}>
                    <FormTitleDiv title='編輯基本資料' close={props.close} checkClass={Ready ? '' : ''}>
                        <Query query={GET_PROFILE(userId)}>
                            {({ loading, error, data, refetch }) => {
                                if (data) {
                                    if (data.member.length > 0) {
                                        if (first) {
                                            setName(data.member[0].name)
                                            setCellphone(data.member[0].cellphone)
                                            setEmail(data.member[0].email)
                                            setBirth(data.member[0].birth)
                                            setGender(data.member[0].gender)
                                            setAddress(data.member[0].address)
                                            setRemark(data.member[0].remark)
                                            setRecommender(data.member[0].recommender)
                                            setFirst(false)
                                        }
                                    }
                                }
                                return (
                                    <>
                                        {data &&
                                            <>
                                                {data.member.length > 0 &&
                                                    <>
                                                        <ContentArea>
                                                            <InputBar inputTitle='姓名'>
                                                                <input id='name' name='name' placeholder='請輸入姓名' value={name} onInput={(e) => setName(e.target.value)} ref={register} required />
                                                            </InputBar>
                                                            <SelectBar inputTitle='生理性別' onClick={handleSubmit(onSubmit)}>
                                                                <Select
                                                                    options={genderOptions}
                                                                    name='reasons'
                                                                    isSearchable={false}
                                                                    defaultValue={data.member[0].gender === '男' || data.member[0].gender === 'M' ? genderOptions[0] : genderOptions[1]}
                                                                    styles={SelectStyles}
                                                                    onChange={onGenderChange}

                                                                />
                                                            </SelectBar>
                                                            <InputDateBar className='black' inputTitle='生日'>
                                                                <DatePicker id='birth' name='birth' placeholder='2020-01-01' defaultValue={data.member[0].birth && moment(data.member[0].birth, 'YYYY/MM/DD')} onChange={onChange} onOpenChange={handleSubmit(onSubmit)} require />
                                                            </InputDateBar>
                                                            <InputBar inputTitle='手機號碼'>
                                                                {/*<input id='cellphone' name='cellphone' type='tel' size="10" minlength="9" placeholder='請輸入手機號碼' value={cellphone} onInput={e => handleCellphone(e)} pattern="09[0-9]{8}" ref={register} required />*/}
                                                                <input id='cellphone' name='cellphone' type='tel' size="10" minlength="9" placeholder='請輸入手機號碼' value={cellphone} onInput={e => handleCellphone(e)} ref={register} required />
                                                            </InputBar>
                                                            <InputBar inputTitle='電子信箱'>
                                                                <input id='email' name='email' type='email' placeholder='請輸入電子信箱' value={email} onInput={e => handleEmail(e)} ref={register} required />
                                                            </InputBar>
                                                            <InputBar inputTitle='推薦人'>
                                                                <input id='recommender' name='recommender' placeholder='請輸入地址' value={recommender} onInput={e => handleRecommender(e)} ref={register} required />
                                                            </InputBar>
                                                            <InputBar inputTitle='地址(寄講義用)'>
                                                                <input id='address' name='address' placeholder='請輸入地址' value={address} onInput={e => handleAddress(e)} ref={register} required />
                                                            </InputBar>
                                                            
                                                            <InputBar inputTitle='備註'>
                                                                <input id='remark' name='remark' placeholder='請輸入地址' value={remark} onInput={e => handleRemark(e)} ref={register} required />
                                                            </InputBar>

                                                        </ContentArea>
                                                    </>
                                                }
                                            </>
                                        }
                                    </>
                                );
                            }}
                        </Query>
                    </FormTitleDiv>
                </form>
            )}
        </Mutation>
    );
}

export default EditProfile;