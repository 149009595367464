import React from 'react'
import styled from 'styled-components'
import { Colors, Fonts, Images, Metrics } from '../../themes'

const Wrapper = styled.div`
    width: 100%;
    background: ${Colors.W100};
    border-radius: 12px;
    @media (max-width: ${Metrics.mobile}){
        border-radius: 0;
        height: 100%;
    }
`

const HeaderArea = styled.div`
    width: auto;
    height: 60px;
    padding: 10px 12px;
    display: grid;
    grid-template-columns: 20px auto 40px;
    justify-items: space-between;
    align-items: center;
    box-shadow: 0 1px 0 0 ${Colors.B10}; 
    box-sizing: border-box;
`

const CloseBtn = styled.div`
    @media (hover: hover) and (pointer: fine) {
        &:hover,&.hover{
            cursor: pointer;
        }
    }
`

const HeaderText = styled(Fonts.h6())`
    text-align: center;
`

const FormArea = styled.div`
    padding: 18px 16px;
    position: relative;
    @media (max-width: ${Metrics.mobile}){
        height: 100vh;
        overflow: hidden;
    }
`

const Store = styled.input.attrs({ 
    type: 'submit',
    value: '確認'
})`
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 2px;
    padding: 0;
    color: #3781E1;
    text-align: center;
    border-radius: 6px;
    border: none;
    transition: 0.8s cubic-bezier(0.2,0.8,0.2,1);
    outline: none;
    background: transparent;
    &.disable{
        pointer-events: none;
        color: #999A9E;
    }
    @media (hover: hover) and (pointer: fine) {
        &:hover{
            cursor: pointer;
        }
    }
`

const FormTitleDiv = (props) => {
    const { children } = props
    
    return ( 
        <Wrapper>
            <HeaderArea>
                <CloseBtn onClick={props.close}>
                     <img src={Images.close} alt='' />
                </CloseBtn>
                <HeaderText>
                    {props.title}
                </HeaderText>
                <Store className={`store ${props.checkClass}`} onClick={props.Submit}/>
            </HeaderArea>
            <FormArea>
                { children } 
            </FormArea>
        </Wrapper>
    )
  }
  
  export default FormTitleDiv