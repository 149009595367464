import React from 'react';
import styled from 'styled-components'
import { FormTitleDiv, CellBDEmpty } from '../../components'
import { Fonts, Images } from '../../themes'
import { Query } from "react-apollo";
import { GET_EVENTDETAIL_FROM_ORDER } from '../../api';
var moment = require('moment');

const ContentArea = styled.div`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    display: grid;
    justify-items: center;
    min-height: 350px;
    align-items: center;
    .selectBar{
        max-width: 344px;
        margin: 0 auto;
    }
    .checkBar{
        margin: 0 auto;
        max-width: 250px;
        z-index: 0;
    }
`
const TextArea = styled.div`
    padding: 22px 24px;
    max-width: 480px;
`

const Text = styled(Fonts.P_16())`
    text-align: left;
    .br{
        margin-bottom: 20px;
    }
`

const RefundSuccess = (props) => {

    return (
        <FormTitleDiv title='退票成功' close={props.close} Submit={()=>window.location.reload()} >
            <ContentArea>
                <Query query={GET_EVENTDETAIL_FROM_ORDER(props.orderNum)}>
                    {({ loading, error, data, refetch }) => {
                        let event
                        if(data){
                            event = data.member_event_session[0]
                        }
                        return (
                            <>
                            {data &&
                                <CellBDEmpty 
                                    img={Images.success}
                                    title='正在處理您的退票申請'
                                    text={`您於【${event.session.classroom.city}場】${event.session.event.title}－${moment(event.session.session_start_time).format('MM')}月份的票券，已於 ${moment(event.session.refund_start_time).format("YYYY/MM/DD H:mm")}\\n退款需要 10 個工作天，再請耐心等候。`}
                                />
                            }
                            </>
                        );
                    }}
                </Query>
                <TextArea>
                    <Text>有任何問題歡迎來信 service@rainbow.com</Text>
                </TextArea>
            </ContentArea>
        </FormTitleDiv>
            
    );
}

export default RefundSuccess;