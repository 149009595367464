import React from 'react';
import styled from 'styled-components'
import { Fonts, Metrics, Colors} from '../../themes'

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    z-index: 102;
    min-height: 360px;
    padding: 40px 60px;
    @media (max-width: ${Metrics.mobile}){
        height: 100%;
        padding: 8px 20px;
    }
`
const Content = styled.div`
    @media (max-width: ${Metrics.mobile}){
        position: relative;
        top: 50%;
        transform: translateY(-50%)
    }
`
const TextArea = styled.div`
    position: relative;
    padding: 24px;
    z-index: 1;
    @media (max-width: ${Metrics.mobile}){
        padding: 24px 0;
    }
`
const Title = styled(Fonts.h3())`
    margin: 0;
    text-align: center;
    padding-bottom: 8px;
    color: ${Colors.B100};
`
const Text = styled(Fonts.P_16())`
    text-align: center;
    color: ${Colors.B100};
    a{
        padding-left: 8px;
        word-wrap: break-word;
        color: ${Colors.Blue};
    }
`


const EventModal_5 = (props) => {
    return (
        <Wrapper>
            <Content>
                <TextArea>
                    <Title>案例研析</Title>
                    <Text>案例研析屬實務操作課程。須學習過該類型之核心基礎課程後始可報名。</Text>
                </TextArea>
            </Content>
        </Wrapper>
    );
}

export default EventModal_5;