import React from 'react';
import { Seo, Layout, CardBannerS, TitleArea, ImgCard, IconCard, StoryLeft, StoryRight , GridCol4, GridCol3, Calculator, LiHungModal } from '../components'
import styled from 'styled-components'
import { Images } from '../themes'
import Modal from 'react-modal'

const Wrapper = styled.div`
    padding: 0 16px;
`
const Close = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 10000;
    width: 24px;
    height: 24px;
    &:hover{
        cursor: pointer;
    }
`
const customStyles = {
    overlay:{
        backgroundColor: "rgba(0,0,0,0.5)",
    },
    content : {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '16px',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
        zIndex: '1000',
    }
};
const customStyles2 = {
    overlay:{
        backgroundColor: "rgba(0,0,0,0.5)",
    },
    content : {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '16px',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
        zIndex: '102',
        width: 'calc(100% - 40px)',
        height: 'calc(100% - 40px)',
        maxWidth: '520px',
        maxHeight: '1080px',
        overflow: 'hidden',
        display: 'block',
    }
};

const TeamPage = (props) => {
    const [modalIsOpen,setIsOpen] = React.useState(false);
    function openModal() {
        setIsOpen(true);
        
    }
    function closeModal(){
        setIsOpen(false);
        
    }
    const [ modalOneIsOpen, setOneIsOpen ] = React.useState(false);

    function openModalOne() {
        setOneIsOpen(true);
        
    }
    function closeModalOne(){
        setOneIsOpen(false);
        
    }
    return (
        <Layout team>
            <Seo 
                title='彩虹數字學會 - 學會介紹'
                metaDescription='運用彩虹數字解讀你的生命密碼，了解自己的生命課題。學習「心性解碼」、「時間解碼」、「感情解碼」課程，免費試算自己的數字。'
                metaTitle='彩虹數字學會 - 學會介紹'
                metaUrl='https://www.rainbownumen.org/team'
                //metaImage={`${Images.Rainbow_SEO}`}
                metaImage='https://rainbow-digital.glitch.me/i/ktlc6ygb.png'
                metaImageAlt=''
            />
            <Wrapper>
                <TitleArea
                    title='中華民國彩虹數字學會'
                    text='致力於以彩虹數字揚昇個人生命的專業非營利組織'
                />
                <GridCol4>
                    <IconCard 
                        img={Images.intro_1}
                        title='學術研究'
                        text='研究彩虹數字學理念與內涵'
                    />
                    <IconCard 
                        img={Images.intro_2}
                        title='課程與講座'
                        text='舉辦各種彩虹數字學的相關課程'
                    />
                    <IconCard 
                        img={Images.intro_3}
                        title='師資培育'
                        text='培育彩虹數字學的講師、諮詢師'
                    />
                    <IconCard 
                        img={Images.intro_4}
                        title='教材出版'
                        text='出版彩虹數字學書籍與相關教材'
                    />
                </GridCol4>
                <StoryLeft
                    title='我們的故事'
                    text='「我的初心很單純，就是不斷分享這神奇的生命QR-Code解讀工具，讓更多人能夠認識自己，進而更愛自己。」——田麗虹\n
                    \n
                    自 2012 年開始，田麗虹老師創立「美麗彩虹工作室」，使用彩虹數字工具諮詢，並開始投入彩虹數字教學。十年間，田老師秉持著純粹助人的心念，帶領了餘百位對生命充滿好奇的學生，透過解讀生命密碼來認識自己、轉化自己，改善親子關係、伴侶關係，重新點燃生命熱忱，助人無數，十年如一日。\n
                    \n
                    由於每年渴望學習的學生愈來愈多，教室愈換愈大，由自家客廳的 6 人溫馨餐桌，擴增到得去租借補習班 100 人教室。因此，在學生與各方不同領域及專業的社會人士們的支持推動下，於 2020 年共同發起了「中華民國彩虹數字學會」， 2020年 6月 13日經內政部核准，正式成立為一非營利社會團體，讓彩虹數字可以幫助更多的人，提供更多正面的能量，揚昇更多美好的生命。\n
                    \n
                    彩虹數字學會以「宣導彩虹數字學的理念與內涵」、「推動研究與發展」為宗旨，每年開辦「彩虹數字課程」、培育「證照級講師與諮詢師」，並提供「人生諮詢」。\n
                    \n
                    學會成立至今，常收到令人感動的回饋：「謝謝老師無私的指導，每次上課都感到充滿希望！」、「老師的諮詢讓我更清楚人生方向，看見一直過去卡關的原因……」、或是喜極而泣感恩地說「我終於找到了尋覓多年的答案！！！」……\n
                    \n
                    特別感謝您們真心的回饋，您們的支持推動著學會不斷前行！\n
                    誠摯邀請每位有興趣的朋友一同參與！！  '
                    img={Images.license}
                />
                <StoryRight
                    title='田麗虹'
                    jobTitle=''
                    text='中華民國彩虹數字學會理事長。除從事彩虹數字學之研究、教學、諮詢、指導之外，並任教於國立台北科技大學，開設藝術欣賞課程。著有「麗子的彩虹數字學」、「原來藝術這麼簡單Ⅰ-史前到中世紀的西方藝術」、「國會助理工作手冊」、「兩岸關係的決策分析」等專書。'
                    onClick={openModalOne}
                    img={Images.ceo}
                />
                <TitleArea
                    title='學會團隊介紹'
                    text='學會幹部致力於推廣彩虹數字之應用'
                />
                <GridCol3>
                    <ImgCard
                        img={Images.t11}
                        title='陳建志'
                        jobTitle='秘書長'
                        text='統籌和監督各部門工作進度、處理內部和外部溝通事宜、策劃重要會議與活動。'
                    />
                    <ImgCard
                        img={Images.Tina}
                        title='娜米'
                        jobTitle='副秘書長'
                        text='負責經營學會會員管理、系統開發、會議安排、預算編列。'
                    />

                </GridCol3>
                <CardBannerS
                    className='center'
                    bg={Images.banner_01}
                    title='彩虹數字\n帶你認識生命的能量'
                    text='用數字改變感情、家庭、事業'
                    BtnTitle='免費試算' 
                    onClick={()=>openModal()}
                />
            </Wrapper>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <Close onClick={closeModal}>
                    <img src={Images.close} alt='' />
                </Close>
                <Calculator />
            </Modal>
            <Modal
                isOpen={modalOneIsOpen}
                onRequestClose={closeModalOne}
                style={customStyles2}
                contentLabel="Example Modal"
            >
                <Close onClick={closeModalOne}>
                    <img src={Images.close} alt='' />
                </Close>
                <LiHungModal />
            </Modal>
        </Layout>
    );
}

export default TeamPage;