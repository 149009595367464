import React from 'react';
import styled from 'styled-components'
import { Fonts, Metrics, Colors } from '../../themes'

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    z-index: 102;
    padding: 40px 60px;
    @media (max-width: ${Metrics.mobile}){
        height: 100%;
        padding: 8px 20px;
    }
`
const Content = styled.div`
    @media (max-width: ${Metrics.mobile}){
        position: relative;
        top: 50%;
        transform: translateY(-50%)
    }
`
const TextArea = styled.div`
    position: relative;
    padding: 24px;
    z-index: 1;
    @media (max-width: ${Metrics.mobile}){
        padding: 24px 0;
    }
`
const Title = styled(Fonts.h3())`
    margin: 0;
    text-align: center;
    padding-bottom: 8px;
    color: ${Colors.B100};
`
const Text = styled(Fonts.P_16())`
    text-align: center;
    color: ${Colors.B100};
    a{
        padding-left: 8px;
        word-wrap: break-word;
        color: ${Colors.Blue};
    }
`

const EventModal_1 = (props) => {
    return (
        <Wrapper>
            <Content>
                <TextArea>
                    <Title>心性解碼</Title>
                    <Text>您了解自己嗎？</Text>
                    <Text>你想了解自己嗎？</Text>
                    <Text>「心性解碼」是彩虹數字的基礎課程，旨在學習「計算生命密碼程式 QR Code」、「1~0 十個數字核心能量意義和特質」、「陽曆農曆模式」。</Text>
                    <Text>由於「數字就是能量，能量就是磁場，磁場形塑個性」，因此，只要學習生命密碼程式語言（如同「資訊工程師」學習 0、1 程式語言），就能透過對生日數字模組進行系統化分析，直接開啟生命密碼能量、解讀生命密碼程式、掌握生命藍圖脈絡，有效分析及處理生命難題，甚至進階成為一名「生命密碼解碼工程師」。</Text>
                </TextArea>
            </Content>
        </Wrapper>
    );
}

export default EventModal_1;