import React from 'react';
import styled from 'styled-components'
import { Colors, Fonts } from '../../themes'

const CheckOne = [
    '不太會堅持自己想法的人。',
    '不太會表達自己的人。通常很多話還沒有說出口，就會先打退堂鼓。',
    '會尊重別人，也會說出自己想法的人，但是你卻不會堅持己見，所以，即使對方不接受你的意見，你也覺得無所謂。',
    '說到做到、貫徹執行的人。因此，通常不需要別人要求，你自己就會設定好目標和進度的把事情完成。',
    '外柔內剛的人。通常你對事情都會有自己的想法，可是卻往往會為了配合別人而不願說出來，但是當結果不如預期時，你又會自己氣自己。',
    '外表缺乏自信、會自我否定、不敢表達己見、只會配合別人的人，但是其實你的內在卻是一位堅持已見、愈挫愈勇、堅強又具有意志力的人。',
    '外表不會堅持己見，會尊重並配合別人的人，但是其實你的內在卻是一位堅持目標、愈挫愈勇、堅強又具有意志力的人。'
]
const CheckNight = [
    '不太習慣地球人類的文明和傳統，因此常常會感覺到與世界格格不入。',
    '相信命運的存在，有時會想探索生命意義，或是去算命。',
    '有些孤僻，有時會喜歡離群索居，把自己關在房間裡，用土法煉鋼的方式自行研究感興趣的事物。',
    '很有智慧又有點自負，因此在面對事情時，常會表現出一副老神在在的樣子。',
    '很純真且富有想像力，有時還會希望自己能夠擁有瞬間移動的特異功能。',
    '很喜歡看美人魚、獨角獸、半人半獸等類型的故事，或是會在夢中夢到這些景象。'
]
const CheckZero = [
    '喜歡單純、不複雜的生活和工作，一遇到比較複雜的問題或事情，還沒開始做，頭就先痛了。',
    '具有戰鬥性格，但是在做決定時又常常覺得這樣做也不是，那樣做也不對，而會比較掙扎些。',
    '不喜歡傷害別人，在觀念上認為男女平等、眾生平等，是一位和平主義者。',
    '想法很另類，在別人眼中是一個怪胎。',
    '是一個帶有高能量頻率的新人類，無懼、有膽識，對任何事並沒有太多的執著，只在乎是否和諧。',
    '對外在時空的敏感度非常的強，是一個帶有高能量頻率的新人類。'
]
const NumberOptions = [
    '',
    '',
    '溫柔，細膩，懂得傾聽，會委曲求全的來配合別人；',
    '樂觀開朗，喜歡學習新事物，對任何事情都充滿好奇心，常有創意新點子；',
    '務實，沉穩，腳踏實地，很有責任感；',
    '喜歡自由自在，富有正義感，熱愛戶外活動，又深具冒險精神；',
    '善解人意，熱心服務，愛心、慈悲心，追求完美主義；',
    '質疑性強，會打破砂鍋問到底，重視氛圍，經常有莫名其妙的好運氣；',
    '察言觀色，謹言慎行，懂得以退為進，也會忍辱負重；',
    '',
]
const SoulLVOptions = [
    {title:'第一級',text:'受爸爸影響很深。每當事情做不好或是做錯事時會很容易哭，尤其不喜歡有人對你大聲講話或是大聲罵你。'},
    {title:'第二級',text:'對老師(或上司)很尊敬，也會乖乖聽老師(或上司)的話，只要老師(或上司) 幫你設定好時間和目標，你都會把事情做好。'},
    {title:'第三級',text:'很叛逆，不喜歡別人用權威的方式來命令你。但是你喜歡熱鬧、喜歡和朋友搏感情，遇到問題時也只會找朋友商量。'},
    {title:'第四級',text:'不喜歡被說教，不想聽別人重複講你已經知道的事情；但是對於不懂的事情你卻又會想追根究底的問清楚。'},
    {title:'第五級',text:'希望別人尊重你，如果尊重你，你就會充滿熱忱的傾囊相受；否則會不想理對方。'},
    {title:'第六級',text:'是一個聰明又有智慧的人，但是卻因為會壓抑自己的個性，悶著頭做自己的事，因此常會有一股莫名的鬱卒感。'},
    {title:'第七級',text:'是一個聰明又有智慧的人，遇到任何問題幾乎都有能力自己解決，可是當發覺沒辦法解決或是學不會時，就會懊惱自己為什麼不會。'},    
]
const TextArea = styled.div`
`
const Text = styled(Fonts.P_16())`
    color:${Colors.G3};
    white-space: pre-wrap;
    &.br{
        padding-bottom: 20px;
    }
`

const CalculateText = props => {
    let date = props.date
    const NumberList = date.split("").filter(i=>i!=='-')
    let BackNumbers = sum(NumberList.map(Number)).toString().split("")
    let SecondBackNumbers = ''
    let MainNmber = ''
    let NumbersCheck =''
    let SoulLV 

    function sum(arr) {
        var len = arr.length;
        if(len === 0){
            return 0;
        } else if (len === 1){
            return arr[0];
        } else {
            return arr[0] + sum(arr.slice(1));
        }
    }

    if(sum(BackNumbers.map(Number)).toString().split("").length>1){
        SecondBackNumbers = sum(BackNumbers.map(Number)).toString().split("")
        MainNmber =  sum(SecondBackNumbers.map(Number)).toString()
    }else{
        MainNmber = sum(BackNumbers.map(Number)).toString()
    }

    function CheckNum(){
        let NumberCheck =[]
        for(let t = 0;t<10;t++){
            if(t===0 || t===1 || t===9){
                NumberCheck.push('')
            }else{
                if(NumberList.filter(i=>i===`${t}`).length > 0){
                    NumberCheck.push(`${t}`)
                }else{
                    NumberCheck.push('')
                }
            }
        }
        NumbersCheck = NumberCheck
    }

    function CheckSoul(){
        if(SecondBackNumbers.length>1){
            if(NumberList.filter(i=>i===MainNmber).length>0){
                if(NumberList.filter(i=>i===BackNumbers[0]).length>0 && NumberList.filter(i=>i===BackNumbers[1]).length>0
                    && NumberList.filter(i=>i===SecondBackNumbers[0]).length>0 && NumberList.filter(i=>i===SecondBackNumbers[1]).length>0
                ){
                    let NumberCheck =[]
                    for(let t = 0;t<10;t++){
                        if(NumberList.filter(i=>i===`${t}`).length > 2){
                            NumberCheck.push('6')
                        }else{
                            NumberCheck.push('7')
                        }
                    }
                    if((NumberCheck.filter(i=>i==="6").length > 0)){
                        SoulLV=6
                    }else{
                        SoulLV=7
                    }
                }else if(NumberList.filter(i=>i!==BackNumbers[0]) && NumberList.filter(i=>i!==BackNumbers[1]) 
                    && NumberList.filter(i=>i!==SecondBackNumbers[0]) && NumberList.filter(i=>i!==SecondBackNumbers[1])
                ){
                    SoulLV=4
                }else{
                    SoulLV=5
                }
            }else{
                if(NumberList.filter(i=>i===BackNumbers[0]).length>0 && NumberList.filter(i=>i===BackNumbers[1]).length>0
                    && NumberList.filter(i=>i===SecondBackNumbers[0]).length>0 && NumberList.filter(i=>i===SecondBackNumbers[1]).length>0
                ){
                    SoulLV=3
                }else if(NumberList.filter(i=>i!==BackNumbers[0]).length===NumberList.length && NumberList.filter(i=>i!==BackNumbers[1]).length===NumberList.length 
                    && NumberList.filter(i=>i!==SecondBackNumbers[0]).length===NumberList.length && NumberList.filter(i=>i!==SecondBackNumbers[1]).length===NumberList.length
                ){
                    SoulLV=1
                }else{
                    SoulLV=2
                }
            }
        }else{
            if(NumberList.filter(i=>i===MainNmber).length>0){
                if(NumberList.filter(i=>i===BackNumbers[0]).length>0 && NumberList.filter(i=>i===BackNumbers[1]).length>0){
                    let NumberCheck =[]
                    for(let t = 0;t<10;t++){
                        if(NumberList.filter(i=>i===`${t}`).length > 2){
                            NumberCheck.push('6')
                        }else{
                            NumberCheck.push('7')
                        }
                    }
                    if((NumberCheck.filter(i=>i==="6").length > 0)){
                        SoulLV=6
                    }else{
                        SoulLV=7
                    }
                }else if(NumberList.filter(i=>i!==BackNumbers[0]).length===NumberList.length && NumberList.filter(i=>i!==BackNumbers[1]).length===NumberList.length){
                    SoulLV=4
                }else{
                    SoulLV=5
                }
            }else{
                if(NumberList.filter(i=>i===BackNumbers[0]).length>0 && NumberList.filter(i=>i===BackNumbers[1]).length>0){
                    SoulLV=3
                }else if(NumberList.filter(i=>i!==BackNumbers[0]).length===NumberList.length && NumberList.filter(i=>i!==BackNumbers[1]).length===NumberList.length){
                    SoulLV=1
                }else{
                    SoulLV=2
                }
            }
        }
        
    }

    CheckNum()
    CheckSoul()

    return(
        <TextArea>
            <Text className='br'>你的基本個性是一位{CheckOne[NumberList.filter(i=>i==='1').length]}</Text>
            <Text className='br'>
                而且，你{CheckNight[NumberList.filter(i=>i==='9').length]}
                再加上，你{CheckZero[NumberList.filter(i=>i==='0').length]}
            </Text>
            <Text className='br'>
                再者，你{SoulLVOptions[SoulLV-1].text}
            </Text>
            <Text className='br'>
                除此之外，你還具備有
                {NumbersCheck.filter(i=>i!=='').map((i, index) => (
                    NumberOptions[NumbersCheck.filter(i=>i!=='')[index]]
                ))}
                等等的特質。
            </Text>
        </TextArea>
    )
}

export default CalculateText