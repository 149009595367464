import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import { InputBar, RadioCheck } from '../../components'
import { Mutation } from "react-apollo";
import { Fonts, Styles, Metrics, Colors } from '../../themes'
import { ADD_UPDATE_TRADE, UPDATE_MEMBER_ROLE } from '../../api';
import Cookies from 'js-cookie';

const Options = [
    {
        id: 'heart',
        name: '心性解碼',
        text: '有，曾參加【心性解碼】課程'
    },
    {
        id: 'time',
        name: '時間解碼',
        text: '有，曾參加【時間解碼】課程'
    },
    {
        id: 'motion',
        name: '感情解碼',
        text: '有，曾參加【感情解碼】課程'
    },
]

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    z-index: 102;
    ${'' /* padding: 40px 60px; */}
    @media (max-width: ${Metrics.mobile}){
        height: 100%;
        padding: 8px 20px;
        overflow: scroll;
    }
`
const Content = styled.div`
    @media (max-width: ${Metrics.mobile}){
        position: relative;
    }
    
`
const TextArea = styled.div`
    position: relative;
    padding: 24px;
    z-index: 1;
    @media (max-width: ${Metrics.mobile}){
        padding: 24px 0;
    }
`
const Title = styled(Fonts.h4())`
    margin: 0;
    text-align: center;
    padding: 8px;
    color: ${Colors.B100};
`
const Text = styled(Fonts.P_16())`
    text-align: center;
    color: ${Colors.B100};
    a{
        padding-left: 8px;
        word-wrap: break-word;
        color: ${Colors.Blue};
    }
`
const BtnL = styled(Styles.btnL())`
   margin: 0 auto;
   &.disable{
       pointer-events: none;
   }
`
const InputAreaTwo = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    box-sizing: border-box;
    margin: 16px 0;
    .no-show{
        width: 0;
        height: 0;
        position: absolute;
        opacity: 0;
    }
    @media (max-width: ${Metrics.mobile}){
        display: block;
        height: 100%;
    }
`
const InputArea = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
    box-sizing: border-box;
    margin: 16px 0;
    .no-show{
        width: 0;
        height: 0;
        position: absolute;
        opacity: 0;
    }
    @media (max-width: ${Metrics.mobile}){
        display: block;
        height: 100%;
    }
`
const InfoArea = styled.div`

`
const Info = styled(Fonts.P_16())`
    color: ${Colors.B100}
`
const CheckCourseModal = (props) => {
    const [userId, setUserId] = React.useState();
    const [name, setName] = useState();
    const [cellphone, setCellphone] = useState();
    const [None, setNone] = useState(false);
    const [Check, setCheck] = useState(false);
    const [Forget, setForget] = useState(false);

    useEffect(() => {
        if (Cookies.get('rainbow')) {
            setUserId(Cookies.get('rainbow'))
        }
        if (None) {
            document.getElementById('heart').checked = false;
            document.getElementById('time').checked = false;
            document.getElementById('motion').checked = false;
            document.getElementById('forget').checked = false;
        }
        if (Check) {
            document.getElementById('none').checked = false;
            document.getElementById('forget').checked = false;
        }
        if (Forget) {
            document.getElementById('none').checked = false;
            document.getElementById('heart').checked = false;
            document.getElementById('time').checked = false;
            document.getElementById('motion').checked = false;
        }

        if (document.getElementById('heart').checked || document.getElementById('time').checked || document.getElementById('motion').checked) {
            setCheck(true)
        }
    }, [Check, Forget, None]);
    return (
        <Mutation mutation={ADD_UPDATE_TRADE} onError={() => console.log('error')}>
            {(mutate) => (
                <Wrapper>
                    <Mutation mutation={UPDATE_MEMBER_ROLE(userId)} onError={() => console.log('error')}>
                        {(mutate2) => (
                            <Content>
                                <form id='courseCheck' name='courseCheck' method="POST" data-netlify="true" onSubmit={(e) => {
                                    let event;
                                    let session;
                                    let role;

                                    if (None) {
                                        role = '新生'
                                    } else if (Forget) {
                                        role = '忘記'
                                    } else {
                                        role = '舊生'
                                        if (document.getElementById('motion').checked) {
                                            if (process.env.REACT_APP_BRANCH === 'master') {
                                                // session = '54'
                                                // event = '68'
                                                mutate({
                                                    variables: { event_id: '68', member_id: userId, payment: "已付款", order_number: "", status: "已結束", session_id: "54" }
                                                })
                                            } else {
                                                // session = '61'
                                                // event = '68'
                                                mutate({
                                                    variables: { event_id: '68', member_id: userId, payment: "已付款", order_number: "", status: "已結束", session_id: "61" }
                                                })
                                            }
                                        }
                                        if (document.getElementById('time').checked) {
                                            if (process.env.REACT_APP_BRANCH === 'master') {
                                                // session = '53'
                                                // event = '63'
                                                mutate({
                                                    variables: { event_id: '63', member_id: userId, payment: "已付款", order_number: "", status: "已結束", session_id: "53" }
                                                })
                                            } else {
                                                // session = '62'
                                                // event = '67'
                                                mutate({
                                                    variables: { event_id: '67', member_id: userId, payment: "已付款", order_number: "", status: "已結束", session_id: "62" }
                                                })
                                            }
                                        }
                                        if (document.getElementById('heart').checked) {
                                            if (process.env.REACT_APP_BRANCH === 'master') {
                                                // session = '52'
                                                // event = '66'
                                                mutate({
                                                    variables: { event_id: '66', member_id: userId, payment: "已付款", order_number: "", status: "已結束", session_id: "52" }
                                                })
                                            } else {
                                                // session = '60'
                                                // event = '66'
                                                mutate({
                                                    variables: { event_id: '66', member_id: userId, payment: "已付款", order_number: "", status: "已結束", session_id: "60" }
                                                })
                                            }
                                        }
                                        // mutate({
                                        //     variables: { event_id: event, member_id: userId, payment: "已付款", order_number: "", status: "已結束", session_id: session }
                                        // })
                                    }

                                    mutate2({
                                        variables: { role: role, name: name, cellphone: cellphone }
                                    }).then(alert("傳送成功！"))
                                    // window.location.href = "/reservation"
                                }}>
                                    <input type="hidden" name="form-name" value="courseCheck" />
                                    <TextArea>
                                        <Title>首次登入表單</Title>
                                        <Text>請填寫以下表單，讓我們知道您過去的上課紀錄</Text>
                                        <InputAreaTwo>
                                            <InputBar inputTitle='姓名'>
                                                <input id='name' name='姓名' placeholder='請輸入姓名' value={name} onInput={(e) => setName(e.target.value)} required />
                                            </InputBar>
                                            <InputBar inputTitle='手機號碼' >
                                                {/*<input id='cellphone' name='手機號碼' type='tel' size="10" minlength="9" placeholder='請輸入手機號碼' value={cellphone} onInput={e => setCellphone(e.target.value)} pattern="09[0-9]{8}" required/>*/}
                                                <input id='cellphone' name='手機號碼' type='tel' size="10" minlength="9" placeholder='請輸入手機號碼' value={cellphone} onInput={e => setCellphone(e.target.value)} required />
                                            </InputBar>
                                        </InputAreaTwo>
                                        <Title>請問您曾經參加過田麗虹老師的彩虹數字課程嗎？（可複選）</Title>
                                        <InputArea>
                                            <RadioCheck
                                                className=''
                                                name='無'
                                                id='none'
                                                value="已選擇"
                                                onChange={() => {
                                                    setNone(!None);
                                                    if (Check) {
                                                        setCheck(!Check)
                                                    }
                                                    if (Forget) {
                                                        setForget(!Forget)
                                                    }
                                                }}
                                                text='無'
                                            />
                                            {Options.map((i, index) => (
                                                <RadioCheck
                                                    className=''
                                                    name={i.name}
                                                    id={`${i.id}`}
                                                    value="已選擇"
                                                    onChange={() => {
                                                        setCheck(!Check);
                                                        if (None) {
                                                            setNone(!None)
                                                        }
                                                        if (Forget) {
                                                            setForget(!Forget)
                                                        }
                                                    }}
                                                    text={i.text}
                                                />
                                            ))}
                                            <RadioCheck
                                                className=''
                                                name='忘記/不確定'
                                                id='forget'
                                                value="已選擇"
                                                onChange={() => {
                                                    setForget(!Forget);
                                                    if (Check) {
                                                        setCheck(!Check)
                                                    }
                                                    if (None) {
                                                        setNone(!None)
                                                    }
                                                }}
                                                text='有，但我忘記/不確定課程名稱'
                                            />
                                        </InputArea>
                                        <InfoArea>
                                            <Info>本學會將針對您填答的資料進行核對，並於5個工作天內將結果登錄在您的修課紀錄中。</Info>
                                            <Info>*未完整修習課程者(有缺課)，將紀錄為未修習該課程</Info>
                                            <Info>*若有補課需求，請透過網頁最下方的 LINE、Facebook 或 Email 給我們，助教將為您人工報名補課。</Info>
                                        </InfoArea>
                                    </TextArea>
                                    <BtnL className={None || Check || Forget ? '' : 'disable'} type='submit'>提交</BtnL>
                                </form>
                            </Content>
                        )}
                    </Mutation>
                </Wrapper>
            )}
        </Mutation>
    );
}

export default CheckCourseModal;