import React from 'react';
import styled from 'styled-components'
import { Fonts, Colors, Metrics } from '../../themes'

const Wrapper = styled.div`
    background: white;
    border: 1px solid ${Colors.B10};
    border-radius: 16px;
    box-shadow: 0px 4px 8px 0 rgba(0,0,0,0.1);
    width: 350px;
    height: auto;
    overflow: hidden;
    position: relative;
    margin-right: 16px;
    margin-bottom: 16px;
    &:hover {
        box-shadow: 0px 8px 12px 0 ${Colors.B20};
        transition: 0.8s cubic-bezier(0.2,0.8,0.2,1);
        cursor: pointer;
        .more{
            opacity: 1;
        }
    }
    @media (max-width: 763px){
        display: grid;
        width: 100%;
        margin: 8px;
        grid-template-columns: 120px auto;
    }
    @media (max-width: ${Metrics.mobile}){
        max-height: 149px;
    }
`
const Img = styled.img`
    width: 100%;
    @media (max-width: 763px){
        height: 100%;
        width: auto;
    }
`
const Content = styled.div`
    background: #FFF;
    @media (max-width: 763px){
        position: relative;
    }
`
const TextArea = styled.div`
    padding: 16px 24px 45px;
    @media (max-width: 763px){
        padding: 16px 16px 65px;
    }
`
const Title = styled(Fonts.h4())`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-bottom: 8px;
`

const Text = styled(Fonts.P_16())`
    color:${Colors.G3};
    
    @media (max-width: 763px){
        &.web{
            display: none;
        }
        &.mobile{
            display: -webkit-box !important;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
`
const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 45px;
    position: absolute;
    bottom: 0;
    padding: 0 24px 16px;
    width: 100%;
    align-items: end;
    @media (max-width: 763px){
        padding: 0 16px 8px;
        bottom: 16px;
    }
`
const Price = styled(Fonts.h6())`
    color:${Colors.Blue};
    // padding-top: 16px;
    align-self: end;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    opacity: 0;
    transition: .2s;
    @media (max-width: 763px){
        opacity: 1;
        font-size: 16px;
        line-height: 28px;
    }
`
const Required = styled.p`
    background: ${Colors.Light_Red};
    padding: 2px 4px;
    border-radius: 4px;
    color: ${Colors.Red};
    text-align: center;
    align-self: end;
    margin: 0;
`

const CardEvent = (props) => {
    return (
        // <Link to={props.href}>
            <Wrapper onClick={props.onClick}>
                <Img src={props.img} alt=''/>
                <Content>
                    <TextArea>
                        <Title>{props.title}</Title>
                        {props.text.split('\\n').map(item => (
                            <div><Text className={`web`}>{item === '' ? <br/> : item}</Text></div>
                        ))}
                        <Text className={`mobile`}>{props.text.replace("\\n","")}</Text>
                    </TextArea>
                    <Grid>
                        <Price className='more'>了解更多</Price>
                        {/* <Price>{props.price}</Price> */}
                        {props.required === '必修' &&
                            <div><Required>{props.required}</Required></div>
                        }
                    </Grid>
                </Content>
            </Wrapper>
        // </Link>
    );
}

export default CardEvent;