import React from 'react'
import styled from 'styled-components'
import { Colors, Fonts } from '../../themes'
import { Link } from "react-router-dom";

const BtnDiv = styled(Fonts.button())`
    text-align: center;
    border-radius: 6px;
	padding: 6px 16px;
    border: none;
    transition: 0.2s;
    outline: none;
    position: relative;
    display: grid;
    height: 34px;
    box-sizing: border-box;
    &.solid{
        background: #f74da5;
        color: ${Colors.W100};
        border: 1px solid #f74da5;
    }
    &.secondary{
        background: ${Colors.W100};
        color: ${Colors.B100};
        border: 1px solid  transparent;
    }

    &:hover{
        cursor: pointer;
        
    }
    &.solid:hover{
        background: #ff9a9e;
        color: ${Colors.W100};
        border: 1px solid #ff9a9e;
    }
   
    &.solid:active{
    }

    &.disable.secondary{
        background: ${Colors.W50};
        border: 1px solid transparent;
        color: ${Colors.W50};
    }
    &.disable.solid:hover{
        cursor: default;
    }

    &.disable.solid{
        background: ${Colors.Light_Blue};
        border: 1px solid ${Colors.Light_Blue};
    }
    &.disable.solid:hover{
        cursor: default;
    }

    &.lg{
        padding: 8px 20px;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        height: 44px;
    }
    &.md{
        padding: 6px 16px;
        font-size: 16px;
        font-weight: 600;
        height: 34px;
    }
    &.sm{
        padding: 4px 8px;
        font-size: 12px;
        font-weight: 400;
        height: 25px;s
    }
    &.bold{
        font-weight: 600;
    }
`

const Btn = props => (
    <Link to={props.link}>
        <BtnDiv className={props.className} onClick={props.onClick}>
            {props.btnName}
        </BtnDiv>
    </Link>
)

export default Btn