import React from 'react';
import styled from 'styled-components'
import { Fonts, Metrics, Colors} from '../../themes'

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    z-index: 102;
    padding: 40px 60px;
    @media (max-width: ${Metrics.mobile}){
        height: 100%;
        padding: 8px 20px;
    }
`
const Content = styled.div`
    @media (max-width: ${Metrics.mobile}){
        position: relative;
        top: 50%;
        transform: translateY(-50%)
    }
`
const TextArea = styled.div`
    position: relative;
    padding: 24px;
    z-index: 1;
    @media (max-width: ${Metrics.mobile}){
        padding: 24px 0;
    }
`
const Title = styled(Fonts.h3())`
    margin: 0;
    text-align: center;
    padding-bottom: 8px;
    color: ${Colors.B100};
`
const Text = styled(Fonts.P_16())`
    text-align: center;
    color: ${Colors.B100};
    a{
        padding-left: 8px;
        word-wrap: break-word;
        color: ${Colors.Blue};
    }
`


const EventModal_3 = (props) => {
    return (
        <Wrapper>
            <Content>
                <TextArea>
                    <Title>感情解碼</Title>
                    <Text>「百年修得同船渡，千年修得共枕眠」、「有緣千里來相會、無緣對面不相識」、「不是冤家不聚頭」…為什麼您偏偏就會遇上這個人? </Text>
                    <Text>「感情解碼」是彩虹數字學的高階課程。透過「感情解碼」獨一無二的感情數字精密計算，可以清楚呈現、解讀人與人之間各種相處模式的累世因果關係，包括夫妻、親子、兄弟姊妹、朋友、同事…，徹底透視感情世界間的恩怨情仇，並引領感情經營之道。</Text>
                    <Text> 感情經營困難之處在於「要照顧自己的需求，也要照顧對方的需求」。透過感情數字能量，能夠洞察對方深層的情感需求，調整彼此間的能量落差，建立起最佳相處互動模式，讓兩性關係、家庭關係、人際關係更加和諧自在。</Text>
                </TextArea>
            </Content>
        </Wrapper>
    );
}

export default EventModal_3;