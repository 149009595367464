import React from 'react';
import styled from 'styled-components'
import { FormTitleDiv, InputBar } from '../../components'
import { useForm } from "react-hook-form";
import { Query, Mutation } from "react-apollo";
import { GET_INFO, UPDATE_INFO } from '../../api';
import Cookies from 'js-cookie';

const ContentArea = styled.div`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    textarea{
        overflow:hidden;
        height: 150px;
    }
`

const EditRecommend = (props) => {
    const [ UserData, setUserData ]= React.useState();
    const [ userId, setUserId ] = React.useState()

    const { register, handleSubmit } = useForm();
    const onSubmit = data => {
        setUserData(data)
    };
    
    React.useEffect(() => {
        if(Cookies.get('rainbow')){
                setUserId(Cookies.get('rainbow'))
            }
    },[]);

    return (
        <Mutation mutation={UPDATE_INFO(userId)} onError={()=>console.log('error')}>
        {(mutate) => (
            <FormTitleDiv title='編輯自我介紹' close={props.close} Submit={(e) => {
                e.preventDefault();
                mutate({
                    variables: { info: UserData.info }
                }).then( window.location.reload() )
            }}>
                <form onChange={handleSubmit(onSubmit)}>
                    <Query query={GET_INFO(userId)}>
                        {({ loading, error, data, refetch }) => {
                            return (
                                <>
                                {data &&
                                    <>
                                    {data.member.length>0 &&
                                        <>
                                            <ContentArea>
                                                <InputBar className='full' inputTitle='自我介紹'>
                                                    <textarea id='recommend' wrap="hard" name='info' placeholder='請輸入自我介紹' ref={register}></textarea>
                                                </InputBar>
                                            </ContentArea>
                                        </>
                                    }
                                    </>  
                                }
                                </>
                            );
                        }}
                    </Query>
                </form>
            </FormTitleDiv>
        )}
        </Mutation>
    );
}

export default EditRecommend;