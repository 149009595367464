import React from 'react';
import styled from 'styled-components'
import { Fonts, Colors } from '../../themes'

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 16px;
    border: 1px solid #E0DFE1;
    border-radius: 10px;
    margin-top: 16px;
`
const Profile = styled.img`
    height: 60px;
    width: 60px;
    border-radius: 100%;
`
const TextArea = styled.div`
    padding-left: 16px;
`
const Name = styled(Fonts.h5())`
    color: ${Colors.G2}
`
// const Title = styled(Fonts.h5())`
// `

const CellAccountLevel = ( props ) => {
    return (
        <Wrapper>
            <Profile src={props.img} alt='profile' />
            <TextArea>
                <Name>{props.name}</Name>
                {/* <Title>{props.title}</Title> */}
            </TextArea>
            
        </Wrapper>
    );
}

export default CellAccountLevel;