import React from 'react';
import { Seo, Layout, TitleArea } from '../components'
import styled from 'styled-components'
import { Colors, Fonts, Metrics, Images } from '../themes'

const Wrapper = styled.div`
    position: relative;
    max-width: 1000px;
    margin: 0 auto;
`
const CardArea = styled.div`
    border: 1px solid #E1E6F3;
    border-radius: 16px;
    background-color: ${Colors.W100};
    box-shadow: 0 2px 4px 0 ${Colors.B10};
    padding: 24px 34px;
    margin: 0 16px
`
const Title = styled(Fonts.h5())`
    padding-bottom: 8px;
`
const Text = styled(Fonts.P_16())`
    padding-bottom: 32px;
    &.no-br{
        padding-bottom: 0;
    }
    @media (max-width: ${Metrics.mobile}){
        padding-bottom: 16px;
    }
`

const TermsOfServicePage = (props) => {

    return (
        <Layout>
            <Seo
                title='彩虹數字學會 - 服務條款'
                metaDescription='運用彩虹數字解讀你的生命密碼，了解自己的生命課題。學習「心性解碼」、「時間解碼」、「感情解碼」課程，免費試算自己的數字。'
                metaTitle='彩虹數字學會 - 服務條款'
                metaUrl='https://www.rainbownumen.org/termsOfService'
                //metaImage={`${Images.Rainbow_SEO}`}
                metaImage='https://rainbow-digital.glitch.me/i/ktlc6ygb.png'
            />
            <Wrapper>
                <TitleArea
                    title='服務條款'
                    text='歡迎您使用「彩虹數字 Rainbow Numen 網站」（以下簡稱本網站）、及其他相關的線上服務所提供之服務（以下合稱本服務），本服務係由碩太網路有限公司（以下簡稱本公司或我們 ﹚負責經營，本服務均依據以下「彩虹數字 Rainbow Numen — 會員服務條款」(以下簡稱「本條款」)，為保障您的權益，於開始使用本服務時，請先詳讀本條款與其隱私權政策，以及其他未來陸續公佈之相關條款，並請您確實遵守下列條款：'
                />
                <CardArea>
                    <Title>一、同意接受條款</Title>
                    <Text>隱私權保護政策內容，包括本網站如何處理在您使用網站服務時收集到的個人識別資料。隱私權保護政策不適用於本網站以外的相關連結網站，也不適用於非本網站所委託或參與管理的人員。</Text>
                    <Text>我們會不定期修訂條款而無需特別通知您。修訂的本條款將於公開張貼當日起即時生效，並取代舊有的條款。當您繼續使用本公司服務時即代表您接受該修訂。當您使用特定的本公司服務時，我們雙方均需要遵守為特定服務而公開張貼(並不定期重新張貼)的指引及規則。這些指引和規則均構成本服務條款的一部分。</Text>
                    <Text>若您為未滿二十歲或具完全行為能力人者， 應於您的家長（或監護人或輔佐人）閱讀、瞭解並同意本約定書之所有內容及其後修改變更後之內容，方得使用或繼續使用本服務。當您使用或繼續使用本服務時，即表示您的家長（或監護人）已閱讀、瞭解並同意接受本約定書之所有內容及其後修改變更之內容</Text>

                    <Title>二、服務說明</Title>
                    <Text className='no-br'>本服務包含 彩虹數字 Rainbow Numen 網站、彩虹數字 Rainbow Numen 行動版、及其他相關的線上服務（以下簡稱「本服務」）。</Text>
                    <Text>本服務或第三方可能會提供鏈接至其他網站或網絡資源的鏈接。您可能會因此連結至其他業者經營的網站，但不表示 彩虹數字 Rainbow Numen 網站與該等業者有任何關系。其他業者經營的網站均由各該業者自行負責，不屬 彩虹數字 Rainbow Numen 網站控制及負責範圍之內。對於 彩虹數字 Rainbow Numen 網站中的外部連結，不擔保其合適性、可依賴性、實時性、有效性、正確性及完整性。</Text>
                    <Text className='no-br'>本服務部分內容取得來自於其他平台商分享，所載資料的版權和其他知識產權屬於其平台商人擁有及保留。本服務不會對任何人因實際或指稱侵犯版權或其他知識產權所引致的損失或損害承擔任何法律責任。若本服務分享之內容造成侵權、影響權益，請聯繫該平台商下架或者聯繫我們。</Text>
                    <Text>您明白及同意本服務可能包括廣告，而這些廣告是本公司為提供本服務所必須的。您亦明白及同意本服務可能包括本公司發出的一些通知，例如服務公告及行政消息，而這些通知是本公司服務的一部份。除非另有其他明文規定，增加或強化目前本服務的任何新功能(包括所推出的新產品)均受到本服務條款之規範。您了解也同意，本服務是依「現況」提供，任何用戶通訊或個人化設定之時效、刪除、傳遞錯誤、和未予儲存，本公司均不予負責。</Text>
                    <Text>本公司保留權利無須事先通知您，暫停全部或部分服務以進行維修、保養、更新或其他本公司認為適當的工作。</Text>
                    <Text>本網站在您使用服務信箱、問卷調查等互動性功能時，會保留您所提供的姓名、電子郵件地址、聯絡方式及使用時間等。</Text>
                    <Text>於一般瀏覽時，伺服器會自行記錄相關行徑，包括您使用連線設備的IP位址、使用時間、使用的瀏覽器、瀏覽及點選資料記錄等，做為我們增進網站服務的參考依據，此記錄為內部應用，決不對外公佈。</Text>
                    <Text>為提供精確的服務，我們會將收集的問卷調查內容進行統計與分析，分析結果之統計數據或說明文字呈現，除供內部研究外，我們會視需要公佈統計數據及說明文字，但不涉及特定個人之資料。</Text>

                    <Title>三、本服務說明</Title>
                    <Text>經本公司提供之資訊或服務，包括所有本網站內容、經本公司提供之任何應用程式及平台服務，其文字、圖像、音訊及視訊，均屬本公司之財產或其他合法權利人所有，本公司及其他合法權利人保留所有權益，本公司保留向任何侵權人士追究之權利。</Text>
                    <Text className='no-br'>本公司在提供資訊或服務之過程中，可能得知用戶之個人資料。本公司將盡力保障用戶個人資料之隱密，唯出現非人力範圍內能控制之情況，致有關資料外泄，本公司恕不負責。</Text>
                    <Text>除經您同意或協助政府機關調查案件所需要外，本公司不會向任何第三方透露用戶個人資料；唯本公司有權向用戶以簡訊或電子郵件、電子報、廣告信函或其他方式，不定期告知會員服務或推廣本公司或廣告客戶之產品或服務、活動推廣訊息。</Text>
                    <Text>本服務接收品質視乎網路當時狀況，如遇網路繁忙或遭封鎖，用戶接收本公司提供之資訊及服務，可能遭受 影響或中斷。本公司不保證本服務所提供之各項服務符合您的要求以及相關服務的穩定、無誤及不中斷，用戶需明白承擔使用本服務的任何風險及可能衍生的任何損失或損害；本公司不擔保任何由本公司應用程式下載的資料及檔案不帶有病毒及傷害性軟體。任何由病毒及傷害性軟體引致的破壞，本公司並不會負責。若因合作夥伴、供應商或相關電信商之網路系統軟硬體設備之故障或損壞、或其他人為操作上之疏忽、或錯誤而造成本服務中斷、暫時沒法使用、遲延、資料傳輸或貯存上之錯誤；或遭第三者入侵進行篡改或偽造資料等情況而造成您的電 腦系統產生損壞或資料流失及其他的損失，本公司將不負任何賠償責任。您需要獨自負責並同意賠償因引用本軟體內容引起的賠償及損失，並同意本公司無需承擔賠償及損失。如下載本服務之應用程式所引起的直接及間接損害，本公司將不會承擔一切責任。</Text>
                    <Text>不論是透過網路（如固定撥接網路、寬頻等）或行動電話服務供應商所提供之數據服務（如GPRS、EDGE、3G、4G等)或其他無線上網服務（WiFi)傳輸，用戶均需自行負責上網費用及其他數據傳輸費用。經由行動電信網路商網路認證手機、下載軟體、瀏覽「  行動版」資訊內容以及其他服務，行動電信網路商將向您收取費用；有關收費方式乃用戶與個別電信網路商所訂立契約之內容細則。本公司不會就用戶與個別電信網路商所訂立契約負任何責任。</Text>
                    <Text>本條款最終解釋權屬於本公司所有。本公司亦保留更改以上使用條款之權利，一切以最新版本之使用條款為依歸。用戶敬請參考本公司網站上最新版本之會員服務條款。</Text>
                    <Text>用戶使用本服務，有下列情形之一者，本公司有權暫停或終止該用戶於本公司資訊服務之所有帳號或終止本公司資訊服務之所有契約，並由客戶負一切法律責任；且於停權期間 未滿時，拒絕提供該用戶所有資訊服務： 竊取、更改、破壞本公司資訊情事者。</Text>
                    <Text className='no-br'>1. 有擅自複製服務資訊轉售、轉載情事者。</Text>
                    <Text className='no-br'>2. 擷取非經本公司正式開放或授權之資源。</Text>
                    <Text className='no-br'>3. 有危害通信或影響其他客戶權益者。</Text>
                    <Text className='no-br'>4. 影響系統運作或加重系統負擔者，或干擾應用程式或它的相關軟體、硬體或伺服器。</Text>
                    <Text>5. 其他違反臺灣法律規定之情事。</Text>
                    <Text className='no-br'>會員註冊：</Text>
                    <Text>您的註冊義務為了能使用本服務，您同意以下事項：</Text>
                    <Text className='no-br'>1. 依本服務註冊內容之提示提供您本人正確、精確、最新及完整的資料（前開資料以下簡稱「會員資料」）。</Text>
                    <Text className='no-br'>2. 維持並立即更新「會員資料」，確保其為正確、精確、最新及完整。若您提供任何錯誤、不實、過時、不完整或有誤導成分的資料，或者本公司有合理的理由懷疑前開資料為錯誤、不實、過時、不完整或有誤導成分的資料，本公司有權暫停或終止您的帳號，並拒絕您於現在和未來使用本服務之全部或部份。本公司十分關心所有使用者（特別是兒童）的安全及隱私。</Text>
                    <Text className='no-br'>3. 本服務因應經營需求，得調整個別會員之服務內容。</Text>
                    <Text>4. 隱私權由本公司保存並與您有關的「會員資料」及其他相關資料均受到個人資料條例和本公司「彩虹數字 Rainbow Numen— 隱私權政策」之規管。請到以下的網頁瀏覽的隱私權政策全文: https://https://www.rainbownumen.org/privacy。</Text>
                    <Text className='no-br'>會員帳號、密碼及安全：</Text>
                    <Text>完成本服務的登記程序之後，您將收到一個密碼及帳號。維持密碼及帳號的機密安全，是您的責任。利用該密碼及帳號所進行的一切行動，您將負完全的責任。您同意以下事項：</Text>
                    <Text className='no-br'>1. 您應自行保管帳號及密碼，如發現帳號及密碼遭盜用或其他不當使用，應即時通知關鍵評論網，並協助提出具體事證，惟本公司相對應採取之有關措施，係為因應保障會員相關權益，並不構成對您有明示及或默示之擔保或賠償責任；本公司對於會員之帳號及密碼遭人非法使用，不負賠償責任；</Text>
                    <Text>2. 每次連線完畢，均結束您的帳號使用。您未能遵守本項規定所衍生之任何損失或損害，本公司無法也不予負責。</Text>
                    <Text>會員行為：</Text>
                    <Text className='no-br'>您了解一切的資訊、資料、文字、軟體、音樂、音訊、照片、圖形、視訊、信息或其他資料（以下簡稱「使用者內容」），無論其為公開張貼或私下傳送，均為前開「使用者內容」提供者之責任。易言之，您，而非公司，將對經由本服務上載、張貼、發送電子郵件或傳送之「使用者內容」負完全的責任。本公司無法控制經由本服務而張貼之「使用者內容」，因此不保証前開「使用者內容」之正確性、完整性或品質。您了解使用本服務時，可能會接觸到令人不快、不適當、令人厭惡之「使用者內容」。在任何情況下，本公司均不為任何「使用者內容」負責，包含但不限於任何「使用者內容」之任何錯誤或遺漏，以及經由本服務張貼、發送電子郵件或傳送而衍生之任何損失或損害。</Text>
                    <Text className='no-br'>本公司承諾力求使用者內容之準確性及完整性，但使用者內容如有錯誤或遺漏，本公司不會承擔任何賠償責任。您了解本公司並未針對「使用者內容」事先加以審查，但本公司及其指定人有權（但無義務）依其自行之考量，拒絕和移除可經由本服務提供之任何「使用者內容」。在不限制前開規定之前提下，本公司及其指定人有權將違反本服務條款和令人厭惡之任何「使用者內容」加以移除。您使用任何「使用者內容」時，包括依賴前開「使用者內容」之正確性、完整性或實用性之情形，您同意必須自行加以評估並承擔所有風險。因此，您同意您不得依賴本公司創造或接受之任何「使用者內容」，包含但不限於本服務其他之部分。</Text>
                    <Text className='no-br'>本公司之資訊及使用者內容以臺灣為基礎範圍，所以資訊及使用者內容未必符合其他地區的需要及規範，或未必切合其他地區的實際情況。</Text>
                    <Text>您了解並同意，本公司依據法律或規定的要求，或基於誠信為了以下目的之合理必要範圍內，認定必須將「使用者內容」加以保存或揭露時，得加以保存及揭露：</Text>

                    <Text className='no-br'>　- 遵守法律程序</Text>
                    <Text className='no-br'>　- 執行本服務條款</Text>
                    <Text className='no-br'>　- 回應任何「使用者內容」侵害第三人權利之主張，或保護本公司、其使用者及公眾之權利、財產或個人安全。</Text>
                    <Text>　- 您了解本服務之技術處理及傳送，包含您「使用者內容」，可能經由各個網路加以傳送，且為了符合及配合連線網路或裝置之技術要求而進行改變。</Text>

                    <Text className='no-br'>國際使用之特別警告：</Text>
                    <Text>您了解網際網路的無國界性，同意遵守當地所有相關規定之網上行為及可接受「使用者內容」之法規。您特別同意遵守關於從中華民國或您居住的國家或地區輸出技術性或個人資料所有相關法律。</Text>

                    <Text>於本網站或 彩虹數字 Rainbow Numen 行動版張貼之公共資料：</Text>

                    <Text className='no-br'>1. 為本服務條款適用之目的，「本服務公開使用區域」係指一般公眾可以使用的區域，包括討論區、留言板、行動應用服務等。</Text>
                    <Text>2. 您選擇於本服務公開使用區域上載或張貼之「使用者內容」將代表您同意本公司可用作向公眾發佈之用，您授予本公司免權利金、永久有效、不可撤銷、非專屬及可完全再授權之下述權利：在全球，使用、重製、修改、重新整理、適應化、發行、翻譯「使用者內容」、創作衍生性著作，並將前開「使用者內容」（一部或全部）加以散佈、演示及展示，及 / 或放入利用任何現在已知和未來開發出之形式、媒體和科技之其他著作物當中。</Text>

                    <Text className='no-br'>賠償：</Text>
                    <Text>由於您經由本服務提供、張貼或傳送之「使用者內容」、您使用本服務、您與本服務連 線、您違反本服務條款、或您侵害其他人任何權利因而衍生或導致任何第三人提出索賠 或請求，包括合理的律師費，您同意賠償本公司及其公司主管、代理人、聯名廠商或其 他夥伴及員工，並使其免受損害。</Text>

                    <Text className='no-br'>服務轉售之禁止：</Text>
                    <Text>本服務任何部分或本服務之使用或存取，您同意不進行重製、拷貝、出售、轉售或作任 何商業目的之使用。</Text>

                    <Text className='no-br'>關於使用及儲存之一般措施：</Text>
                    <Text>您承認關於使用本服務，本公司得訂定一般措施及限制，包含但不限於本服務將保留短 消息、佈告欄內容或其他上載「內容」之最長期間、本服務一個帳號當中可收發短消息 的數量限制，以及一般特定期間內您使用本服務之次數上限（及每次使用時間之上限）。 若本公司將本服務維持或傳送之任何訊息及其他通訊和其他「內容」刪除或未予儲存， 您同意本公司毋須承擔任何責任。您亦同意，長時間未使用的帳號，本公司有權關閉。 您也同意，本公司有權隨時變更這些一般措施及限制，而不需要作出任何通知。</Text>

                    <Text className='no-br'>服務之修改：</Text>
                    <Text>本公司有權於任何時間暫時或永久修改或終止本服務（或其任何部分），無論其通知與 否。並可以隨時終止您使用部份及全部服務內容,事先毋須以書面通知。本服務任何修 改、暫停或終止，您同意本公司對您和任何第三人均不承擔責任。</Text>

                    <Text className='no-br'>終止：</Text>
                    <Text>您同意本公司得基於其自行之考量，因任何理由，包含但不限於缺乏使用，或本公司認 為您已經違反本服務條款的明文規定及精神，終止您的密碼、帳號（或其任何部分）或 本服務之使用，並將本服務內任何「內容」加以移除並刪除。本公司無論有否通知您， 都可以依其自行之考量隨時終止本服務或其任何部分。您同意依本服務條款任何規定提 供之本服務，無需事先通知您即可暫停或終止，您承認並同意，本公司得立即關閉或刪 除您的帳號及您帳號中所有相關資料及上載「內容」，及 / 或禁止前開檔案和本服務之 使用。此外，您同意若您被暫停或終止進入服務，或您的帳戶或有關的資料和檔案被凍 結或刪除時，本公司對您或任何第三人均不承擔責任。</Text>

                    <Text className='no-br'>連結：</Text>
                    <Text>您進一步同意，因使用或信賴存在或經由前開任何網站 或資源之任何「內容」、商品或服務所生或據稱所生之任何損害或損失，本公司不負任 何直接或間接之責任。</Text>

                    <Text className='no-br'>免責聲明您明確了解並同意：</Text>

                    <Text className='no-br'>1. 本公司根據本條款履行與服務有關的義務，只限於以合理的技能和謹慎為您提供的相關服務。</Text>
                    <Text>2. 您使用本服務之風險由您個人負擔。本服務係依「現況」及「現有」基礎提供。本公司明示不提供任何明示或默示的擔保，包含但不限於商業適售性、特定目的之適用性及未侵害第三方的權利。任何收看/參與互動節目的人士，須自行承擔一切風險，本公司不會負責任何因瀏覽或使用本網站而引起之損失或破壞。</Text>

                    <Text className='no-br'>3. 本公司不保証以下事項：</Text>
                    <Text className='no-br'>　- 本服務將符合您的要求</Text>
                    <Text className='no-br'>　- 本服務不受干擾、及時提供、安全可靠或免於出錯</Text>
                    <Text className='no-br'>　- 由本服務之使用而取得之結果為正確或可靠</Text>
                    <Text>　- 您經由本服務購買或取得之任何產品、服務、資訊或其他資料將符合您的期望。</Text>

                    <Text className='no-br'>4. 是否經由本服務之使用下載或取得任何資料應由您自行考量且自負風險，因前開任何資料之下載而導致您電腦系統之任何損壞或資料流失，您應負完全責任。</Text>
                    <Text>5. 您自本公司或經由本服務取得之建議和資訊，無論其為書面或口頭，絕不構成本服務條款未明示規定之任何保証。</Text>

                    <Text className='no-br'>責任限制：</Text>
                    <Text>您明確了解並同意，基於以下原因而造成之損失，包括但不限於利潤、商譽、使用、資 料損失或其他無形損失，本公司不承擔任何直接、間接、附帶、特別、衍生性或懲罰性 賠償（即使本公司已被告知前開賠償之可能性亦然）：</Text>

                    <Text className='no-br'>1. 本服務之使用或無法使用</Text>
                    <Text>2. 經由或透過本服務購買或取得之任何商品、資料、資訊或服務，或接收之訊息，或進行之交易所衍生之替代商品及服務之購買成本</Text>
                    <Text>3. 您的傳輸或資料遭到未獲授權的存取或變造</Text>
                    <Text>4. 本服務中任何第三人之聲明或行為，或本服務(在本條款中以其他方式明確提供的除外)其他相關事宜。</Text>

                    <Text className='no-br'>著作權及著作權代理人：</Text>
                    <Text className='no-br'>本公司尊重他人知識財產，亦呼籲使用者同樣尊重他人之知識財產。若您認為您的著作 遭到重製之情形已構成著作權之侵害，請提供以下資料予本公司：</Text>
                    <Text>彩虹數字 Rainbow Numen 尊重他人著作權，亦要求我們的使用者同樣尊重他人著作權，若您認為 彩虹數字 Rainbow Numen 網站中之任何網頁內容侵害您的著作權， 請提供相關數據將有關侵犯版權透過以下電郵通知我們:</Text>

                    <Text className='no-br'>著作權聲明：</Text>
                    <Text className='no-br'>1. 本服務內容（包括但不限於︰本網站商標、服務及標記、商業名稱、商業設計和商品、文字、圖像、音訊視訊），由本公司自行製作或經合法授權而公開傳輸，均受到中華民國著作權法、版權、商標、專利權或其他知識產權法例保障。</Text>
                    <Text className='no-br'>2. 本服務的內容或服務僅供個人、非商業用途之使用，未經內容著作權人之授權，不得轉載、公開播送或公開傳輸。所有商業性質利用或合理使用範圍外的個人利用，｀均須自行取得著作權人之授權。</Text>
                    <Text className='no-br'>3. 使用者利用本服務時必須遵守著作權法的所有相關規定，不可變更、發行、公開播送、重製、改作、散布、公開演出、公開展示、公開傳輸或對外有任何非經授權之營利或非營利行為。</Text>
                    <Text className='no-br'>4. 使用者自行發表文章內容於本服務，即同意授權留存於本服務網域內使用，供網友合法瀏覽使用。</Text>
                    <Text className='no-br'>5. 本服務所刊載之內容，或有提供或建置相關連結等，該等連結所指向之網頁或資料，均為被連結網站所提供，相關權利為該等網站或合法權利人所有，本服務不擔保其正確性、即時性或完整性。</Text>
                    <Text>6. 本服務所刊載內容所包含之著作權人、著作權聲明、授權條款等，受著作權法之保護，利用人不得更改或移除。</Text>

                    <Title>四、個別條款之效力：本條款之全部或一部份無效時，不影響其他約定之效力。</Title>
                    <Text></Text>
                    <Title>五、 一般條款：</Title>
                    <Text>本條款構成您與本公司之全部合意，並規範您對於本服務之使用，並取代您先前與本公司所為之任何約定。您使用相關服務、第三方內容或軟體時，亦應符合適用之額外條款及條件。</Text>
                    <Text>本服務條款及您與本公司之關係，均受到中華民國法律所管轄，不適用其涉外法律原則。</Text>
                    <Text>本服務條款之解釋與適用，以及與本服務條款有關的爭議，均以中華民國法律為準據法，並以台灣台北地方法院為第一審管轄法院。</Text>
                    <Text>本公司未行使或執行本服務條款任何權利或規定，不構成前開權利或權利之拋棄。倘本服務條款任何規定經管轄法院認定無效，當事人依然同意法院應努力使前開規定反映之當事人意向具備效力，且本條款其他規定仍應保有完整的效力及效果。</Text>
                    <Text>您同意無論任何法令或法律是否有其他規定，本服務之使用或本服務條款所衍生或相關之任何主張或訴訟原因，應於前開主張或訴訟原因發生後一年內提出，否則永遠不得提出。</Text>
                    <Text>您同意本公司保留一切就本服務收費的權利，並同意本公司有權不時更改該費用。如本公司因您違反任何服務條款而暫停或終止您的帳號，您將不會獲任何退款。</Text>
                </CardArea>
            </Wrapper>
        </Layout>
    );
}

export default TermsOfServicePage;