import React from 'react';
import styled from 'styled-components'
import { Colors, Fonts, Images, Metrics } from '../../themes'

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    z-index: 102;
    max-width: 680px;
`
const Header = styled.div`
    display: grid;
    grid-gap: 12px;
    grid-template-columns: 58px 1fr;
    position: relative;
    align-items: center;
    padding-bottom: 12px;
`
const Img = styled.img`
    width: 58px;
    border-radius: 100%;
`
const MobileScroll = styled.div`
    position: relative;
    width: 100%;
	height: 100%;
	max-height: calc(100% - 58px);
	overflow: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
`
const SectionTitle = styled(Fonts.h3())`
  color: ${Colors.B100};
  margin: 0;
  align-self: flex-end;
`
const SectionText = styled(Fonts.h6())`
  color: ${Colors.B100};
  margin: 0;
  @media (max-width: ${Metrics.tablet}) {
    font-size: 14px;	
    line-height: 20px;
  }
`
const Text = styled(Fonts.P_20())`
    color: ${Colors.B100};
    text-align: left;
    a{
        padding-left: 8px;
    }
    &.br{
        padding-bottom: 20px;
    }
    &:last-child{
        padding-bottom: 40px;
    }
`
const LiHungModal = (props) => {

    return (
        <Wrapper>
            <Header>
                <Img src={Images.ceo} />
                <div>
                    <SectionTitle>田麗虹</SectionTitle>
                    <SectionText>中華民國彩虹數字學會理事長</SectionText>
                </div>
            </Header>
            <MobileScroll>
                <Text className='br'>田老師精研彩虹數字學，擅長以數字解析個人生命藍圖，提供超越矛盾困境之建議方向。除開設各項彩虹數字學教學課程外，亦提供個人與企業之諮詢服務。著有「麗子的彩虹數字學～理論基礎與數字解碼」一書。</Text>
                <Text className='br'>田老師同時也是一位具有多重身分的彩虹數字學者-----</Text>
                <Text className=''>藝術文化方面：</Text>
                <Text className=''>從水墨畫創作轉入西方藝術教學及研究。</Text>
                <Text className='br'>早年師事嶺南派國畫大師歐豪年，近十餘年來於國立臺北科技大學開設「藝術欣賞」、「篆刻藝術創作」等課程，亦擔任中央警察大學推廣教育訓練中心講師。曾任國家圖書館藝術暨視聽資料中心顧問、財團法人李天祿布袋戲文教基金會董事、財團法人人文空間發展文教基金會董事。著有「原來藝術這麼簡單Ⅰ-史前到中世紀的西方藝術」。</Text>
                <Text className=''>政治方面：</Text>
                <Text className='br'>曾任立法委員國會辦公室主任 20 年， 發起成立國會助理工會，當選第一屆理事。獲日本交流協會聘請為歷史研究者，赴日進行「台日國會運作制度比較」之研究；亦應美國在台協會之邀，赴美從事參、眾兩院功能之訪問考察。著有「國會助理工作手冊～國會生態地圖總導覽」。</Text>
                <Text className=''>兩岸關係方面：</Text>
                <Text className='br'>早年多次受邀至廈門大學、南京大學等台研所進行專題講座。有關大陸政策及中國區域發展研究論文多發表於國內重要專門期刊。現為國立政治大學國家發展研究所博士候選人，著有「兩岸關係的決策分析～解析行政立法關係下的大陸政策」一書，並參與「兩岸政策藍皮書」專題寫作。</Text>
                <Text className=''>企業經營管理方面：</Text>
                <Text className='br'>曾任一通科技股份有限公司董事長、長鴻營造股份有限公司策略總監。現任立華智能股份有限公司董事長、經緯航太股份有限公司策略總監。</Text>
                
            </MobileScroll>
        </Wrapper>
    );
}

export default LiHungModal;