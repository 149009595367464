import React from 'react';
import styled from 'styled-components'
import { Fonts, Colors, Styles,Metrics } from '../../themes'

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 0;
    width: 100%;
    @media (max-width: ${Metrics.mobile}){
        flex-wrap: wrap;
    }
`

const Title = styled(Fonts.P_16())`
    padding-left: 6px;
    color: ${Colors.G2};
    min-width: 110px;
    @media (max-width: ${Metrics.mobile}){
        min-width: 90px;
    }
    @media (max-width: 325px){
        min-width: 85px;
    }
`
const Text = styled(Fonts.P_16())`
    padding: 0 0 0 6px;
`
const Btn = styled(Styles.btnS())`
    margin-left: auto;
    background: ${Colors.Light_Red};
    color: ${Colors.Red};
    min-width: 96px;
    transition: .3s;
    &:hover{
        background: rgba(230, 92, 82, 0.2);
    }
    @media (max-width: ${Metrics.mobile}){
        width: 100%;
        margin-top: 10px;
    }
`
const CellTable = ( props ) => {
    return (
        <Wrapper>
            <Title>{props.title}</Title>
            <Text>{props.text}</Text>
            {props.logout &&
                <Btn onClick={props.onClick}>會員登出</Btn>
            }
        </Wrapper>
    );
}

export default CellTable;