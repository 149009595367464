import React from 'react';
import styled from 'styled-components'
import { Colors, Fonts, Styles } from '../../themes'
// import { BtnL } from '../../components'

const Wrapper = styled.div`
    position: relative;
    max-width: 1080px;
    height: 360px;
    border-radius: 20px;
    margin: 0 16px;
    overflow: hidden;
    &.center{
        margin: 0 auto;
        img{
            width: auto;
            height: 100%;
        }
    }
    img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: auto;
        height: 100%;
        max-width: 1080px;
    }
    &.sh-img{
        img{
            width: 100%;
            height: auto;
        }
    }
    @media (max-width: 820px){
        &.center,&.sh-img{
            img{
                width: auto;
                height: 100%;
            }
        }
    }
`

const TopLayer = styled(Styles.toplayer())`
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    padding: 68px auto;
`

const TitleArea = styled.div`
    margin-bottom: 16px;
    padding: 0 16px;
`

const Title = styled(Fonts.h2())`
    text-align: center;
    color: ${Colors.W100};
    text-shadow: 0px 2px 4px ${Colors.B50};
`

const Text = styled(Fonts.h5())`
    text-align: center;
    color: ${Colors.W100};
    padding: 0 16px;
    text-shadow: 0px 2px 4px ${Colors.B50};
`

const BtnL = styled(Styles.btnL())`
    margin: 16px auto;
`


const CardBannerS = (props) => {
    return (
        <Wrapper className={props.className}>
            <img src={props.bg} alt='' />
            <TopLayer>
                <TitleArea>
                    {props.title.split('\\n').map((item,index) => (
                        <div key={`title-${index}`}><Title >{item === '' ? <br/> : item}</Title></div>
                    ))}
                </TitleArea>
                {props.text.split('\\n').map((item,index)=> (
                    <div key={`text-${index}`}><Text >{item === '' ? <br/> : item}</Text></div>
                ))}
                {props.BtnTitle &&
                    <BtnL className='secondary' onClick={props.onClick}>
                        <p>{props.BtnTitle}</p>
                    </BtnL>
                }
            </TopLayer>
        </Wrapper>
    );
}

export default CardBannerS;