import React, { useState }  from 'react';
import {
    Seo, LayoutNothing, 
    FlexFree, CalculatorLove
} from '../components'
import styled from 'styled-components'
import { useHistory } from "react-router-dom";

const TabView = styled.div`
    padding: 24px 16px 40px;
`

const CardBottom = styled.div`
    height: 16px;
`

const ComputePage = (props) => {
    const id = props.match?.params?.id;
    const [date, setDate] = useState('2000-01-01');
    const [time, setTime] = useState('');
    const [name, setName] = useState('');
    const [gender, setGender] = useState('');

    const [value, setValue] = React.useState(Number(id));
    const history = useHistory();
    
    return (
        
        <LayoutNothing event>
            <Seo
                title='戀愛數字測驗'
                metaDescription='戀愛數字測驗'
                metaTitle='戀愛數字測驗'
                metaUrl='https://www.rainbownumen.org/event/0'
                //metaImage={`${Images.Rainbow_SEO}`}
                metaImage='https://rainbow-digital.glitch.me/i/ktlc6ygb.png'
            />
            {value === 0 &&
                <TabView>
                    <FlexFree>
                    <CalculatorLove parent_date={date} parent_time={time} parent_name={name} parent_gender={gender} set_parent_date={setDate} set_parent_time={setTime} set_parent_name={setName} set_parent_gender={setGender}/>
                    </FlexFree>
                    <CardBottom />
                </TabView>
            }
        </LayoutNothing>

    );
}

export default ComputePage;
