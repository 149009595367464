import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components'
import { Images, Colors, Fonts, Metrics } from '../themes'
import { CalculateBtn, InputDateBar, CheckBar, InputBar } from '.'
import Slider from "react-slick"
import { DatePicker, Space, TimePicker } from 'antd';
import { isMobileOnly, isSafari } from 'react-device-detect';
import html2canvas from 'html2canvas';
var moment = require('moment');
const { Solar, Lunar, HolidayUtil } = require('lunar-javascript')

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding-bottom: 80px;
    .slick-slider{
        width: 100%;
    }
    .slick-next, .slick-prev{
        position: relative;
        display: flex;
        top: 0;
        left: 50%;
        background: #000;
    }

`
const Content = styled.div`
    position: relative;
    display: grid !important;
    justify-items: center;
    padding: 46px 24px 20px;
    width: 100%;
    box-sizing: border-box;
    .submit{
        margin-top: 24px;
        position: relative;
        z-index: 10000;
    }
    .checkBar{
        justify-self: center;
    }
`
const Title = styled.p`
    font-size: ${window.innerWidth < 360 ? '22px' : '24px'};
    font-weight:bold;
    color: ${Colors.B100};
    text-align: left;
    margin: 0 !important;
    padding-bottom: 12px;

`
const Text = styled.p`
    color: ${Colors.B100};
    font-size: 20px;
    margin: 0;
    letter-spacing: 0;
    line-height: 28px;
    max-height: 210px;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }

`
const BtnArea = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    bottom: 0px !important;
    z-index: 1000;
    margin-bottom: 16px;
    button{
        margin: 0 4px;
    }
`
const ContentArea = styled.div`
    position: relative;
    
    grid-template-columns: 50% 50%;
    grid-gap: 6px;
    width: 340px;
    box-sizing: border-box;
    padding-bottom: 24px;
    .input-area,.black{
        padding: 8px 6px 4px 0;
    }
    @media (max-width: ${Metrics.mobile}){
        display: block;
        height: 100%;
    }
`
const Calculator = (props) => {
    const [date, setDate] = useState(props.parent_date);
    const [time, setTime] = useState(props.parent_time);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [timeBak, setTimeBak] = useState('');
    const [check, setCheck] = useState(false);
    const [alert, setAlert] = useState(false)
    const [width, setWidth] = useState(window.innerWidth);
    const [only_hour, setOnlyHour] = useState(false);
    const [form1, setForm1] = useState(
        {
            username: props.parent_name,
            gender: props.parent_gender,
            question: '',
            advice: '',
            adviceDate: '',
            adviceName: '',
            date: '',
            ldate: '',
            time: '',
            year: '',
            month: '',
            day: '',
            lyear: '',
            lmonth: '',
            lday: '',
            hour: '',
            minute: '',
            forget_minute: false,
            cross_year: false,
            only_hour: false,
            add_month: false,
            name: '',
            age: '',
            BackNumbers: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
            SecondBackNumbers: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
            MainNumber: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
            Soul: ['', '', '', '', '', '', '', '', '', ''],
        }
    );

    const [form2, setForm2] = useState(
        {
            username: '',
            gender: '',
            question: '',
            advice: '',
            adviceDate: '',
            adviceName: '',
            date: '',
            ldate: '',
            time: '',
            year: '',
            month: '',
            day: '',
            lyear: '',
            lmonth: '',
            lday: '',
            hour: '',
            minute: '',
            forget_minute: false,
            cross_year: false,
            only_hour: false,
            add_month: false,
            name: '',
            age: '',
            BackNumbers: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
            SecondBackNumbers: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
            MainNumber: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
            Soul: ['', '', '', '', '', '', '', '', '', ''],
        }
    );
    function calculateAll(date_value, time_value, only_hour_value, name_value, gender_value, form) {
        form.date = date_value;
        form.only_hour = only_hour_value;
        form.name = name_value;
        form.gender = gender_value;
        const gregorianYear = moment(date_value).format('YYYY');
        const gregorianMonth = moment(date_value).format('MM');
        const gregorianDay = moment(date_value).format('DD');

        const date1 = new Date(date_value);
        form.year = date1.getFullYear();
        form.month = date1.getMonth() + 1; // 月份從 0 開始，所以要加 1
        form.day = date1.getDate();

        const lunarDate = Solar.fromYmd(gregorianYear, gregorianMonth, gregorianDay).getLunar();
        form.ldate = lunarDate.getYear() + '-' + String(Math.abs(lunarDate.getMonth())).padStart(2, '0') + '-' + String(lunarDate.getDay()).padStart(2, '0')

        form.lyear = lunarDate.getYear();
        form.lmonth = Math.abs(lunarDate.getMonth());
        form.lday = lunarDate.getDay();
        form.add_month = (lunarDate.getMonth()) >= 0 ? false : true;
        form.time = time_value;
        form.hour = time_value != '' ? time_value.split(':')[0] : '';
        form.minute = only_hour_value ? '' : time_value != '' ? time_value.split(':')[1] : '';

        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1; // 月份從 0 開始，所以要加 1
        const day = currentDate.getDate();
        const lunarDateNow = Solar.fromYmd(year, month, day).getLunar();
        const year2 = lunarDateNow.getYear();
        const month2 = Math.abs(lunarDateNow.getMonth());
        const day2 = lunarDateNow.getDay();
        let syear = '';
        let age = year - form.year
        if (month > form.month) {
            syear = year;
        } else if (month < form.month) {
            syear = year - 1;
            age = age - 1
        } else {
            if (day > form.day) {
                syear = year;
            } else if (day < form.day) {
                syear = year - 1;
                age = age - 1
            } else {
                syear = year;
            }
        }
        if (form.year > form.lyear) {
            form.cross_year = true
        } else {
            form.cross_year = false
        }
        form.age = age;
        const sdate = String(syear) + '-' + String(form.month).padStart(2, '0') + '-' + String(form.day).padStart(2, '0')
        const ldate = String(syear - (form.cross_year ? 1 : 0)) + '-' + String(form.lmonth).padStart(2, '0') + '-' + String(form.lday).padStart(2, '0')

        const NumberList = date_value.split("").filter(i => i !== '-')
        const NumberListLunar = form.ldate.split("").filter(i => i !== '-')
        const NumberList1 = time_value.split("").filter(i => i !== ':')
        const NumberList2 = NumberList.concat(NumberList1)
        const NumberList2Lunar = NumberListLunar.concat(NumberList1)
        const NumberList3 = sdate.split("").filter(i => i !== '-')
        const NumberList3Lunar = ldate.split("").filter(i => i !== '-')

        form.BackNumbers[0] = sum(NumberList.slice(0, 4).map(Number)).toString().padStart(2, '0').split("")
        form.BackNumbers[1] = sum(NumberList.slice(0, 6).map(Number)).toString().padStart(2, '0').split("")
        form.BackNumbers[2] = sum(NumberList.map(Number)).toString().padStart(2, '0').split("")
        form.BackNumbers[3] = sum(NumberList2.slice(0, 10).map(Number)).toString().padStart(2, '0').split("")
        form.BackNumbers[4] = sum(NumberList2.map(Number)).toString().padStart(2, '0').split("")
        form.BackNumbers[5] = sum(NumberListLunar.slice(0, 4).map(Number)).toString().padStart(2, '0').split("")
        form.BackNumbers[6] = sum(NumberListLunar.slice(0, 6).map(Number)).toString().padStart(2, '0').split("")
        form.BackNumbers[7] = sum(NumberListLunar.map(Number)).toString().padStart(2, '0').split("")
        form.BackNumbers[8] = sum(NumberList2Lunar.slice(0, 10).map(Number)).toString().padStart(2, '0').split("")
        form.BackNumbers[9] = sum(NumberList2Lunar.map(Number)).toString().padStart(2, '0').split("")
        form.BackNumbers[10] = sum(NumberList3.map(Number)).toString().padStart(2, '0').split("")
        form.BackNumbers[11] = sum(NumberList3Lunar.map(Number)).toString().padStart(2, '0').split("")

        for (let i = 0; i < 12; i++) {
            [form.SecondBackNumbers[i], form.MainNumber[i]] = calculateNumbers(form.BackNumbers[i], form.SecondBackNumbers[i], form.MainNumber[i])
        }
        for (let i = 0; i < 5; i++) {
            form.Soul[i] = CheckSoul0(form.BackNumbers[i], form.SecondBackNumbers[i], form.MainNumber[i], NumberList)
        }
        for (let i = 5; i < 10; i++) {
            form.Soul[i] = CheckSoul0(form.BackNumbers[i], form.SecondBackNumbers[i], form.MainNumber[i], NumberListLunar)
        }
        if (time_value == '') {
            form.Soul[3] = form.Soul[4] = form.Soul[8] = form.Soul[9] = 0
        }
    }
    function calculateNumbers(BackNumbers, SecondBackNumbers, MainNumber) {
        if (sum(BackNumbers.map(Number)).toString().split("").length > 1) {
            SecondBackNumbers = sum(BackNumbers.map(Number)).toString().split("")
            MainNumber = sum(SecondBackNumbers.map(Number)).toString()
        } else {
            SecondBackNumbers = ''
            MainNumber = sum(BackNumbers.map(Number)).toString()
        }
        return [SecondBackNumbers, MainNumber]
    }
    props.set_parent_date(date)
    props.set_parent_time(time)
    props.set_parent_name(form1.username)
    props.set_parent_gender(form1.gender)

    const fsize = window.innerWidth < 360 ? '19px' : '22px';
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // 月份從 0 開始，所以要加 1
    const day = currentDate.getDate();
    const currentDateFormat = year + '-' + String(Math.abs(month)).padStart(2, '0') + '-' + String(day).padStart(2, '0')
    const gregorianYear = moment(date).format('YYYY');
    const gregorianMonth = moment(date).format('MM');
    const gregorianDay = moment(date).format('DD');
    const date1 = new Date(date);
    const year1 = date1.getFullYear();
    const month1 = date1.getMonth() + 1; // 月份從 0 開始，所以要加 1
    const day1 = date1.getDate();
    let syear = '';
    let age = year - year1
    //console.log(age)
    if (month > month1) {
        syear = year;
    } else if (month < month1) {
        syear = year - 1;
        age = age - 1
    } else {
        if (day > day1) {
            syear = year;
        } else if (day < day1) {
            syear = year - 1;
            age = age - 1
        } else {
            syear = year;
        }
    }
    //console.log(age)
    // if (month >= month1) {
    //     syear = year;
    // } else {
    //     syear = year - 1;
    // } 
    const lunarDate1 = Solar.fromYmd(gregorianYear, gregorianMonth, gregorianDay).getLunar();
    const lunarDateNow1 = Solar.fromYmd(year, month, day).getLunar();
    const lunarDate = lunarDate1.getYear() + '-' + String(Math.abs(lunarDate1.getMonth())).padStart(2, '0') + '-' + String(lunarDate1.getDay()).padStart(2, '0')

    const year2 = lunarDateNow1.getYear();
    const month2 = Math.abs(lunarDateNow1.getMonth());
    const day2 = lunarDateNow1.getDay();
    const year3 = lunarDate1.getYear();
    const month3 = Math.abs(lunarDate1.getMonth());
    const day3 = lunarDate1.getDay();
    let second_date = (lunarDate1.getMonth() < 0) ? Lunar.fromYmd(year3, month3 + 1, day3).getSolar().toString():date;
    calculateAll(date, time, only_hour, form1.name, form1.gender, form1);
    calculateAll(second_date, time, only_hour, form1.name, form1.gender, form2);
    //console.log(lunarDate,year2,month2,day2,month3);
    let lyear = '';

    if (month2 > month3) {
        lyear = year2;
    } else if (month2 < month3) {
        lyear = year2 - 1;
    } else {
        if (day2 > day3) {
            lyear = year2;
        } else if (day2 < day3) {
            lyear = year2 - 1;
        } else {
            lyear = year2;
        }
    }
    // if (month2 >= month3) {
    //     lyear = year2;
    // } else {
    //     lyear = year2 - 1;
    // } 
    //lyear = syear
    //console.log(syear,lyear);
    const sdate = String(syear) + '-' + String(month1).padStart(2, '0') + '-' + String(day1).padStart(2, '0')
    const ldate = String(lyear) + '-' + String(month3).padStart(2, '0') + '-' + String(day3).padStart(2, '0')
    //console.log(sdate, ldate);
    const NumberList = date.split("").filter(i => i !== '-')
    const NumberListLunar = lunarDate.split("").filter(i => i !== '-')
    //console.log('time=',time)
    const NumberList1 = time.split("").filter(i => i !== ':')
    const NumberList2 = NumberList.concat(NumberList1)
    const NumberList2Lunar = NumberListLunar.concat(NumberList1)
    const NumberList3 = sdate.split("").filter(i => i !== '-')
    const NumberList3Lunar = ldate.split("").filter(i => i !== '-')

    let NumbersCheck = ''
    let NumbersCheck2 = ''

    let BackNumbers1 = sum(NumberList.slice(0, 4).map(Number)).toString().padStart(2, '0').split("")
    let SecondBackNumbers1 = ''
    let MainNmber1 = ''
    let SoulLV1
    let BackNumbers2 = sum(NumberList.slice(0, 6).map(Number)).toString().padStart(2, '0').split("")
    let SecondBackNumbers2 = ''
    let MainNmber2 = ''
    let SoulLV2
    let BackNumbers3 = sum(NumberList.map(Number)).toString().padStart(2, '0').split("")
    let SecondBackNumbers3 = ''
    let MainNmber3 = ''
    let SoulLV3
    let BackNumbers4 = sum(NumberList2.slice(0, 10).map(Number)).toString().padStart(2, '0').split("")
    let SecondBackNumbers4 = ''
    let MainNmber4 = ''
    let SoulLV4
    let BackNumbers5 = sum(NumberList2.map(Number)).toString().padStart(2, '0').split("")
    let SecondBackNumbers5 = ''
    let MainNmber5 = ''
    let SoulLV5

    let BackNumbers6 = sum(NumberListLunar.slice(0, 4).map(Number)).toString().padStart(2, '0').split("")
    let SecondBackNumbers6 = ''
    let MainNmber6 = ''
    let SoulLV6
    let BackNumbers7 = sum(NumberListLunar.slice(0, 6).map(Number)).toString().padStart(2, '0').split("")
    let SecondBackNumbers7 = ''
    let MainNmber7 = ''
    let SoulLV7
    let BackNumbers8 = sum(NumberListLunar.map(Number)).toString().padStart(2, '0').split("")
    let SecondBackNumbers8 = ''
    let MainNmber8 = ''
    let SoulLV8
    let BackNumbers9 = sum(NumberList2Lunar.slice(0, 10).map(Number)).toString().padStart(2, '0').split("")
    let SecondBackNumbers9 = ''
    let MainNmber9 = ''
    let SoulLV9
    let BackNumbers10 = sum(NumberList2Lunar.map(Number)).toString().padStart(2, '0').split("")
    let SecondBackNumbers10 = ''
    let MainNmber10 = ''
    let SoulLV10

    let BackNumbers11 = sum(NumberList3.map(Number)).toString().padStart(2, '0').split("")
    let SecondBackNumbers11 = ''
    let MainNmber11 = ''
    let BackNumbers12 = sum(NumberList3Lunar.map(Number)).toString().padStart(2, '0').split("")
    let SecondBackNumbers12 = ''
    let MainNmber12 = ''
    //form.hour = time != '' ? time.split(':')[0] : '';
    //form.minute = only_hour ? '' : time != '' ? time.split(':')[1] : '';

    const ResultSlider = useRef();
    const [sliderSettings] = useState({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        speed: 300,
        cssEase: `linear`,
        adaptiveHeight: true,
        draggable: false,
        swipe: false,
    })
    const [index, setIndex] = useState(0);

    const gotoNext = () => {
        ResultSlider.current.slickNext()
        setIndex(index + 1)
    }
    const gotoPrev = () => {
        ResultSlider.current.slickPrev()
        setIndex(index - 1)
    }
    const Restart = () => {
        setImageLoaded(false);
        ResultSlider.current.slickGoTo(0)
        setIndex(0)
    }

    function onChange(e, dateString) {
        setAlert(false)
        // if (isMobileOnly) {
        //     setDate(e.target.value)
        //     if (e.target.value === '') {
        //         setDate('2000-01-01')
        //     }
        // } else {
        setDate(dateString)
        if (dateString === '') {
            setDate('2000-01-01')
        }
        //}
    }
    function Submit() {
        if (date) {
            setAlert(false)
            ResultSlider.current.slickNext()
            setIndex(index + 1)
        } else {
            setAlert(true)
        }
    }

    function sum(arr) {
        var len = arr.length;
        if (len === 0) {
            return 0;
        } else if (len === 1) {
            return arr[0];
        } else {
            return arr[0] + sum(arr.slice(1));
        }
    }

    if (sum(BackNumbers1.map(Number)).toString().split("").length > 1) {
        SecondBackNumbers1 = sum(BackNumbers1.map(Number)).toString().split("")
        MainNmber1 = sum(SecondBackNumbers1.map(Number)).toString()
    } else {
        MainNmber1 = sum(BackNumbers1.map(Number)).toString()
    }
    if (sum(BackNumbers2.map(Number)).toString().split("").length > 1) {
        SecondBackNumbers2 = sum(BackNumbers2.map(Number)).toString().split("")
        MainNmber2 = sum(SecondBackNumbers2.map(Number)).toString()
    } else {
        MainNmber2 = sum(BackNumbers2.map(Number)).toString()
    }
    if (sum(BackNumbers3.map(Number)).toString().split("").length > 1) {
        SecondBackNumbers3 = sum(BackNumbers3.map(Number)).toString().split("")
        MainNmber3 = sum(SecondBackNumbers3.map(Number)).toString()
    } else {
        MainNmber3 = sum(BackNumbers3.map(Number)).toString()
    }
    if (sum(BackNumbers4.map(Number)).toString().split("").length > 1) {
        SecondBackNumbers4 = sum(BackNumbers4.map(Number)).toString().split("")
        MainNmber4 = sum(SecondBackNumbers4.map(Number)).toString()
    } else {
        MainNmber4 = sum(BackNumbers4.map(Number)).toString()
    }
    if (sum(BackNumbers5.map(Number)).toString().split("").length > 1) {
        SecondBackNumbers5 = sum(BackNumbers5.map(Number)).toString().split("")
        MainNmber5 = sum(SecondBackNumbers5.map(Number)).toString()
    } else {
        MainNmber5 = sum(BackNumbers5.map(Number)).toString()
    }
    if (sum(BackNumbers6.map(Number)).toString().split("").length > 1) {
        SecondBackNumbers6 = sum(BackNumbers6.map(Number)).toString().split("")
        MainNmber6 = sum(SecondBackNumbers6.map(Number)).toString()
    } else {
        MainNmber6 = sum(BackNumbers6.map(Number)).toString()
    }
    if (sum(BackNumbers7.map(Number)).toString().split("").length > 1) {
        SecondBackNumbers7 = sum(BackNumbers7.map(Number)).toString().split("")
        MainNmber7 = sum(SecondBackNumbers7.map(Number)).toString()
    } else {
        MainNmber7 = sum(BackNumbers7.map(Number)).toString()
    }
    if (sum(BackNumbers8.map(Number)).toString().split("").length > 1) {
        SecondBackNumbers8 = sum(BackNumbers8.map(Number)).toString().split("")
        MainNmber8 = sum(SecondBackNumbers8.map(Number)).toString()
    } else {
        MainNmber8 = sum(BackNumbers8.map(Number)).toString()
    }
    if (sum(BackNumbers9.map(Number)).toString().split("").length > 1) {
        SecondBackNumbers9 = sum(BackNumbers9.map(Number)).toString().split("")
        MainNmber9 = sum(SecondBackNumbers9.map(Number)).toString()
    } else {
        MainNmber9 = sum(BackNumbers9.map(Number)).toString()
    }
    if (sum(BackNumbers10.map(Number)).toString().split("").length > 1) {
        SecondBackNumbers10 = sum(BackNumbers10.map(Number)).toString().split("")
        MainNmber10 = sum(SecondBackNumbers10.map(Number)).toString()
    } else {
        MainNmber10 = sum(BackNumbers10.map(Number)).toString()
    }
    if (sum(BackNumbers11.map(Number)).toString().split("").length > 1) {
        SecondBackNumbers11 = sum(BackNumbers11.map(Number)).toString().split("")
        MainNmber11 = sum(SecondBackNumbers11.map(Number)).toString()
    } else {
        MainNmber11 = sum(BackNumbers11.map(Number)).toString()
    }
    if (sum(BackNumbers12.map(Number)).toString().split("").length > 1) {
        SecondBackNumbers12 = sum(BackNumbers12.map(Number)).toString().split("")
        MainNmber12 = sum(SecondBackNumbers12.map(Number)).toString()
    } else {
        MainNmber12 = sum(BackNumbers12.map(Number)).toString()
    }

    function CheckNum() {
        let NumberCheck = []
        for (let t = 0; t < 10; t++) {
            if (t === 0 || t === 1 || t === 9) {
                NumberCheck.push('')
            } else {
                if (NumberList.filter(i => i === `${t}`).length > 0) {
                    NumberCheck.push(`${t}`)
                } else {
                    NumberCheck.push('')
                }
            }
        }
        NumbersCheck = NumberCheck
    }
    function CheckNum2() {
        let NumberCheck = []
        for (let t = 0; t < 10; t++) {
            if (t === 0 || t === 1 || t === 9) {
                NumberCheck.push('')
            } else {
                if (NumberListLunar.filter(i => i === `${t}`).length > 0) {
                    NumberCheck.push(`${t}`)
                } else {
                    NumberCheck.push('')
                }
            }
        }
        NumbersCheck2 = NumberCheck
    }
    function checkArrayValues(array1, array2) {
        // 检查第二个数组中的值是否都存在于第一个数组中
        const allValuesExist = array2.every(value => array1.includes(value));

        // 检查第二个数组中是否有任意一个值存在于第一个数组中
        const anyValueExists = array2.some(value => array1.includes(value));

        if (allValuesExist) {
            return 1; // 第二个数组中的所有值都在第一个数组中
        } else if (anyValueExists) {
            return 2; // 第二个数组中有值存在于第一个数组中
        } else {
            return 3; // 第二个数组中的所有值都不在第一个数组中
        }
    }
    function CheckSoul0(BackNumbers, SecondBackNumbers, MainNumber, NumberCheckList) {
        let origin = []
        if (SecondBackNumbers.length > 1) {
            origin = BackNumbers.concat(SecondBackNumbers)
        } else {
            origin = BackNumbers
        }
        let result = origin.filter(function (element, index, arr) {
            return arr.indexOf(element) === index;
        });
        if (NumberCheckList.filter(i => i === MainNumber).length > 0) {
            if (checkArrayValues(NumberCheckList, result) == 1) {
                let NumberCheck = []
                for (let t = 0; t < 10; t++) {
                    if (NumberCheckList.filter(i => i === `${t}`).length > 2 && t !== 0) {
                        NumberCheck.push('6')
                    } else {
                        NumberCheck.push('7')
                    }
                }
                if ((NumberCheck.filter(i => i === "6").length > 0)) {
                    return 6
                } else {
                    return 7
                }
            } else if (checkArrayValues(NumberCheckList, result) == 2) {
                return 5
            } else {
                return 4
            }
        } else {
            if (checkArrayValues(NumberCheckList, result) == 1) {
                return 3
            } else if (checkArrayValues(NumberCheckList, result) == 2) {
                return 2
            } else {
                return 1
            }
        }
    }
    function CheckSoul(BackNumbers, SecondBackNumbers, MainNmber) {
        let origin = []
        if (SecondBackNumbers.length > 1) {
            origin = BackNumbers.concat(SecondBackNumbers)
        } else {
            origin = BackNumbers
        }
        let result = origin.filter(function (element, index, arr) {
            return arr.indexOf(element) === index;
        });
        if (NumberList.filter(i => i === MainNmber).length > 0) {
            if (checkArrayValues(NumberList, result) == 1) {
                let NumberCheck = []
                for (let t = 0; t < 10; t++) {
                    if (NumberList.filter(i => i === `${t}`).length > 2 && t !== 0) {
                        NumberCheck.push('6')
                    } else {
                        NumberCheck.push('7')
                    }
                }
                if ((NumberCheck.filter(i => i === "6").length > 0)) {
                    return 6
                } else {
                    return 7
                }
            } else if (checkArrayValues(NumberList, result) == 2) {
                return 5
            } else {
                return 4
            }
        } else {
            if (checkArrayValues(NumberList, result) == 1) {
                return 3
            } else if (checkArrayValues(NumberList, result) == 2) {
                return 2
            } else {
                return 1
            }
        }
    }
    function CheckSoul2(BackNumbers, SecondBackNumbers, MainNmber) {
        let origin = []
        if (SecondBackNumbers.length > 1) {
            origin = BackNumbers.concat(SecondBackNumbers)
        } else {
            origin = BackNumbers
        }
        let result = origin.filter(function (element, index, arr) {
            return arr.indexOf(element) === index;
        });
        if (NumberListLunar.filter(i => i === MainNmber).length > 0) {
            if (checkArrayValues(NumberListLunar, result) == 1) {
                let NumberCheck = []
                for (let t = 0; t < 10; t++) {
                    if (NumberListLunar.filter(i => i === `${t}`).length > 2 && t !== 0) {
                        NumberCheck.push('6')
                    } else {
                        NumberCheck.push('7')
                    }
                }
                if ((NumberCheck.filter(i => i === "6").length > 0)) {
                    return 6
                } else {
                    return 7
                }
            } else if (checkArrayValues(NumberListLunar, result) == 2) {
                return 5
            } else {
                return 4
            }
        } else {
            if (checkArrayValues(NumberListLunar, result) == 1) {
                return 3
            } else if (checkArrayValues(NumberListLunar, result) == 2) {
                return 2
            } else {
                return 1
            }
        }
    }

    CheckNum()
    CheckNum2()
    SoulLV1 = CheckSoul(BackNumbers1, SecondBackNumbers1, MainNmber1)
    SoulLV2 = CheckSoul(BackNumbers2, SecondBackNumbers2, MainNmber2)
    SoulLV3 = CheckSoul(BackNumbers3, SecondBackNumbers3, MainNmber3)
    SoulLV4 = CheckSoul(BackNumbers4, SecondBackNumbers4, MainNmber4)
    SoulLV5 = CheckSoul(BackNumbers5, SecondBackNumbers5, MainNmber5)
    SoulLV6 = CheckSoul2(BackNumbers6, SecondBackNumbers6, MainNmber6)
    SoulLV7 = CheckSoul2(BackNumbers7, SecondBackNumbers7, MainNmber7)
    SoulLV8 = CheckSoul2(BackNumbers8, SecondBackNumbers8, MainNmber8)
    SoulLV9 = CheckSoul2(BackNumbers9, SecondBackNumbers9, MainNmber9)
    SoulLV10 = CheckSoul2(BackNumbers10, SecondBackNumbers10, MainNmber10)
    if (time == '') {
        SoulLV4 = 0
        SoulLV5 = 0
        SoulLV9 = 0
        SoulLV10 = 0
    }
    //console.log(SoulLV1,SoulLV2,SoulLV3,SoulLV4,SoulLV5)
    let snumber = []
    let lnumber = []
    let sunIndex = 0
    let lunIndex = 0
    for (let i = 0; i < 9; i++) {
        let sn = ((Number(MainNmber3) + i) % 9) === 0 ? 9 : (Number(MainNmber3) + i) % 9
        if (Number(MainNmber11) === sn) {
            sn = '+' + BackNumbers11.join("") + '/' + (SecondBackNumbers11 == "" ? "" : SecondBackNumbers11.join("") + "/") + MainNmber11
            sunIndex = i
        }
        snumber.push(sn)
        let ln = ((Number(MainNmber8) + i) % 9) === 0 ? 9 : (Number(MainNmber8) + i) % 9
        if (Number(MainNmber12) === ln) {
            ln = '-' + BackNumbers12.join("") + '/' + (SecondBackNumbers12 == "" ? "" : SecondBackNumbers12.join("") + "/") + MainNmber12
            lunIndex = i
        }
        lnumber.push(ln)
    }
    //console.log(snumber,lnumber)
    const handleDownloadWhite = () => {
        const imageUrl = Images.word; // 图片的URL

        // 创建一个新的<a>元素
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = '彩虹數字諮詢單.png'; // 下载的文件名

        // 将<a>元素添加到DOM，并模拟点击
        document.body.appendChild(link);
        link.click();

        // 清理工作，将<a>元素从DOM中移除
        document.body.removeChild(link);
    };
    const handleDownload = () => {
        setImageLoaded(false);
        const element = document.getElementById('rainbowimg');
        html2canvas(element).then(canvas => {
            const link = document.createElement('a');
            link.download = '彩虹數字諮詢單.png';
            link.href = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
            link.click();
            setImageLoaded(true);
        });
    };
    function allChinese(val) {
        let reg = new RegExp("[\\u4E00-\\u9FFF]+$", "g");
        if (reg.test(val)) return true
        return false
    }
    function ImageNum(soul) {
        if (soul === 1) {
            return <img style={{
                width: '26px'
            }} src={Images.c1}></img>
        } else if (soul === 2) {
            return <img style={{
                width: '26px'
            }} src={Images.c2}></img>
        } else if (soul === 3) {
            return <img style={{
                width: '26px'
            }} src={Images.c3}></img>
        } else if (soul === 4) {
            return <img style={{
                width: '26px'
            }} src={Images.c4}></img>
        } else if (soul === 5) {
            return <img style={{
                width: '26px'
            }} src={Images.c5}></img>
        } else if (soul === 6) {
            return <img style={{
                width: '26px'
            }} src={Images.c6}></img>
        } else if (soul === 7) {
            return <img style={{
                width: '26px'
            }} src={Images.c7}></img>
        }
    }
    function NumPosition(BackNumbers, SecondBackNumbers, MainNmber, soul, fl) {
        return <>
            <div style={{ position: 'relative', flex: fl }}>
                <div
                    style={{
                        position: 'absolute',
                        top: '-15px',
                        left: '40px',
                        color: 'black',
                        fontSize: fsize,
                    }}>{BackNumbers.join("")}
                </div>
                <img src={Images.slash} style={{
                    position: 'absolute',
                    top: '5px',
                    left: '37px',
                    color: 'black',
                    fontSize: fsize,
                    width: '30px',
                }} />
                {SecondBackNumbers == "" ? <>
                    <div
                        style={{
                            position: 'absolute',
                            top: '12px',
                            left: '52px',
                            color: 'black',
                            fontSize: fsize,
                        }}>{MainNmber}
                    </div>
                    <div
                        style={{
                            position: 'absolute',
                            top: '42px',
                            left: '42px',
                            color: 'black',
                            fontSize: fsize,
                        }}>{ImageNum(soul)}
                    </div>
                </> : <div
                    style={{
                        position: 'absolute',
                        top: '14px',
                        left: `${parseInt(SecondBackNumbers.join("")) >= 10 ? '43px' : '50px'}`,
                        color: 'black',
                        fontSize: fsize,
                    }}>{SecondBackNumbers.join("")}<img src={Images.slash} style={{
                        position: 'absolute',
                        top: '20px',
                        left: '-3px',
                        color: 'black',
                        fontSize: fsize,
                        width: '30px',
                    }} />
                    <div
                        style={{
                            position: 'absolute',
                            top: '25px',
                            left: '10px',
                            color: 'black',
                            fontSize: fsize,
                        }}>{MainNmber}
                    </div>
                    <div
                        style={{
                            position: 'absolute',
                            top: '59px',
                            left: '0px',
                            color: 'black',
                            fontSize: fsize,
                        }}>{ImageNum(soul)}
                    </div>
                </div>}
            </div>
        </>
    }
    function ImageTri(index, slnumber) {
        return <div style={{ position: 'relative', flex: 1 }}>
            &nbsp;
            <img src={Images.lyear} alt="Image" />
            <div
                style={{
                    position: 'absolute',
                    top: '-1px',
                    left: '61px',
                    color: `${index === 3 ? 'red' : 'black'}`,
                    fontSize: '15px',
                }}>{slnumber[3]}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '30px',
                    left: `${22 - 18 * (index === 2 ? 1 : 0)}px`,
                    color: `${index === 2 ? 'red' : 'black'}`,
                    fontSize: '15px',
                }}>{slnumber[2]}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '30px',
                    left: '100px',
                    color: `${index === 4 ? 'red' : 'black'}`,
                    fontSize: '15px',
                }}>{slnumber[4]}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '61px',
                    left: `${1 - 18 * (index === 1 ? 1 : 0)}px`,
                    color: `${index === 1 ? 'red' : 'black'}`,
                    fontSize: '15px',
                }}>{slnumber[1]}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '61px',
                    left: '117px',
                    color: `${index === 5 ? 'red' : 'black'}`,
                    fontSize: '15px',
                }}>{slnumber[5]}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '115px',
                    left: `${-15 - 18 * (index === 0 ? 1 : 0)}px`,
                    color: `${index === 0 ? 'red' : 'black'}`,
                    fontSize: '15px',
                }}>{slnumber[0]}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '115px',
                    left: '135px',
                    color: `${index === 6 ? 'red' : 'black'}`,
                    fontSize: '15px',
                }}>{slnumber[6]}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '125px',
                    left: `${37 - 18 * (index === 8 ? 1 : 0)}px`,
                    color: `${index === 8 ? 'red' : 'black'}`,
                    fontSize: '15px',
                }}>{slnumber[8]}
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: '125px',
                    left: `${84 - 18 * (index === 7 ? 1 : 0)}px`,
                    color: `${index === 7 ? 'red' : 'black'}`,
                    fontSize: '15px',
                }}>{slnumber[7]}
            </div>
        </div>
    }
    function tri(sun) {
        return sun === 1 ? <>
            {ImageTri(sunIndex, snumber)}
        </> : <>
            {ImageTri(lunIndex, lnumber)}
        </>
    }
    function word() {
        return <>
            <Text>&nbsp;</Text>
            <div style={{ display: 'flex' }}>
                <div style={{ position: 'relative', flex: 100 }}>
                    <Title>+{date.replace('-', ' . ').replace('-', ' . ')}{form1.hour !== '' && (' . ' + form1.hour)}{form1.minute !== '' && (' . ' + form1.minute)}{<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}</Title>
                    <div style={{ display: 'flex' }}>
                        &nbsp;&nbsp;
                        {NumPosition(BackNumbers1, SecondBackNumbers1, MainNmber1, SoulLV1, block1)}
                        {NumPosition(BackNumbers2, SecondBackNumbers2, MainNmber2, SoulLV2, block1)}
                        {NumPosition(BackNumbers3, SecondBackNumbers3, MainNmber3, SoulLV3, (form1.hour !== '' || form1.minute !== '') ? block1 : block2)}
                        {form1.hour !== '' && <>
                            {NumPosition(BackNumbers4, SecondBackNumbers4, MainNmber4, SoulLV4, form1.minute !== '' ? block1 : block2)}
                        </>}
                        {form1.minute !== '' && <>
                            {NumPosition(BackNumbers5, SecondBackNumbers5, MainNmber5, SoulLV5, block2)}
                        </>}
                    </div>
                </div>
                {<div style={{ position: 'relative', flex: 1 }}>&nbsp;</div>}
                {tri(1)}
            </div>
            <Text>&nbsp;</Text>
            <Text>&nbsp;</Text>
            <div style={{ display: 'flex' }}>
                <div style={{ position: 'relative', flex: 100 }}>
                    <Title>-{lunarDate.replace('-', ' . ').replace('-', ' . ')}{form1.hour !== '' && (' . ' + form1.hour)}{form1.minute !== '' && (' . ' + form1.minute)}{<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}</Title>
                    <div style={{ display: 'flex' }}>
                        {NumPosition(BackNumbers6, SecondBackNumbers6, MainNmber6, SoulLV6, block1)}
                        {NumPosition(BackNumbers7, SecondBackNumbers7, MainNmber7, SoulLV7, block1)}
                        {NumPosition(BackNumbers8, SecondBackNumbers8, MainNmber8, SoulLV8, (form1.hour !== '' || form1.minute !== '') ? block1 : block2 + 1)}
                        {form1.hour !== '' && <>
                            {NumPosition(BackNumbers9, SecondBackNumbers9, MainNmber9, SoulLV9, form1.minute !== '' ? block1 : block2 + 1)}
                        </>}
                        {form1.minute !== '' && <>
                            {NumPosition(BackNumbers10, SecondBackNumbers10, MainNmber10, SoulLV10, block2 + 1)}
                        </>}
                    </div>
                </div>
                {<div style={{ position: 'relative', flex: 1 }}>&nbsp;</div>}
                {tri(0)}
            </div>
            <Text>&nbsp;</Text>
            <Text>&nbsp;</Text>
        </>
    }

    function tri1(form, sun) {
        let snumber = []
        let lnumber = []
        let sunIndex = 0
        let lunIndex = 0
        for (let i = 0; i < 9; i++) {
            let sn = ((Number(form.MainNumber[2]) + i) % 9) === 0 ? 9 : (Number(form.MainNumber[2]) + i) % 9
            if (Number(form.MainNumber[10]) === sn) {
                sn = '+' + form.BackNumbers[10].join("") + '/' + (form.SecondBackNumbers[10] == "" ? "" : form.SecondBackNumbers[10].join("") + "/") + form.MainNumber[10]
                sunIndex = i
            }
            snumber.push(sn)
            let ln = ((Number(form.MainNumber[7]) + i) % 9) === 0 ? 9 : (Number(form.MainNumber[7]) + i) % 9
            if (Number(form.MainNumber[11]) === ln) {
                ln = '-' + form.BackNumbers[11].join("") + '/' + (form.SecondBackNumbers[11] == "" ? "" : form.SecondBackNumbers[11].join("") + "/") + form.MainNumber[11]
                lunIndex = i
            }
            lnumber.push(ln)
        }
        return sun === 1 ? <>
            {ImageTri(sunIndex, snumber)}
        </> : <>
            {ImageTri(lunIndex, lnumber)}
        </>
    }
    function positions1(form, sun) {
        const block11 = 7
        return sun === 1 ? <>
            {NumPosition(form.BackNumbers[0], form.SecondBackNumbers[0], form.MainNumber[0], form.Soul[0], block11)}
            {NumPosition(form.BackNumbers[1], form.SecondBackNumbers[1], form.MainNumber[1], form.Soul[1], block11)}
            {NumPosition(form.BackNumbers[2], form.SecondBackNumbers[2], form.MainNumber[2], form.Soul[2], block11 + (form.hour !== '' || form.minute !== '' ? 0 : 9))}
            {form.hour !== '' && <>
                {NumPosition(form.BackNumbers[3], form.SecondBackNumbers[3], form.MainNumber[3], form.Soul[3], block11 + (form.minute !== '' ? 0 : 9))}
            </>}
            {form.minute !== '' && <>
                {NumPosition(form.BackNumbers[4], form.SecondBackNumbers[4], form.MainNumber[4], form.Soul[4], 16)}
            </>}
        </> : <>
            {NumPosition(form.BackNumbers[5], form.SecondBackNumbers[5], form.MainNumber[5], form.Soul[5], block11)}
            {NumPosition(form.BackNumbers[6], form.SecondBackNumbers[6], form.MainNumber[6], form.Soul[6], block11)}
            {NumPosition(form.BackNumbers[7], form.SecondBackNumbers[7], form.MainNumber[7], form.Soul[7], block11 + (form.hour !== '' || form.minute !== '' ? 0 : 10))}
            {form.hour !== '' && <>
                {NumPosition(form.BackNumbers[8], form.SecondBackNumbers[8], form.MainNumber[8], form.Soul[8], block11 + (form.minute !== '' ? 0 : 10))}
            </>}
            {form.minute !== '' && <>
                {NumPosition(form.BackNumbers[9], form.SecondBackNumbers[9], form.MainNumber[9], form.Soul[9], 17)}
            </>}
        </>
    }
    function word1(form) {
        return <>
            <Text>&nbsp;</Text>
            <div style={{ display: 'flex' }}>
                <div style={{ position: 'relative', flex: 100 }}>
                    <Title>+{form.date.replace('-', ' . ').replace('-', ' . ')}{form.hour !== '' && (' . ' + form.hour)}{form.minute !== '' && (' . ' + form.minute)}{<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}</Title>
                    <div style={{ display: 'flex' }}>
                        &nbsp;&nbsp;
                        {positions1(form, 1)}
                    </div>
                </div>
                {tri1(form, 1)}
            </div>
            <Text>&nbsp;</Text>
            <Text>&nbsp;</Text>
            <div style={{ display: 'flex' }}>
                <div style={{ position: 'relative', flex: 100 }}>
                    <Title>-{form.ldate.replace('-', ' . ').replace('-', ' . ')}{form.hour !== '' && (' . ' + form.hour)}{form.minute !== '' && (' . ' + form.minute)}{<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}</Title>
                    <div style={{ display: 'flex' }}>
                        {positions1(form, 0)}
                    </div>
                </div>
                {tri1(form, 0)}
            </div>
        </>
    }


    let block1 = 8
    let block2 = 25

    const handleTimeChange = (t) => {
        let hours = t.hours().toString().padStart(2, "0");
        let minutes = t.minutes().toString().padStart(2, "0");
        setTime(`${hours}:${minutes}`);
    };
    const handleTimeBlur = (t) => {
        // 在失去焦點時更新時間狀態
        setTime(t.target.value);
    };
    const handleCheckMinute = (e) => {
        setOnlyHour(!only_hour)
    };
    const handleCheck = () => {
        setCheck(!check)
        if (!check) {
            setTimeBak(time)
            setTime('');
        } else {
            setTime(timeBak);
        }

    };
    const handleChange = (e) => {
        //console.log(e.target.name);
        if (e.target.name !== 'adviceDate') {
            e.preventDefault()
            e.stopPropagation()
        }
        setForm1({
            ...form1,
            [e.target.name]: e.target.value,
        });
    };
    function PreloadImage(props) {
        const { src, alt } = props;
    
        useEffect(() => {
          const image = new Image();
          image.src = src;
    
          image.onload = () => {
            setImageLoaded(true);
          };
    
          return () => {
            image.onload = null;
          };
        }, [src]);
    
        return (
          <img src={imageLoaded ? src : Images.loading} alt={alt} style={{ width: imageLoaded ? "100%" : '' }} />
        );
    }
    const Loading = () => {
    
        return (
          <div className="page-loading">
            <script src="https://cdn.lordicon.com/bhenfmcm.js"></script>
            <div className="-load">
              <div className="-icon">
                <lord-icon src="https://cdn.lordicon.com/xjovhxra.json" trigger="loop" delay="300" colors="primary:#ffffff,secondary:#ffffff" style={{ width: '60px', height: '60px' }}></lord-icon>
              </div>
              <div className="-font">
                <span>l</span>
                <span>o</span>
                <span>a</span>
                <span>d</span>
                <span>i</span>
                <span>n</span>
                <span>g</span>
              </div>
            </div>
          </div>
        );
    };
    return (
        <Wrapper>
            <Slider {...sliderSettings} ref={ResultSlider}>
                <Content>
                    <Title>生命密碼檔案程式</Title>
                    <InputDateBar inputTitle='年、月、日 (單月、日要多加一個0，例:1983-01-03)' className='top'>
                        <Space direction="vertical">
                            <DatePicker className={alert ? 'alert' : ''} placeholder={date} onChange={onChange} require />
                        </Space>
                    </InputDateBar>
                    <InputDateBar inputTitle='時、分' className='top'>
                        <TimePicker
                            //defaultValue={moment('00:00', 'HH:mm')}
                            value={time === '' ? moment('00:00', 'HH:mm') : moment(time, 'HH:mm')}
                            onChange={handleTimeChange}
                            format='HH:mm'
                            allowClear={false}
                            className={`${check ? 'disable' : ''}`}
                            disabled={check ? true : false}
                            inputReadOnly
                            clearIcon={false}
                            showNow={false}
                            onBlur={handleTimeBlur}
                        />
                    </InputDateBar>
                    <div className="checkbox-container">
                        <CheckBar className='blue'>
                            <input type="checkbox" name="minute" id='minute' value={only_hour} onChange={(e) => handleCheckMinute(e)} />
                            <span htmlFor="minute">只忘記分</span>
                        </CheckBar>
                        <CheckBar className='blue'>
                            <input type="checkbox" name="time" id='time' value={check} onChange={(e) => handleCheck(e)} />
                            <span htmlFor="time">忘記時、分</span>
                        </CheckBar>
                    </div>

                    <form id='form' >
                        <ContentArea>
                            <InputBar inputTitle='個案姓名' className='top'>
                                <input id='username' name='username' type='text' placeholder='請輸入個案姓名' maxLength={30} value={form1.username} onChange={(e) => handleChange(e)} />
                            </InputBar>
                            <InputBar inputTitle='性別' className='top'>
                                <input id='gender' name='gender' type='text' placeholder='請輸入個案性別' maxLength={2} value={form1.gender} onChange={(e) => handleChange(e)} />
                            </InputBar>
                            <InputBar inputTitle='個案提問(限150字)'>
                                <textarea
                                    id='question'
                                    name='question'
                                    placeholder='請輸入個案提問'
                                    rows={5}
                                    value={form1.question}
                                    maxLength={150} // 设置最大字数限制
                                    wrap="soft" // 自动换行
                                    style={{ resize: 'vertical' }} // 可选，垂直方向可调整大小
                                    onChange={(e) => handleChange(e)}
                                />
                            </InputBar>
                            <InputBar inputTitle='諮詢師建議(限250字)'>
                                <textarea
                                    id='advice'
                                    name='advice'
                                    placeholder='請輸入諮詢師建議'
                                    rows={5}
                                    value={form1.advice}
                                    maxLength={250} // 设置最大字数限制
                                    wrap="soft" // 自动换行
                                    style={{ resize: 'vertical' }} // 可选，垂直方向可调整大小
                                    onChange={(e) => handleChange(e)}
                                />
                            </InputBar>
                            <InputBar inputTitle='諮詢師' className='top'>
                                <input id='adviceName' name='adviceName' type='text' placeholder='請輸入諮詢師' maxLength={16} value={form1.adviceName} onChange={(e) => handleChange(e)} />
                            </InputBar>
                        </ContentArea>
                    </form>
                    <InputDateBar inputTitle='諮詢日期' className='top'>
                        <input id='adviceDate' name='adviceDate' className={date ? 'no-placeholder' : ''} type='date' placeholder={currentDateFormat} value={form1.adviceDate} onChange={e => handleChange(e)} />
                        <img src={Images.duedate_w} alt='duedate' />
                    </InputDateBar>
                </Content>
                {index === 1 &&
                    <Content id={'rainbowimg'}>
                        {<>
                            {!imageLoaded && <Loading />}
                            <PreloadImage style={{ width: '100%' }} src={Images.word} alt="Image" />
                            {imageLoaded && (<>
                            <div style={{ fontSize: '28px', letterSpacing: allChinese(form1.username) ? '20px' : '', position: 'absolute', top: '17.7%', left: '15%' }}>
                                {form1.username}
                            </div>
                            <div style={{ fontSize: '28px', position: 'absolute', top: '17.7%', left: '78%' }}>
                                {age}
                            </div>
                            <div style={{ fontSize: '28px', position: 'absolute', top: '17.7%', left: '89%' }}>
                                {form1.gender}
                            </div>
                            {
                                (lunarDate1.getMonth() < 0) &&
                                <div style={{ fontSize: '28px', position: 'absolute', top: '20%', left: '28%' }}>
                                    {'(有兩組)'}
                                </div>
                            }
                            {
                                (lunarDate1.getMonth() > 0) &&
                                <div style={{ position: 'absolute', top: '24%', left: '25%' }}>
                                    {word()}
                                </div>
                            }
                            {
                                (lunarDate1.getMonth() < 0) &&
                                <div style={{ position: 'absolute', top: '24%', left: '6%' }}>
                                    {word1(form1)}
                                </div>
                            }
                            {
                                (lunarDate1.getMonth() < 0) &&
                                <div style={{ position: 'absolute', top: '24%', left: '51%' }}>
                                    {word1(form2)}
                                </div>
                            }
                            <div style={{ fontSize: '28px', lineHeight: '1.25', position: 'absolute', top: '51.6%', left: '9%', paddingRight: '2em' }} dangerouslySetInnerHTML={{ __html: form1.question.replace(/\n/g, '<br />') }} />
                            <div style={{ fontSize: '28px', lineHeight: '1.25', position: 'absolute', top: '67.5%', left: '9%', paddingRight: '2em' }} dangerouslySetInnerHTML={{ __html: form1.advice.replace(/\n/g, '<br />') }} />
                            <div style={{ fontSize: '22px', position: 'absolute', top: '90.6%', left: '82.5%' }}>
                                {form1.adviceDate}
                            </div>
                            <div style={{ fontSize: '28px', letterSpacing: allChinese(form1.adviceName) ? '15px' : '', position: 'absolute', top: '90.2%', left: '62.5%' }}>
                                {form1.adviceName}
                            </div></>)}
                        </>}
                        
                    </Content>}
            </Slider>
            {index === 0 &&
                <BtnArea>
                    <CalculateBtn
                        className="prev solid lg"
                        btnName='下載空白諮詢單'
                        onClick={() => handleDownloadWhite()}
                    />
                    <CalculateBtn
                        className={date ? 'submit solid lg' : 'solid submit disable lg'}
                        btnName='產生諮詢單'
                        onClick={() => Submit()}
                    />
                </BtnArea>
            }
            {index === 1 &&
                <BtnArea>
                    <CalculateBtn
                        className="prev solid lg"
                        btnName='下載諮詢單'
                        onClick={() => handleDownload()}
                    />
                    <CalculateBtn
                        className='solid lg'
                        btnName='回上一頁'
                        onClick={Restart}
                    />
                </BtnArea>
            }
        </Wrapper>
    );
}

export default Calculator;
