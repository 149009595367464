import React from 'react';
import styled from 'styled-components'
import { Fonts,Colors, Metrics } from '../../themes'


const Wrapper = styled.div`
    box-sizing: border-box;
    max-width: 520px;
    min-height: 246px;
    padding: 24px;
    border: 1px solid ${Colors.B10};
    background-color: ${Colors.W100};
    border-radius: 16px;
    box-shadow: 0px 4px 8px 0 ${Colors.B10};
    position: relative;
    margin: 0 8px;
    &:hover {
        box-shadow: 0px 8px 12px 0 ${Colors.B20};
        transition: 0.8s cubic-bezier(0.2,0.8,0.2,1);
    }
    @media (max-width: ${Metrics.tablet}){
        max-width: 300px;
        height: auto;
    }
`
const Grid = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 64px 1fr;
`
const Img = styled.img`
    width: 48px;
    height: 48px;
    margin-right: 16px;
`
const Title = styled(Fonts.h3())`
`
const Text = styled(Fonts.P_20())`
     margin-top: 8px;
     color: ${Colors.G3};
`
const CardEvent = (props) => {
    return (
        <Wrapper>
            <Grid>
                <Img src={props.img} alt=''/>
                <Title>{props.title}</Title>
            </Grid>                
            <Text>{props.text}</Text>
        </Wrapper>
    );
}

export default CardEvent;