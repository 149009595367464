import React from 'react'
import { Helmet } from "react-helmet";

const SEO = (props) => {
  return(
    <Helmet>
        <meta charSet="utf-8" />
        <title>{props.title}</title>
        {props.metaDescription && <meta name="description" content={props.metaDescription} /> }
        {/* Open Graph / Facebook */}
        <meta property='og:type' content='website' />
        {props.metaTitle && <meta property='og:title' content={props.metaTitle} /> }
        {props.metaDescription && <meta property='og:description' content={props.metaDescription} /> }
        {props.metaUrl && <meta property='og:url' content={props.metaUrl} /> }
        {/* {props.metaSiteName && <meta property='og:site_name' content={props.metaSiteName} /> } */}
        {props.metaImage && <meta property='og:image' content={props.metaImage} /> }
        {props.metaUrl && <link rel="canonical" href={props.metaUrl} /> }
        <meta name="keywords" content="彩虹數字,心性解碼,時間解碼,感情解碼,免費試算" />
        <meta name="apple-mobile-web-app-capable" content="yes"/>
    </Helmet>
  )
}

export default SEO