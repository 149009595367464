import React from 'react';
import styled from 'styled-components'
import { Fonts, Colors, Images } from '../../themes'

const Wrapper = styled.div`
    background: white;
    border-radius: 16px;
    box-shadow: 0px 4px 8px 0 rgba(0,0,0,0.1);
    width: 100%;
    max-width: 704px;
    height: min-content;
    min-height: 500px;
    overflow: hidden;
`
const TitleArea = styled.div`
    padding: 24px 16px 15px;
    border-bottom: 1px solid ${Colors.B10};
`
const BigTitle = styled(Fonts.h4())`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: center;
`
const InfoArea = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 3px 30px;
    max-width: 500px;
    margin: 0 auto;
    justify-content: flex-start;
    
`
const Info = styled.div`
    display: flex;
    justify-content: left;
    padding: 9px 20px;
`
const InfoText = styled(Fonts.h6())`
    padding-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`
const TextArea = styled.div`
    padding: 16px 24px ;
    border-top: 1px solid ${Colors.B10};
`

const Title = styled(Fonts.h5())`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-top: 40px;
    &:first-child{
        margin-top: 0;
    }
`

const Text = styled(Fonts.P_16())`
    margin-top: 4px;
    color:${Colors.G3};
    white-space: pre-wrap;
    &.br{
        padding-bottom: 20px;
    }
`


const CardEventDetail = (props) => {
    function parseLinks(text, regex) {
        // 將符合正則表達式的部分轉換為超連結
        return text.split(regex).map((part, index) => {
          if (index % 2 === 1) {
            return (
              <a key={index} href={part} target="_blank" rel="noopener noreferrer">
                {part}
              </a>
            );
          }
          return part;
        });
      }
      function parseContent(content) {
        // 檢測超連結的正則表達式
        const linkRegex = /(http:\/\/|https:\/\/\S+)/g;
      
        return content.split('\\n').map((item, index) => (
          <div key={index}>
            <Text className='br'>
              {item === '' ? <br /> : parseLinks(item, linkRegex)}
            </Text>
          </div>
        ));
      }
    return (
        <Wrapper>
            <TitleArea>
                <BigTitle>{props.bigTitle}</BigTitle>
            </TitleArea>
            {/* <InfoArea>
                <Info>
                    <img src={Images.mic} alt='' />
                    <InfoText>{props.teacher} 老師</InfoText>
                </Info>
                <Info>
                    <img src={Images.clock} alt='' />
                    <InfoText>{props.duration} 個小時</InfoText>
                </Info>
                <Info>
                    <img src={Images.location} alt='' />
                    <InfoText>{props.city}</InfoText>
                </Info>
            </InfoArea> */}
            <TextArea>
                <Title>課程摘要</Title>
                {props.excerpt.split('\\n ').map(item => (
                    <div><Text>{item === '' ? <br/> : item}</Text></div>
                ))}  
                <Title>課程介紹</Title>
                {parseContent(props.content)}
            </TextArea>
        </Wrapper>

    );
}

export default CardEventDetail;