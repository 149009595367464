import Colors from './Colors'

const SelectStyles = {
    container: (provided, state) => ({
      ...provided,
      borderRadius: '6px',
      background: `${Colors.W100}`,
      border: state.isFocused ? `1px solid ${Colors.Blue} !important`: `1px solid #E0E0E1`,
      boxSizing: 'border-box',
      "&:hover,&.hover": {
        cursor: 'pointer',
        borderColor: `${Colors.Blue}`,
      },
      "&.no-hover:hover": {
        borderColor: state.isFocused ? `${Colors.Blue} !important`: `#E0E0E1`,
      }
    }),
    input: () => ({
      fontSize: '14px',
      alignSelf: 'center',
      lineHeight: '20px',
      fontFamily: 'sans-serif,"微軟正黑體","Helvetica Neue","Noto Sans TC"'
    }),
    placeholder: () => ({
      fontSize: '14px',
      alignSelf: 'center',
      lineHeight: '20px',
      position: 'absolute',
      fontFamily: 'sans-serif,"微軟正黑體","Helvetica Neue","Noto Sans TC"'
    }),
    control: (state)=> ({
      display: 'grid',
      gridTemplateColumns: 'auto 35px',
    }),
    valueContainer: () => ({
      width: '100%',
      fontSize: '14px',
      alignSelf: 'center',
      padding: '10px 8px',
      boxSizing: 'border-box',
      height: '42px'
    }),
    group: () => ({
      borderRadius: '0px',
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? `${Colors.W100}` : `${Colors.B100}`,
      background: state.isSelected ? `${Colors.Blue}` : `${Colors.W100}`,
      "&:hover": {
          background: state.isSelected ? `${Colors.Blue}` : `${Colors.Light_Blue}`,
      },
      padding: '8px 10px',
      lineHeight: '20px',
      fontSize: '14px',
    }),
    singleValue: () => ({
      color: `hsl(0,0%,20%)`,
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      position: 'absolute'
    }),
    
  }

  export default SelectStyles;