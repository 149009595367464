import React from 'react'
import styled from 'styled-components'
import { Header, Footer, Copyright, Calculator, } from '.'
import { Images, Colors, Metrics, } from '../themes'
import Modal from 'react-modal'
// const axios = require('axios');

const Close = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 10000;
    width: 24px;
    height: 24px;
    &:hover{
        cursor: pointer;
    }
`
const customStyles = {
    overlay:{
        backgroundColor: "rgba(0,0,0,0.5)",
    },
    content : {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '16px',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
        zIndex: '1000',
    }
};

const Wrapper = styled.div`
    background: ${Colors.BackGround};
    overflow: scroll;
    position: relative;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-x: hidden;
    ::-webkit-scrollbar {
        display: none;
    }
`
const Content = styled.div`
    padding: 116px 0 5vw;
    position: relative;
    @media (max-width: 700px){
        padding-top: 85px;
    }
    @media (max-width: ${Metrics.mobile}){
        padding-top: 55px;
    }
`

const Layout = (props) => {
    const { children } = props
    // const [ modalLogin, setLogin ] = React.useState(false);

    // function openLogin() {
    //     setLogin(true);
    // }
    // function closeLogin(){
    //     setLogin(false);
    // }

    const [modalIsOpen,setIsOpen] = React.useState(false);
    function openModal() {
        setIsOpen(true);
    }
    function closeModal(){
        setIsOpen(false);
    }
    function Login(){
        if(process.env.REACT_APP_BRANCH === 'master'){
            window.location.href = 'https://rainbow-numen.us.auth0.com/login?client=sa7C299bSSb7nvb4zTCRl0JtkFx03R92&protocol=oauth2&response_type=token%20id_token&redirect_uri=https://www.rainbownumen.org&scope=openid%20profile'
        }else{
            window.location.href = 'https://rainbow-numen-dev.us.auth0.com/login?client=PyCenk283wvaE4dnOnEFJPPjgOr7Evd5&protocol=oauth2&response_type=token%20id_token&redirect_uri=http://localhost:3000&scope=openid%20profile'
        }
    }
    React.useEffect(() => {
       
    })
    return ( 
        <Wrapper ref={props.ref}>
            <Content >
                { children }
            </Content>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <Close onClick={closeModal}>
                    <img src={Images.close} alt='' />
                </Close>
                <Calculator />
            </Modal>
        </Wrapper>
    )
  }
  
  export default Layout