import React from 'react';
import styled from 'styled-components'
import { Fonts, Images } from '../../themes'

const Wrapper = styled.div`
    padding: 6px 0;
    margin-bottom: 15px;
    &:nth-last-child(2){
        margin-bottom: 0;
    }
`
const TitleArea = styled.div`
    display: grid;
    grid-template-columns: auto 25px;
    align-items: start;
    padding-bottom: 8px;
    text-align: left !important;
`
const Title = styled(Fonts.h5())`
    text-overflow: inherit;
    text-align: left !important;
    &:hover{
        cursor: pointer;
    }
`
const Icon = styled.img`
    filter: invert(0.4) sepia(1) saturate(20) hue-rotate(187.2deg) brightness(0.84);
    margin: 2px 0;
    &:hover{
        cursor: pointer;
    }
`
const Text = styled(Fonts.P_16())`
    max-height: 1000px;
    transition: .3s;
    text-align: left;
    &.Collapsed{
      max-height: 0;
      overflow: hidden;
    }
    
`

const CellToggle = ( props ) => {
    const [ collapsed, setCollapsed ] = React.useState(true)
    return (
        <Wrapper>
            <TitleArea>
                <Title onClick={() => setCollapsed(!collapsed)}>{props.title}</Title>
                <Icon src={collapsed ? Images.add : Images.minus} alt='toggle-icon' onClick={() => setCollapsed(!collapsed)} />
            </TitleArea>
            {props.text &&
                <>
                {props.text.split('\\n').map((item,index) => (
                    <div><Text className={collapsed ? 'Collapsed' : 'open'} key={`text-${index}`}>
                        {item === '' ? <br/> : item}
                    </Text></div>
                ))}
                </>
            }
            {props.signUp &&
                <Text className={collapsed ? 'Collapsed' : 'open'}>可以在官方網站上，點選<a href='/event/0'>立即報名</a>，選擇您想要報名的課程，再進行學費匯款，就完成報名囉~</Text>
            }
            {props.map &&
                <>
                    <Text className={collapsed ? 'Collapsed' : 'open'}>有的~課程之間是有連結性的！</Text>
                    <Text className={collapsed ? 'Collapsed' : 'open'}>課程進度規劃上有不同單元的安排，依據其關聯性、難易度、主題性…等等，做不同的課程編定，有些課程是有循序漸進的修課順序的唷。（可以參考<a href='/#map'>課程地圖</a>說明）</Text>
                </>
            }
        </Wrapper>
    );
}

export default CellToggle;