import { gql } from 'apollo-boost';

//GET
export const GET_LIST = gql`
  query MyQuery{
    event(order_by: {code: asc}, where: {status: {_eq: "已上架"}}){
      id
      code
      title
      excerpt
      open
      required
      type
      category
      cover
      status   
      sessions(limit: 1, order_by: {price: desc}) {
        price
        session_start_time
      }
    }
  }
`;

export const GET_PROMO = gql`
  query MyQuery{
    promo(where: {status: {_eq: "已上架"}}){
      id
      pcode
      name
      price
      event_id
    }
  }
`;

export const GET_NEWS = gql`
  query MyQuery {
    news(order_by: {id: desc}) {
      id
      title
      cover
      content
      tag
      updated_at
    }
  }
`;

export const GET_MEMBER = userId => gql`
  query MyQuery {
    member(where: {id: {_eq: "${userId}" }}) {
      id
      picture
      name
      role
      birth
      cellphone
      remark
      recommender
      gender
      info
      email
      address
  }
}
`;

export const GET_DETAIL = event_id => gql`
  query MyQuery {
    event(where: {id: {_eq: "${event_id}" }}) {
      id
      title
      excerpt
      content
      open
      required
      type
      category
      cover
      code
      caution
      sessions(order_by: {id: asc}, where: {title: {_neq: "表單"}, status: {_eq: "已上架"}}) {
        id
        session_start_time
        session_end_time
        title
        price
        duration
        teacher_id
        member {
          name
        }
        classroom {
          city
        }
      }
    }
  }
`;

export const GET_PROFILE = userId => gql`
  query MyQuery {
    member(where: {id: {_eq: "${userId}" }}) {
      name
      gender
      birth
      cellphone
      email
      address
      role
      remark
      recommender
    }
  }
`;

export const GET_INFO = userId => gql`
  query MyQuery {
    member(where: {id: {_eq: "${userId}" }}) {
      info
    }
  }
`;

export const GET_FORM = id => gql`
  query MyQuery {
    event(where: {id: {_eq: "45"}}) {
      title
      excerpt
      code
      required
      sessions(order_by: {price: desc}) {
        id
        classroom {
          city
          name
        }
        session_start_time
        session_end_time
        title
        price
        excerpt
      }
    }
  }
`;

export const GET_FORM_PACKAGE = id => gql`
  query MyQuery {
    event(where: {id: {_eq: "${id}"}}) {
      title
      code
      excerpt
      open
      required
      caution
      sessions(order_by: {session_start_time: asc}, where: {_not: {title: {_eq: "表單"}}}, limit: 3) {
        id
        duration
        classroom {
          city
          name
          dist
          address
        }
        session_start_time
        session_end_time
        title
        price
        excerpt
        content
      }
    }
  }
`;
/*
export const GET_FORM_LIST = id => gql`
  query MyQuery {
    event(where: {id: {_eq: "${id}"}}) {
      title
      code
      excerpt
      required
      caution
      id
      sessions(order_by: {session_start_time: asc}, where: {status: {_eq: "已上架"}, title: {_ilike: "%舊生%"}, _not: {title: {_eq: "表單"}}}, limit: 3) {
        id
        classroom {
          city
          name
          dist
          address
        }
        content
        session_start_time
        session_end_time
        title
        price
        duration
        excerpt
        member{
          name
        }
      }
    }
  }
`;

export const GET_FORM_LIST_NEW = id => gql`
  query MyQuery {
    event(where: {id: {_eq: "${id}"}}) {
      title
      code
      excerpt
      required
      caution
      id
      sessions(order_by: {session_start_time: asc}, where: {status: {_eq: "已上架"}, title: {_nilike: "%舊生%"}, _not: {title: {_eq: "表單"}}}, limit: 3) {
        id
        classroom {
          city
          name
          dist
          address
        }
        content
        session_start_time
        session_end_time
        title
        price
        duration
        excerpt
        member{
          name
        }
      }
    }
  }
`;
*/

export const GET_FORM_LIST = id => gql`
  query MyQuery {
    event(where: {id: {_eq: "${id}"}}) {
      title
      code
      excerpt
      open
      required
      caution
      id
      sessions(order_by: {session_start_time: asc}, where: {status: {_eq: "已上架"}, _not: {title: {_eq: "表單"}}}) {
        id
        classroom {
          city
          name
          dist
          address
        }
        content
        session_start_time
        session_end_time
        title
        price
        duration
        excerpt
        member{
          name
        }
        tickets(order_by: {price: desc}, where: {status: {_eq: "已上架"}}){
          id
          name
          price
          excerpt
          status
        }
      }
    }
  }
`;

export const GET_FORM_LIST_NEW = id => gql`
  query MyQuery {
    event(where: {id: {_eq: "${id}"}}) {
      title
      code
      excerpt
      open
      required
      caution
      id
      sessions(order_by: {session_start_time: asc}, where: {status: {_eq: "已上架"}, _not: {title: {_eq: "表單"}}}) {
        id
        classroom {
          city
          name
          dist
          address
        }
        content
        session_start_time
        session_end_time
        title
        price
        duration
        excerpt
        member{
          name
        }
        tickets(order_by: {price: desc}, where: {status: {_eq: "已上架"}}){
          id
          name
          price
          excerpt
          status
        }
      }
    }
  }
`;

export const GET_EVENTLIST = userId => gql`
  query MyQuery {
    member_event_session(where: {member_id: {_eq: "${userId}"}}) {
      event_id
      order_number
      status
      event {
        title
        code
      }
      session {
        id
        price
        content
        session_start_time
        session_end_time
        classroom {
          city
          name
          address
        }
      }
      member {
        name
      }
    }
  }
`;

export const GET_EVENTDETAIL_FROM_ORDER = (orderNum) => gql`
  query MyQuery {
    member_event_session(where: {order_number: {_eq: "${orderNum}"}}) {
      refund_start_time
      session {
        session_start_time
        classroom {
          city
        }
        event {
          title
        }
      }
    }
  }
`

//UPDATE
export const UPDATE_PROFILE = userId => gql`
  mutation MyMutation($name: String!,$gender: String!,$birth: String!,$cellphone: String!,$email: String!,$address: String!,$remark: String!,$recommender: String!) {
    update_member(where: {id: {_eq: "${userId}"}},_set:{ name: $name,gender: $gender,birth: $birth, cellphone: $cellphone, email: $email, address: $address, recommender: $recommender, remark: $remark }) {
      affected_rows
    }
  }
`;

export const UPDATE_INFO = userId => gql`
  mutation MyMutation($info: String!) {
    update_member(where: {id: {_eq: "${userId}"}},_set:{ info: $info }) {
      affected_rows
    }
  }
`;

export const ADD_UPDATE_TRADE = gql`
  mutation AddMemberEvent($event_id: bigint!,$member_id: String!,$payment: String!,$order_number: String!,$status: String!,$session_id: bigint!,$ticket_id: bigint!,$promo_id: bigint!) {
    insert_member_event_session(
      objects: {
        member_id: $member_id, 
        payment: $payment, 
        status: $status, 
        event_id: $event_id,
        session_id: $session_id,
        order_number: $order_number,
        ticket_id: $ticket_id,
        promo_id: $promo_id
      }, 
      on_conflict: {
        constraint: member_event_member_id_event_id_session_id_key, 
        update_columns: [status,order_number,payment,promo_id]
      }
    ){
      affected_rows
      returning {
        id
        member_id
        order_number
        payment
        status
      }
    }
  }
`

export const SEARCH_EVENTLIST = (userId, id) => gql`
  query MyQuery {
    member_event_session(where: {member_id: {_eq: "${userId}"}, event_id: {_eq: "${id}"}, status: {_eq: "已付款"}}) {
      event_id
      event {
        title
      }
      status
      session {
        price
        session_start_time
        session_end_time
        classroom {
          city
          name
          address
        }
      }
    }
  }
`;

export const UPDATE_REFUND = (userId, orderNum) => gql`
  mutation MyMutation {
    update_member_event_session(where: {member: {id: {_eq: "${userId}"}}, order_number: {_eq: "${orderNum}"}}, _set: {status: "處理中"}) {
      affected_rows
    }
  }
`

export const UPDATE_MEMBER_ROLE = (userId) => gql`
  mutation MyMutation($role: String!,$name: String!,$cellphone: String!) {
    update_member(where: {id: {_eq: "${userId}"}}, _set: {role: $role,name: $name,cellphone: $cellphone}) {
      affected_rows
    }
  }
`
