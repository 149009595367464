import React, { useState, useEffect } from 'react';
import { Seo, Layout, CardBanner, TitleArea, CardA, CardB, 
    GridCol3, Calculator, ContactModal, CardBannerS, CardBannerY  } from '../components'
import styled from 'styled-components'
import { Images, Metrics } from '../themes'
import Modal from 'react-modal'
import { useHistory } from "react-router-dom";

const Wrapper = styled.div`
    position: relative;
    padding: 0 16px;
`
const Close = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 10000;
    width: 24px;
    height: 24px;
    &:hover{
        cursor: pointer;
    }
`
const customStyles = {
    overlay:{
        backgroundColor: "rgba(0,0,0,0.5)",
    },
    content : {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '16px',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
        zIndex: '10000',
    }
};

const Img = styled.div`
    width: 80%;
    margin: 0 auto;
    position: relative;
    img{
        width: 100%;
    }

    @media (max-width: ${Metrics.tablet}){
        width: 100%;
      }

    @media (max-width: ${Metrics.mobile}){
        width: 100%;
      }
`

const IndexPage = (props) => {
    const history = useHistory();
    const [modalIsOpen,setIsOpen] = useState(false);
    function openModal() {
        setIsOpen(true);
    }
    function closeModal(){
        setIsOpen(false);
    }

    const [ ContactIsOpen, setContact ] = useState(false);
    const [ cehckValue, setCheckValue ] = useState();
    function openContact(data) {
        setCheckValue(data)
        setContact(true);
    }
    function closeContact(){
        setContact(false);
    }
    

    const [width, setWidth] = useState(window.innerWidth);
    const updateWidthAndHeight = () => { setWidth(window.innerWidth); } 
   
    useEffect(() => {
        if(history.location.hash==='#media'){
            if(width>568){
                window.scrollTo({ 
                    top: 1500, 
                    behavior: "smooth" 
                });
            }else{
                window.scrollTo({ 
                    top: 2200, 
                    behavior: "smooth" 
                });
            }
        }
        if(history.location.hash==='#map'){
            if(width>568){
                window.scrollTo({ 
                    top: 1000, 
                    behavior: "smooth" 
                });
            }else{
                window.scrollTo({ 
                    top: 1800, 
                    behavior: "smooth" 
                });
            }
        }
    })

    window.addEventListener("resize", updateWidthAndHeight);
    return (
        <Layout>
            <Seo 
                title='彩虹數字學會'
                metaDescription='運用彩虹數字解讀你的生命密碼，了解自己的生命課題。學習「心性解碼」、「時間解碼」、「感情解碼」課程，免費試算自己的數字。'
                metaTitle='彩虹數字學會'
                metaUrl='https://www.rainbownumen.org'
                //metaImage={`${Images.Rainbow_SEO}`}
                metaImage='https://rainbow-digital.glitch.me/i/ktlc6ygb.png'
            />
            <Wrapper>
                <CardBanner
                    bg={Images.cover}
                    title={width>568 ? `造物主用數字來塑造這個世界，\\n萬事萬物皆是由數字組成` : '造物主用數字來塑造這個世界，萬事萬物皆是由數字組成 - 畢達哥拉斯'}
                    small={width>568 ? '- 畢達哥拉斯' : ''}
                    text={width>568 ? '你的靈魂選擇了某個時空點誕生在地球，\\n出生年月日就是你在地球上的生命密碼，每一個數字\\n代表著不同的個性能量展現' : '你的靈魂選擇了某個時空點誕生在地球，出生年月日就是你在地球上的生命密碼，每一個數字代表著不同的個性能量展現'}
                    BtnTitle='認識彩虹數字'
                    //onClick={()=>history.push(`/learn/0`)}
                    onClick={()=>history.push(`/team`)}
                />
                <TitleArea
                    title='彩虹數字 改變人生'
                    text='彩虹數字可以這樣幫助你'
                />

                <GridCol3>
                    <CardA
                        title='了解自己的生命課題' text='透析生命密碼，掌握生命藍圖脈絡，做自己生命的主人。'
                        img={Images.Reservation_03}
                        onClick={()=>window.location.href='https://line.me/R/ti/p/%40289exmlj'}
                        linkText='預約諮詢'
                    />
                    <CardA
                        title='讓工作成為快樂的事'
                        text='透析生命密碼，激發內在潛能，做自己擅長和熱愛的工作。'
                        img={Images.Reservation_05}
                        onClick={()=>window.location.href='https://line.me/R/ti/p/%40289exmlj'}
                        linkText='預約諮詢'
                    />
                    <CardA
                        title='讓我愛的人也愛我'
                        text='透析生命密碼，洞察對方深層的情感需求，建立起最佳相處互動模式。'
                        img={Images.Reservation_01}
                        onClick={()=>window.location.href='https://line.me/R/ti/p/%40289exmlj'}
                        linkText='預約諮詢'
                    />
                </GridCol3>
                <TitleArea
                    title='課程地圖'
                    link='/event/0'
                    linkText='前往探索課程'
                />
                <Img>
                    {width > 568 
                        ? <img src={Images.Class} alt='' />
                        : <img src={Images.class_mobile} alt='' />
                    }
                </Img>
                <TitleArea
                    title='最新消息'
                    text='歡迎透過相關新聞更了解彩虹數字'
                    id='media'
                />
                <GridCol3>
                    <CardB
                        img={Images.News_01}
                        title='蔡英文衝突的生命歷程：解析她的彩虹數字'
                        source='財訊'
                        date='2017-07-19'
                        href='https://www.wealth.com.tw/home/articles/11464'
                    />
                    <CardB
                        img={Images.News_02}
                        title='彩虹數字學會，非營利教學之社團組織，培訓台灣在地人材'
                        source='尋夢新聞'
                        date='2020-09-10'
                        href='https://ek21.com/news/202685/'
                    />
                    <CardB
                        img={Images.News_03}
                        title='用彩虹生命數字學看歐普拉數字 9 的女人學不會拒絕'
                        source='ETtoday新聞雲'
                        date='2017-06-21'
                        href='https://www.ettoday.net/news/20170621/949721.htm'
                    />
                </GridCol3>
                <CardBannerS
                    className='center'
                    bg={Images.banner_02}
                    title='快速體驗彩虹數字的神奇'
                    text='馬上試算，了解自己的基本個性。'
                    BtnTitle='免費試算' 
                    onClick={()=>openModal()}
                />
                <br /><br /><br />
                <CardBannerY
                    className='center'
                />
            </Wrapper>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <Close onClick={closeModal}>
                    <img src={Images.close} alt='' />
                </Close>
                <Calculator />
            </Modal>
            <Modal
                isOpen={ContactIsOpen}
                onRequestClose={closeContact}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <Close onClick={closeContact}>
                    <img src={Images.close} alt='' />
                </Close>
                <ContactModal
                    check={cehckValue}
                    onClick={closeContact}
                />
            </Modal>
        </Layout>
    );
}

export default IndexPage;