/* eslint-disable no-multi-str */
import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Images, Colors, Metrics } from "../themes";
import { CalculateBtn, InputDateBar, InputBar } from ".";
import Slider from "react-slick";
import { DatePicker, Space } from "antd";

import html2canvas from "html2canvas";
var moment = require("moment");
const { Solar } = require("lunar-javascript");

const CheckOne = [
  '不太會堅持自己想法的人。',
  '不太會表達自己的人。通常很多話還沒有說出口，就會先打退堂鼓。',
  '會尊重別人，也會說出自己想法的人，但是你卻不會堅持己見，所以，即使對方不接受你的意見，你也覺得無所謂。',
  '說到做到、貫徹執行的人。因此，通常不需要別人要求，你自己就會設定好目標和進度的把事情完成。',
  '外柔內剛的人。通常你對事情都會有自己的想法，可是卻往往會為了配合別人而不願說出來，但是當結果不如預期時，你又會自己氣自己。',
  '外表缺乏自信、會自我否定、不敢表達己見、只會配合別人的人，但是其實你的內在卻是一位堅持已見、愈挫愈勇、堅強又具有意志力的人。',
  '外表不會堅持己見，會尊重並配合別人的人，但是其實你的內在卻是一位堅持目標、愈挫愈勇、堅強又具有意志力的人。'
]
const CheckNight = [
  '不太習慣地球人類的文明和傳統，因此常常會感覺到與世界格格不入。',
  '相信命運的存在，有時會想探索生命意義，或是去算命。',
  '有些孤僻，有時會喜歡離群索居，把自己關在房間裡，用土法煉鋼的方式自行研究感興趣的事物。',
  '很有智慧又有點自負，因此在面對事情時，常會表現出一副老神在在的樣子。',
  '很純真且富有想像力，有時還會希望自己能夠擁有瞬間移動的特異功能。',
  '很喜歡看美人魚、獨角獸、半人半獸等類型的故事，或是會在夢中夢到這些景象。'
]
const CheckZero = [
  '喜歡單純、不複雜的生活和工作，一遇到比較複雜的問題或事情，還沒開始做，頭就先痛了。',
  '具有戰鬥性格，但是在做決定時又常常覺得這樣做也不是，那樣做也不對，而會比較掙扎些。',
  '不喜歡傷害別人，在觀念上認為男女平等、眾生平等，是一位和平主義者。',
  '想法很另類，在別人眼中是一個怪胎。',
  '是一個帶有高能量頻率的新人類，無懼、有膽識，對任何事並沒有太多的執著，只在乎是否和諧。',
  '對外在時空的敏感度非常的強，是一個帶有高能量頻率的新人類。'
]
const NumberOptions = [
  '',
  '',
  '溫柔，細膩，懂得傾聽，會委曲求全的來配合別人；',
  '樂觀開朗，喜歡學習新事物，對任何事情都充滿好奇心，常有創意新點子；',
  '務實，沉穩，腳踏實地，很有責任感；',
  '喜歡自由自在，富有正義感，熱愛戶外活動，又深具冒險精神；',
  '善解人意，熱心服務，愛心、慈悲心，追求完美主義；',
  '質疑性強，會打破砂鍋問到底，重視氛圍，經常有莫名其妙的好運氣；',
  '察言觀色，謹言慎行，懂得以退為進，也會忍辱負重；',
  '',
]
const SoulLVOptions = [
  { title: '第一級', text: '受爸爸影響很深。每當事情做不好或是做錯事時會很容易哭，尤其不喜歡有人對你大聲講話或是大聲罵你。' },
  { title: '第二級', text: '對老師(或上司)很尊敬，也會乖乖聽老師(或上司)的話，只要老師(或上司) 幫你設定好時間和目標，你都會把事情做好。' },
  { title: '第三級', text: '很叛逆，不喜歡別人用權威的方式來命令你。但是你喜歡熱鬧、喜歡和朋友搏感情，遇到問題時也只會找朋友商量。' },
  { title: '第四級', text: '不喜歡被說教，不想聽別人重複講你已經知道的事情；但是對於不懂的事情你卻又會想追根究底的問清楚。' },
  { title: '第五級', text: '希望別人尊重你，如果尊重你，你就會充滿熱忱的傾囊相受；否則會不想理對方。' },
  { title: '第六級', text: '是一個聰明又有智慧的人，但是卻因為會壓抑自己的個性，悶著頭做自己的事，因此常會有一股莫名的鬱卒感。' },
  { title: '第七級', text: '是一個聰明又有智慧的人，遇到任何問題幾乎都有能力自己解決，可是當發覺沒辦法解決或是學不會時，就會懊惱自己為什麼不會。' },
]

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding-bottom: 80px;
  .slick-slider {
    width: 100%;
  }
  .slick-next,
  .slick-prev {
    position: relative;
    display: flex;
    top: 0;
    left: 50%;
    background: #000;
  }
`;
const Content = styled.div`
  position: relative;
  display: grid !important;
  justify-items: center;
  padding: 46px 24px 20px;
  width: 100%;
  box-sizing: border-box;
  .submit {
    margin-top: 24px;
    position: relative;
    z-index: 10000;
  }
  .checkBar {
    justify-self: center;
  }
`;
const Title = styled.p`
  font-size: ${window.innerWidth < 360 ? '21px' : '25px'};
  font-weight: bold;
  color: #4054B2;
  text-align: left;
  margin: 0 !important;
  padding-bottom: 12px;
  @media (max-width: ${Metrics.tablet}){
    font-size: 3vw;
    padding-bottom: 2vw;
  }
`;

const BtnArea = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 0px !important;
  z-index: 1000;
  margin-bottom: 16px;
  button {
    margin: 0 4px;
  }
`;
const ContentArea = styled.div`
  position: relative;

  grid-template-columns: 50% 50%;
  grid-gap: 6px;
  width: 340px;
  box-sizing: border-box;
  padding-bottom: 24px;
  .input-area,
  .black {
    padding: 8px 6px 4px 0;
  }
  @media (max-width: ${Metrics.mobile}) {
    display: block;
    height: 100%;
  }
`;
const Text = styled.p`
    width: 90%;
    color: ${Colors.B100};
    font-size: 20px;
    margin: 0;
    letter-spacing: 0;
    line-height: 28px;
    text-align: left;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
    @media (max-width: ${Metrics.mobile}){
      width: 87%;
      font-size: 1.8vw;
      line-height: 2.2vw;

    }
`
const TextC = styled.p`
    font-size: 28px;
    position: absolute;
    @media (max-width: ${Metrics.mobile}){
      font-size: 2.2vw;
    }
`
const TextP = styled.p`
    font-size: 26px;
    position: absolute;
    @media (max-width: ${Metrics.mobile}){
      font-size: 2vw;
    }
`
const TextG = styled.p`
    font-size: 20px;
    position: absolute;
    @media (max-width: ${Metrics.mobile}){
      font-size: 1.8vw;
    }
`
const Calculator = (props) => {
  const [date, setDate] = useState(props.parent_date);
  const [time, setTime] = useState(props.parent_time);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [timeBak, setTimeBak] = useState("");
  const [check, setCheck] = useState(false);
  const [alert, setAlert] = useState(false);
  const [width, setWidth] = useState(window.innerWidth < 568);
  const fsize = window.innerWidth < 568 ? '2.7vw' : '20px';
  const [only_hour, setOnlyHour] = useState(false);
  const [form1, setForm1] = useState({
    // username: props.parent_name || "林成翰",
    // gender: props.parent_gender || "男",
    // company: "立華",
    // store: "資訊部",
    // job: "工程師",
    // adviceName: "田麗虹",
    // adviceDate: "2023-11-23",
    username: props.parent_name || "",
    gender: props.parent_gender || "",
    company: "",
    store: "",
    job: "",
    adviceName: "",
    adviceDate: "",
    date: "",
    ldate: "",
    time: "",
    year: "",
    month: "",
    day: "",
    lyear: "",
    lmonth: "",
    lday: "",
    hour: "",
    minute: "",
    forget_minute: false,
    cross_year: false,
    only_hour: false,
    add_month: false,
    name: "",
    age: "",
    BackNumbers: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "",],
    SecondBackNumbers: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "",],
    MainNumber: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "",],
    Soul: ["", "", "", "", "", "", "", "", "", ""],
    practiceNumbers: []
  });
  function checkArrayValues(array1, array2) {
    // 检查第二个数组中的值是否都存在于第一个数组中
    const allValuesExist = array2.every(value => array1.includes(value));

    // 检查第二个数组中是否有任意一个值存在于第一个数组中
    const anyValueExists = array2.some(value => array1.includes(value));

    if (allValuesExist) {
      return 1; // 第二个数组中的所有值都在第一个数组中
    } else if (anyValueExists) {
      return 2; // 第二个数组中有值存在于第一个数组中
    } else {
      return 3; // 第二个数组中的所有值都不在第一个数组中
    }
  }
  function CheckSoul(BackNumbers, SecondBackNumbers, MainNumber, NumberCheckList) {
    let origin = []
    if (SecondBackNumbers.length > 1) {
      origin = BackNumbers.concat(SecondBackNumbers)
    } else {
      origin = BackNumbers
    }
    let result = origin.filter(function (element, index, arr) {
      return arr.indexOf(element) === index;
    });
    if (NumberCheckList.filter(i => i === MainNumber).length > 0) {
      if (checkArrayValues(NumberCheckList, result) == 1) {
        let NumberCheck = []
        for (let t = 0; t < 10; t++) {
          if (NumberCheckList.filter(i => i === `${t}`).length > 2 && t !== 0) {
            NumberCheck.push('6')
          } else {
            NumberCheck.push('7')
          }
        }
        if ((NumberCheck.filter(i => i === "6").length > 0)) {
          return 6
        } else {
          return 7
        }
      } else if (checkArrayValues(NumberCheckList, result) == 2) {
        return 5
      } else {
        return 4
      }
    } else {
      if (checkArrayValues(NumberCheckList, result) == 1) {
        return 3
      } else if (checkArrayValues(NumberCheckList, result) == 2) {
        return 2
      } else {
        return 1
      }
    }
  }
  function calculateAll(
    date_value,
    time_value,
    only_hour_value,
    name_value,
    gender_value,
    form
  ) {
    form.date = date_value;
    form.only_hour = only_hour_value;
    form.name = name_value;
    form.gender = gender_value;
    const gregorianYear = moment(date_value).format("YYYY");
    const gregorianMonth = moment(date_value).format("MM");
    const gregorianDay = moment(date_value).format("DD");

    const date1 = new Date(date_value);
    form.year = date1.getFullYear();
    form.month = date1.getMonth() + 1; // 月份從 0 開始，所以要加 1
    form.day = date1.getDate();

    const lunarDate = Solar.fromYmd(
      gregorianYear,
      gregorianMonth,
      gregorianDay
    ).getLunar();
    form.ldate =
      lunarDate.getYear() +
      "-" +
      String(Math.abs(lunarDate.getMonth())).padStart(2, "0") +
      "-" +
      String(lunarDate.getDay()).padStart(2, "0");

    form.lyear = lunarDate.getYear();
    form.lmonth = Math.abs(lunarDate.getMonth());
    form.lday = lunarDate.getDay();
    form.add_month = lunarDate.getMonth() >= 0 ? false : true;
    form.time = time_value;
    form.hour = time_value != "" ? time_value.split(":")[0] : "";
    form.minute = only_hour_value
      ? ""
      : time_value != ""
        ? time_value.split(":")[1]
        : "";

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // 月份從 0 開始，所以要加 1
    const day = currentDate.getDate();
    const lunarDateNow = Solar.fromYmd(year, month, day).getLunar();
    const year2 = lunarDateNow.getYear();
    const month2 = Math.abs(lunarDateNow.getMonth());
    const day2 = lunarDateNow.getDay();
    let syear = "";
    let age = year - form.year;
    if (month > form.month) {
      syear = year;
    } else if (month < form.month) {
      syear = year - 1;
      age = age - 1;
    } else {
      if (day > form.day) {
        syear = year;
      } else if (day < form.day) {
        syear = year - 1;
        age = age - 1;
      } else {
        syear = year;
      }
    }
    if (form.year > form.lyear) {
      form.cross_year = true;
    } else {
      form.cross_year = false;
    }
    form.age = age;
    const sdate =
      String(syear) +
      "-" +
      String(form.month).padStart(2, "0") +
      "-" +
      String(form.day).padStart(2, "0");
    const ldate =
      String(syear - (form.cross_year ? 1 : 0)) +
      "-" +
      String(form.lmonth).padStart(2, "0") +
      "-" +
      String(form.lday).padStart(2, "0");

    const NumberList = date_value.split("").filter((i) => i !== "-");
    const NumberListLunar = form.ldate.split("").filter((i) => i !== "-");
    const NumberList1 = time_value.split("").filter((i) => i !== ":");
    const NumberList2 = NumberList.concat(NumberList1);
    const NumberList2Lunar = NumberListLunar.concat(NumberList1);
    const NumberList3 = sdate.split("").filter((i) => i !== "-");
    const NumberList3Lunar = ldate.split("").filter((i) => i !== "-");

    form.BackNumbers[0] = sum(NumberList.slice(0, 4).map(Number))
      .toString()
      .padStart(2, "0")
      .split("");
    form.BackNumbers[1] = sum(NumberList.slice(0, 6).map(Number))
      .toString()
      .padStart(2, "0")
      .split("");
    form.BackNumbers[2] = sum(NumberList.map(Number))
      .toString()
      .padStart(2, "0")
      .split("");
    form.BackNumbers[3] = sum(NumberList2.slice(0, 10).map(Number))
      .toString()
      .padStart(2, "0")
      .split("");
    form.BackNumbers[4] = sum(NumberList2.map(Number))
      .toString()
      .padStart(2, "0")
      .split("");
    form.BackNumbers[5] = sum(NumberListLunar.slice(0, 4).map(Number))
      .toString()
      .padStart(2, "0")
      .split("");
    form.BackNumbers[6] = sum(NumberListLunar.slice(0, 6).map(Number))
      .toString()
      .padStart(2, "0")
      .split("");
    form.BackNumbers[7] = sum(NumberListLunar.map(Number))
      .toString()
      .padStart(2, "0")
      .split("");
    form.BackNumbers[8] = sum(NumberList2Lunar.slice(0, 10).map(Number))
      .toString()
      .padStart(2, "0")
      .split("");
    form.BackNumbers[9] = sum(NumberList2Lunar.map(Number))
      .toString()
      .padStart(2, "0")
      .split("");
    form.BackNumbers[10] = sum(NumberList3.map(Number))
      .toString()
      .padStart(2, "0")
      .split("");
    form.BackNumbers[11] = sum(NumberList3Lunar.map(Number))
      .toString()
      .padStart(2, "0")
      .split("");

    for (let i = 0; i < 12; i++) {
      [form.SecondBackNumbers[i], form.MainNumber[i]] = calculateNumbers(
        form.BackNumbers[i],
        form.SecondBackNumbers[i],
        form.MainNumber[i]
      );
    }
    for (let i = 0; i < 5; i++) {
      form.Soul[i] = CheckSoul(form.BackNumbers[i], form.SecondBackNumbers[i], form.MainNumber[i], NumberList)
    }
    form.practiceNumbers = []
    for (const item of form.BackNumbers[3]) {
      console.log(item)
      if (!form.practiceNumbers.includes(item)) {
        form.practiceNumbers.push(item);
      }
    }
    for (const item of form.SecondBackNumbers[3]) {
      console.log(item)
      if (!form.practiceNumbers.includes(item)) {
        form.practiceNumbers.push(item);
      }
    }
    switch (parseInt(form.MainNumber[3])) {
      case 1:
        form.luckyColor = '紅色'
        form.loveStyle = '專心愛一個人'
        form.keyword = '獨立、專注、領導、\n教導、無中生有'
        form.special = '老大語氣、以我為主、\n享受孤獨、喜歡指使對方'
        form.practice = '1. 設定明確目標\n2. 累積實力經驗\n3. 挑戰整個過程'
        form.mines = '最討厭別人試圖改變或說服自己'
        break;
      case 2:
        form.luckyColor = '橘色'
        form.loveStyle = '白頭偕老、互相陪伴'
        form.keyword = '溫柔、細膩、傾聽、\n配合、依賴'
        form.special = '委婉、沉默、\n不喜被背叛、重視小細節'
        form.practice = '1. 真誠表達自己\n2. 逆向思考\n3. 接受與包容\n4. 做足事前準備'
        form.mines = '不喜歡別人太直接，\n想要保有空間跟時間'
        break;
      case 3:
        form.luckyColor = '黃色'
        form.loveStyle = '重視外表、\n喜歡新鮮感和變化'
        form.keyword = '自由、彈性、表演、\n溝通、好奇、樂觀'
        form.special = '喜歡談情說愛、\n用音樂唱歌表達、容易突發奇想'
        form.practice = '1. 學說話的藝術\n2. 客觀中性立場\n3. 發揮創意與敏銳力'
        form.mines = '最討厭被忽視，想要被看見、\n關心自己感興趣的事'
        break;
      case 4:
        form.luckyColor = '綠色'
        form.loveStyle = '重視隱私、門當戶對、\n成家立業'
        form.keyword = '穩定、務實、冷靜、\n腳踏實地、責任感'
        form.special = '邏輯思考、制定規範、\n保護自己人、給人安全感'
        form.practice = '1. 清晰決策力\n2. 具體行動力\n3. 承擔責任與結果\n4. 打破舊有習氣'
        form.mines = '最討厭被侵犯隱私，\n或被打破自己的原則與規範'
        break;
      case 5:
        form.luckyColor = '藍色'
        form.loveStyle = '喜歡自由、熱情、\n心電感應'
        form.keyword = '探索、冒險、戶外、\n自由、正義、野性、海派'
        form.special = '多情、有攻擊性、\n喜歡距離感、勇敢熱忱'
        form.practice = '1. 找到心之所向\n2. 勇敢冒險\n3. 解開心結\n4. 全力以赴'
        form.mines = '討厭別人過於堅持，\n或有強大支配欲'
        break;
      case 6:
        form.luckyColor = '靛色'
        form.loveStyle = '找尋一生真愛、照顧對方'
        form.keyword = '美、高貴、愛心、\n善解人意、熱心服務、慈悲心'
        form.special = '喜歡照顧別人、\n在乎雙贏、追求完美'
        form.practice = '1. 付出剛剛好的愛\n2. 共創彈性與雙贏\n3. 優雅謙虛'
        form.mines = '最討厭一昧索取，\n吝嗇，不願意付出的人'
        break;
      case 7:
        form.luckyColor = '紫色'
        form.loveStyle = '重視氛圍、幸福感'
        form.keyword = '直覺、哲理、心靈、\n靈感、好運氣、質疑'
        form.special = '情緒化、一定要贏、\n慷慨大方、旅遊度假伴侶'
        form.practice = '1. 放鬆 幽默 感恩\n2. 信任第六感\n3. 運用知識力量\n4. 送禮物'
        form.mines = '討厭別人講話慢條斯理、不斷重複'
        break;
      case 8:
        form.luckyColor = '金色'
        form.loveStyle = '經營感情是一種投資'
        form.keyword = '商業、洞察遠見、氣魄、\n權力、執行組織、存錢'
        form.special = '察言觀色、深思熟慮、\n重視成就感、善用錢&權、曖昧、挑逗'
        form.practice = '1. 愛錢但不貪\n2. 掌握而非控制\n3. 培養人際關係\n4. 投射正向意念'
        form.mines = '對自己的事情很在乎，\n討厭別人拿自己的成就冷嘲熱諷'
        break;
      case 9:
        form.luckyColor = '白色'
        form.loveStyle = '夢中情人、靈魂伴侶'
        form.keyword = '博愛、樂觀、同理心、\n希望、關懷、分享'
        form.special = '迷戀崇拜偶像、有智慧有深度\n、隨緣、成全他人'
        form.practice = '1. 實踐夢想\n2. 腳踏實地\n3. 修練提高智慧\n4. 有捨才有得'
        form.mines = '不喜歡過度鑽研，\n或被強迫做沒興趣的事情'
        break;
      default:
        form.luckyColor = '紅色'
        form.loveStyle = '專心愛一個人'
        form.keyword = '獨立、專注、領導、\n教導、無中生有'
        form.special = '老大語氣、以我為主、\n享受孤獨、喜歡指使對方'
        form.practice = '1. 設定明確目標\n2. 累積實力經驗\n3. 挑戰整個過程'
        form.mines = '最討厭別人試圖改變或說服自己'
        break;
    }
  }
  function calculateNumbers(BackNumbers, SecondBackNumbers, MainNumber) {
    if (sum(BackNumbers.map(Number)).toString().split("").length > 1) {
      SecondBackNumbers = sum(BackNumbers.map(Number)).toString().split("");
      MainNumber = sum(SecondBackNumbers.map(Number)).toString();
    } else {
      SecondBackNumbers = "";
      MainNumber = sum(BackNumbers.map(Number)).toString();
    }
    return [SecondBackNumbers, MainNumber];
  }
  calculateAll(date, time, only_hour, form1.name, form1.gender, form1);
  console.log(form1);
  const ResultSlider = useRef();
  const [sliderSettings] = useState({
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    speed: 300,
    cssEase: `linear`,
    adaptiveHeight: true,
    draggable: false,
    swipe: false,
  });
  const [index, setIndex] = useState(0);

  const gotoNext = () => {
    ResultSlider.current.slickNext();
    setIndex(index + 1);
  };
  const gotoPrev = () => {
    ResultSlider.current.slickPrev();
    setIndex(index - 1);
  };
  const Restart = () => {
    setImageLoaded(false);
    ResultSlider.current.slickGoTo(0);
    setIndex(0);
  };

  function onChange(e, dateString) {
    setAlert(false);
    setDate(dateString);
    if (dateString === "") {
      setDate("2000-01-01");
    }
  }
  function Submit() {
    if (date) {
      setAlert(false);
      ResultSlider.current.slickNext();
      setIndex(index + 1);
    } else {
      setAlert(true);
    }
  }

  function sum(arr) {
    var len = arr.length;
    if (len === 0) {
      return 0;
    } else if (len === 1) {
      return arr[0];
    } else {
      return arr[0] + sum(arr.slice(1));
    }
  }

  const handleDownload = () => {
    setImageLoaded(false);
    const element = document.getElementById("rainbowimg");
    html2canvas(element).then((canvas) => {
      const link = document.createElement("a");
      link.download = "彩虹數字企業諮詢報告.png";
      link.href = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      link.click();
      setImageLoaded(true);
    });
  };

  function firstPractice(n) {
    switch (parseInt(n)) {
      case 1:
        return "數字1具有領導力及創造力。數字1的能量特質如下:\n\
        1. 有主見、能夠表達自我\n\
        2. 確立目標、專心一致\n\
        3. 主管的密碼、具有領導者的特質\n\
        4. 傳承經驗、教導他人的能力\n\
        5. 主軸目標明確 – 大目標導向\n\
        6. 堅強經驗實力 – 專業、達人等級\n\
        7. 挑戰整個過程 – 事必躬親\n\
        8. 較為擇善固執 – 經驗法則掛帥"
      case 2:
        return "數字2會配合、合作、陪伴或跟隨。數字2的能量特質如下:\n\
        1. 溫柔、懂得傾聽\n\
        2. 老二哲學\n\
        3. 判別真假、洞察真相\n\
        4. 心思細膩、成熟內斂\n\
        5. 前置作業完善\n\
        6. 擅於分析脈絡、細節\n\
        7. 較為悲觀、有比較心\n\
        8. 較為依賴、優柔寡斷、猶豫不決"
      case 3:
        return "數字3具有超越性的新觀點、新視角。數字3的能量特質如下:\n\
        1. 面對事情時，會有不一樣的思考高度\n\
        2. 擅於溝通、協調\n\
        3. 充滿好奇心\n\
        4. 機智反應、臨場反應強\n\
        5. 喜好學習新事物、滿足好奇心\n\
        6. 有創意、點子多\n\
        7. 跳躍式思考、快速轉移目標\n\
        8. 不按牌理出牌"
      case 4:
        return "數字4會注重規矩與制度。數字4的能量特質如下:\n\
        1. 按部就班、沉穩內斂\n\
        2. 理性思維、務實、重視效益和效率\n\
        3. 願意承擔責任與壓力\n\
        4. 組織規劃、制度架構、系統化能力\n\
        5. 整合資源與共享\n\
        6. 組織管理與領導\n\
        7. 注重安全感、保護性較強\n\
        8. 較為封閉、固執 ，有時僵化或過分強調紀律"
      case 5:
        return "數字5是向外突破、拓展的能量。數字5的能量特質如下:\n\
        1. 具運動家精神\n\
        2. 有心就有動能 – 投入熱情所在\n\
        3. 主動積極\n\
        4. 榮譽心 – 自發性的榮譽感驅動\n\
        5. 有膽識挑戰未知\n\
        6. 公平正義感\n\
        7. 不喜歡受約束\n\
        8. 勇敢熱忱"
      case 6:
        return "數字6具有犧牲奉獻、服務他人的精神。數字6的能量特質如下:\n\
        1. 彈性、創造雙贏\n\
        2. 凡事都想做到盡善盡美\n\
        3. 完美主義傾向，會批評挑剔\n\
        4. 善解人意、具有同理心\n\
        5. 能主動修補關係以達到共識\n\
        6. 熱心公益活動\n\
        7. 追求品味\n\
        8. 清楚自己要什麼"
      case 7:
        return "數字7是公司、團體中的關鍵人物。數字7的能量特質如下:\n\
        1. 重視周遭及團體中的氛圍\n\
        2. 不服輸，勇往直前\n\
        3. 直來直往、速戰速決\n\
        4. 質疑力較強\n\
        5. 有勇氣去面對未知、不可知\n\
        6. 探究真理，喜歡追根究底\n\
        7. 有時較情緒化、會鑽牛角尖\n\
        8. 常較憑瞬間感覺做事"
      case 8:
        return "數字8會察言觀色、謀定而後動。數字8的能量特質如下:\n\
        1. 懂得風險評估\n\
        2. 深思熟慮、經營管理\n\
        3. 口風緊、謹言慎行\n\
        4. 以退為進\n\
        5. 有時曖昧不明、話中有話\n\
        6. 策略靈活\n\
        7. 交際手腕強\n\
        8. 追求成就感、重視成就感"
      case 9:
        return "數字9是追求智慧、靈性、夢想的能量。數字9的能量特質如下:\n\
        1. 有夢想、但有時會天馬行空\n\
        2. 日夜顛倒、夜貓子\n\
        3. 知行合一、實踐計劃\n\
        4. 成就別人\n\
        5. 歡喜做、甘願受\n\
        6. 具有生命智慧\n\
        7. 注重身心靈整合\n\
        8. 一切隨緣"
      default:
        return "數字1具有領導力及創造力。數字1的能量特質如下:\n\
        1. 有主見、能夠表達自我\n\
        2. 確立目標、專心一致\n\
        3. 主管的密碼、具有領導者的特質\n\
        4. 傳承經驗、教導他人的能力\n\
        5. 主軸目標明確 – 大目標導向\n\
        6. 堅強經驗實力 – 專業、達人等級\n\
        7. 挑戰整個過程 – 事必躬親\n\
        8. 較為擇善固執 – 經驗法則掛帥"
    }
  }
  function secondPractice(n) {
    switch (parseInt(n)) {
      case 0:
        return "數字0: 喜愛大自然、有時會放空。"
      case 1:
        return "數字1: 有自己的想法、目標和原則。"
      case 2:
        return "數字2: 心思細膩，能設身處地為他人著想。"
      case 3:
        return "數字3: 好奇心強、有創意，喜歡溝通。"
      case 4:
        return "數字4: 較重CP值，腳踏實地、很有責任感。"
      case 5:
        return "數字5: 熱情、自由自在、富有正義感。"
      case 6:
        return "數字6: 很有愛心、追求圓滿、完美主義者。"
      case 7:
        return "數字7: 重視氛圍、具有追根究底的特質。"
      case 8:
        return "數字8: 察言觀色、謹言慎行、口風緊。"
      case 9:
        return "數字9: 會追求夢想、智慧和靈性。"
      default:
        return "數字1: 有自己的想法、目標和原則。"
    }
  }
  function niceJob(n) {
    switch (parseInt(n)) {
      case 1:
        return "- 專業型職務\n\
        - 主管職、導師職\n\
        - 可獨立作業"
      case 2:
        return "- 合夥人、二把手\n\
        - 幕僚職 (助理、秘書、軍師、文字記錄)\n\
        - 品管、考核性質"
      case 3:
        return "- 溝通型職務 (公關、發言人 )\n\
        - 企劃、創意類、新事業部\n\
        - 講師、教學、顧問類"
      case 4:
        return "- 基層人員、廠務管理、部門管理\n\
        - 保安型、需證照類型\n\
        - 土地、建築、會計"
      case 5:
        return "- 業務行銷\n\
        - 跨國、派駐國外\n\
        - 多元型領域開拓"
      case 6:
        return "- 高端、精品類型\n\
        - 服務業、諮詢類\n\
        - 醫療型、公益慈善基金會"
      case 7:
        return "- 知識含量高類型\n\
        - 公司休閒旅遊規劃\n\
        - 法務、稽查"
      case 8:
        return "- 財經類型、投資型\n\
        - 經營管理型\n\
        - 趨勢分析型"
      case 9:
        return "- 顧問、學者型職務\n\
        - 電腦資訊相關職務\n\
        - 網路、虛擬類型"
      default:
        return "- 專業型職務\n\
        - 主管職、導師職\n\
        - 可獨立作業"
    }
  }
  const handleChange = (e) => {
    if (e.target.name !== "adviceDate") {
      e.preventDefault();
      e.stopPropagation();
    }
    setForm1({
      ...form1,
      [e.target.name]: e.target.value,
    });
  };
  function ImageNum(soul) {
    if (soul === 1) {
      return <img style={{
        width: '26px'
      }} alt='soul' src={Images.c1}></img>
    } else if (soul === 2) {
      return <img style={{
        width: '26px'
      }} alt='soul' src={Images.c2}></img>
    } else if (soul === 3) {
      return <img style={{
        width: '26px'
      }} alt='soul' src={Images.c3}></img>
    } else if (soul === 4) {
      return <img style={{
        width: '26px'
      }} alt='soul' src={Images.c4}></img>
    } else if (soul === 5) {
      return <img style={{
        width: '26px'
      }} alt='soul' src={Images.c5}></img>
    } else if (soul === 6) {
      return <img style={{
        width: '26px'
      }} alt='soul' src={Images.c6}></img>
    } else if (soul === 7) {
      return <img style={{
        width: '26px'
      }} alt='soul' src={Images.c7}></img>
    }
  }
  function NumPosition(BackNumbers, SecondBackNumbers, MainNumber, soul, fl) {
    return <>
      <div style={{ position: 'relative', flex: fl }}>
        <div
          style={{
            position: 'absolute',
            top: '-15px',
            left: '40px',
            color: 'black',
            fontSize: fsize,
          }}>{BackNumbers.join("")}
        </div>
        <img src={Images.slash} alt='slash' style={{
          position: 'absolute',
          top: '5px',
          left: '37px',
          color: 'black',
          fontSize: fsize,
          width: '30px',
        }} />
        {SecondBackNumbers == "" ? <>
          <div
            style={{
              position: 'absolute',
              top: '12px',
              left: '52px',
              color: 'black',
              fontSize: fsize,
            }}>{MainNumber}
          </div>
          {/* <div
            style={{
              position: 'absolute',
              top: '42px',
              left: '42px',
              color: 'black',
              fontSize: fsize,
            }}>{ImageNum(soul)}
          </div> */}
        </> : <div
          style={{
            position: 'absolute',
            top: '14px',
            left: `${parseInt(SecondBackNumbers.join("")) >= 10 ? '43px' : '50px'}`,
            color: 'black',
            fontSize: fsize,
          }}>{SecondBackNumbers.join("")}<img src={Images.slash} alt='slash' style={{
            position: 'absolute',
            top: '20px',
            left: '-3px',
            color: 'black',
            fontSize: fsize,
            width: '30px',
          }} />
          <div
            style={{
              position: 'absolute',
              top: '25px',
              left: '10px',
              color: 'black',
              fontSize: fsize,
            }}>{MainNumber}
          </div>
          {/* <div
            style={{
              position: 'absolute',
              top: '59px',
              left: '0px',
              color: 'black',
              fontSize: fsize,
            }}>{ImageNum(soul)}
          </div> */}
        </div>}
      </div>
    </>
  }
  function NumPosition2(BackNumbers, SecondBackNumbers, MainNumber, soul, fl) {
    return <>
      <div style={{ position: 'relative', flex: fl }}>
        <div
          style={{
            position: 'absolute',
            top: '-15px',
            left: '21px',
            color: 'black',
            fontSize: fsize,
          }}>{BackNumbers.join("")}
        </div>
        <img src={Images.slash} alt='slash' style={{
          position: 'absolute',
          top: '-5px',
          left: '18px',
          color: 'black',
          fontSize: fsize,
          width: '20px',
        }} />
        {SecondBackNumbers == "" ? <>
          <div
            style={{
              position: 'absolute',
              top: '1px',
              left: '27px',
              color: 'black',
              fontSize: fsize,
            }}>{MainNumber}
          </div>
        </> : <div
          style={{
            position: 'absolute',
            top: '1px',
            left: `${parseInt(SecondBackNumbers.join("")) >= 10 ? '24px' : '28px'}`,
            color: 'black',
            fontSize: fsize,
          }}>{SecondBackNumbers.join("")}<img src={Images.slash} alt='slash' style={{
            position: 'absolute',
            top: '11px',
            left: '-5px',
            color: 'black',
            fontSize: fsize,
            width: '20px',
          }} />
          <div
            style={{
              position: 'absolute',
              top: '15px',
              left: '5px',
              color: 'black',
              fontSize: fsize,
            }}>{MainNumber}
          </div>
        </div>}
      </div>
    </>
  }
  function positions(form, sun) {
    const block1 = 7
    return sun === 1 ? <>
      {NumPosition(form.BackNumbers[0], form.SecondBackNumbers[0], form.MainNumber[0], form.Soul[0], block1)}
      {NumPosition(form.BackNumbers[1], form.SecondBackNumbers[1], form.MainNumber[1], form.Soul[1], block1)}
      {NumPosition(form.BackNumbers[2], form.SecondBackNumbers[2], form.MainNumber[2], form.Soul[2], block1 + 9)}
    </> : <>
      {NumPosition2(form.BackNumbers[0], form.SecondBackNumbers[0], form.MainNumber[0], form.Soul[0], block1)}
      {NumPosition2(form.BackNumbers[1], form.SecondBackNumbers[1], form.MainNumber[1], form.Soul[1], block1)}
      {NumPosition2(form.BackNumbers[2], form.SecondBackNumbers[2], form.MainNumber[2], form.Soul[2], block1 + 9)}
    </>
  }
  const NumberList1 = date.split("").filter(i => i !== '-')
  let NumbersCheck = ''
  CheckNum()
  function CheckNum() {
    let NumberCheck = []
    for (let t = 0; t < 10; t++) {
      if (t === 0 || t === 1 || t === 9) {
        NumberCheck.push('')
      } else {
        if (NumberList1.filter(i => i === `${t}`).length > 0) {
          NumberCheck.push(`${t}`)
        } else {
          NumberCheck.push('')
        }
      }
    }
    NumbersCheck = NumberCheck
  }
  function personal(form) {
    return <Text>你的基本個性是一位{CheckOne[NumberList1.filter(i => i === '1').length]}
      而且，你{CheckNight[NumberList1.filter(i => i === '9').length]}
      再加上，你{CheckZero[NumberList1.filter(i => i === '0').length]}
      再者，你{SoulLVOptions[form.Soul[2] - 1].text}
      除此之外，你還具備有
      {NumbersCheck.filter(i => i !== '').map((i, index) => (
        NumberOptions[NumbersCheck.filter(i => i !== '')[index]]
      ))}
      等等的特質。
    </Text>
  }

  function PreloadImage(props) {

    const { src, alt } = props;

    useEffect(() => {
      const image = new Image();
      image.src = src;

      image.onload = () => {
        setImageLoaded(true);
      };

      return () => {
        image.onload = null;
      };
    }, [src]);

    return (
      <img src={imageLoaded ? src : Images.loading} alt={alt} style={{ width: imageLoaded ? "100%" : '' }} />
    );
  }

  const Loading = () => {

    return (
      <div className="page-loading">
        <script src="https://cdn.lordicon.com/bhenfmcm.js"></script>
        <div className="-load">
          <div className="-icon">
            <lord-icon src="https://cdn.lordicon.com/xjovhxra.json" trigger="loop" delay="300" colors="primary:#ffffff,secondary:#ffffff" style={{ width: '60px', height: '60px' }}></lord-icon>
          </div>
          <div className="-font">
            <span>l</span>
            <span>o</span>
            <span>a</span>
            <span>d</span>
            <span>i</span>
            <span>n</span>
            <span>g</span>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Wrapper>
      <Slider {...sliderSettings} ref={ResultSlider}>
        <Content>
          <Title>彩虹數字企業諮詢報告</Title>
          <InputDateBar
            inputTitle="生日年、月、日 (單月、日要多加一個0，例:1983-01-03)"
            className="top"
          >
            <Space direction="vertical">
              <DatePicker
                className={alert ? "alert" : ""}
                placeholder={date}
                onChange={onChange}
                require
              />
            </Space>
          </InputDateBar>
          <form id="form">
            <ContentArea>
              <InputBar inputTitle="姓名" className="top">
                <input
                  id="username"
                  name="username"
                  type="text"
                  placeholder="請輸入姓名"
                  maxLength={30}
                  value={form1.username}
                  onChange={(e) => handleChange(e)}
                />
              </InputBar>
              <InputBar inputTitle="性別" className="top">
                <input
                  id="gender"
                  name="gender"
                  type="text"
                  placeholder="請輸入性別"
                  maxLength={2}
                  value={form1.gender}
                  onChange={(e) => handleChange(e)}
                />
              </InputBar>
              <InputBar inputTitle="公司" className="top">
                <input
                  id="company"
                  name="company"
                  type="text"
                  placeholder="請輸入公司"
                  value={form1.company}
                  onChange={(e) => handleChange(e)}
                />
              </InputBar>
              <InputBar inputTitle="部門" className="top">
                <input
                  id="store"
                  name="store"
                  type="text"
                  placeholder="請輸入部門"
                  value={form1.store}
                  onChange={(e) => handleChange(e)}
                />
              </InputBar>
              <InputBar inputTitle="職稱" className="top">
                <input
                  id="job"
                  name="job"
                  type="text"
                  placeholder="請輸入職稱"
                  value={form1.job}
                  onChange={(e) => handleChange(e)}
                />
              </InputBar>
              <InputBar inputTitle='諮詢師' className='top'>
                <input id='adviceName' name='adviceName' type='text' placeholder='請輸入諮詢師' maxLength={16} value={form1.adviceName} onChange={(e) => handleChange(e)} />
              </InputBar>
            </ContentArea>
          </form>
          <InputDateBar inputTitle='諮詢日期' className='top'>
            <input id='adviceDate' name='adviceDate' className={date ? 'no-placeholder' : ''} type='date' value={form1.adviceDate} onChange={e => handleChange(e)} />
            <img src={Images.duedate_w} alt='duedate' />
          </InputDateBar>
        </Content>
        {index === 1 && (
          <Content id={"rainbowimg"}>
            <>
              {!imageLoaded && <Loading />}
              <PreloadImage
                style={{ width: "100%" }}
                src={Images.enterprise}
                alt="enterprise"
              />
              {imageLoaded && (
                <div>
                  <TextC
                    style={width?{ top: "21.9%", left: "17%" }:{top: "17.9%", left: "15%",}}
                  >
                    {form1.company}
                  </TextC>
                  <TextC
                    style={width?{ top: "25.1%", left: "17%" }:{top: "21.3%", left: "15%",}}
                  >
                    {form1.store}
                  </TextC>
                  <TextC
                    style={width?{ top: "28.2%", left: "17%" }:{top: "24.7%", left: "15%",}}
                  >
                    {form1.job}
                  </TextC>
                  <TextC
                    style={width?{ top: "24.2%", left: "55%" }:{top: "20.4%", left: "55%",}}
                  >
                    {form1.username}
                  </TextC>
                  <TextC
                    style={width?{ top: "26.7%", left: "55%" }:{top: "23%", left: "55%",}}
                  >
                    {form1.gender}
                  </TextC>
                  <TextC
                    style={width?{ top: "21%", left: "65%" }:{top: "17.9%", left: "67%",}}
                  >
                    {<div style={{ position: 'relative', flex: 100 }}>
                      <Title>+{form1.date.replace('-', ' . ').replace('-', ' . ')}{<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}</Title>
                      <div style={{ display: 'flex' }}>
                        &nbsp;&nbsp;
                        {positions(form1, width?0:1)}
                      </div>
                    </div>}
                  </TextC>
                  <TextP
                    style={width?{ top: "35.7%", left: "13%" }:{top: "32.5%", left: "10%",}}
                  >
                    {personal(form1)}
                  </TextP>
                  <TextG
                    style={width?{ top: "52.7%", left: "13%" }:{top: "51.5%", left: "11%",}}
                  >
                    {firstPractice(form1.MainNumber[2]).split('\n').map((line, index) => (
                      index <= 4 && <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </TextG>
                  <TextG
                    style={width?{ top: "55%", left: "51%" }:{top: "53.5%", left: "46%",}}
                  >
                    {firstPractice(form1.MainNumber[2]).split('\n').map((line, index) => (
                      index > 4 && <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </TextG>
                  <TextG
                    style={width?{ top: "69.5%", left: "13%" }:{top: "70%", left: "11%",}}
                  >
                    {form1.practiceNumbers.map((number, index1) => (
                      index1 <= 1 && <div key={index1}>
                        {secondPractice(number).split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                      </div>
                    ))}
                  </TextG>
                  <TextG
                    style={width?{ top: "69.5%", left: "48%" }:{top: "70%", left: "48%",}}
                  >
                    {form1.practiceNumbers.map((number, index1) => (
                      index1 > 1 && <div key={index1}>
                        {secondPractice(number).split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                      </div>
                    ))}
                  </TextG>
                  <TextG
                    style={width?{ top: "77.5%", left: "13%" }:{top: "78.2%", left: "11%",}}
                  >
                    {niceJob(form1.MainNumber[2]).split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </TextG>
                  <TextC
                    style={width?{ top: "89.55%", left: "79.7%" }:{top: "91.3%", left: "82.2%",}}
                  >
                    {form1.adviceDate}
                  </TextC>
                  <TextC
                    style={width?{ top: "89.55%", left: "61.7%" }:{top: "91.4%", left: "64%",}}
                  >
                    {form1.adviceName}
                  </TextC>
                </div>
              )}
            </>
          </Content>
        )}
      </Slider>
      {index === 0 && (
        <BtnArea>
          <CalculateBtn
            className={date ? "submit solid lg" : "solid submit disable lg"}
            btnName="產生諮詢單"
            onClick={() => Submit()}
          />
        </BtnArea>
      )}
      {index === 1 && (
        <BtnArea>
          <CalculateBtn
            className="prev solid lg"
            btnName="下載諮詢單"
            onClick={() => handleDownload()}
          />
          <CalculateBtn
            className="solid lg"
            btnName="回上一頁"
            onClick={Restart}
          />
        </BtnArea>
      )}
    </Wrapper>
  );
};

export default Calculator;
