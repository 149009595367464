import React from 'react';
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Colors, Fonts, Metrics } from '../../themes'


const Wrapper = styled.div`
    max-width: 880px;
    margin: 24px auto;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    align-items: center;
    grid-gap: 40px;
    padding: 16px;

    @media (max-width: ${Metrics.mobile}){
        grid-template-columns: repeat(1, 100%);
        align-self: center;
        grid-gap: 16px;
      }
`
const ImgArea = styled.div`

img{ 
    width: 100%;
}
    @media (max-width: ${Metrics.mobile}){
        grid-row: 1;
}
`
const TextArea = styled.div`
`
const Title = styled(Fonts.h3())`
    margin-right: 8px;
`

const JobTitle =styled(Fonts.h6())`
    color:${Colors.G2}
`

const TitleArea = styled.div`
    display: flex;
    grid-template-columns: 104px 1fr;
    align-items: center;
`

const Text = styled(Fonts.P_16())`
    margin-top: 8px;
    color:${Colors.G3}
`

const LinkText = styled.h3``

const StoryLeft = (props) => {
    return (
        <Wrapper>
            <TextArea>
                <TitleArea>
                    <Title>{props.title}</Title>
                    {props.jobTitle &&
                        <JobTitle>{props.jobTitle}</JobTitle>
                    }
                </TitleArea>
                
                {props.text.split('\\n').map(item => (
                    <Text>{item === '' ? <br/> : item}</Text>
                ))}
                {props.link &&
                    <Link to={props.link}>
                        <LinkText> 認識更多 </LinkText>
                    </Link>
                }
            </TextArea>
            <ImgArea>
                <img src={props.img} alt='' />
            </ImgArea>
        </Wrapper>
    );
}

export default StoryLeft;