import React from 'react'
import styled from 'styled-components'
import { Colors, Metrics } from '../../themes'
import 'antd/dist/antd.css';

const InputGroup = styled.div`
    padding: 8px 8px 4px;
    position: relative;
    &.white p{
        color: ${Colors.W100};
    }
    input,textarea {
        padding: 10px 8px;
        cursor: pointer;
        background: ${Colors.W100};
        border-radius: 6px;
        border: 1px solid #E0E0E1;
        outline: none;
        font-size: 14px;
        line-height: 20px;
        color: ${Colors.G100};	
        transition: 0.8s cubic-bezier(0.2,0.8,0.2,1);
        width: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        
    }
    &.white input,&.white textarea {
        background: ${Colors.W10};
        color: ${Colors.W100};
        border: 1px solid ${Colors.W100};
    }
    &.white input::-webkit-input-placeholder,
    &.white textarea::-webkit-input-placeholder { 
        color: ${Colors.W80};
    }
    &.white input::placeholder,
    &.white textarea::-webkit-input-placeholder {
        color: ${Colors.W80};
    }
    input:hover,textarea:hover{
        cursor: pointer;
        border-color: ${Colors.Blue}
    }
    &.white input:hover,&.white textarea:hover{
        border-color: ${Colors.W100}
    }
    input:focus, textarea:focus{
        cursor: pointer;
        border-color: ${Colors.Blue};
    }
    &.white input:focus, &.white textarea:focus{
        border-color: ${Colors.W100};
    }
    input.readOnly{
        cursor: default;
        pointer-events: none;
        color: ${Colors.G2};
        background: ${Colors.BackGround};
    }
    input:-internal-autofill-selected {
        background: ${Colors.W10} !important;
    }
    @media (max-width: ${Metrics.mobile}){
        input{
        max-width: inherit;
        }
    }
    .top{
        z-index: 1000000;
    }
`
const InputTitle = styled.p`
    font-size: 12px;
    letter-spacing: 0;
    line-height: 17px;
    position: relative;
    width: 100%;
    padding-left: 8px;
`

const InputBar = ( props ) => {
    const { children } = props

    return ( 
        <InputGroup className={`input-area ${props.className}`}>
            <InputTitle>{props.inputTitle}</InputTitle>
            { children }
        </InputGroup>
    )
  }
  
  export default InputBar