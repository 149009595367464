import React from 'react';
import styled from 'styled-components'
import { Fonts, Images, Colors } from '../../themes'
import { Rounded } from '../../components'

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 16px 0;
    justify-content: space-between;
    &.null, img, h6{
        justify-self: end;
        color:  ${Colors.G2};
    }
`
const TextArea = styled.div`
    display: flex;
    align-items: center;
`
const Icon = styled.img`
`
const Title = styled(Fonts.h6())`
    padding-left: 6px;
    
`

const CellIconTitle = ( props ) => {
    return (
        <Wrapper className={props.className}>
            <TextArea>
                <Icon src={props.icon} alt={props.alt} />
                <Title>{props.title}</Title>
            </TextArea>
            {props.onClick &&
                <Rounded 
                    icon={Images.pencil}
                    bg={Colors.Light_Blue}
                    filter='invert(0.4) sepia(1) saturate(20) hue-rotate(187.2deg) brightness(0.75);'
                    onClick={props.onClick}
                />
            }
        </Wrapper>
    );
}

export default CellIconTitle;