import React from 'react';
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Images, Fonts, Colors, Metrics } from '../themes'

const Wrapper = styled.div`
    padding: 24px 0;

    @media (max-width: ${Metrics.tablet}){
        margin: 0;
        padding: 16px 40px;
        max-height: 100vw;
        max-width: 100vw;
    } 

    @media (max-width: ${Metrics.mobile}){
        margin: 0;
        padding: 16px 24px;
        max-height: 100vw;
        max-width: 100vw;
    }
`
const Title = styled(Fonts.h2())`
    text-align: center;
    color: ${Colors.B100};
`

const Text = styled(Fonts.P_20())`
    text-align: center;
    color: ${Colors.G3};
    margin-top: 8px;
    
`

const LinkDiv = styled(Fonts.h5())`
    display: flex;
    color: ${Colors.Blue};
    justify-content: center;
    margin-top: 8px;
    img{
        filter: invert(0.4) sepia(1) saturate(20) hue-rotate(187.2deg) brightness(0.84);
    }
`

const TitleArea = (props) => {
    return (
        <Wrapper id={props.id}>
            <Title>{props.title}</Title>
            {props.link
                ? <LinkDiv>
                    <Link to={props.link}>{props.linkText}</Link>
                    <img src={Images.arrow_right} alt='' />
                </LinkDiv>
                : <><Text>{props.text}</Text><Text>{props.text1||''}</Text></>
            }
        </Wrapper>
    );
}

export default TitleArea;