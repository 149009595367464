import React from 'react';
import styled from 'styled-components'
import { Colors, Fonts, Metrics } from '../../themes'


const Wrapper = styled.div`
    max-width: 880px;
    margin: 24px auto;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    align-items: center;
    grid-gap: 40px;
    padding: 16px;

    @media (max-width: ${Metrics.mobile}){
        grid-template-columns: repeat(1, 100%);
        align-self: center;
        grid-gap: 16px;
      }
`
const ImgArea = styled.div`
    img{ 
        width: 100%;
}
`
const TextArea = styled.div`
`
const Title = styled(Fonts.h3())`
    margin-right: 8px;
`

const JobTitle = styled(Fonts.h6())`
    color:${Colors.G2}
`

const TitleArea = styled.div`
    display: flex;
    grid-template-columns: 104px 1fr;
    align-items: center;
`

const Text = styled(Fonts.P_16())`
    margin-top: 8px;
    color:${Colors.G3}
`

const LinkText = styled(Fonts.h6())`
    margin-top: 16px;
    color:${Colors.Blue};
    &:hover{
        cursor: pointer;
    }

    @media (max-width: ${Metrics.mobile}){
        font-size: 16px;
      }

`

const StoryLeft = (props) => {
    return (
        <Wrapper>
            <ImgArea>
                <img src={props.img} alt='' />
            </ImgArea>
            <TextArea>
                <TitleArea>
                    <Title>{props.title}</Title>
                    {props.jobTitle &&
                        <JobTitle>{props.jobTitle}</JobTitle>
                    }
                </TitleArea>
                {props.text.split('\\n').map(item => (
                    <Text>{item === '' ? <br/> : item}</Text>
                ))}
                {props.onClick &&
                    <LinkText onClick={props.onClick}> 認識更多 </LinkText>
                }
                {props.onClick1 &&
                    <LinkText onClick={props.onClick1}> 預約諮詢 </LinkText>
                }
            </TextArea>
        </Wrapper>
    );
}

export default StoryLeft;