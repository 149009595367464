import React from 'react';
import styled from 'styled-components'
import { FormTitleDiv, CellBDEmpty } from '../../components'
import { Images } from '../../themes'

const ContentArea = styled.div`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    display: grid;
    justify-items: center;
    align-items: center;
    min-height: 350px;
    .selectBar{
        max-width: 344px;
        margin: 0 auto;
    }
    .checkBar{
        margin: 0 auto;
        max-width: 250px;
        z-index: 0;
    }
`

const RefundSuccess = (props) => {

    return (
        <FormTitleDiv title='退票失敗' close={props.close} Submit={()=>window.location.reload()} >
            <ContentArea>
                <CellBDEmpty 
                    img={Images.error}
                    title='退票失敗'
                    text={`退票交易失敗，請檢察網路連線再試一次\\n有任何問題歡迎來信\\nservice@rainbow.com`}
                />
            </ContentArea>
        </FormTitleDiv>
    );
}

export default RefundSuccess;