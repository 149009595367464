import React from 'react';
import {
    Seo, Layout, CardBannerS, CardEventSignUp,
    CardEventDetail, CardEventSignUpFixed
} from '../components'
import styled from 'styled-components'
import { Query } from "react-apollo";
import { Images } from '../themes'
import { useHistory } from "react-router-dom";
import {
    GET_EVENTLIST, GET_DETAIL,
    GET_PROFILE, GET_FORM_LIST, GET_FORM_LIST_NEW
} from '../api';
import Cookies from 'js-cookie';
var moment = require('moment');

const Wrapper = styled.div`
    position: relative;
    height: auto;
    padding: 0 16px;
`
const Content = styled.div`
    padding: 24px 0;
    display: grid;
    grid-template-columns: auto 349px;
    max-width: 1080px;
    margin: 0 auto;
    grid-gap: 16px;
    @media (max-width: 700px){
        padding: 13px 0;
        grid-template-columns: 100%;
        grid-gap: inherit;
    }
`

const EventDetailPage = (props) => {
    const id = props.match?.params?.id;
    const history = useHistory();
    const [userId, setUserId] = React.useState()

    const [hasScrolled, setScrolled] = React.useState(false)
    const [height, setHeight] = React.useState(0);
    const elementRef = React.createRef();
    const [width, setWidth] = React.useState(window.innerWidth);

    function Login() {
        if (process.env.REACT_APP_BRANCH === 'master') {
            Cookies.set('rainbow_return', `https://www.rainbownumen.org/eventDetail/${id}`, { path: '/' });
            window.location.href = `https://rainbow-numen.us.auth0.com/login?client=sa7C299bSSb7nvb4zTCRl0JtkFx03R92&protocol=oauth2&response_type=token%20id_token&redirect_uri=https://www.rainbownumen.org&scope=openid%20profile`
        } else {
            Cookies.set('rainbow_return', `http://localhost:3000/eventDetail/${id}`, { path: '/' });
            window.location.href = `https://rainbow-numen-dev.us.auth0.com/login?client=PyCenk283wvaE4dnOnEFJPPjgOr7Evd5&protocol=oauth2&response_type=token%20id_token&redirect_uri=http://localhost:3000&scope=openid%20profile`
        }
    }

    // 設定取得寬度
    const updateWidthAndHeight = () => {
        setWidth(window.innerWidth);
    };
    React.useEffect(() => {
        //取得用戶
        if (parseInt(id)===182) {
            window.location.href = '/';
        }

    }, [])
    React.useEffect(() => {

        //取得用戶
        if (Cookies.get('rainbow')) {
            setUserId(Cookies.get('rainbow'))
        }

        // 取得頁面高度
        if (elementRef.current) {
            setHeight(elementRef.current.offsetHeight);
        }
        const headerScrollEvent = window.addEventListener('scroll', e =>
            setScrolled(window.pageYOffset > 320 && window.innerWidth > 425)
        )
        return () => {
            headerScrollEvent && window.removeEventListener(headerScrollEvent)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementRef])

    // 偵測寬度
    window.addEventListener("resize", updateWidthAndHeight);

    return (
        <Query query={GET_EVENTLIST(userId)} >
            {({ loading, error, data, refetch }) => {
                if (loading) return <p>Loading...</p>;
                if (error) return <p>Error :(</p>;

                //過濾已完成的課程
                let DoneList = data.member_event_session.filter(i => i.status === '已結束')
                let UnDoneList = data.member_event_session.filter(i => (i.status === '處理中' || i.status === '已付款' || i.status === '公關票'))
                //用戶資格 暫時都可報名
                let level = 3;
                //判斷用戶資格

                if (DoneList.filter(i => i.event.code === 'A-3-0').length > 0) {
                    level = 3
                } else if (DoneList.filter(i => i.event.code === 'A-2-0').length > 0) {
                    level = 2
                } else if (DoneList.filter(i => i.event.code === 'A-1-0').length > 0) {
                    level = 1
                } else {
                    level = 0
                }
                return (
                    <Query query={GET_PROFILE(userId)} >
                        {({ loading, error, data, refetch }) => {
                            if (loading) return <p>Loading...</p>;
                            if (error) return <p>Error :(</p>;
                            const User = data
                            return (
                                <>
                                    <Query query={GET_DETAIL(id)} >
                                        {({ loading, error, data, refetch }) => {
                                            if (loading) return <p>Loading...</p>;
                                            if (error) return <p>Error :(</p>;
                                            const detail = data.event[0]
                                            const img = require(`../img/Card/${detail.cover}.svg`)
                                            //用戶是否有資格報名
                                            let CanUserSignUp;
                                            //用戶是否已經報名
                                            let isSignUp;
                                            //是否為打包課程
                                            let Pack = false;
                                            //立即報名將前往的位置
                                            let GoHref;
                                            //是否有注意事項
                                            let Noti;
                                            
                                            //基本已報名偵測，根據 session[0] id
                                            if (DoneList.filter(i => i.session.id === detail.sessions[0].id).length > 0) {
                                                isSignUp = true;
                                            } else if (UnDoneList.filter(i => i.session.id === detail.sessions[0].id).length > 0) {
                                                isSignUp = true;
                                            } else {
                                                isSignUp = false;
                                            }
                                            /*
                                            //偵測打包課程
                                            if (detail.code.includes('A')) {
                                                if (detail.code.includes('-1-')) {
                                                    if (UnDoneList.filter(i => i.event.code === 'A-1-0').length > 0) {
                                                        isSignUp = true;
                                                    } else if (UnDoneList.filter(i => i.event.code === detail.code).length > 0) {
                                                        isSignUp = true;
                                                    } else if (DoneList.filter(i => i.session.id === detail.sessions[0].id).length > 0) {
                                                        isSignUp = true;
                                                    } else {
                                                        isSignUp = false;
                                                    }
                                                } else if (detail.code.includes('-2-')) {
                                                    if (UnDoneList.filter(i => i.event.code === 'A-2-0').length > 0) {
                                                        isSignUp = true;
                                                    } else if (UnDoneList.filter(i => i.event.code === detail.code).length > 0) {
                                                        isSignUp = true;
                                                    } else if (DoneList.filter(i => i.session.id === detail.sessions[0].id).length > 0) {
                                                        isSignUp = true;
                                                    } else {
                                                        isSignUp = false;
                                                    }
                                                } else if (detail.code.includes('-3-')) {
                                                    if (UnDoneList.filter(i => i.event.code === 'A-3-0').length > 0) {
                                                        isSignUp = true;
                                                    } else if (UnDoneList.filter(i => i.event.code === detail.code).length > 0) {
                                                        isSignUp = true;
                                                    } else if (DoneList.filter(i => i.session.id === detail.sessions[0].id).length > 0) {
                                                        isSignUp = true;
                                                    } else {
                                                        isSignUp = false;
                                                    }
                                                }
                                            }

                                            if (level === 1) {
                                                //已經上過第一階段打包課程
                                                if (detail.code.includes('A-1-') || detail.code.includes('B-1-')
                                                    || detail.code === 'D-1' || detail.code.includes('C')) {
                                                    //已有資格報名，非打包課程
                                                    CanUserSignUp = true;
                                                } else if (detail.code.includes('A-2-')) {
                                                    //第二階段打包課程
                                                    CanUserSignUp = false;
                                                    Pack = true;
                                                    if (process.env.REACT_APP_BRANCH === 'master') {
                                                        GoHref = '/signUp/63';
                                                    } else {
                                                        GoHref = '/signUp/67';
                                                    }
                                                    Noti = true;
                                                } else {
                                                    //尚未可以報名
                                                    CanUserSignUp = false;
                                                }
                                            } else if (level === 2) {
                                                //已經上過第二階段打包課
                                                if (detail.code.includes('A-3-')) {
                                                    //第三階段打包課程
                                                    CanUserSignUp = false;
                                                    Pack = true;
                                                    if (process.env.REACT_APP_BRANCH === 'master') {
                                                        GoHref = '/signUp/68';
                                                    } else {
                                                        GoHref = '/signUp/68';
                                                    }
                                                    Noti = true;
                                                } else if (detail.code.includes('B-3-') || detail.code === 'D-3') {
                                                    //尚未可以報名
                                                    CanUserSignUp = false;
                                                } else {
                                                    //符合報名資格
                                                    CanUserSignUp = true;
                                                }
                                            } else if (level === 3) {
                                                //完成所有打包課程
                                                CanUserSignUp = true;
                                            } else {
                                                //從未上過任何課程
                                                CanUserSignUp = false;
                                                Noti = false;
                                                /*
                                                if (detail.code.includes('A-1-')) {
                                                    //第一階段打包課程
                                                    CanUserSignUp = false;
                                                    Pack = true;
                                                    Noti = true;
                                                    GoHref = '/signUp/66';
                                                } else {
                                                    //尚未可以報名
                                                    CanUserSignUp = false;
                                                    Noti = false;
                                                }
                                            }*/

                                            if (detail.category === '其他活動') {
                                                CanUserSignUp = true;
                                            }

                                            //強制打開報名 63
                                            if (detail.code.includes('A-1-0') || detail.code.includes('A-2-0')) {
                                                CanUserSignUp = true;
                                            }

                                            //判斷課程是否過期
                                            var CurrentDate = moment();
                                            let PastFilterPack = detail.sessions.filter(i => moment(i.session_end_time).diff(CurrentDate) > 0);
                                            //console.log(PastFilterPack[0])
                                            return (
                                                <Layout event >
                                                    <Seo
                                                        title='彩虹數字學會 - 探索課程'
                                                        metaDescription='運用彩虹數字解讀你的生命密碼，了解自己的生命課題。學習「心性解碼」、「時間解碼」、「感情解碼」課程，免費試算自己的數字。'
                                                        metaTitle='彩虹數字學會 - 探索課程'
                                                        metaUrl='https://www.rainbownumen.org/event/0'
                                                        //metaImage={`${Images.Rainbow_SEO}`}
                                                        metaImage='https://rainbow-digital.glitch.me/i/ktlc6ygb.png'
                                                    />
                                                    <Wrapper ref={elementRef}>
                                                        <CardBannerS
                                                            className='center sh-img'
                                                            bg={img}
                                                            title={`${detail.title}`}
                                                            text={`${detail.excerpt}`}
                                                        />
                                                        <Content>
                                                            <CardEventDetail
                                                                bigTitle={`${detail.title}`}
                                                                excerpt={`${detail.excerpt}`}
                                                                content={`${detail.content}`}
                                                                teacher={`${detail.sessions[0].member.name}`}
                                                                duration={`${detail.sessions[0].duration}`}
                                                                city={`${detail.sessions[0].classroom.city}`}
                                                            />
                                                            <Query query={User.role === '舊生' ? GET_FORM_LIST(id) : GET_FORM_LIST_NEW(id)} >
                                                                {({ loading, error, data, refetch }) => {
                                                                    if (loading) return <p>Loading...</p>;
                                                                    if (error) return <p>Error :(</p>;
                                                                    let detail = data.event[0]

                                                                    return (
                                                                        <>
                                                                            {data &&
                                                                                <>
                                                                                    {width > 700
                                                                                        ? <CardEventSignUp
                                                                                            className={hasScrolled ? 'hasImg' : ''}
                                                                                            height={height}
                                                                                            // 內容
                                                                                            code={detail.code}
                                                                                            title={detail.title}
                                                                                            // price={`${moment(detail.sessions[0].session_start_time).format("YYYY/MM/DD")}`}
                                                                                            required={`${detail.required}`}
                                                                                            img={img}
                                                                                            //是否顯示注意事項
                                                                                            noti={detail.caution}
                                                                                            // 檢查報名狀態
                                                                                            btnClass={
                                                                                                detail.sessions.length > 0 ? '' : 'disable'
                                                                                                /*
                                                                                                CanUserSignUp && detail.sessions.length > 0 
                                                                                                ? '' 
                                                                                                : 'disable'*/
                                                                                            }
                                                                                            already={isSignUp ? true : false}
                                                                                            // 執行動作
                                                                                            onClick={() => {
                                                                                                if (Pack) {
                                                                                                    history.push(`${GoHref}`)
                                                                                                } else {
                                                                                                    history.push(`/signUp/${detail.id}`)
                                                                                                }
                                                                                            }}
                                                                                            Login={Login}
                                                                                        />
                                                                                        : <CardEventSignUpFixed
                                                                                            className={hasScrolled ? 'hasImg' : ''}
                                                                                            height={height}
                                                                                            // 元件狀態
                                                                                            eventdetail
                                                                                            now={1}
                                                                                            // 內容
                                                                                            code={detail.code}
                                                                                            title={detail.title}
                                                                                            // price={`${moment(detail.sessions[0].session_start_time).format("YYYY/MM/DD")}`}
                                                                                            required={`${detail.required}`}
                                                                                            img={img}
                                                                                            //是否顯示注意事項
                                                                                            noti={detail.caution}
                                                                                            // 檢查報名狀態
                                                                                            already={isSignUp ? true : false}
                                                                                            btnClass={
                                                                                                detail.sessions.length > 0 ? '' : 'disable'
                                                                                                /*
                                                                                                CanUserSignUp && detail.sessions.length > 0 
                                                                                                ? '' 
                                                                                                : 'disable'*/
                                                                                            }
                                                                                            // 執行動作
                                                                                            onClick={() => {
                                                                                                if (Pack) {
                                                                                                    history.push(`${GoHref}`)
                                                                                                } else {
                                                                                                    history.push(`/signUp/${detail.id}`)
                                                                                                }
                                                                                            }}
                                                                                            Login={Login}
                                                                                        />
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </>
                                                                    );
                                                                }}
                                                            </Query>
                                                        </Content>
                                                    </Wrapper>
                                                </Layout>
                                            );
                                        }}
                                    </Query>
                                </>
                            );
                        }}
                    </Query>
                );
            }}
        </Query>
    );
}

export default EventDetailPage;