import React from 'react';
import styled from 'styled-components'
import { Fonts,Colors } from '../../themes'
import '../../css/modalWhite.css'
import 'moment/locale/zh-tw' 
import { Query } from "react-apollo";
import { GET_FORM_PACKAGE } from '../../api';
var moment = require('moment');

const Wrapper = styled.div`
    background: white;
    border: 1px solid ${Colors.B10};
    border-radius: 10px;
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    padding: 16px;
    margin-top: 16px;
    &:hover {
        ${'' /* box-shadow: 0px 8px 12px 0 ${Colors.B20}; */}
        transition: 0.8s cubic-bezier(0.2,0.8,0.2,1);
        ${'' /* cursor: pointer; */}
    }
    &:first-child{
        margin-top: 0;
    }
`
const TextArea = styled.div`
`
const Title = styled(Fonts.h4())`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-bottom: 8px;
`
const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 75px;
    bottom: 0;
    padding: 0 0 16px;
    width: 100%;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    align-items: start;
`
const Price = styled(Fonts.h3())`
    color:${Colors.Blue};
    align-self: end;
`
const Control = styled.p`
    background: ${Colors.Light_Red};
    padding: 2px 4px;
    border-radius: 4px;
    color: ${Colors.Red};
    text-align: center;
    margin: 0;
    &:hover{
        cursor: pointer;
    }
    &.pay{
        background: ${Colors.Blue};
        color: ${Colors.W100};
    }
    &.resign{
        background: ${Colors.Light_Blue};
        color: ${Colors.Blue};
    }
    &.normal{
        background: transparent ;
        color: transparent;
    }
    &.dealWith{
        background: #E6E6E6;
        color: ${Colors.TitleBlack};
    }
    &.dealWith:hover{
        cursor: default;
    }
`
const SubTitle = styled(Fonts.h6())`
    padding: 8px 0;
`
const Text = styled(Fonts.P_16())`
    ${'' /* margin-top: 8px; */}
    color:${Colors.G3};
    white-space: pre-wrap;
    &.br{
        padding-bottom: 20px;
    }
    &:last-child{
        ${'' /* padding-bottom: 20px; */}
    }
`

const CardTicket = (props) => {
    return (
        <Query query={GET_FORM_PACKAGE(props.id)}>
            {({ loading, error, data, refetch }) => {
                return (
                    <>
                    {data &&
                        <Wrapper>
                            <Grid>
                                <TextArea>
                                    <Title>{props.title}</Title>
                                    <Price>{props.price}</Price>
                                </TextArea>
                                <Control className={props.status} onClick={props.onClick}>{props.control}</Control>
                            </Grid>

                            <SubTitle>課程時間</SubTitle>
                            <Text className='info'>{props.content}</Text>
                            {/*moment(props.time_start).format('MM/DD') === moment(props.time_end).format('MM/DD')
                                    ? <Text className='info'>上課時間：{moment(props.time_start).format('YYYY/MM/DD (dd) a H:mm')} ~ {moment(props.time_end).format('a H:mm')}</Text>
                                    : <Text className='info'>上課時間：{moment(props.time_start).format('YYYY/MM/DD (dd) a H:mm')} ~ {moment(props.time_end).format('MM/DD (dd) H:mm')}</Text>
                            */}
                            <SubTitle>課程地點</SubTitle>
                            <Text>{props.location}</Text>
                            <SubTitle>訂單編號</SubTitle>
                            <Text>{props.ticket_id}</Text>
                        </Wrapper>
                    }
                    </>
                );
            }}
        </Query>
    );
}

export default CardTicket;