import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import ScrollToTop from './hooks/scrollToTop.js'
import { Home, Team, Compute, Shesay, Enterprise, Event, News, Learn, Reservation, 
  EventDetail, Account, SignUp, TermsOfService, PrivacyPoclicy,
  EventDetailPack } from './pages'

const Main = () => {
  return (
      <Router>
        <ScrollToTop />
          <Route exact path='/' component={Home}></Route>
          <Route exact path='/team' component={Team}></Route>
          <Route exact path='/compute/:id' component={Compute}></Route>
          <Route exact path='/shesay/:id' component={Shesay}></Route>
          <Route exact path='/enterprise/:id' component={Enterprise}></Route>
          <Route exact path='/event/:id' component={Event}></Route>
          <Route exact path='/news/:id' component={News}></Route>
          <Route exact path='/learn/:id' component={Learn}></Route>
          <Route exact path='/reservation' component={Reservation}></Route>
          <Route exact path='/eventDetail/:id' component={EventDetail}></Route>
          <Route exact path='/eventDetailPack/:id' component={EventDetailPack}></Route>
          <Route exact path='/account/:id' component={Account}></Route>
          <Route exact path='/signUp/:id' component={SignUp}></Route>
          <Route exact path='/termsOfService' component={TermsOfService}></Route>
          <Route exact path='/privacyPoclicy' component={PrivacyPoclicy}></Route>
      </Router>
    
  );
}

export default Main;
