export const Colors = {

    // White Color
    W100: 'rgba(255, 255, 255, 1)',
    W80: 'rgba(255, 255, 255, 0.8)',
    W50: 'rgba(255, 255, 255, 0.5)',
    W30: 'rgba(255, 255, 255, 0.3)',
    W20: 'rgba(255, 255, 255, 0.2)',
    W10: 'rgba(255,255,255,0.1)',
    W5: 'rgba(255,255,255,0.05)',

    //Black Color
    B100: 'rgba(0,0,0,1)',
    B80: 'rgba(0,0,0,0.8)',
    B50: 'rgba(0,0,0,0.5)',
    B30: 'rgba(0,0,0,0.3)',
    B20: 'rgba(0,0,0,0.2)',
    B10: 'rgba(0,0,0,0.1)',
    B07: 'rgba(0,0,0,0.07)',
    B05: 'rgba(0,0,0,0.05)',

    //Gray Color
    G5: '#171818',
    G4: '#171818',
    G3: '#414248',
    G2: '#62636A',
    G1: '#F3F3F3',

    //Primary Color
    Blue: '#3578F1',
    Blue05: 'rgba(53,120,241,0.05)',
    Light_Blue: '#E5ECF8',
    Dark_Blue: '#0F4EBF',
    Red: '#E65C52',
    Light_Red: '#F7E9E8',
    Green: '##59C08E',
    Lught_Green: '#E9F3EE',
    Yellow: '#FF9C30',
    Light_Yellow: '#FEF4EB',

     //Shadow
    Shadow_1: '0 2px 4px 0 rgba(0,0,0,0.15);',
    Shadow_2: '2px 4px 8px 0 rgba(0,0,0,0.15);',
    Shadow_3: '4px 8px 16px 0 rgba(0,0,0,0.15);',
    Shadow_4: '6px 12px 8px 0 rgba(0,0,0,0.15);',

    BackGround: '#FAFAFA',

    TitleBlack: '#171818',
    SubTitleGray: '#414248'
  };

export default Colors;
  
