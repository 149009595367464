import React from 'react';
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Fonts, Images, Metrics, Colors } from '../../themes'
import LoginBtn from '../Button/LoginBtn'
 
const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    z-index: 102;
    padding: 40px 60px;
    @media (max-width: ${Metrics.mobile}){
        padding: 8px 20px;
        top: 50%;
        transform: translateY(-50%)
    }
`
const TextArea = styled.div`
    position: relative;
    padding: 24px;
    z-index: 1;
`
const Title = styled(Fonts.h3())`
    margin: 0;
    text-align: center;
    padding-bottom: 8px;
    color: ${Colors.W100};
    
`
const Text = styled(Fonts.P_16())`
    text-align: center;
    color: ${Colors.W100};
    a{
        padding-left: 8px;
    }
`

const Info = styled(Fonts.info())`
    text-align: center;
    margin-top: 20px;
    color: ${Colors.W100};
`


const LoginModal = (props) => {
    return (
        <Wrapper>
            <TextArea>
                <Title>登入</Title>
                <Text>想多了解彩虹數字，就從登入開始！</Text>
            </TextArea>
            <LoginBtn 
                bg={Colors.W100} 
                img={Images.facebook} 
                alt='facebook-logo' 
                text='使用 Facebook 登入' 
                onClick={props.onClick}
            />
            <LoginBtn 
                bg={Colors.W100} 
                img={Images.google}   
                alt='google-logo' 
                text='使用 Google 登入' 
                onClick={props.onClick}
            />
            <Info>登入即表示同意我們的 <Link to='/privacyPoclicy'>隱私權政策</Link> 及 <Link to='/termsOfService'>服務條款</Link></Info>
        </Wrapper>
    );
}

export default LoginModal;