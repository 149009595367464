import { Fonts, Metrics } from '.'
import styled from 'styled-components'
import Colors from './Colors';

const Styles = {
  wrapper: () => styled.section`
    width: 100%;
    height: auto;
    margin: 0 auto;
    min-height: 400px;
    max-width: 1280px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
  `,
  //button
  btnL: () => styled.button`
    display: block;
    outline: 0;
    border: 0;
    color: ${Colors.W100} ;
    background: ${Colors.Blue};
    text-align: center;
    border-radius: 8px;
    padding: ${Metrics.px2} ${Metrics.px5};
    font-size: 20px;
    line-height: 28px;
    font-weight: bold;
    transition: 0.8s cubic-bezier(0.2,0.8,0.2,1);
    font-family: ${Fonts.base};
    max-height: 44px;
    &.full-width{
      width: 100%;
    }
    &.secondary{
      background: ${Colors.W100};
      color: ${Colors.B100};
    }
    &.secondary:hover {
        background: ${Colors.B100};
        color: ${Colors.W100};
        cursor: pointer;
    }
    &.disable{
      background: ${Colors.B10};
      color: ${Colors.B30};
      pointer-events: none;
    }
    &.disable:hover{
      background: ${Colors.B10};
      color: ${Colors.B30};
      cursor: default;
    }
    &:hover {
        background: ${Colors.Dark_Blue};
        color: ${Colors.W100};
        cursor: pointer;
    }
    &.third{
      background: ${Colors.W100};
      color: ${Colors.Blue};
      border: 1px solid ${Colors.Blue};
    }
    &.calculator{
      background: ${Colors.W10};
      color: ${Colors.W100};
      border: 1px solid  ${Colors.W50};
    }

    &.calculator:hover{
        background: ${Colors.W100};
        color: ${Colors.B100};
        border: 1px solid  transparent;
    }

    &.disable.calculator{
        background: ${Colors.W10};
        color: ${Colors.W50};
        border: 1px solid  ${Colors.W10};
    }
  `,
  btnM: () => styled.button`
    outline: 0;
    border: 0;
    background: ${Colors.Blue};
    &:hover{
      cursor: pointer;
    }
  `,
  btnS: () => styled.button`
    outline: 0;
    border: 0;
    color: ${Colors.W100} ;
    background: ${Colors.Blue};
    padding: 6px 16px;
    text-align: center;
    border-radius: 8px;
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    &:hover{
      cursor: pointer;
      background: ${Colors.Dark_Blue};
    }
  `,


  //card
  card: () => styled.div`
    box-sizing: border-box;
    width:349px;
    height: 400px;
    border: 1px solid ${Colors.B10};
    background-color: ${Colors.W100};
    border-radius: 16px;
    box-shadow: 2px 4px 8px 0 ${Colors.B10};
    position: relative;
    transition: 0.8s cubic-bezier(0.2,0.8,0.2,1);
    @media (max-width: ${Metrics.mobile}) {
      width: 100%;
    }
  `,
  //TopLayer
  toplayer: () => styled.div`
    
  `

}
export default Styles