import React from 'react';
import styled from 'styled-components'
import { Fonts, Styles, Metrics } from '../../themes'
// import { BtnL } from '../../components'

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
    overflow: hidden;
    &.center{
        top: 50%;
        transform: translateY(60%);
    }
    @media (max-width: ${Metrics.tablet}){
        &.center{
            top: 0;
            transform: translateY(0);
            padding: 137px 0;
        }
    }
    @media (max-width: ${Metrics.mobile}){
        &.center{
            padding: 60px 0;
        }
    }
`
const Img = styled.img`
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: auto;
    max-width: 97px;
    padding-bottom: 18px;
`
const TextArea = styled.div`
    margin-bottom: 24px;
`
const Title = styled(Fonts.h4())`
    text-align: center;
    padding-bottom: 8px;
`

const Text = styled(Fonts.P_16())`
    text-align: center;
`

const BtnL = styled(Styles.btnL())`
    margin: 16px auto;
    @media (max-width: ${Metrics.mobile}){
        width: 100%;
    }
`

const CellBDEmpty = (props) => {
    return (
        <Wrapper className={props.className}>
            <Img src={props.img} alt='' />
            <TextArea>
                <Title>{props.title}</Title>
                {props.text.split('\\n').map(item => (
                    <Text>{item === '' ? <br/> : item}</Text>
                ))}
            </TextArea>
            {props.btnTitle &&
                <BtnL className='solid' onClick={props.onClick}>
                    <div><p>{props.btnTitle}</p></div>
                </BtnL>
            }
        </Wrapper>
    );
}

export default CellBDEmpty;