import React from 'react'
import styled from 'styled-components'
import { Colors, Fonts } from '../themes'



const Wrapper = styled.div`
  padding:32px;
  background: ${Colors.B10};
`
const Title =  styled(Fonts.P_16())`
  font-size: 16px;
  text-align: center;
`
const Text =  styled(Fonts.info())`
  text-align: center;
  margin-top: 8px;
`
const Version = styled.p`
    color: ${Colors.G3};
    position: absolute;
    left: 24px;
    bottom: 16px;
` 
const Copyright = props => (
  <Wrapper >
    <Title>© Copyright 2020   彩虹數字學會   Professional</Title>
    <Text>彩虹數字學會，致力於以彩虹數字揚昇個人生命的專業非營利組織，客服信箱：service@rainbownumen.org</Text>
    <Version>v.1.6.0</Version>
  </Wrapper>
)
export default Copyright
