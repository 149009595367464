import React, { useState, useRef }  from 'react';
import {
    Seo, Layout, TitleArea, TitleOnlyArea, CardEvent,
    FlexFree, CellNote, EventModal1, EventModal2, EventModal3,
    EventModal4, EventModal5, Calculator, CalculatorYear, 
    CalculatorForm, CalculatorEmo
} from '../components'
import styled from 'styled-components'
import { Images, Colors, Fonts } from '../themes'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Query } from "react-apollo";
import { useHistory } from "react-router-dom";
import Modal from 'react-modal'
import { GET_LIST } from '../api';

var moment = require('moment');

const TextArea = styled.div`
`
const Text = styled(Fonts.P_16())`
    color:${Colors.G3};
    white-space: pre-wrap;
    &.br{
        padding-bottom: 20px;
    }
    text-align: center;
`
const TabView = styled.div`
    padding: 24px 16px 40px;
`

const CardBottom = styled.div`
    height: 16px;
`
const Close = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 10000;
    width: 24px;
    height: 24px;
    &:hover{
        cursor: pointer;
    }
`
const customStyles = {
    overlay: {
        backgroundColor: "rgba(0,0,0,0.5)",
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '16px',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
        zIndex: '1000',
    }
};


const ComputePage = (props) => {
    const id = props.match?.params?.id;
    const [date, setDate] = useState('2000-01-01');
    const [time, setTime] = useState('');
    const [name, setName] = useState('');
    const [gender, setGender] = useState('');

    const [value, setValue] = React.useState(Number(id));
    const history = useHistory();
    const handleChange = (event, newValue) => {
        setValue(newValue);
        history.push(`${newValue}`)
    };
    // 心性解碼
    const [modalHeart, setModalHeart] = React.useState(false);
    function openHeart() {
        setModalHeart(true);
    }
    function closeHeart() {
        setModalHeart(false);
    }
    // 時間解碼
    const [modalTime, setModalTime] = React.useState(false);
    function openTime() {
        setModalTime(true);
    }
    function closeTime() {
        setModalTime(false);
    }
    // 感情解碼
    const [modalEmotion, setModalEmotion] = React.useState(false);
    function openEmotion() {
        setModalEmotion(true);
    }
    function closeEmotion() {
        setModalEmotion(false);
    }
    //單元主題
    const [modalUnit, setModalUnit] = React.useState(false);
    function openUnit() {
        setModalUnit(true);
    }
    function closeUnit() {
        setModalUnit(false);
    }
    //案例分析
    const [modalCase, setModalCase] = React.useState(false);
    function openCase() {
        setModalCase(true);
    }
    function closeCase() {
        setModalCase(false);
    }
    return (
        <Query query={GET_LIST}>
            {({ loading, error, data, refetch }) => {
                if (loading) return <p></p>;
                if (error) return <p>error</p>;
                /*
                const DefaultList = data.event.filter(i=>i.category==='核心基礎課程').filter(i => !i.code.includes('-0'))
                const DeepList = data.event.filter(i=>i.category==='深度解碼課程')
                const UnitList = data.event.filter(i=>i.category==='單元主題課程' || i.category==='案例研析課程' )  
                const BasicList = data.event.filter(i=>i.category==='核心基礎課程'&&i.code.includes('-0'))
                const TeaList = data.event.filter(i=>i.category==='其他活動')
                
                const DefaultList = data.event.filter(i => i.type === '心性解碼').filter(i => i.status === '已上架')
                const DeepList = data.event.filter(i => i.type === '時間解碼').filter(i => i.status === '已上架')
                const BasicList = data.event.filter(i => i.type === '感情解碼').filter(i => i.status === '已上架')
                const UnitList = data.event.filter(i => i.type.includes('單元')).filter(i => i.status === '已上架')
                const TeaList = data.event.filter(i => i.type.includes('其他')).filter(i => i.status === '已上架')
                
                */
                //const DefaultList = data.event.filter(i => i.category.includes('入門')).filter(i => i.status === '已上架')
                const DefaultList = data.event.filter(i => i.category.includes('活動講座')).filter(i => i.status === '已上架')
                const DeepList = data.event.filter(i => i.category.includes('進階')).filter(i => i.status === '已上架')
                const BasicList = data.event.filter(i => i.category.includes('講師')).filter(i => i.status === '已上架')
                const UnitList = data.event.filter(i => i.category.includes('單元')).filter(i => i.status === '已上架')
                const TeaList = data.event.filter(i => i.category.includes('其他')).filter(i => i.status === '已上架')
                return (
                    <Layout event>
                        <Seo
                            title='彩虹數字學會 - 探索課程'
                            metaDescription='運用彩虹數字解讀你的生命密碼，了解自己的生命課題。學習「心性解碼」、「時間解碼」、「感情解碼」課程，免費試算自己的數字。'
                            metaTitle='彩虹數字學會 - 探索課程'
                            metaUrl='https://www.rainbownumen.org/event/0'
                            //metaImage={`${Images.Rainbow_SEO}`}
                            metaImage='https://rainbow-digital.glitch.me/i/ktlc6ygb.png'
                        />
                        <TitleArea
                            title='彩虹數字計算機'
                        //text='彩虹數字學課程分為三階段：「心性解碼」用以解讀人生藍圖與特質潛能，「時間解碼」用以掌握時機及探索前世今生，「感情解碼」用以剖析人與人之間的緣份與相處模式。
                        //建議初學者循序漸進由入門系列修到核心系列；已有彩虹數字基礎者，可考慮直接修核心課程。'
                        />
                        <TextArea>


                            <Text >
                                你想知道自己的生命密碼嗎?
                            </Text>
                            <Text >
                                馬上試算，了解自己的基本個性。
                            </Text>


                        </TextArea>


                        <Tabs
                            value={value}
                            className='EventTab'
                            onChange={handleChange}
                        >
                            <Tab label='基本個性分析' />
                            <Tab label='計算五大階段數、流年' />
                            <Tab label='計算感情的緣與份' />
                            <Tab label='諮詢單下載' />
                        </Tabs>
                        {value === 0 &&
                            <TabView>
                                <CellNote
                                    text='基本個性分析：請輸入你的出生年月日時分，若不知道時跟分，可只填西元出生年月日。'
                                />
                                <TitleOnlyArea title='基本個性分析' //onClick={openHeart} 
                                />
                                <FlexFree>
                                <Calculator parent_date={date} parent_time={time} parent_name={name} parent_gender={gender} set_parent_date={setDate} set_parent_time={setTime} set_parent_name={setName} set_parent_gender={setGender}/>
                                </FlexFree>
                                <CardBottom />
                            </TabView>
                        }
                        {value === 1 &&
                            <TabView>
                                <CellNote
                                    text='計算五大階段數：請輸入你的出生年月日時分，若不知道時跟分，可只填西元出生年月日。'
                                />
                                <TitleOnlyArea title='計算五大階段數、流年' //onClick={openTime} 
                                />
                                <FlexFree>
                                <CalculatorYear parent_date={date} parent_time={time} parent_name={name} parent_gender={gender} set_parent_date={setDate} set_parent_time={setTime} set_parent_name={setName} set_parent_gender={setGender}/>
                                </FlexFree>
                                <CardBottom />

                            </TabView>
                        }
                        {value === 2 &&
                            <TabView>
                                <CellNote
                                    text='計算感情的緣與分：請輸入你與對象的出生年月日時分，若不知道時跟分，可只填西元出生年月日。'
                                />
                                <TitleOnlyArea title='感情的緣與份' onClick={openEmotion} 
                                />
                                <FlexFree>
                                <CalculatorEmo parent_date={date} parent_time={time} parent_name={name} parent_gender={gender} set_parent_date={setDate} set_parent_time={setTime} set_parent_name={setName} set_parent_gender={setGender}/>
                                </FlexFree>

                            </TabView>
                        }
                        {value === 3 &&
                            <TabView>
                                <CellNote
                                    text='學會提供諮詢師用電腦版列印諮詢單'
                                />
                                <TitleOnlyArea title='諮詢單下載' //onClick={openEmotion} 
                                />
                                <FlexFree>
                                <CalculatorForm parent_date={date} parent_time={time} parent_name={name} parent_gender={gender} set_parent_date={setDate} set_parent_time={setTime} set_parent_name={setName} set_parent_gender={setGender}/>
                                </FlexFree>

                            </TabView>
                        }
                        <Modal
                            isOpen={modalHeart}
                            onRequestClose={closeHeart}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <Close onClick={closeHeart}>
                                <img src={Images.close} alt='' />
                            </Close>
                            <EventModal1 />
                        </Modal>
                        <Modal
                            isOpen={modalTime}
                            onRequestClose={closeTime}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <Close onClick={closeTime}>
                                <img src={Images.close} alt='' />
                            </Close>
                            <EventModal2 />
                        </Modal>
                        <Modal
                            isOpen={modalEmotion}
                            onRequestClose={closeEmotion}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <Close onClick={closeEmotion}>
                                <img src={Images.close} alt='' />
                            </Close>
                            <EventModal3 />
                        </Modal>
                        <Modal
                            isOpen={modalUnit}
                            onRequestClose={closeUnit}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <Close onClick={closeUnit}>
                                <img src={Images.close} alt='' />
                            </Close>
                            <EventModal4 />
                        </Modal>
                        <Modal
                            isOpen={modalCase}
                            onRequestClose={closeCase}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <Close onClick={closeCase}>
                                <img src={Images.close} alt='' />
                            </Close>
                            <EventModal5 />
                        </Modal>
                    </Layout>
                );
            }}
        </Query>
    );
}

export default ComputePage;
