import React from 'react';
import styled from 'styled-components'
import { Colors, Metrics  } from '../../themes'

const Wrapper = styled.div`
    background: white;
    border-radius: 16px;
    box-shadow: 0px 4px 8px 0 rgba(0,0,0,0.1);
    height: min-content;
    min-height: 500px;
    overflow: hidden;
    margin-bottom: 16px;
    margin: 0 16px 16px;
    box-sizing: border-box;
    table {
        border-collapse: separate;
        border-spacing: 0;
    }
    tr {
        border-radius: 16px;
        & th:first-child{
            min-width: 90px;
        }
    }
    tr th {
        font-size: 16px;
        line-height: 22px;
        font-weight: 600;
        text-align: left;
        padding: 8px 16px;
        background: ${Colors.Light_Yellow};
    }
    th, td { 
        border: 1px solid ${Colors.B30};
        text-align: left;
        font-size: 16px;
        line-height: 22px;
    }
    tr td {
        padding: 8px 12px;
    }
    .bold {
        font-weight: 600;
    }
    tr:first-child th:first-child{
        border-top-left-radius: 10px;
    }
    .last{
        border-bottom-left-radius: 10px;
    }
    tr:first-child th:last-child{
        border-top-right-radius: 10px;
    }
    tr:last-child.last td:last-child{
        border-bottom-right-radius: 10px;
    }

    h5{
        margin: 32px 0 8px;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        text-align: center;
    }
    h5:first-child{
        margin-top: 0;
    }
    .title{
        margin-bottom: 8px;
        text-align: center;
    }
    p{
        margin: 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        white-space: pre-wrap;
    }
    .info{
        color: #9B9B9B;
        margin: 0;
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
    }
    h6{
        margin: 0;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
    }
    @media (max-width: ${Metrics.mobile}){
        min-height: 250px;
        .table{
            overflow-x: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;
            ::-webkit-scrollbar {
                display: none;
            }
        }
        tr th{
            padding: 4px 8px;
            text-align: center;
            min-width: 100px;
        }
    }
`

const TextArea = styled.div`
    padding: 16px 24px;
    @media (max-width: ${Metrics.mobile}){
        padding: 16px;
    }
`

const CardArticle = ( {children} ) => {
    return (
        <Wrapper>
            <TextArea>
                {children}
            </TextArea>
        </Wrapper>
    );
}

export default CardArticle;