import React from 'react';
import styled from 'styled-components'
import { Fonts, Colors, Images } from '../../themes'
var moment = require('moment');

const RadioOption = styled.div`
    width: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    margin-bottom: 16px;
    label{
        width: 100%;
        display: inline-block;
        padding: 12px 16px ;
        border: 1px solid ${Colors.B10};
        margin: 0;
        cursor: pointer;
        border-radius: 0.25em;
        background-color: ${Colors.W100};
        transition: 0.8s cubic-bezier(0.2,0.8,0.2,1);
        border-radius: 10px;
        position: relative;
    }
    input[type="radio"]:hover+label{
        border: 1px solid ${Colors.Blue};
        background-color: ${Colors.Blue05};
        transition: .2s;
        h5,p,h4,h3,h6{
            color: ${Colors.Blue};
        }
    }
    &.check input[type="radio"]+label,
    input[type="radio"]:checked+label{
        border: 1px solid ${Colors.Blue};
        background-color: ${Colors.Blue05};
        transition: .2s;
        h5,p,h4,h3,h6{
            color: ${Colors.Blue};
        }
    }
    .check{
        display: none;
    }
    &.check input[type="radio"]+label .no-check,
    input[type="radio"]:checked+label .no-check{
        display: none;
    }
    &.check input[type="radio"]+label .check,
    input[type="radio"]:checked+label .check{
        display: block;
    }
    .hidden{
        display: none;
    }

    input[type="radio"]:hover+label{
        border: 1px solid ${Colors.Blue};
        background-color: ${Colors.Blue05};
        transition: .2s;
        h5,p{
            color: ${Colors.Blue};
        }
    }
`

const Wrapper = styled.div`
    border-radius: 10px;
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    &:hover {
        ${'' /* box-shadow: 0px 8px 12px 0 ${Colors.B20}; */}
        transition: 0.8s cubic-bezier(0.2,0.8,0.2,1);
        cursor: pointer;
    }
    &:first-child{
        margin-top: 0;
    }
`
const TextArea = styled.div`
`
/*
const Title = styled(Fonts.h4())`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-bottom: 8px;
`*/
const Title = styled(Fonts.h4())`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin-bottom: 8px;
`
const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 75px;
    bottom: 0;
    padding: 0 0 16px;
    width: 100%;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    align-items: start;
`
const Price = styled(Fonts.h3())`
    color:${Colors.Blue};
    align-self: end;
`
const Control = styled.p`
    background: ${Colors.Light_Red};
    padding: 2px 4px;
    border-radius: 4px;
    color: ${Colors.Red};
    text-align: center;
    margin: 0;
    &.pay{
        background: ${Colors.Blue};
        color: ${Colors.W100};
    }
    &.resign{
        background: ${Colors.Light_Blue};
        color: ${Colors.Blue};
    }
    &.normal{
        background: transparent ;
        color: transparent;
    }
`
const SubTitle = styled(Fonts.h6())`
    padding: 8px 0;
`
const Text = styled(Fonts.P_16())`
    ${'' /* margin-top: 8px; */}
    color:${Colors.G3};
    white-space: pre-wrap;
    &.br{
        padding-bottom: 20px;
    }
    &:last-child{
        ${'' /* padding-bottom: 20px; */}
    }
`
const UnCheck = styled.img`
    position: absolute;
    top: 12px;
    right: 16px;
`
const CheckIcon = styled.img`
    position: absolute;
    top: 12px;
    right: 16px;
    color: ${Colors.Blue};
`
const CardTicket = (props) => {
    return (
        <RadioOption className={`radioBar ${props.className}`} onClick={props.onClick}>
            <input
                type='radio'
                name={props.name}
                id={props.id}
                value={props.value}
                className='hidden'
                onChange={props.onChange}
            />
            <label for={props.id}>
                <Wrapper>
                    <Grid>
                        <TextArea>
                            <Title>{props.title}</Title>
                            {/*<Price>{props.price}</Price>*/}
                        </TextArea>
                        <Control className={props.status} onClick={props.onClick}>{props.control}</Control>
                    </Grid>

                    <SubTitle>課程時間</SubTitle>
                    <Text className='info'>{props.content}</Text>
                    {/* {props.startTime && props.endTime 
                        ? <>
                            {moment(props.startTime).format('MM/DD') === moment(props.endTime).format('MM/DD')
                                ? <Text className='info'>上課時間：{moment(props.startTime).format('YYYY/MM/DD (dd) a H:mm')} ~ {moment(props.endTime).format('a H:mm')}</Text>
                                : <Text className='info'>上課時間：{moment(props.startTime).format('YYYY/MM/DD (dd) a H:mm')} ~ {moment(props.endTime).format('MM/DD (dd) a H:mm')}</Text>
                            }
                        </>
                        : <Text className='info'>上課時間：--</Text>
                    } */}
                    <SubTitle>課程地點</SubTitle>
                    <Text>{props.location}</Text>

                </Wrapper>
                <CheckIcon className='check' src={Images.check_circle} alt='check' />
                <UnCheck className='no-check' src={Images.radio_n} alt='no-check' />
            </label>
        </RadioOption>
    );
}

export default CardTicket;