import React from 'react';
import styled from 'styled-components'
import { Fonts, Images } from '../themes'

const Wrapper = styled.div`
    width: 1080px;
    margin: 16px auto 16px;
    display: flex;
    align-items: center;
`
const Title = styled(Fonts.h3())`
    padding-right: 10px;
`

const InfoIcon = styled.div`
    img{
        opacity: 0.5;
        transition: .2s;
    }
    &:hover img{
        opacity: 1;
        cursor: pointer;
    }
    &.no-noti{
        display: none;
    }
`
const TitleArea = (props) => {
    return (
        <Wrapper>
            <Title>{props.title}</Title>
            <InfoIcon onClick={props.onClick} className={props.noti}>
                <img src={Images.notice} alt='icon-notice' />
            </InfoIcon>
        </Wrapper>
    );
}

export default TitleArea;