import React from 'react'
import styled from 'styled-components'
import { Colors, Fonts, Images } from '../../themes'

const RadioOption = styled.div`
    width: 100%;
    margin: 0;
    position: relative;
    padding: 8px 8px 4px;
    label{
        width: 100%;
        display: inline-block;
        padding: 10px 8px;
        border: 1px solid ${Colors.B10};
        margin: 0;
        cursor: pointer;
        border-radius: 0.25em;
        // background-color: ${Colors.B10};
        transition: 0.8s cubic-bezier(0.2,0.8,0.2,1);
        border-radius: 8px;
        position: relative;
        display: flex;
        color: ${Colors.B100};
        p{
            color: ${Colors.B100}
        }
    }   

    input[type="checkbox"]:hover+label{
        transition: .2s;
        background-color: ${Colors.W30};
    }
    input[type="checkbox"]:checked+label,
    &.check input[type="checkbox"]+label{
        background-color: ${Colors.W30};
        transition: .2s;
    }
    input[type="checkbox"]+label span:before {
        content: '';
        border: 2px solid ${Colors.B10};
        border-radius: 3px;
        display: inline-block;
        width: 18px;
        height: 18px;
        margin-right: 10px;
        vertical-align: -4px;
    }
    input[type="checkbox"]:focus+label span:before,
    input[type="checkbox"]:not(:disabled)+label span:hover:before {
        background-color: ${Colors.W50};
    }
    input[type="checkbox"]:checked+label span:before {
        background: url(${Images.check});
        background-repeat: no-repeat;
        background-position: center;
        background-size: 16px;
        ${'' /* for 原藍色   */}
        border: 2px solid ${Colors.B10};
    }

    .hidden{
        display: none;
    }
    .two:nth-child(2){
        margin-top: 8px;
    }
`

const Title = styled(Fonts.P_16())`
    color: ${Colors.W100};
`


const RadioCheck = (props) => {
    return ( 
        <RadioOption className={`radioBar ${props.className}`} onClick={props.onClick}>
            <input
                type='checkbox'
                name={props.name}
                id={props.id}
                value={props.value}
                className='hidden'
                onChange={props.onChange}
            />
            
            <label for={props.id}>
                <span></span>
                <Title>{props.text}</Title>
            </label>
        </RadioOption>
    )
}
  
export default RadioCheck