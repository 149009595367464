import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Images, Colors, Fonts, Metrics } from "../themes";
import { LoveBtn, InputDateBar, CheckBar, InputBar } from ".";
import Slider from "react-slick";
import { DatePicker, Space, TimePicker } from "antd";
import { isMobileOnly, isSafari } from "react-device-detect";
import html2canvas from "html2canvas";
var moment = require("moment");
const { Solar, Lunar, HolidayUtil } = require("lunar-javascript");

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding-bottom: 80px;
  .slick-slider {
    width: 100%;
  }
  .slick-next,
  .slick-prev {
    position: relative;
    display: flex;
    top: 0;
    left: 50%;
    background: #000;
  }
`;
const Content = styled.div`
  position: relative;
  display: grid !important;
  justify-items: center;
  padding: 46px 24px 20px;
  width: 100%;
  box-sizing: border-box;
  .submit {
    margin-top: 24px;
    position: relative;
    z-index: 10000;
  }
  .checkBar {
    justify-self: center;
  }
`;
const Title = styled.p`
  font-size: ${window.innerWidth < 360 ? "22px" : "24px"};
  font-weight: bold;
  color: #4054B2;
  text-align: left;
  margin: 0 !important;
  padding-bottom: 12px;
`;
const Text = styled.p`
  color: ${Colors.B100};
  font-size: 20px;
  margin: 0;
  letter-spacing: 0;
  line-height: 28px;
  max-height: 210px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const TextC = styled.p`
    font-size: 28px;
    position: absolute;
    @media (max-width: ${Metrics.mobile}){
      font-size: 2.2vw;
    }
`
const TextG = styled.p`
    font-size: 26px;
    position: absolute;
    @media (max-width: ${Metrics.mobile}){
      font-size: 2vw;
    }
`
const TextP = styled.p`
    font-size: 22px;
    position: absolute;
    @media (max-width: ${Metrics.mobile}){
      font-size: 1.8vw;
    }
`
const BtnArea = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 0px !important;
  z-index: 1000;
  margin-bottom: 16px;
  button {
    margin: 0 4px;
  }
`;
const ContentArea = styled.div`
  position: relative;

  grid-template-columns: 50% 50%;
  grid-gap: 6px;
  width: 340px;
  box-sizing: border-box;
  padding-bottom: 24px;
  .input-area,
  .black {
    padding: 8px 6px 4px 0;
  }
  @media (max-width: ${Metrics.mobile}) {
    display: block;
    height: 100%;
  }
`;
const Calculator = (props) => {
  const [date, setDate] = useState(props.parent_date);
  const [time, setTime] = useState(props.parent_time);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [timeBak, setTimeBak] = useState("");
  const [check, setCheck] = useState(false);
  const [alert, setAlert] = useState(false);
  const [width, setWidth] = useState(window.innerWidth < 568);
  const [only_hour, setOnlyHour] = useState(false);
  const [form1, setForm1] = useState({
    username: props.parent_name||"",
    gender: props.parent_gender||"",
    luckyColor: "",
    loveStyle: "",
    keyword: "",
    special: "",
    practice: "",
    mines: "",
    date: "",
    ldate: "",
    time: "",
    year: "",
    month: "",
    day: "",
    lyear: "",
    lmonth: "",
    lday: "",
    hour: "",
    minute: "",
    forget_minute: false,
    cross_year: false,
    only_hour: false,
    add_month: false,
    name: "",
    age: "",
    BackNumbers: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "",],
    SecondBackNumbers: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "",],
    MainNumber: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "",],
    Soul: ["", "", "", "", "", "", "", "", "", ""],
    practiceNumbers: []
  });
  function calculateAll(
    date_value,
    time_value,
    only_hour_value,
    name_value,
    gender_value,
    form
  ) {
    form.date = date_value;
    form.only_hour = only_hour_value;
    form.name = name_value;
    form.gender = gender_value;
    const gregorianYear = moment(date_value).format("YYYY");
    const gregorianMonth = moment(date_value).format("MM");
    const gregorianDay = moment(date_value).format("DD");

    const date1 = new Date(date_value);
    form.year = date1.getFullYear();
    form.month = date1.getMonth() + 1; // 月份從 0 開始，所以要加 1
    form.day = date1.getDate();

    const lunarDate = Solar.fromYmd(
      gregorianYear,
      gregorianMonth,
      gregorianDay
    ).getLunar();
    form.ldate =
      lunarDate.getYear() +
      "-" +
      String(Math.abs(lunarDate.getMonth())).padStart(2, "0") +
      "-" +
      String(lunarDate.getDay()).padStart(2, "0");

    form.lyear = lunarDate.getYear();
    form.lmonth = Math.abs(lunarDate.getMonth());
    form.lday = lunarDate.getDay();
    form.add_month = lunarDate.getMonth() >= 0 ? false : true;
    form.time = time_value;
    form.hour = time_value != "" ? time_value.split(":")[0] : "";
    form.minute = only_hour_value
      ? ""
      : time_value != ""
        ? time_value.split(":")[1]
        : "";

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // 月份從 0 開始，所以要加 1
    const day = currentDate.getDate();
    const lunarDateNow = Solar.fromYmd(year, month, day).getLunar();
    const year2 = lunarDateNow.getYear();
    const month2 = Math.abs(lunarDateNow.getMonth());
    const day2 = lunarDateNow.getDay();
    let syear = "";
    let age = year - form.year;
    if (month > form.month) {
      syear = year;
    } else if (month < form.month) {
      syear = year - 1;
      age = age - 1;
    } else {
      if (day > form.day) {
        syear = year;
      } else if (day < form.day) {
        syear = year - 1;
        age = age - 1;
      } else {
        syear = year;
      }
    }
    if (form.year > form.lyear) {
      form.cross_year = true;
    } else {
      form.cross_year = false;
    }
    form.age = age;
    const sdate =
      String(syear) +
      "-" +
      String(form.month).padStart(2, "0") +
      "-" +
      String(form.day).padStart(2, "0");
    const ldate =
      String(syear - (form.cross_year ? 1 : 0)) +
      "-" +
      String(form.lmonth).padStart(2, "0") +
      "-" +
      String(form.lday).padStart(2, "0");

    const NumberList = date_value.split("").filter((i) => i !== "-");
    const NumberListLunar = form.ldate.split("").filter((i) => i !== "-");
    const NumberList1 = time_value.split("").filter((i) => i !== ":");
    const NumberList2 = NumberList.concat(NumberList1);
    const NumberList2Lunar = NumberListLunar.concat(NumberList1);
    const NumberList3 = sdate.split("").filter((i) => i !== "-");
    const NumberList3Lunar = ldate.split("").filter((i) => i !== "-");

    form.BackNumbers[0] = sum(NumberList.slice(0, 4).map(Number))
      .toString()
      .padStart(2, "0")
      .split("");
    form.BackNumbers[1] = sum(NumberList.slice(0, 6).map(Number))
      .toString()
      .padStart(2, "0")
      .split("");
    form.BackNumbers[2] = sum(NumberList.map(Number))
      .toString()
      .padStart(2, "0")
      .split("");
    form.BackNumbers[3] = sum(NumberList2.slice(0, 10).map(Number))
      .toString()
      .padStart(2, "0")
      .split("");
    form.BackNumbers[4] = sum(NumberList2.map(Number))
      .toString()
      .padStart(2, "0")
      .split("");
    form.BackNumbers[5] = sum(NumberListLunar.slice(0, 4).map(Number))
      .toString()
      .padStart(2, "0")
      .split("");
    form.BackNumbers[6] = sum(NumberListLunar.slice(0, 6).map(Number))
      .toString()
      .padStart(2, "0")
      .split("");
    form.BackNumbers[7] = sum(NumberListLunar.map(Number))
      .toString()
      .padStart(2, "0")
      .split("");
    form.BackNumbers[8] = sum(NumberList2Lunar.slice(0, 10).map(Number))
      .toString()
      .padStart(2, "0")
      .split("");
    form.BackNumbers[9] = sum(NumberList2Lunar.map(Number))
      .toString()
      .padStart(2, "0")
      .split("");
    form.BackNumbers[10] = sum(NumberList3.map(Number))
      .toString()
      .padStart(2, "0")
      .split("");
    form.BackNumbers[11] = sum(NumberList3Lunar.map(Number))
      .toString()
      .padStart(2, "0")
      .split("");

    for (let i = 0; i < 12; i++) {
      [form.SecondBackNumbers[i], form.MainNumber[i]] = calculateNumbers(
        form.BackNumbers[i],
        form.SecondBackNumbers[i],
        form.MainNumber[i]
      );
    }
    form.practiceNumbers = []
    for (const item of form.BackNumbers[3]) {
      console.log(item)
      if (!form.practiceNumbers.includes(item)) {
        form.practiceNumbers.push(item);
      }
    }
    for (const item of form.SecondBackNumbers[3]) {
      console.log(item)
      if (!form.practiceNumbers.includes(item)) {
        form.practiceNumbers.push(item);
      }
    }
    switch (parseInt(form.MainNumber[3])) {
      case 1:
        form.luckyColor = '紅色'
        form.loveStyle = '專心愛一個人'
        form.keyword = '獨立、專注、領導、\n教導、無中生有'
        form.special = '老大語氣、以我為主、\n享受孤獨、喜歡指使對方'
        form.practice = '1. 設定明確目標\n2. 累積實力經驗\n3. 挑戰整個過程'
        form.mines = '最討厭別人試圖改變或說服自己'
        break;
      case 2:
        form.luckyColor = '橘色'
        form.loveStyle = '白頭偕老、互相陪伴'
        form.keyword = '溫柔、細膩、傾聽、\n配合、依賴'
        form.special = '委婉、沉默、\n不喜被背叛、重視小細節'
        form.practice = '1. 真誠表達自己\n2. 逆向思考\n3. 接受與包容\n4. 做足事前準備'
        form.mines = '不喜歡別人太直接，\n想要保有空間跟時間'
        break;
      case 3:
        form.luckyColor = '黃色'
        form.loveStyle = '重視外表、\n喜歡新鮮感和變化'
        form.keyword = '自由、彈性、表演、\n溝通、好奇、樂觀'
        form.special = '喜歡談情說愛、\n用音樂唱歌表達、容易突發奇想'
        form.practice = '1. 學說話的藝術\n2. 客觀中性立場\n3. 發揮創意與敏銳力'
        form.mines = '最討厭被忽視，想要被看見、\n關心自己感興趣的事'
        break;
      case 4:
        form.luckyColor = '綠色'
        form.loveStyle = '重視隱私、門當戶對、\n成家立業'
        form.keyword = '穩定、務實、冷靜、\n腳踏實地、責任感'
        form.special = '邏輯思考、制定規範、\n保護自己人、給人安全感'
        form.practice = '1. 清晰決策力\n2. 具體行動力\n3. 承擔責任與結果\n4. 打破舊有習氣'
        form.mines = '最討厭被侵犯隱私，\n或被打破自己的原則與規範'
        break;
      case 5:
        form.luckyColor = '藍色'
        form.loveStyle = '喜歡自由、熱情、\n心電感應'
        form.keyword = '探索、冒險、戶外、\n自由、正義、野性、海派'
        form.special = '多情、有攻擊性、\n喜歡距離感、勇敢熱忱'
        form.practice = '1. 找到心之所向\n2. 勇敢冒險\n3. 解開心結\n4. 全力以赴'
        form.mines = '討厭別人過於堅持，\n或有強大支配欲'
        break;
      case 6:
        form.luckyColor = '靛色'
        form.loveStyle = '找尋一生真愛、照顧對方'
        form.keyword = '美、高貴、愛心、\n善解人意、熱心服務、慈悲心'
        form.special = '喜歡照顧別人、\n在乎雙贏、追求完美'
        form.practice = '1. 付出剛剛好的愛\n2. 共創彈性與雙贏\n3. 優雅謙虛'
        form.mines = '最討厭一昧索取，\n吝嗇，不願意付出的人'
        break;
      case 7:
        form.luckyColor = '紫色'
        form.loveStyle = '重視氛圍、幸福感'
        form.keyword = '直覺、哲理、心靈、\n靈感、好運氣、質疑'
        form.special = '情緒化、一定要贏、\n慷慨大方、旅遊度假伴侶'
        form.practice = '1. 放鬆 幽默 感恩\n2. 信任第六感\n3. 運用知識力量\n4. 送禮物'
        form.mines = '討厭別人講話慢條斯理、不斷重複'
        break;
      case 8:
        form.luckyColor = '金色'
        form.loveStyle = '經營感情是一種投資'
        form.keyword = '商業、洞察遠見、氣魄、\n權力、執行組織、存錢'
        form.special = '察言觀色、深思熟慮、\n重視成就感、善用錢&權、曖昧、挑逗'
        form.practice = '1. 愛錢但不貪\n2. 掌握而非控制\n3. 培養人際關係\n4. 投射正向意念'
        form.mines = '對自己的事情很在乎，\n討厭別人拿自己的成就冷嘲熱諷'
        break;
      case 9:
        form.luckyColor = '白色'
        form.loveStyle = '夢中情人、靈魂伴侶'
        form.keyword = '博愛、樂觀、同理心、\n希望、關懷、分享'
        form.special = '迷戀崇拜偶像、有智慧有深度\n、隨緣、成全他人'
        form.practice = '1. 實踐夢想\n2. 腳踏實地\n3. 修練提高智慧\n4. 有捨才有得'
        form.mines = '不喜歡過度鑽研，\n或被強迫做沒興趣的事情'
        break;
      default:
        form.luckyColor = '紅色'
        form.loveStyle = '專心愛一個人'
        form.keyword = '獨立、專注、領導、\n教導、無中生有'
        form.special = '老大語氣、以我為主、\n享受孤獨、喜歡指使對方'
        form.practice = '1. 設定明確目標\n2. 累積實力經驗\n3. 挑戰整個過程'
        form.mines = '最討厭別人試圖改變或說服自己'
        break;
    }
  }
  function calculateNumbers(BackNumbers, SecondBackNumbers, MainNumber) {
    if (sum(BackNumbers.map(Number)).toString().split("").length > 1) {
      SecondBackNumbers = sum(BackNumbers.map(Number)).toString().split("");
      MainNumber = sum(SecondBackNumbers.map(Number)).toString();
    } else {
      SecondBackNumbers = "";
      MainNumber = sum(BackNumbers.map(Number)).toString();
    }
    return [SecondBackNumbers, MainNumber];
  }
  calculateAll(date, time, only_hour, form1.name, form1.gender, form1);
  console.log(form1);
  const ResultSlider = useRef();
  const [sliderSettings] = useState({
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    speed: 300,
    cssEase: `linear`,
    adaptiveHeight: true,
    draggable: false,
    swipe: false,
  });
  const [index, setIndex] = useState(0);

  const gotoNext = () => {
    ResultSlider.current.slickNext();
    setIndex(index + 1);
  };
  const gotoPrev = () => {
    ResultSlider.current.slickPrev();
    setIndex(index - 1);
  };
  const Restart = () => {
    setImageLoaded(false);
    ResultSlider.current.slickGoTo(0);
    setIndex(0);
  };

  function onChange(e, dateString) {
    setAlert(false);
    setDate(dateString);
    if (dateString === "") {
      setDate("2000-01-01");
    }
  }
  function Submit() {
    if (date) {
      setAlert(false);
      ResultSlider.current.slickNext();
      setIndex(index + 1);
    } else {
      setAlert(true);
    }
  }

  function sum(arr) {
    var len = arr.length;
    if (len === 0) {
      return 0;
    } else if (len === 1) {
      return arr[0];
    } else {
      return arr[0] + sum(arr.slice(1));
    }
  }

  const handleDownload = () => {
    setImageLoaded(false);
    const element = document.getElementById("rainbowimg");
    html2canvas(element).then((canvas) => {
      const link = document.createElement("a");
      link.download = "戀愛特質速測表.png";
      link.href = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      link.click();
      setImageLoaded(true);
    });
  };

  function secondPractice(n) {
    switch (parseInt(n)) {
      case 0:
        return "【 0 】的鍛鍊：\n1. 警覺性的思維、2. 集中力量、3. 把握機緣、4. 擁抱自然"
      case 1:
        return "【 1 】的鍛鍊：\n1. 設定明確目標、2. 累積實力經驗、3. 挑戰整個過程"
      case 2:
        return "【 2 】的鍛鍊：\n1. 真誠表達自己、2. 逆向思考、3. 接受與包容、4. 做足事前準備"
      case 3:
        return "【 3 】的鍛鍊：\n1. 學說話的藝術、2. 客觀中性立場、3. 發揮創意與敏銳力"
      case 4:
        return "【 4 】的鍛鍊：\n1. 清晰決策力、2. 具體行動力、3.承擔責任與結果、4. 打破舊有習氣"
      case 5:
        return "【 5 】的鍛鍊：\n1. 找到心之所向、2. 勇敢冒險、3. 解開心結、4. 全力以赴"
      case 6:
        return "【 6 】的鍛鍊：\n1. 付出剛剛好的愛、2. 共創彈性與雙贏、3. 優雅謙虛"
      case 7:
        return "【 7 】的鍛鍊：\n1. 放鬆 幽默 感恩、2. 信任第六感、3. 運用知識力量、4. 送禮物"
      case 8:
        return "【 8 】的鍛鍊：\n1. 愛錢但不貪、2. 掌握而非控制、3. 培養人際關係、4. 投射正向意念"
      case 9:
        return "【 9 】的鍛鍊：\n1. 實踐夢想、2. 腳踏實地、3. 修練提高智慧、4. 有捨才有得"
      default:
        return "【 1 】的鍛鍊：\n1. 設定明確目標、2. 累積實力經驗、3. 挑戰整個過程"
    }
  }

  const handleTimeChange = (t) => {
    let hours = t.hours().toString().padStart(2, "0");
    let minutes = t.minutes().toString().padStart(2, "0");
    setTime(`${hours}:${minutes}`);
  };
  const handleTimeBlur = (t) => {
    // 在失去焦點時更新時間狀態
    setTime(t.target.value);
  };
  const handleCheckMinute = (e) => {
    setOnlyHour(!only_hour);
  };
  const handleCheck = () => {
    setCheck(!check);
    if (!check) {
      setTimeBak(time);
      setTime("");
    } else {
      setTime(timeBak);
    }
  };
  const handleChange = (e) => {
    if (e.target.name !== "adviceDate") {
      e.preventDefault();
      e.stopPropagation();
    }
    setForm1({
      ...form1,
      [e.target.name]: e.target.value,
    });
  };
  function PreloadImage(props) {
    const { src, alt } = props;

    useEffect(() => {
      const image = new Image();
      image.src = src;

      image.onload = () => {
        setImageLoaded(true);
      };

      return () => {
        image.onload = null;
      };
    }, [src]);

    return (
      <img src={imageLoaded ? src : Images.loading} alt={alt} style={{ width: imageLoaded ? "100%" : '' }} />
    );
  }
  const Loading = () => {

    return (
      <div className="page-loading">
        <script src="https://cdn.lordicon.com/bhenfmcm.js"></script>
        <div className="-load">
          <div className="-icon">
            <lord-icon src="https://cdn.lordicon.com/xjovhxra.json" trigger="loop" delay="300" colors="primary:#ffffff,secondary:#ffffff" style={{ width: '60px', height: '60px' }}></lord-icon>
          </div>
          <div className="-font">
            <span>l</span>
            <span>o</span>
            <span>a</span>
            <span>d</span>
            <span>i</span>
            <span>n</span>
            <span>g</span>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Wrapper>
      <Slider {...sliderSettings} ref={ResultSlider}>
        <Content>
          <Title>戀愛特質速測</Title>
          <form id="form">
            <ContentArea>
              <InputBar inputTitle="姓名" className="top">
                <input
                  id="username"
                  name="username"
                  type="text"
                  placeholder="請輸入姓名"
                  maxLength={30}
                  value={form1.username}
                  onChange={(e) => handleChange(e)}
                />
              </InputBar>
              <InputBar inputTitle="性別" className="top">
                <input
                  id="gender"
                  name="gender"
                  type="text"
                  placeholder="請輸入性別"
                  maxLength={2}
                  value={form1.gender}
                  onChange={(e) => handleChange(e)}
                />
              </InputBar>
            </ContentArea>
          </form>
          <InputDateBar
            inputTitle="生日"
            className="top"
          >
            <Space direction="vertical">
              <DatePicker
                className={alert ? "alert" : ""}
                placeholder={date}
                onChange={onChange}
                require
              />
            </Space>
          </InputDateBar>
        </Content>
        {index === 1 && (
          <Content id={"rainbowimg"} >
            <>
              {!imageLoaded && <Loading />}
              <PreloadImage
                style={{ width: "100%" }}
                src={Images.white}
                alt="Image"
              />
              {imageLoaded && (
                <div>
                  <TextC
                    style={width?{ top: "21.6%", left: "29%" }:{top: "17.5%", left: "27%",}}
                  >
                    {form1.username}
                  </TextC>
                  <TextC
                    style={width?{ top: "21.6%", left: "52%" }:{top: "17.5%", left: "52%",}}
                  >
                    {form1.gender}
                  </TextC>
                  <TextC
                    style={width?{ top: "21.6%", left: "67%" }:{top: "17.5%", left: "67%",}}
                  >
                    {form1.date}
                  </TextC>
                  <TextC
                    style={width?{ fontWeight: "bold", top: "25.6%", left: "72%" }:{fontWeight: "bold",top: "22%", left: "74%",}}
                  >
                    {form1.MainNumber[3]}
                  </TextC>
                  <TextC
                    style={width?{ top: "31.1%", left: "35%" }:{top: "27.8%", left: "34%",}}
                  >
                    {form1.luckyColor}
                  </TextC>
                  <TextC
                    style={width?{ top: "30.1%", left: "63.5%" }:{top: "27.8%", left: "64%",}}
                  >
                    {form1.loveStyle.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </TextC>
                  <TextC
                    style={width?{ top: "42.1%", left: "23.5%" }:{top: "40.8%", left: "20%",}}
                  >
                    {form1.keyword.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </TextC>
                  <TextC
                    style={width?{ top: "42.1%", left: "54.5%" }:{top: "40.8%", left: "55%",}}
                  >
                    {form1.special.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </TextC>
                  <TextP
                    style={width?{ top: "55.8%", left: "23.5%" }:{top: "55.8%", left: "20%",}}
                  >
                    {form1.practice.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </TextP>
                  <TextG
                    style={width?{ top: "57.8%", left: "54.5%" }:{top: "56.8%", left: "54%",}}
                  >
                    {form1.mines.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </TextG>  
                  <TextC
                    style={width?{ top: "67.1%", left: "66%" }:{top: "66.8%", left: "67%",}}
                  >
                    {form1.practiceNumbers.map((number, index) => (
                      (index == 0 ? "" : "、") + number
                    ))}
                  </TextC>                
                  <TextG
                    style={width?{ top: "72.2%", left: "14.6%" }:{top: "70.8%", left: "13%",}}
                  >
                    {form1.practiceNumbers.map((number, index1) => (
                      <div key={index1}>
                        {secondPractice(number).split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                      </div>
                    ))}
                  </TextG>  
                </div>
              )}
            </>
          </Content>
        )}
      </Slider>
      {index === 0 && (
        <BtnArea>
          <LoveBtn
            className={date ? "submit solid lg" : "solid submit disable lg"}
            btnName="產生速測表"
            onClick={() => Submit()}
          />
        </BtnArea>
      )}
      {index === 1 && (
        <BtnArea>
          <LoveBtn
            className="prev solid lg"
            btnName="下載速測表"
            onClick={() => handleDownload()}
          />
          <LoveBtn
            className="solid lg"
            btnName="回上一頁"
            onClick={Restart}
          />
        </BtnArea>
      )}
    </Wrapper>
  );
};

export default Calculator;
