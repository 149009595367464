import React from 'react';
import styled from 'styled-components'
import { Colors, Fonts, Metrics } from '../../themes'


const Wrapper = styled.div`
    box-sizing: border-box;
    border: 1px solid ${Colors.B10};
    background-color: ${Colors.W100};
    border-radius: 16px;
    box-shadow: 0px 4px 8px 0 ${Colors.B10};
    position: relative;
    overflow: hidden;
    &:hover{
        box-shadow: 0px 8px 12px 0 ${Colors.B20};
        transition: 0.2s cubic-bezier(0.2,0.8,0.2,1);
     }
    &:hover .link{
    opacity: 1;
    transition: 0.2s cubic-bezier(0.2,0.8,0.2,1);
    }

    &.card .textArea{
        padding-bottom: 180px;
    }

    @media (max-width: ${Metrics.tablet}){
        width: 100%;
        margin: 0 auto; 
        min-height: 200px;
    }

     @media (max-width: ${Metrics.mobile}){
        width: 100%;
        min-height: 340px;
    }
`
const TextArea = styled.div`
    position: relative;
    padding: 24px;
    z-index: 1;
    padding-bottom: 240px;
    @media (max-width: ${Metrics.tablet}){
        max-width: 60%;
    }

    @media (max-width: ${Metrics.mobile}){
        max-width: 100%;
    }
`

const Title = styled(Fonts.h3())`

`

const Subtitle = styled(Fonts.h6())`
    margin: 8px 0;
    color: ${Colors.G3};
`

const Text = styled(Fonts.P_16())`
    color: ${Colors.G3};
`

const TextLink = styled(Fonts.h5())`
    color: ${Colors.Blue};
    margin-top: 8px;
    opacity: 0;
    transition: 0.4s cubic-bezier(0.2,0.8,0.2,1);
    &:hover{
        cursor: pointer;
    }
`

const Img = styled.img`
    right: 0;
    bottom: 0;
    position: absolute;
    @media (max-width: ${Metrics.tablet}){
        max-width: 60%;
    }
`


const Card = (props) => {
    return (
        <Wrapper className={props.className}>
            <TextArea className='textArea'>
                <Title>{props.title}</Title>
                <Subtitle>{props.subtitle}</Subtitle>
                {props.text.split('\\n').map((item,index)=> (
                    <Text>{item === '' ? <br/> : item}</Text>
                ))}
                {props.onClick &&
                    <div>
                    <TextLink className='link' onClick={props.onClick}>
                        {props.linkText}
                    </TextLink>
                    </div>
                }
            </TextArea>
            <Img src={props.img} alt=''/>
        </Wrapper>
    );
}

export default Card;