import React from 'react';
import styled from 'styled-components'
import { Fonts, Colors, Styles, Images } from '../../themes'
import Sticky from 'react-stickynode';
import Cookies from 'js-cookie';

const Wrapper = styled.div`
    background: white;
    border-radius: 16px;
    box-shadow: 0px 4px 8px 0 ${Colors.B10};
    width: 350px;
    ${'' /* max-height: 220px; */}
    transition: .4s;
    top: 116px !important;
    ${'' /* &:hover {
        box-shadow: 0px 8px 12px 0 ${Colors.B20};
        transition: 0.8s cubic-bezier(0.2,0.8,0.2,1);
    } */}
    &.hasImg{
        max-height: 1280px;
    }
    &.hasImg img{
        max-height: 500px;
        padding-bottom: 20px;
    }
    &.forBottom{
        align-self: end;
    }
`
const Img = styled.img`
    width: 100%;
    max-height: 0;
    border-radius: 16px;
`
const TextArea = styled.div`
    padding: 0px 24px 16px;
`

const Title = styled(Fonts.h4())`
 
`
const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 55px;
    padding: 10px 0;
    align-items: start;
`
// const Price = styled(Fonts.h4())`
//     color:${Colors.Blue};
// `
const Required = styled.p`
    background: ${Colors.Light_Red};
    padding: 2px 4px;
    border-radius: 4px;
    color: ${Colors.Red};
    text-align: center;
    margin: 0;
`
const Notice = styled.div`
    margin-top: 16px;
    position: relative;
    display: grid;
    grid-template-columns: 36px auto;
    padding: 16px 20px 16px 16px;
    border: 1px solid ${Colors.Yellow};
    background: ${Colors.Light_Yellow};
    border-radius: 8px;
    li{
        font-size: 16px;
        color: ${Colors.B100};
        font-weight: 500;
        list-style: none;
    }
    li:hover{
        cursor: pointer;
    }
    li.disable{
        color: ${Colors.B100} !important;
        font-weight: 400;
    }
    li.disable:hover{
        cursor: default;
        pointer-events: none;
    }
`
const Icon = styled.img`
    filter: invert(0.4) sepia(1) saturate(17.4) hue-rotate(0deg) brightness(0.96);
`
// const InfoArea = styled.div`
//     padding: 12px 0;
//     border-top: 1px solid ${Colors.B10};
// `
// const InfoName = styled(Fonts.h6())`
// `
// const Text = styled(Fonts.P_16())`
// `
const Button = styled(Styles.btnL())`
    margin-top: 16px;
`

const CardEventSignUp = (props) => {
    const [bottom, setBottom] = React.useState(false)
    const height = props.height + 100

    React.useEffect(() => {
        if (window.pageYOffset > 0) {
            setBottom(true)
            // console.log(height)
        } else {
            setBottom(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Sticky enabled={true} top={116} bottomBoundary={height} activeClass='sticky' releasedClass='noSticky'>
            <Wrapper className={`${props.className} ${bottom ? 'forBottom' : ''}`} >
                <Img src={props.img} alt='' className='img' />
                <TextArea>
                    {props.required === '必修'
                        ? <>
                            <Grid>
                                <Title>{props.code}</Title>
                                {props.required === '必修' &&
                                    <Required>{props.required}</Required>
                                }
                            </Grid>
                            <Title>{props.title}</Title>
                        </>
                        : <>
                            <Title>{props.code}</Title>
                            <Title>{props.title}</Title>
                        </>
                    }
                    {/* {props.pack &&
                            <>
                            {props.data.map( (i,index) => (
                                <InfoArea>
                                    <InfoName>{i.title}</InfoName>
                                    <Text className='info'>上課時間：{i.time}</Text>
                                </InfoArea>
                            ))}
                            </>
                        } */}

                    {props.already
                        ? <Button className={`solid full-width disable`} >已付款</Button>
                        : <>
                            {!Cookies.get('rainbow')
                                ? <Button className={`solid full-width ${props.btnClass}`} onClick={props.Login}>登入以查看課程資訊</Button>
                                : <Button className={`solid full-width ${props.btnClass}`} onClick={props.onClick}>查看課程資訊</Button>
                            }
                        </>
                    }
                </TextArea>
            </Wrapper>
            {!props.already && props.noti &&
                <Notice>
                    <Icon src={Images.notice} alt='info-icon' />
                    <div>
                        <li>報名須知</li>
                        {props.noti.split('．').map((item, index) => (
                            <>
                                {!(item === '' && index == 0) &&      
                                    <li key={`title-${index}`}>{item === '' ? <br /> : `．${item}`}</li>
                                }
                            </>
                        ))}
                    </div>
                </Notice>
            }
        </Sticky>
    );
}

export default CardEventSignUp;