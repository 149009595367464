import React from 'react'
import styled from 'styled-components'
import { Images, Colors } from '../../themes'

const CheckDiv = styled.label`
    padding: 8px 4px 4px;
    position: relative;
    pointer-events: none;
    z-index: 10000;
    input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        z-index: -1;
        z-index: 10000;
    }
    input[type="checkbox"]+span {
        cursor: pointer;
        font: 16px sans-serif,"微軟正黑體","Helvetica Neue","Noto Sans TC";
        color: black;
        pointer-events: auto;
        color: ${Colors.W100};
    }
    &.checkBar input[type="checkbox"]+span {
        color: ${Colors.B100};
    }
    &.checkBar.white input[type="checkbox"]+span {
        color: ${Colors.W100};
    }
    input[type="checkbox"]+span:before {
        content: '';
        border: 2px solid #808181;
        border-radius: 3px;
        display: inline-block;
        width: 18px;
        height: 18px;
        margin-right: 10px;
        vertical-align: -4px;
    }
    input[type="checkbox"]:focus+span:before,
    input[type="checkbox"]:not(:disabled)+span:hover:before {
        background-color: #ddd;
    }
    input[type="checkbox"]:checked+span:before {
        background: url(${Images.check});
        background-repeat: no-repeat;
        background-position: center;
        background-size: 16px;
        filter: invert(1);
        ${'' /* for 原藍色   */}
        border-color: #ddd ;
        background-color: transparent;
    }
    &.blue input[type="checkbox"]:checked+span:before {
        border-color: ${Colors.Yellow};
        background-color: ${Colors.Yellow};
    }
    &.blue input[type="checkbox"]:checked+span:hover:before {
        background-color: ${Colors.Yellow};
        border-color: ${Colors.Yellow};
    }
    /* Disabled checkbox styles */
    input[type="checkbox"]:disabled+span {
    }
    .hover-bg{
        position: absolute;
        top: -1px;
        left: -6px;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        z-index: -1;
        transition: 0.8s cubic-bezier(0.2,0.8,0.2,1);
    }
    input[type="checkbox"]+span.bold{
        font-weight: 600;
    }
`

const CheckBar = ( props ) => {
    const { children } = props
    return ( 
        <CheckDiv className={`checkBar ${props.className}`}>
            { children }
        </CheckDiv>
    )
  }
  
  export default CheckBar